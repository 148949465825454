import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import conf from '../src/assets/conf/conf.json'

Vue.use(VueSweetalert2);


import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// console.log('Starting App')
// console.log(process.env.VUE_APP_STREAMING_SERVER)
// console.log('Test : ', conf.videoServer);
Vue.use(conf);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
