<template>
<!-- <div>
  SSSSSSSSSSSSSSSSSSSSSSSSSS
</div> -->
  <div class="slide-bar">
    <div class="slide-bar-menu">
      <ul>
        <!-- <li>
          <router-link href="" to="/dashboard" class="active"
            ><span class="las la-chart-pie"></span> </router-link
          >
        </li>
        <li>
          <router-link href="" to="/operation">
            <span class="lab la-telegram-plane"></span></router-link
          >
        </li>
        <li>
          <router-link href="" to="/cctv">
            <span class="las la-video"></span></router-link
          >
        </li> -->
        <li>
          <router-link href="" to="/"
            ><span class="las la-home"></span></router-link
          >
        </li>
        <li>
          <router-link href="" to="/planner2"
            ><span class="las la-calendar-week"></span></router-link
          >
        </li>
        <li>
          <router-link href="" to="/task"
            ><span class="las la-list"></span> </router-link
          >
        </li>
        <!-- <li>
          <router-link href="" to="/mission">
            <span class="las la-route"></span>
          </router-link>
        </li>
        <li>
          <router-link href="" to="/site">
            <span class="las la-map-marked-alt"></span>
          </router-link>
        </li>
        <li>
          <router-link href="" to="/asset">
            <span class="las la-sitemap"></span>
          </router-link>
        </li> -->
        <!-- <li>
          <router-link href="" to="/asset">
            <span class="las la-bell"></span><b-badge pill variant="danger">3</b-badge>
          </router-link>
        </li> -->
        <li>
          <router-link href="" to="/logout">
            <span><b-icon icon="key" aria-hidden="true"></b-icon></span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Nav-Bar",
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style scoped>
.slide-bar {
  /* transition: width 300ms; */
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  /* padding-top: 1rem; */
  /* height: 80px; */
  background: var(--main-color);
  z-index: 999;
  text-align: center;
}
.slide-bar-menu {
  /* margin-bottom: 0; */
}
/* .slide-bar-brand span {
  display: inline-block;
  transition: display 300ms;
  margin-left: 1rem;
  margin-top: 2rem;
}
.slide-bar-brand span {
  display: inline-block;
  transition: display 300ms;
  margin-left: 1rem;
  margin-top: 2rem;
} */
/* .slide-bar-brand {
  height: 90px;
  padding-left: 1rem 0rem 1rem 2rem;
  color: #fff;
  padding-left: 1rem;
  transition: display 300ms;
}
.slide-bar-brand span {
  display: inline-block;
  transition: display 300ms;
  margin-left: 1rem;
  margin-top: 2rem;
}*/
.slide-bar-menu li {
  display: inline-block;
  width: 25%;
  /* background: brown; */
  /* padding: auto; */
  /* margin: auto; */
  /* margin-bottom: 1.7rem; */
  /* padding-left: 1rem; */
}
.slide-bar-menu a {
  /* transition: padding 300ms; */
  /* padding-left: 1rem; */
  /* padding-top: 1rem; */
  /* display: inline; */
  width: 100%;
  height: 100%;
  color: #fff;
  /* background: #000; */
  font-size: 1.1rem;
}
/* .slide-bar-menu a.active {
  background: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--main-color);
  border-radius: 30px 0px 0px 30px;
} */
.slide-bar-menu a.router-link-exact-active {
  /* color: #42b983; */
  /* background: #fff; */
  /* padding-top: 1rem; */
  /* padding-left: 1rem; */
  /* padding-bottom: 1rem; */
  /* color: var(--main-color); */
  /* width: 100%;   */
  /* border-radius: 30px 0px 0px 30px; */
}

.slide-bar-menu a span:first-child {
  font-size: 2rem;
  padding-right: 1rem;
}

/* #nav-toggle:checked + .slide-bar {
  width: 80px;
}
#nav-toggle:checked + .slide-bar .slide-bar-brand,
#nav-toggle:checked + .slide-bar li {
  padding-left: 1rem;
  text-align: center;
}

#nav-toggle:checked + .slide-bar li a {
  padding-left: 0rem;
}

#nav-toggle:checked + .slide-bar .slide-bar-brand h2 span:last-child,
#nav-toggle:checked + .slide-bar li a span:last-child {
  display: none;
} */

@media only screen and (max-height: 780px) {
  /* .clock {
    display: none;
  } */
}
</style>
