<template>
  <div class="login-container">
    <img src="@/assets/HORRUS.png" class="logo-img" />
    <form v-on:submit.prevent="authen">
      <label for="username"><b>Username</b></label>
      <input
        v-model="form.uname"
        type="text"
        placeholder="Enter Username"
        name="username"
        required
      />

      <label for="password"><b>Password</b></label>
      <input
        v-model="form.pass"
        type="password"
        placeholder="Enter Password"
        name="password"
        required
      />

      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import swal from "sweetalert2";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      form: {
        uname: null,
        pass: null,
        encryptedPass: null,
      },
      errorAuthen: true,
      apiServer: this.$store.state.apiDataServe,
      webAPI : this.$store.state.webAPI,
      apiKey : this.$store.state.apiKey,
    };
  },
  methods: {
    authz() {
        let currentObj = this;
      this.encryptText();
      console.log(this.form.encryptedPass);
      currentObj.$store.commit("setMntLoginState", true);
    },

    encryptText() {
      const encrypted = CryptoJS.AES.encrypt(
        this.form.pass,
        this.$store.state.appName
      ).toString();
      let currentObj = this;
      currentObj.form.encryptedPass = encrypted;
    },

    
    async authen() {
      let currentObj = this;
      currentObj.errorAuthen = true;
      await this.axios
        .post(this.webAPI + "/authorization", {
          user: this.form.uname,
          key: this.form.pass,
        }) 
        .then((response) => {
          console.log("res_code : ", response);
          if (response.status == 201) {
            sessionStorage.setItem("setMntLoginState", true);
            sessionStorage.setItem("setAPIKey", response.data.apiKey);
            sessionStorage.setItem("setUser", response.data.username);
            sessionStorage.setItem("setcustomerID", response.data.cid);
            currentObj.$store.commit("setAPIKey", response.data.apiKey);
            currentObj.$store.commit("setMntLoginState", true);
            currentObj.$store.commit("setUser", response.data.username);
            currentObj.$store.commit("setcustomerID", response.data.cid);
            sessionStorage.setItem("setSessionEndURL", '/#/mnt');
            currentObj.$store.commit("setSessionEndURL", '/#/mnt');
            this.$store.commit("setUserLevel", response.data.accessLevel);
            sessionStorage.setItem("setUserLevel", response.data.accessLevel);
            // this.$router.push('/mnt/battery')
            // currentObj.$store.commit("setOwner", response.data.company);
            // window.location.href = "/#/";
          } else if (response.status == 404) {
            swal
              .fire({
                icon: "error",
                title: "API Server Connection Failed!",
                text: " Cannot access to API Server, Please contact system administrator.",
                showCancelButton: false,
                confirmButtonText: `Ok`,
              })
              
              .then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "/#/";
                }
                // else{
                //   window.location.href = "/#/";
                // }
              })
              .catch(e => console.error(e));
          }else {
            currentObj.errorAuthen = false;
          }
        })
        .catch(function(error) {
          // currentObj.output2 = error;
          // console.log("Request Post Error!!!!!!!!!!!!!!!!!!!!!!!!");
          console.warn(error);
          currentObj.errorAuthen = false;
        });
      // this.caltime();
    },
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.logo-img {
  margin-top: -80px;
  margin-bottom: 20px;
  width: 250px;
  text-align: center;
  display: block;
}
.login-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 30vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
input[type="text"],
input[type="password"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 5px;
}
button {
  background-color: #1760ff;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}
button:hover {
  opacity: 0.8;
}
</style>