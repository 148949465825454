<template>
  <div class="body-outer" :class="{ sos: item.type.toLowerCase() === 'sos' }">
    <!-- <t> titleppp = {{ title }}, asd </t> -->
    <img
        class="x-icon-action"
        width="18px"
        src="/imgs/v3/icons/icon_x.svg"
        alt=""
        @click="$emit('callbackAlert')"
      />
    <div class="body-inner">
      <img
        width="80%"
        class="img-alert"
        :src="'data:image/jpeg;base64,' + item.image"
        alt=""
      />
      <div class="contain-data">
        <div class="header-layout ">
        <div :class="types[item.type.toLowerCase()].iconClass">
          <img
            class="icon-img"
            :src="types[item.type.toLowerCase()].icon"
            alt=""
          />
        </div>
          <h2 class="header-name">{{ item.command }}</h2>
        </div>
        <div class="data-container">
          <div class="data-container-info">
            
            <p class="container-data">
              Date : {{ displayDate(parseFloat(item.time)) }}
            </p>
            <p class="container-data">
              {{ item.from }} on [{{ item.latitude }}, {{ item.longitude }}]
            </p>
            <p class="container-data">
              {{ item.reason }}
            </p>
          </div>
        </div>
        <div class="map" ref="map"></div>
      </div>

      
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    index: Number,
  },
  data() {
    return {
      types: {
        warning: {
          imgLogo: "/imgs/v3/icons/Ellipse.svg",
          icon: "/imgs/v3/icons/warning_icon.svg",
          iconClass: "icon-container",
        },
        sos: {
          imgLogo: "/imgs/v3/icons/Ellipse.svg",
          icon: "/imgs/v3/icons/sos_icon.png",
          iconClass: "icon-container-sos",
        },
      },
    };
  },
  mounted(){
    var mapStyles = [
      {
        featureType: "all",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ];
    this.map = new window.google.maps.Map(this.$refs["map"], {
      mapTypeId: "hybrid",
      center: { lat: parseFloat(this.item.latitude), lng: parseFloat(this.item.longitude) },
      zoom: 20,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      styles: mapStyles,
    });
    var image = {
        // url: require("../../"+"/imgs/v3/icons/marker-icon-iot-error.png"),
        url: require('../../../assets/markers/marker-icon-iot-error.png'),
        // url: require("../assets/drone2.png"),
        //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new window.google.maps.Size(60, 60),
        // The origin for this image is (0, 0).
        origin: new window.google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(0, 30),
      };
      let currentObj = this;
      currentObj.item.marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          this.item.latitude,
          this.item.longitude
        ),
        icon: image,
        title: this.item.command, //"DBX Station" + index.toString(),
        map: currentObj.map,
        draggable: false,
      });
  },
  methods: {
    displayDate(time) {
      var date = new Date(time);
      return date.toDateString() + " | " + date.toLocaleTimeString();
    },
  },
};
</script>

<style scoped>

.map {
  width: 100%;
  height: 250px;
}
.x-icon-action {
  position: absolute;
  top: 10px;
  right: 10px;
}
.x-icon-action:hover {
  cursor: pointer;
  background: #ff0000;
}
.img-alert {
  /* text-shadow: 2px 2px; */
}
.img-alert:hover {
  text-shadow: 2px 2px;
  cursor: pointer;
}
.header {
  display: flex;
  width: 290px;
  margin-left: 100px;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-primary-50, #ececef);
  background: var(--opacity-gray-secondary-90080, rgba(13, 13, 13, 0.8));
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
}
.body-outer {
  display: inline-flex;
  padding: 16px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  border-radius: 20px;
  border: 1px solid var(--gray-primary-900, #1f2937);
  background: var(--opacity-gray-secondary-90080, rgba(13, 13, 13, 0.8));
  backdrop-filter: blur(10px);

  /* color: var(--warning-500, #ffbf5f); */
  width: 90vw;
  /* height: 90vh; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sos {
  color: var(--warning-500, #ff0000);
}

.body-inner {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.icon-container {
  padding: auto;
  text-align: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  background: var(--opacity-warning-50020, rgba(255, 191, 95, 0.2));
}
.icon-container-sos {
  padding: auto;
  text-align: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  background: var(--opacity-warning-50020, rgba(255, 95, 95, 0.2));
}

.icon-img {
  margin: auto;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.icon-x {
  width: 18px;
  height: 18px;
}
.data-container-info {
  display: flex;
  width: 224px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
}
.data-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: var(--warning-500, #ffbf5f);
}.header-layout {
  /* ENG/Subtitle1 */
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  color: var(--warning-500, #ffbf5f);
}
.header-name {
  /* ENG/Subtitle1 */
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  width: 240px;
  height: 22px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.container-data {
  /* color: #FFBF5F; */
  /* ENG/Body3 */
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.contain-data{
  padding-top: 20px;
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
  display: flex;
  width: 20%;
  flex-direction: column;
}
</style>
