<template>
  <div
    class="slide-bar"
    :class="{ 'slide-bar-full': displayMenuName }"
    @mouseover="displayMenuName = true"
    @mouseleave="
      displayMenuName = false;
      displayUserMenu = false;
    "
  >
    <div class="user-menu" v-if="displayUserMenu && displayMenuName">
      <ul>
        <li disabled class="user-menu-item">User Information</li>
        <li disabled class="user-menu-item">Change Password</li>
        <li class="user-menu-item" @click="confirmation">Logout</li>
      </ul>
    </div>
    <div class="Nav-container" :class="{ 'slide-bar-full': displayMenuName }">
      <div class="reset-style Nav-Org-layout">
        <div class="Nav-Org-logo">
          <img width="48" height="48" src="../../assets/v3/imgs/Org_logo.png" />
        </div>
        <div v-if="displayMenuName" class="Nav-Org-text">
          {{ organizationName }}
        </div>
      </div>
      <div class="Nav-Bar">
        <ul>
          <li @click="$bvModal.show('Notifications-modal')">
            <div
              class="Nav-item"
              :class="{ 'user-info-full': displayMenuName }"
            >
              <i class="las la-bell"></i>
              <div v-if="displayMenuName" class="Nav-Menu-text">
                Notifications
              </div>
              <b-badge
                pill
                variant="danger"
                v-if="notifyCount > 0"
                class="badge-notify"
                >{{ notifyCount }}</b-badge
              >
            </div>
          </li>
          <li>
            <router-link href="" to="/mnt/asset"
              ><div
                class="Nav-item"
                :class="{ 'user-info-full': displayMenuName }"
              >
                <i class="las la-robot"></i>
                <div v-if="displayMenuName" class="Nav-Menu-text">
                  Assets Management
                </div>
              </div></router-link
            >
          </li>
          <li>
            <router-link href="" to="/mnt/streaming"
              ><div
                class="Nav-item"
                :class="{ 'user-info-full': displayMenuName }"
              >
                <i class="las la-video"></i>
                <div v-if="displayMenuName" class="Nav-Menu-text">
                  Streaming Management
                </div>
              </div></router-link
            >
          </li>
          <li>
            <router-link href="" to="/mnt/battery">
              <div
                class="Nav-item"
                :class="{ 'user-info-full': displayMenuName }"
              >
                <i class="las la-battery-half"></i>
                <div v-if="displayMenuName" class="Nav-Menu-text">
                  Battery Management
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link href="" to="/mnt/organization">
              <div
                class="Nav-item"
                :class="{ 'user-info-full': displayMenuName }"
              >
                <i class="las la-building"></i>
                <div v-if="displayMenuName" class="Nav-Menu-text">
                  Organization Management
                </div>
              </div></router-link
            >
          </li>
          <li>
            <router-link href="" to="/mnt/users">
              <div
                class="Nav-item"
                :class="{ 'user-info-full': displayMenuName }"
              >
                <i class="las la-user-alt"></i>
                <div v-if="displayMenuName" class="Nav-Menu-text">
                  User Management
                </div>
              </div></router-link
            >
          </li>
          <li>
            <router-link href="" to="/mnt/logs"
              ><div
                class="Nav-item"
                :class="{ 'user-info-full': displayMenuName }"
              >
                <i class="las la-comment"></i>
                <div v-if="displayMenuName" class="Nav-Menu-text">
                  Events and Logs
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="2"
          viewBox="0 0 218 2"
          fill="none"
          class="spacer-div1"
        >
          <path
            d="M1 1H217"
            stroke="#fff"
            class="spacer-div1"
            stroke-linecap="round"
          />
        </svg>
        <div class="fleet-menu"></div>
        <div class="spacer2"></div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="5"
          viewBox="0 0 218 2"
          fill="none"
          class="spacer-div1"
        >
          <path
            d="M1 1H217"
            stroke="#fff"
            class="spacer-div1"
            stroke-linecap="round"
          />
        </svg>
        <div class="user-info" :class="{ 'user-info-full': displayMenuName }">
          <!-- <img class="user-picture" src="@/assets/v3/imgs/person.png" /> -->
          <i class="las la-user-circle" style="font-size: 48px"></i>
          <div v-if="displayMenuName" class="user-data">
            <strong class="user-name-text">{{ userName }}</strong>
            <small class="user-title-text">{{ userTitle }}</small>
          </div>
          <div
            class="more-btn"
            v-if="displayMenuName"
            @click="displayUserMenu = !displayUserMenu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14.5126 4.25556C14.5127 4.55186 14.4544 4.84528 14.3411 5.11906C14.2278 5.39284 14.0617 5.64163 13.8522 5.85121C13.6428 6.06079 13.3941 6.22707 13.1204 6.34055C12.8467 6.45403 12.5533 6.51248 12.257 6.51258C11.9607 6.51267 11.6673 6.4544 11.3935 6.3411C11.1197 6.2278 10.8709 6.06169 10.6614 5.85224C10.4518 5.64279 10.2855 5.39411 10.172 5.1204C10.0586 4.84669 10.0001 4.55331 10 4.25701C9.99981 3.65861 10.2373 3.08464 10.6603 2.66136C11.0833 2.23809 11.6572 2.00019 12.2556 2C12.854 1.99981 13.4279 2.23734 13.8512 2.66034C14.2745 3.08334 14.5124 3.65716 14.5126 4.25556Z"
                fill="#757C87"
              />
              <path
                d="M12.2565 14.2548C13.5023 14.2548 14.5121 13.2449 14.5121 11.9992C14.5121 10.7535 13.5023 9.74365 12.2565 9.74365C11.0108 9.74365 10.001 10.7535 10.001 11.9992C10.001 13.2449 11.0108 14.2548 12.2565 14.2548Z"
                fill="#757C87"
              />
              <path
                d="M12.2565 21.9999C13.5023 21.9999 14.5121 20.99 14.5121 19.7443C14.5121 18.4986 13.5023 17.4888 12.2565 17.4888C11.0108 17.4888 10.001 18.4986 10.001 19.7443C10.001 20.99 11.0108 21.9999 12.2565 21.9999Z"
                fill="#757C87"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
export default {
  name: "Nav-Bar",
  props: {
    notifyCount: Number,
  },
  data() {
    return {
      time: {
        hour: "00",
        min: "00",
        sec: "00",
      },
      displayMenuName: false,
      organizationName: "AI And Robotics Ventures",
      // userName: "ARV Admin",
      userTitle: "System Admin",
      displayUserMenu: false,
    };
  },

  computed: {
    loginState() {
      return this.$store.state.isLogedin;
    },
    webAPI() {
      return this.$store.state.webAPI;
    },
    apiKey() {
      return this.$store.state.apiKey;
    },
    sioServer() {
      return this.$store.state.sioServer;
    },
    customerID() {
      return this.$store.state.customerID;
    },
    userName() {
      return this.$store.state.user;
    },
    customerAssets() {
      return this.$store.state.customerAsset;
    },
  },
  mounted() {
    this.Caltime();
    // deepcode ignore VueMissingCleanup: <please specify a reason of ignoring this>
    setInterval(this.Caltime, 200);
  },
  methods: {
    Caltime: function () {
      let currentObj = this;
      var today = new Date();
      currentObj.time.hour = today.getHours().toString().padStart(2, "0");
      currentObj.time.min = today.getMinutes().toString().padStart(2, "0");
      currentObj.time.sec = today.getSeconds().toString().padStart(2, "0");
    },
    async confirmation() {
      // deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
      await swal
        .fire({
          icon: "warning",
          title: "Please confirm that, Do you want to Sign-out?",
          showCancelButton: true,
          confirmButtonText: `Confirm`,
          target: document.getElementById("app"),
        })

        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.confirm = true;
            setTimeout(this.logout_process, 1500);
            // this.$swal.fire("Deleted!", item.missionID, "error");
            swal.fire({
              //position: 'top-end',
              icon: "success",
              title: "Signed out.",
              showConfirmButton: false,
              timer: 1500,
              target: document.getElementById("app"),
            });
          } else {
            history.back();
          }
        })
        .catch((e) => console.error(e));
    },
    logout_process() {
      // sessionStorage.clear();
      let currentObj = this;
      sessionStorage.clear();
      currentObj.$store.commit("setLoginState", false);
      currentObj.$store.commit("setAPIKey", null);
      currentObj.$store.commit("setMntLoginState", false);
      currentObj.$store.commit("setUser", null);
      currentObj.$store.commit("setcustomerID", null);
      currentObj.$store.commit("setSessionEndURL", '/#/mnt');
      // let sessionEndURL = this.$store.state.sessionEndURL;
      // let sessionEndURL = sessionStorage.getItem("setSessionEndURL");
      // this.$router.push({ path: "home" });
      // window.location.href = "/#/";
      window.location.href = this.$store.state.sessionEndURL;
    },
  },
};
</script>
<style scoped>
/* p.Nav-Menu-text {
  font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 14px;
  height:75px;
  margin: 0px;
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  border: 1px solid #f00;
} */
.reset-style,
.reset-style * {
  all: revert;
}
.user-menu-item {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  width: 200px;
  color: #ffffff;
}
.user-menu-item:hover {
  border-radius: 8px;
  background: var(--opacity-primary-50020, rgba(53, 73, 255, 0.2));
  text-decoration: none;
  cursor: pointer;
}
.user-menu {
  position: absolute;
  z-index: 999;
  bottom: 10px;
  left: 240px;
  display: flex;
  width: 216px;
  padding: 8px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 14px;
  background: var(--opacity-gray-secondary-80020, rgb(0, 0, 0));
}
.Nav-Menu-text {
  flex: 1 0 0;
  color: var(--gray-primary-700, #ffffff);

  /* ENG/Subtitle2 */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  text-align: start;
  vertical-align: middle;

  /* height: 48px; */
  /* padding: auto; */
}
.clock {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80px;
  color: white;
  z-index: 999;
  font: 1.2em sans-serif;
  text-align: center;
  margin-bottom: 2rem;
  /* transform: translate(0%, -100%); */
}

.slide-bar {
  transition: width 300ms;
  width: 80px;
  position: fixed;
  left: 0;
  top: 0;
  /* padding-top: 1rem; */
  height: 100%;
  /* background: var(--main-color); */
  background: var(--opacity-gray-secondary-90090, rgba(0, 85, 255, 0.9));
  backdrop-filter: blur(25px);

  z-index: 100;
  /* text-align: center; */
}
.slide-bar-full {
  width: 248px !important;
}
.slide-bar-menu {
  margin-top: 2rem;
}

/* .slide-bar-brand span {
  display: inline-block;
  transition: display 300ms;
  margin-left: 1rem;
  margin-top: 2rem;
}
.slide-bar-brand span {
  display: inline-block;
  transition: display 300ms;
  margin-left: 1rem;
  margin-top: 2rem;
} */
/* .slide-bar-brand {
  height: 90px;
  padding-left: 1rem 0rem 1rem 2rem;
  color: #fff;
  padding-left: 1rem;
  transition: display 300ms;
}
.slide-bar-brand span {
  display: inline-block;
  transition: display 300ms;
  margin-left: 1rem;
  margin-top: 2rem;
}*/
/* //////////////////////////////////// */
/* .slide-bar-menu li {
  width: 100%;
  margin-bottom: 1.7rem;
  padding-left: 1rem;
} */
/* .slide-bar-menu a {
  transition: padding 300ms;
  padding-top: 1rem;pointer
  display: block;
  color: #fff;
  font-size: 1.1rem;
} */
a.router-link-active div {
  text-decoration: none;
  /* background: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--main-color);*/
  /* border-radius: 30px 0px 0px 30px;  */
  /* border-color: red; */
  /* background: var(--opacity-blue-primary-50020, rgba(53, 73, 255, 0.2)); */
  /* cursor: pointer; */
  /* color: var(--primary-400, #6778ff); */
}

a.router-link-active {
  /* color: aqua; */
  text-decoration: none;
}

a.router-link-exact-active div:first-child {
  /* color: #42b983;
  background: #fff;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  color: var(--main-color);
  border-radius: 30px 0px 0px 30px;

  backdrop-filter: blur(10px); */
  background: var(--opacity-blue-primary-50020, rgba(214, 214, 214, 0.2));
  /* cursor: pointer; */
  color: var(--primary-400, #6778ff);
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

/* .slide-bar-menu a span:first-child {
  font-size: 2rem;
  padding-right: 1rem;
} */

/* 
#nav-toggle:checked + .slide-bar {
  width: 80px;
}
#nav-toggle:checked + .slide-bar .slide-bar-brand,
#nav-toggle:checked + .slide-bar li {
  padding-left: 1rem;
  text-align: center;
}

#nav-toggle:checked + .slide-bar li a {
  padding-left: 0rem;
}

#nav-toggle:checked + .slide-bar .slide-bar-brand h2 span:last-child,
#nav-toggle:checked + .slide-bar li a span:last-child {
  display: none;
} */

@media only screen and (max-height: 780px) {
  .clock {
    display: none;
  }
}
.more-btn {
  display: flex;
  align-items: center;
  align-self: stretch;
}
.more-btn :hover {
  cursor: pointer;
}
.Nav-container {
  display: inline-flex;
  height: 100vh;
  /* width: 88px; */
  padding: 24px 16px 33px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  flex-shrink: 0;
  /* background: var(--opacity-gray-secondary-90090, rgba(13, 13, 13, 0.9)); */
  backdrop-filter: blur(25px);
  width: 80px;
  transition-property: width;
  transition-duration: 0.5s;
}

.Nav-Org-layout {
  display: flex;
  height: 48px;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
}
.Nav-Org-logo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden !important;
}
.Nav-Org-text {
  display: flex;
  width: 210px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--gray-primary-50, #ececef);

  /* ENG/Headline [H6] */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}
.Nav-Organize-Info-item {
  display: flex;
  /* width: 48px; */
  height: 48px;
  padding: auto;
  text-align: center;

  /* padding: 0px 102px 0px 0px; */
  align-items: center;
  /* gap: 8px; */
  flex-shrink: 0;
  max-width: 48px;
  max-height: 48px;
  border-radius: 48px;
  background: #ececef;
  /* overflow: hidden; */
  position: relative;
}
.Nav-Organize-Info-item-full {
  display: flex;
  width: auto;
  height: 48px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
}

.org-logo {
  position: absolute;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  /* width: 48px; */
  /* height: 48px; */
  /* transform: rotate(90deg); */
  /* flex-shrink: 0; */
  /* border-radius: 48px; */
  /* background: #ececef; */
  /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
  /* border-radius: 48px; */
  /* background: url('../../assets/v3/imgs/Org_logo.png'), lightgray 50% / cover no-repeat; */
}

.org-name-text {
  display: flex;
  /* width: 160px; */
  flex-direction: column;
  flex-shrink: 0;
  color: var(--gray-primary-50, #ececef);

  /* ENG/Headline [H6] */
  font-size: 18px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 28px;
}

.Nav-item {
  display: flex;
  /* width: 216px; */
  color: #ececef !important;
  height: 40px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.Nav-sub-item:hover,
.Nav-item:hover {
  background: var(--opacity-blue-primary-50020, rgba(255, 255, 255, 0.2));
  cursor: pointer;
  color: var(--primary-400, #000000);
}

.Nav-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.Nav-text {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;

  /* ENG/Subtitle2 */
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 22px;
}

.Nav-Bar {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  display: flex;
  
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0; */
  /* width: 56px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.Nav-specer {
  width: 100% !important;
  display: block;
  height: 1px;
  background: var(--gray-primary-600, #757c87);
  border-color: #282827;
}

.Nav-sub-nav {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 10px;
  /* background: var(--opacity-gray-primary-80020, rgba(62, 71, 84, 0.2)); */
}

.Nav-sub-item {
  display: flex;
  /* width: 192px; */
  /* max-width: 100%; */
  /* height: 48px; */
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  color: var(--gray-primary-600, #757c87);
  margin-bottom: 5px;

  /* ENG/Subtitle2 */
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 22px;
}

.spacer2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}

.user-info {
  display: flex;
  /* width: 216px; */
  align-items: center;
  gap: 8px;
}
.user-info-full {
  width: 216px;
}
.user-picture {
  width: 48px;
  height: 48px;
  /* transform: rotate(90deg); */
  flex-shrink: 0;
  border-radius: 48px;
  /* background: url(<path-to-image>),
    lightgray 391900767148670500px 8304296894657687px / -816459931559730300% -544374660573338100%
      no-repeat; */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.user-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 130px;
}

.user-name-text {
  display: flex;
  /* width: 160px; */
  height: 22px;
  flex-direction: column;
  color: #ececef;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 600;
  line-height: 22px;
}

.user-title-text {
  display: flex;
  /* width: 160px; */
  height: 18px;
  flex-direction: column;
  color: #757c87;
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
}
.spacer-div1 {
  stroke-width: 1px;
  stroke: var(--gray-secondary-400, #282827);
}
.fleet-menu {
  border-radius: 10px;
  background: var(--opacity-gray-primary-80020, rgba(62, 71, 84, 0.2));
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.las {
  font-size: 32px;
  font-weight: bolder;
  color: #ffffff;
}
</style>
