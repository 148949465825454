<template>
  <div class="containerCard">
      <div class="CardData" style="width: 40%;">{{name}}</div>
      <div class="CardData">:  {{value}}</div>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    value: null,
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.containerCard {
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100%;
  // transition: color 0.3s, background 0.3s
  // transition-delay: 300ms
//   background-color: brown;
//   font: 0.8em sans-serif;
}
.CardData
{
    text-align: left;

    margin: 0;
    padding: 5px;
    display: inline-block;
    // background-color: chartreuse;
}
.containerCard:hover{
  cursor: pointer;
  color: black;
  background: rgba($color: #FFF, $alpha: 0.7);
}

</style>
