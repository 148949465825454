<template>
  <div class="mission">
    <!-- <h1>This is an about page</h1> -->
    <div class="map full" ref="map"></div>
    <div class="contentMission">
      <h3 style="padding: 3px; margin: 0px; text-align: left; padding: 20px">
        Site Configuration
      </h3>
      <div>
        <b-form-group id="input-group-1" label="Site Name :" label-for="input-1">
          <b-form-select
            style="margin-bottom: 1rem"
            @change="decodeSite"
            v-model="selectedSite"
            :options="selectOption"
          ></b-form-select>
          <b-form-input
            id="input-1"
            placeholder="Enter site name"
            v-model="site.name"
            v-if="!selectedSite.name"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-1" label="Description :" label-for="input-1">
          <b-form-textarea
            id="textarea-1"
            placeholder="Enter site description..."
            v-model="site.description"
          ></b-form-textarea>
        </b-form-group>
        <!-- <div class="site-location">
          <label>Site Location</label>
        </div>
        <b-form-group
          id="input-group-1"
          label="Latitude :"
          label-for="input-lat"
        >
          <b-form-input
            id="input-lat"
            class="location-input"
            v-model="site.location[0]"
            @change="locationchange"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Longitude :"
          label-for="input-lng"
        >
          <b-form-input
            id="input-lng"
            class="location-input"
            v-model="site.location[1]"
            @change="locationchange"
          ></b-form-input>
        </b-form-group> -->
        <b-form-group id="input-group-1" label="Configuration" label-for="input-lng">
          <b-list-group>
            <b-list-group-item
              button
              :active="activeNumber == -1"
              variant="dark"
              @click="changedItem(-1)"
              >Site Area (GEO Fence)</b-list-group-item
            >
            <b-list-group-item
              button
              variant="dark"
              v-for="(item, index) in restricArea"
              :key="'list-group-' + index"
              :active="activeNumber == index"
              @click="changedItem(index)"
              ><b-icon
                icon="dash-circle-fill"
                scale="1.25"
                variant="danger"
                aria-hidden="true"
                @click="removeRestrictionArea(index)"
              ></b-icon>
              Restriction #{{ index + 1 }}</b-list-group-item
            >
            <!-- <b-list-group-item button variant="dark">Restriction #2</b-list-group-item>
            <b-list-group-item button variant="dark">Restriction #3</b-list-group-item> -->
          </b-list-group>
          <b-list-group>
            <b-list-group-item button variant="warning" @click="addRestrictionArea">
              <b-icon icon="plus-circle" aria-hidden="true"></b-icon> Add
              Restriction</b-list-group-item
            >
          </b-list-group>
        </b-form-group>
      </div>
      <div class="mission-footer">
        <b-button
          size="lg"
          @click="createSite"
          variant="success"
          style="width: 100%"
          v-if="!selectedSite.name"
          >Create New Site</b-button
        >
        <b-button
          size="lg"
          @click="updateMission"
          variant="secondary"
          style="width: 100%; margin-top: 5px"
          v-if="selectedSite.name"
          >Update</b-button
        >
        <b-button
          size="lg"
          @click="deleteSite"
          variant="danger"
          style="width: 100%; margin-top: 5px"
          v-if="selectedSite.name"
          >Delete</b-button
        >
      </div>
    </div>
    <!-- <div class="search-wrapper">
      <span class="las la-search"></span>
      <input
        id="pac-input"
        v-model="search.input"
        class="controls"
        type="text"
        placeholder="Search here"
        ref="searchInput"
      />
    </div> -->
  </div>
</template>
<script>
// @ is an alias to /src
// import MainMenu from '@/components/NavigateManu.vue'

import swal from "sweetalert2";
// import WaypointForm from "@/components/mission/Waypoint.vue";

export default {
  name: "SiteConfiguration",
  components: {
    // MainMenu
    // WaypointForm,
  },
  data() {
    return {
      site: {
        name: "",
        description: "",
        location: [12.994, 101.445],
        markers: [],
        paths: [],
        polygon: {},
        midleLine: [],
        polyLine: [],
      },
      activeNumber: -1,
      restricArea_Template: {
        markers: [],
        paths: [],
        polygon: {},
        midleLine: [],
        polyLine: [],
      },
      restricArea: [],
      siteData: {
        name: "",
        desc: "",
        fence: [],
        restricArea: [],
        cid: null,
        sid: null,
      },
      selectedSite: {},
      selectOption: [],
      sitesInfo: [],
      user: this.$store.state.user,
      siteName: this.$store.state.site,
      siteLocation: this.$store.state.siteLocation,
      owner: this.$store.state.owner,

      visible: [false],
      commandOptions: [],
      commandCodeDetail: {},
      indexOfCMD: [0, 6, 4],
      upload: false,
      file: "",
      items: null,
      map: null,
      boxtatus: null,
      timeNow: "XX:XX:XX",
      apiServer: this.$store.state.apiDataServe,
      marker: [],
      marker2: [],
      planPath: new window.google.maps.Polyline(),
      flightPath: new window.google.maps.Polyline(),
      menualpanelShow: false,
      paramShow: false,
      plan: [],
      path: [],
      icon: [],
      closeLine: new window.google.maps.Polyline(),
      planPointer: null,
      LoadedPlanNo: 0,
      // planPath:null,
      MissionID: 205,
      assets: [],
      customer: "ARV",
      drones: [],
      boxlocation: {},
      boxs: [],
      flyRange: 0,      
      //////////////////////////
      webAPI: this.$store.state.webAPI,
      deviceKey: this.$store.state.deviceKey,
      selectedAsset: this.$store.state.selectedAsset,
      customerAssets: this.$store.state.customerAsset,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
    };
  },
  mounted() {
    this.getSiteInfo();
    // if (this.siteName === null || this.siteLocation === []) {
    //   alert("Site and Location are not set!!");
    //   window.location.href = "/#/";
    // }
    // this.getdata();
    this.map = new window.google.maps.Map(this.$refs["map"], {
      mapTypeId: "satellite",
      center: { lat: 13.932, lng: 100.573 },
      zoom: 16,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    });
    const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(12.993362, 101.442383),
        new window.google.maps.LatLng(12.996520, 101.449361)
      );
      let image = require("../assets/map/ptic.png");

    class USGSOverlay extends window.google.maps.OverlayView {
        bounds;
        image;
        div;
        constructor(bounds, image) {
          super();
          this.bounds = bounds;
          this.image = image;
        }
        /**
         * onAdd is called when the map's panes are ready and the overlay has been
         * added to the map.
         */
        onAdd() {
          this.div = document.createElement("div");
          this.div.style.borderStyle = "none";
          this.div.style.borderWidth = "0px";
          this.div.style.position = "absolute";
    
          // Create the img element and attach it to the div.
          const img = document.createElement("img");
    
          img.src = this.image;
          img.style.width = "100%";
          img.style.height = "100%";
          img.style.position = "absolute";
          this.div.appendChild(img);
    
          // Add the element to the "overlayLayer" pane.
          const panes = this.getPanes();
    
          panes.overlayLayer.appendChild(this.div);
        }
        draw() {
          // We use the south-west and north-east
          // coordinates of the overlay to peg it to the correct position and size.
          // To do this, we need to retrieve the projection from the overlay.
          const overlayProjection = this.getProjection();
          // Retrieve the south-west and north-east coordinates of this overlay
          // in LatLngs and convert them to pixel coordinates.
          // We'll use these coordinates to resize the div.
          const sw = overlayProjection.fromLatLngToDivPixel(
            this.bounds.getSouthWest()
          );
          const ne = overlayProjection.fromLatLngToDivPixel(
            this.bounds.getNorthEast()
          );
    
          // Resize the image's div to fit the indicated dimensions.
          if (this.div) {
            this.div.style.left = sw.x + "px";
            this.div.style.top = ne.y + "px";
            this.div.style.width = ne.x - sw.x + "px";
            this.div.style.height = sw.y - ne.y + "px";
          }
        }
        /**
         * The onRemove() method will be called automatically from the API if
         * we ever set the overlay's map property to 'null'.
         */
        onRemove() {
          if (this.div) {
            this.div.parentNode.removeChild(this.div);
            delete this.div;
          }
        }
        /**
         *  Set the visibility to 'hidden' or 'visible'.
         */
        hide() {
          if (this.div) {
            this.div.style.visibility = "hidden";
          }
        }
        show() {
          if (this.div) {
            this.div.style.visibility = "visible";
          }
        }
        toggle() {
          if (this.div) {
            if (this.div.style.visibility === "hidden") {
              this.show();
            } else {
              this.hide();
            }
          }
        }
        toggleDOM(map) {
          if (this.getMap()) {
            this.setMap(null);
          } else {
            this.setMap(map);
          }
        }
      }
    
      const overlay = new USGSOverlay(bounds, image);
    
      overlay.setMap(this.map);
    this.locationchange();
    const that = this;
    this.map.addListener("click", function (mapsMouseEvent) {
      console.log("activeNumber : ", that.activeNumber);
      if (that.activeNumber === -1) {
        if (that.site.markers.length < 4) that.addpoint(mapsMouseEvent.latLng);
      } else {
        if (that.restricArea[that.activeNumber].markers.length < 4)
          that.addPointRestriction(mapsMouseEvent.latLng);
      }
    });
  },
  created() {
    // let commandCode = require("../assets/cmd.json");
    // this.commandOptions = commandCode.cmd;
    // this.commandOptions.forEach(this.generateCommandCode);
  },
  methods: {
    locationchange() {
      // console.log(this.site.location[0], ", ", this.site.location[1]);
      this.map.setCenter(
        new window.google.maps.LatLng(this.site.location[0], this.site.location[1])
      );
    },
    addpoint(location) {
      var marker = this.displayMarker(location.lat(), location.lng(), " ");
      marker.addListener("drag", () => {
        if (this.site.paths.length > 1) {
          //
          try {
            this.site.polygon.setMap(null);
          } catch {
            //
          }
          this.site.polygon = this.drawPolygon();
          this.drawMidlePoint();
        }
      });
      this.site.markers.push(marker);
      // this.site.paths.push(location);
      if (this.site.markers.length > 1) {
        //
        try {
          this.site.polygon.setMap(null);
        } catch {
          //
        }
        this.site.polygon = this.drawPolygon();
        this.drawMidlePoint();
      }
    },
    addPointRestriction(location) {
      var marker = this.displayRestrictionMarker(location.lat(), location.lng(), " ");
      marker.addListener("drag", () => {
        if (this.restricArea[this.activeNumber].paths.length > 1) {
          //
          try {
            this.restricArea[this.activeNumber].polygon.setMap(null);
          } catch {
            //
          }
          this.restricArea[this.activeNumber].polygon = this.drawRestrictionPolygon();
          this.drawRestrictionMidlePoint();
        }
      });
      this.restricArea[this.activeNumber].markers.push(marker);
      // this.site.paths.push(location);
      if (this.restricArea[this.activeNumber].markers.length > 1) {
        //
        try {
          this.restricArea[this.activeNumber].polygon.setMap(null);
        } catch {
          //
        }
        this.restricArea[this.activeNumber].polygon = this.drawRestrictionPolygon();
        this.drawRestrictionMidlePoint();
      }
    },
    drawPolygon() {
      this.site.paths = [];
      this.site.markers.forEach(this.buildNewPath);
      return new window.google.maps.Polygon({
        paths: this.site.paths,
        strokeColor: "#FFA500",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: "#FFA500",
        fillOpacity: 0.35,
        map: this.map,
      });
    },
    buildNewPath(item) {
      this.site.paths.push(item.position);
    },
    drawRestrictionPolygon() {
      this.restricArea[this.activeNumber].paths = [];
      this.restricArea[this.activeNumber].markers.forEach(this.buildRestrictionPath);
      return new window.google.maps.Polygon({
        paths: this.restricArea[this.activeNumber].paths,
        strokeColor: "#D9211B",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: "#D9211B",
        fillOpacity: 0.35,
        map: this.map,
      });
    },
    buildRestrictionPath(item) {
      this.restricArea[this.activeNumber].paths.push(item.position);
    },
    drawRestrictionMidlePoint() {
      this.restricArea[this.activeNumber].midleLine.forEach((item) => item.setMap(null));
      this.restricArea[this.activeNumber].midleLine = [];
      var pl = this.restricArea[this.activeNumber].markers.length;
      for (let i = 1; i < pl; i++) {
        // console.log(this.site.markers[i - 1].position.lat());
        // console.log(this.site.markers[i].position.lat());
        var calPoint = this.calculateMidlePosition(
          this.restricArea[this.activeNumber].markers[i - 1],
          this.restricArea[this.activeNumber].markers[i]
        );
        // console.log(calPoint.lat, ", ", calPoint.lng);
        this.buildRestrictionMidlePoint(calPoint.lat, calPoint.lng, i - 1);
      }
      var point = this.calculateMidlePosition(
        this.restricArea[this.activeNumber].markers[pl - 1],
        this.restricArea[this.activeNumber].markers[0]
      );
      this.buildRestrictionMidlePoint(point.lat, point.lng, pl - 1);
    },
    buildRestrictionMidlePoint(lat, lng, index) {
      var item = this.displayRestrictionMarker(lat, lng, "+");
      item.addListener("click", () => {
        console.log("Clicked : ", index);
        var newMarker = this.displayRestrictionMarker(
          item.position.lat(),
          item.position.lng(),
          " "
        );
        newMarker.addListener("drag", () => {
          if (this.restricArea[this.activeNumber].paths.length > 1) {
            //
            try {
              this.restricArea[this.activeNumber].polygon.setMap(null);
            } catch {
              //
            }
            this.restricArea[this.activeNumber].polygon = this.drawRestrictionPolygon();
            this.drawRestrictionMidlePoint();
          }
        });
        this.restricArea[this.activeNumber].markers.splice(index + 1, 0, newMarker);
        try {
          this.restricArea[this.activeNumber].polygon.setMap(null);
        } catch {
          //
        }
        this.restricArea[this.activeNumber].polygon = this.drawRestrictionPolygon();
        this.drawRestrictionMidlePoint();
      });
      this.restricArea[this.activeNumber].midleLine.push(item);
    },
    drawMidlePoint() {
      this.site.midleLine.forEach((item) => item.setMap(null));
      this.site.midleLine = [];
      var pl = this.site.markers.length;
      for (let i = 1; i < pl; i++) {
        // console.log(this.site.markers[i - 1].position.lat());
        // console.log(this.site.markers[i].position.lat());
        var calPoint = this.calculateMidlePosition(
          this.site.markers[i - 1],
          this.site.markers[i]
        );
        // console.log(calPoint.lat, ", ", calPoint.lng);
        this.buildMidlePoint(calPoint.lat, calPoint.lng, i - 1);
      }
      var point = this.calculateMidlePosition(
        this.site.markers[pl - 1],
        this.site.markers[0]
      );
      this.buildMidlePoint(point.lat, point.lng, pl - 1);
    },
    calculateMidlePosition(p1, p2) {
      var lat = (p1.position.lat() + p2.position.lat()) / 2;
      var lng = (p1.position.lng() + p2.position.lng()) / 2;
      return { lat: lat, lng: lng };
    },
    buildMidlePoint(lat, lng, index) {
      var item = this.displayMarker(lat, lng, "+");
      item.addListener("click", () => {
        console.log("Clicked : ", index);
        var newMarker = this.displayMarker(item.position.lat(), item.position.lng(), " ");
        newMarker.addListener("drag", () => {
          if (this.site.paths.length > 1) {
            //
            try {
              this.site.polygon.setMap(null);
            } catch {
              //
            }
            this.site.polygon = this.drawPolygon();
            this.drawMidlePoint();
          }
        });
        this.site.markers.splice(index + 1, 0, newMarker);
        try {
          this.site.polygon.setMap(null);
        } catch {
          //
        }
        this.site.polygon = this.drawPolygon();
        this.drawMidlePoint();
      });
      this.site.midleLine.push(item);
    },
    displayMarker(lat, lon, label) {
      var dragOption = true;
      var image = {
        path: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
        fillColor: "white",
        fillOpacity: 0.8,
        strokeColor: "#FFA500",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        // strokeColor: "white",
        // rotation: rotation,
        scale: 0.2,
        labelOrigin: new window.google.maps.Point(100, 100),
        anchor: new window.google.maps.Point(100, 100),
      };
      if (label == "+") {
        image = {
          path: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
          fillColor: "white",
          fillOpacity: 0.5,
          strokeWeight: 1,
          strokeColor: "white",
          // rotation: rotation,
          scale: 0.25,
          labelOrigin: new window.google.maps.Point(100, 100),
          anchor: new window.google.maps.Point(100, 100),
        };
        dragOption = false;
      }
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lon),
        icon: image,
        label: {
          text: label,
          color: "white",
          fontSize: "30px",
        },
        map: this.map,
        draggable: dragOption,
        // animation: new window.google.maps.Animation.DROP,
        // zIndex: 0,
        // title: "P#" + (index + 1).toString(),
      });
    },
    displayRestrictionMarker(lat, lon, label) {
      var dragOption = true;
      var image = {
        path: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
        fillColor: "white",
        fillOpacity: 0.8,
        strokeColor: "#D9211B",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        // strokeColor: "white",
        // rotation: rotation,
        scale: 0.2,
        labelOrigin: new window.google.maps.Point(100, 100),
        anchor: new window.google.maps.Point(100, 100),
      };
      if (label == "+") {
        image = {
          path: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
          fillColor: "white",
          fillOpacity: 0.5,
          strokeWeight: 1,
          strokeColor: "white",
          // rotation: rotation,
          scale: 0.25,
          labelOrigin: new window.google.maps.Point(100, 100),
          anchor: new window.google.maps.Point(100, 100),
        };
        dragOption = false;
      }
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lon),
        icon: image,
        label: {
          text: label,
          color: "white",
          fontSize: "30px",
        },
        map: this.map,
        draggable: dragOption,
        // animation: new window.google.maps.Animation.DROP,
        // zIndex: 0,
        // title: "P#" + (index + 1).toString(),
      });
    },

    async getSiteInfo() {
      let currentObj = this;
      await this.axios
        .get(this.webAPI + "/manage/api/sid?cid=" + this.customerID, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          currentObj.sitesInfo = response.data;
        })
        .catch(function (error) {
          currentObj.output2 = error;
        });
      this.sitesInfo.sites.forEach(this.buildSiteOption);
      this.selectOption.push({ value: {}, text: "---Create New Site---" });
    },
    buildSiteOption(item) {
      this.selectOption.push({ value: item, text: item.name });
    },

    clearMap(item) {
      try {
        item.marker.setMap(null);
        item.path.setMap(null);
      } catch {
        //
        // item.marker.setMap(null);
      }
    },
    clearActiveMap(item, IsSite) {
      // try {
      item.markers.forEach((item) => item.setMap(null));
      item.midleLine.forEach((item) => item.setMap(null));
      if (IsSite) {
        item.polyLine.forEach((item) => item.setMap(null));
        item.polyLine = [];
        for (var i = 0; i < item.markers.length; i++) {
          if (i + 1 == item.markers.length) {
            item.polyLine.push(
              this.polyPath([item.markers[i].position, item.markers[0].position], IsSite)
            );
          } else {
            item.polyLine.push(
              this.polyPath(
                [item.markers[i].position, item.markers[i + 1].position],
                IsSite
              )
            );
          }
        }
        item.polygon.setMap(null);
      }
      // To be set polygon to polyline
      // } catch {
      //   //
      //   // item.marker.setMap(null);
      // }
    },
    polyPath(path, IsSite) {
      var strokeColor = "RED";
      if (IsSite) strokeColor = "#FFA500";
      return new window.google.maps.Polyline({
        path: path,
        geodesic: true,
        strokeColor: strokeColor,
        strokeOpacity: 1,
        strokeWeight: 4,
        map: this.map,
      });
    },
    addRestrictionArea() {
      this.restricArea.push(JSON.parse(JSON.stringify(this.restricArea_Template)));
      this.changedItem(this.restricArea.length - 1);
    },
    removeRestrictionArea(index) {
      this.restricArea[index].markers.forEach((item) => item.setMap(null));
      this.restricArea[index].midleLine.forEach((item) => item.setMap(null));
      this.restricArea[index].polygon.setMap(null);
      this.restricArea.splice(index, 1);
      if (index == this.activeNumber) {
        if (this.restricArea.length == 0) {
          this.activeNumber = -1;
          this.changedItem(-1);
        } else if (index >= this.restricArea.length) {
          this.activeNumber = this.restricArea.length - 1;
          this.changedItem(this.activeNumber);
        }
      }
    },
    changedItem(index) {
      console.log("index = ", index, " | Active Number = ", this.activeNumber);
      if (index < this.restricArea.length) {
        if (this.activeNumber == -1) {
          this.clearActiveMap(this.site, true);
        } else {
          if (this.restricArea.length > 0)
            this.clearActiveMap(this.restricArea[this.activeNumber], false);
        }
        this.activeNumber = index;
        if (index == -1) {
          this.site.markers.forEach((item) => item.setMap(this.map));
          this.site.midleLine.forEach((item) => item.setMap(this.map));
          this.site.polygon.setMap(this.map);
          this.site.polyLine.forEach((item) => item.setMap(null));
        } else {
          this.restricArea[index].markers.forEach((item) => item.setMap(this.map));
          this.restricArea[index].midleLine.forEach((item) => item.setMap(this.map));
          // this.restricArea[index].polygon.setMap(this.map);
          this.restricArea[index].polyLine.forEach((item) => item.setMap(null));
        }
      }
    },
    buildRestrictionArea() {
      var resArea = [];
      this.restricArea.forEach((element) => {
        var resItem = [];
        element.markers.forEach((item) => {
          resItem.push([item.position.lat(), item.position.lng()]);
        });
        resArea.push(resItem);
      });
      console.log(resArea);
      return resArea;
    },
    buildGeoFence() {
      var fence = [];
      this.site.markers.forEach((item) => {
        fence.push([item.position.lat(), item.position.lng()]);
      });
      console.log(fence);
      return fence;
    },
    async createSite() {
      console.log(JSON.stringify(this.siteData));
      let updateData = JSON.parse(JSON.stringify(this.siteData));
      updateData.name = this.site.name;
      updateData.desc = this.site.description;
      updateData.restricArea = this.buildRestrictionArea();
      updateData.fence = this.buildGeoFence();
      updateData.cid = this.customerID;
      console.log(updateData);
      if (updateData.name != "")
      {
        var data = JSON.stringify(updateData);
        await this.axios
          .post(this.webAPI + "/manage/api/sid", data, {
            headers: {
              authorization: this.apiKey,
              "Content-Type": "application/json",
            },
          })
          .then(function (response) {
            console.log("res_code : ", response);
            swal.fire({
              icon: "success",
              title: "Your Site has been created",
              showConfirmButton: false,
              timer: 2000,
            });
            window.location.href = "/#/";
          })
          .catch(function (error) {
            console.warn(error);
            swal.fire({
              icon: "error",
              title: "Oops... ",
              text: "Something went wrong! Please try again later",
              //footer: "<a href>Why do I have this issue?</a>",
            });
          });
        }
        else{
          swal.fire({
            icon: "error",
            title: "Oops... ",
            text: "Name must not be empty",
            //footer: "<a href>Why do I have this issue?</a>",
          });
        }
    },
    async updateMission() {
      let updateData = JSON.parse(JSON.stringify(this.siteData));
      // updateData.name = this.site.name;
      delete updateData["name"];
      updateData.desc = this.site.description;
      updateData.restricArea = this.buildRestrictionArea();
      updateData.fence = this.buildGeoFence();
      updateData.cid = this.customerID;
      updateData.sid = this.selectedSite.sid;
      console.log(updateData);
      var data = JSON.stringify(updateData);
      await this.axios
        .put(this.webAPI + "/manage/api/sid", data, {
          headers: {
            authorization: this.apiKey,
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log("res_code : ", response);
          swal.fire({
            icon: "success",
            title: "Your Site has been updated",
            showConfirmButton: false,
            timer: 2000,
          });
          window.location.href = "/#/";
        })
        .catch(function (error) {
          console.warn(error);
          swal.fire({
            icon: "error",
            title: "Oops... ",
            text: "Something went wrong! Please try again later",
            //footer: "<a href>Why do I have this issue?</a>",
          });
        });
    },

    async deleteSite() {
      let currentObj = this;
      var data = JSON.stringify({sid: this.selectedSite.sid});
      console.log(data);
      
      var config = {
        method: 'delete',
        url: this.webAPI + "/manage/api/sid",
        headers: { 
          'authorization': this.apiKey, 
          'Content-Type': 'application/json'
        },
        data : data
      };
      await this.axios(config)
        .then(function (response) {
          console.log("res_code : ", response);
          swal.fire({
            icon: "success",
            title: "Site Name : " + currentObj.selectedSite.name + " has been removed.",
            showConfirmButton: false,
            timer: 2000,
          });
          window.location.href = "/#/";
        })
        .catch(function (error) {
          console.warn(error);
          swal.fire({
            icon: "error",
            title: "Oops... ",
            text: "Something went wrong! Please try again later",
            //footer: "<a href>Why do I have this issue?</a>",
          });
        });
    },
    decodeSite() {
      console.log("decodeMission");
      this.site.markers.forEach((item) => item.setMap(null));
      this.site.markers = [];
      this.site.midleLine.forEach((item) => item.setMap(null));
      this.site.midleLine = [];
      this.restricArea.forEach((item) => {
        // Clear all markers
        item.markers.forEach((item) => item.setMap(null));
        // Clear all middle line markers
        item.midleLine.forEach((item) => item.setMap(null));
        // Clear Polygon
        try {
          item.polygon.setMap(null);
        } catch {
          //
        }
      });
      this.restricArea = [];
      // deepcode ignore UncomaparableValues: <please specify a reason of ignoring this>
      if (this.selectedSite != {}) {
        this.site.name = this.selectedSite.name;
        this.site.description = this.selectedSite.desc;
        this.site.location = this.selectedSite.fence[0];
        this.activeNumber = -1;
        // try{
        this.selectedSite.restricArea.forEach((item) => {
          var resItem = JSON.parse(JSON.stringify(this.restricArea_Template));
          this.activeNumber++;
          item.forEach((point) => {
            var newPoint = this.displayRestrictionMarker(point[0], point[1], " ");
            newPoint.addListener("drag", () => {
              if (this.restricArea[this.activeNumber].paths.length > 1) {
                //
                try {
                  this.restricArea[this.activeNumber].polygon.setMap(null);
                } catch {
                  //
                }
                // deepcode ignore PrototypePollution: <please specify a reason of ignoring this>
                this.restricArea[
                  this.activeNumber
                ].polygon = this.drawRestrictionPolygon();
                this.drawRestrictionMidlePoint();
              }
            });
            newPoint.setMap(null);
            resItem.markers.push(newPoint);
          });
          // resItem.polygon =
          this.restricArea.push(resItem);
          this.drawRestrictionMidlePoint();
          this.restricArea[this.activeNumber].midleLine.forEach((item) =>
            item.setMap(null)
          );
          // deepcode ignore PrototypePollution: <please specify a reason of ignoring this>
          this.restricArea[this.activeNumber].polygon = this.drawRestrictionPolygon();
        });
        // }catch {
        //   //
        // }
        this.activeNumber = -1;
        try {
          for (let i = 0; i < this.selectedSite.fence.length; i++) {
            var newMarker = this.displayMarker(
              this.selectedSite.fence[i][0],
              this.selectedSite.fence[i][1],
              " "
            );
            newMarker.addListener("drag", () => {
              if (this.site.paths.length > 1) {
                //
                try {
                  this.site.polygon.setMap(null);
                } catch {
                  //
                }
                this.site.polygon = this.drawPolygon();
                this.drawMidlePoint();
              }
            });
            this.site.markers.push(newMarker);
          }
        } catch {
          //
        }
      } else {
        this.site.location = [12.994, 101.445];
        this.site.name = " ";
        this.site.description = "";
        this.site.markers = [];
      }
      try {
        this.site.polygon.setMap(null);
      } catch {
        //
      }
      this.locationchange();
      this.site.polygon = this.drawPolygon();
      this.drawMidlePoint();
    },
  },
};
</script>

<style scoped>
.mission {
  position: relative;
  width: calc(100%);
  height: 100vh;
}
.full {
  /* position: absolute; */
  /* left: 0px; */
  /* bottom: 0px; */
  height: 100vh;
  width: 100%;
  display: block;
  margin: 0px;
  padding: 0px;
  z-index: 0;
}
.contentMission {
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 300px;
  height: 100%;
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 9999;
  padding: 1rem;
  overflow: scroll none;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
  
  
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 400px;
  padding: 0px;
  margin: 0px;
  height: 100%;
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 9999;
  padding: 1rem;
  overflow: scroll none;

  padding: 32px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);

}
.mission-footer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: block;
  margin-left: auto;
  margin-left: 0rem;
  width: 270px;
  padding: 1 rem;
}
.location-input {
  padding-left: 2rem;
}
.site-location {
  display: inline-grid;
  width: 100%;
  align-content: space-between;
  /* align-items: flex-start; */
}

.search-wrapper {
  position: absolute;
  background: white;
  top: 0rem;
  left: 50%;
  margin: 1rem;
  transform: translate(-50%, 0);
  border: 1px solid #ccc;
  border-radius: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.search-wrapper span {
  display: inline-block;
  padding: 0rem 1rem;
  font-size: 1.5rem;
}
.search-wrapper input {
  height: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
}
</style>
