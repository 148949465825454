<template>
  <div class="notify-card">
    <p>HIR Request from {{ getDeviceName(hirItem.msgFrom) }}</p>
    <p style="font-size: 0.7rem">
      Please consider to approval to start task ID {{ hirItem.taskID }}. {{ hirItem.request.message.split('#')[0] }}
    </p>
    <p class="mb-2" style="font-size: 0.7rem" v-for="item, index in covertMGS(hirItem.request.message)" :key="hirItem.taskID + index"><b-icon icon="exclamation-triangle-fill" variant="warning"></b-icon> {{item}}</p>
    <div class="notify-container">
      <b-container>
        <b-row>
          <b-col>
            <div class="icon-wrapper">
              <span class="las la-tasks"></span>
              <div>
                <h4>{{ hirItem.taskID }}</h4>
                <small>Task ID</small>
              </div>
            </div></b-col
          >
          <b-col>
            <div class="icon-wrapper">
              <span class="las la-plane"></span>
              <div>
                <h4 @click="routeProcess('/panel/uav/' + hirItem.droneID)">{{getDeviceName(hirItem.droneID)}}</h4>
                <small>Drone</small>
              </div>
            </div></b-col
          >
          <b-col>
            <div class="icon-wrapper">
              <span class="las la-plane-departure"></span>
              <div>
                <h4 @click="routeProcess('/panel/station/' + hirItem.takeoffStationID)"
                  >{{getDeviceName(hirItem.takeoffStationID)}}</h4
                >
                <small>Departure</small>
              </div>
            </div></b-col
          >
          <b-col>
            <div
              class="icon-wrapper"
            >
              <span class="las la-plane-arrival"></span>
              <div>
                <h4 @click="routeProcess('/panel/station/' + hirItem.landingStationID)">{{getDeviceName(hirItem.landingStationID)}}</h4>
                <small>Destination</small>
              </div>
            </div></b-col
          >
        </b-row>
      </b-container>
    </div>
    <b-button-group class="review-btn">
      <b-button variant="outline-primary" @click="routeProcess('/panel/station/' + hirItem.msgFrom)">
        <b-icon icon="info-circle"></b-icon> Review
      </b-button>
      <b-button variant="outline-danger" disabled>
        <b-icon icon="x-circle"></b-icon> Reject
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
export default {
  name: "WebAppNotifies",
  props: {
    // taskID: Number,
    // droneID: String,
    // takeOffStationID: String,
    // landingStationID: String,
    // msg: String,
    hirItem:Object,
  },

  data() {
    return {};
  },

  computed: {
    loginState() {
      return this.$store.state.isLogedin;
    },
    webAPI() {
      return this.$store.state.webAPI;
    },
    apiKey() {
      return this.$store.state.apiKey;
    },
    sioServer() {
      return this.$store.state.sioServer;
    },
    customerID() {
      return this.$store.state.customerID;
    },
    userName() {
      return this.$store.state.user;
    },
    customerAssets() {
      return this.$store.state.customerAsset;
    },
  },

  mounted() {},

  methods: {
    covertMGS(msg){
      let msgArray = msg.split('#');
      msgArray.shift();
      console.log(msgArray);
      return msgArray;
    },
    routeProcess(path){
        this.$emit("close-modal");
        this.$router.push({ path: path });
    },
    getDeviceName(aid) {
      var assetName = null;
      try {
        this.customerAssets.devices.forEach((obj) => {
          if (String(obj.aid) === String(aid)) {
            assetName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return assetName;
    },
  },
};
</script>

<style scoped>
.review-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
.notify-card {
  position: relative;
  display: block;
  margin: 5px;
  padding: 10px;
  border: 1px black solid;
  border-radius: 10px;
  background-color: whitesmoke;
  backdrop-filter: 10px;
}
.notify-container {
  display: inline;
  padding: 0;
  margin: 0;
}
/* .icon-wrapper:hover {
  cursor: pointer;
  border: 1px black solid; 
  border-radius: 10px;
  background-color: gray;
} */
.icon-wrapper {
  display: flex;
  /* background-color: bisque; */
  align-items: center;
  /* width: 250px; */
  /* margin-bottom: 10pex; */
  /* display: inline; */
}
.icon-wrapper span {
  border-radius: 50%;
  margin-right: 0.5rem;
  font-size: 2rem;
  /* font-size: 2rem; */
}
.icon-wrapper h4:hover {
  cursor: pointer;
  font-weight: bolder;
  color: blue;
}
.icon-wrapper h4 {
  font-size: 0.8rem;
  margin-bottom: -2px;
}
.icon-wrapper small {
  font-size: 0.6rem;
  display: inline-block;
  /* color: var(--text-grey); */
  margin-top: -10rem !important;
}
</style>