<template>
  <div>
    <b-container fluid>
      <b-row align-h="center" style="height:50vh">
        <div class="map" ref="map"></div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import levelBar from "@/components/levelbar.vue";
// import axios from "axios";
// import messagePanel from "../components/dronemessage.vue";
// import permit from "../components/permission.vue";
// import boxMark from '../assets/DB2_50x50.png';

export default {
  //   name: "GoogleMap",
  props:{
    loc:Array,
    missionDataID:Number,
  },
  components: {
    // boxMark
  },
  data() {
    return {
      mission:{},
      droneID: this.$store.state.selectedDroneID,
      apiServer: this.$store.state.apiDataServe,
      permitaction: false,
      permitAction: {
        flightID: null,
        status: false,
        action: null,
      },
      items: {
        data: {
          ArmedState: false,
          Attitude: 0,
          Speed: 0,
          drone_active: true,
          Mode: "Unknow",
          path: [{ gps_lat: 13.932, gps_lon: 100.573 }],
          flightData: {
            status: "",
            flightID: "",
            flightDate: "",
            flightTime: "",
          },
        },
      },
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      cloud_active: false,
      firstload: true,
      command: {
        flightPermission: {
          flightID: "",
          approveState: "",
        },
      },
      output: "",
      zoom: 7,
      map: null,
      marker: null,
      flightPath: null,
      center: { lat: -35.3631779, lng: 149.1652337 },
      mPOS: { lat: -35.3631779, lng: 149.1652337 },
      //dronelist: [],
      //////////////////////////
      webAPI: this.$store.state.webAPI,
      deviceKey: this.$store.state.deviceKey,
      selectedAsset: this.$store.state.selectedAsset,
      customerAssets: this.$store.state.customerAsset,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,

    };
  },
  computed: {
    // carStyles() {
    classObject: function() {
      return {
        top: this.items.display[0] + "px",
        left: this.items.display[1] + "px",
        width: "30px",
        height: "30px",
      };
    },
  },
  mounted() {
    // axios
    //   .get(this.apiServer + "/list")
    //   .then((response) => (this.dronelist = response.data));
    // this.btcTrkAPICall();
    // console.log(this.$refs["map"]);

    this.map = new window.google.maps.Map(this.$refs["map"], {
      mapTypeId: "satellite",
      center: { lat: 13.932, lng: 100.573 },
      zoom: 20,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    });
    const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(12.993362, 101.442383),
        new window.google.maps.LatLng(12.996520, 101.449361)
      );
      let image = require("../assets/map/ptic.png");

    class USGSOverlay extends window.google.maps.OverlayView {
        bounds;
        image;
        div;
        constructor(bounds, image) {
          super();
          this.bounds = bounds;
          this.image = image;
        }
        /**
         * onAdd is called when the map's panes are ready and the overlay has been
         * added to the map.
         */
        onAdd() {
          this.div = document.createElement("div");
          this.div.style.borderStyle = "none";
          this.div.style.borderWidth = "0px";
          this.div.style.position = "absolute";
    
          // Create the img element and attach it to the div.
          const img = document.createElement("img");
    
          img.src = this.image;
          img.style.width = "100%";
          img.style.height = "100%";
          img.style.position = "absolute";
          this.div.appendChild(img);
    
          // Add the element to the "overlayLayer" pane.
          const panes = this.getPanes();
    
          panes.overlayLayer.appendChild(this.div);
        }
        draw() {
          // We use the south-west and north-east
          // coordinates of the overlay to peg it to the correct position and size.
          // To do this, we need to retrieve the projection from the overlay.
          const overlayProjection = this.getProjection();
          // Retrieve the south-west and north-east coordinates of this overlay
          // in LatLngs and convert them to pixel coordinates.
          // We'll use these coordinates to resize the div.
          const sw = overlayProjection.fromLatLngToDivPixel(
            this.bounds.getSouthWest()
          );
          const ne = overlayProjection.fromLatLngToDivPixel(
            this.bounds.getNorthEast()
          );
    
          // Resize the image's div to fit the indicated dimensions.
          if (this.div) {
            this.div.style.left = sw.x + "px";
            this.div.style.top = ne.y + "px";
            this.div.style.width = ne.x - sw.x + "px";
            this.div.style.height = sw.y - ne.y + "px";
          }
        }
        /**
         * The onRemove() method will be called automatically from the API if
         * we ever set the overlay's map property to 'null'.
         */
        onRemove() {
          if (this.div) {
            this.div.parentNode.removeChild(this.div);
            delete this.div;
          }
        }
        /**
         *  Set the visibility to 'hidden' or 'visible'.
         */
        hide() {
          if (this.div) {
            this.div.style.visibility = "hidden";
          }
        }
        show() {
          if (this.div) {
            this.div.style.visibility = "visible";
          }
        }
        toggle() {
          if (this.div) {
            if (this.div.style.visibility === "hidden") {
              this.show();
            } else {
              this.hide();
            }
          }
        }
        toggleDOM(map) {
          if (this.getMap()) {
            this.setMap(null);
          } else {
            this.setMap(map);
          }
        }
      }
    
      const overlay = new USGSOverlay(bounds, image);
    
      overlay.setMap(this.map);
    this.map.setCenter({ lat: 13.7191758, lng: 100.5205329 });
    var image_box = {
      url: require("../assets/DB2_50x50.png"),
      // url: require("../assets/drone2.png"),
      //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      // This marker is 20 pixels wide by 32 pixels high.
      size: new window.google.maps.Size(50, 50),
      // The origin for this image is (0, 0).
      origin: new window.google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new window.google.maps.Point(35, 35),
    };
    this.marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(13.7191758, 100.5205329),
      icon: image_box,
      title: "ARV Drone",
    });
    this.getMissions();
    // this.flightPath = new window.google.maps.Polyline({
    //   path: [],
    //   geodesic: true,
    //   strokeColor: "#FF0000",
    //   strokeOpacity: 1.0,
    //   strokeWeight: 2,
    // });

    // this.flightPath.setMap(this.map);

    // this.intervalFetchData();
    // this.interval2Data();
    // this.locChange();

  },
  methods: {
    locChange() {
      // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      this.flightPath.setMap(null);
      this.marker.setPosition(
        new window.google.maps.LatLng(
          // this.items.data.gps_lat,
          // this.items.data.gps_lon
          13.7191758,
          100.5205329
        )
      );
      this.marker.addListener("click", () => {
        this.map.setZoom(18);
        this.map.setCenter(this.marker.getPosition());
      });
      // this.marker.setMap(null);
      this.marker.setMap(this.map);

      // var path = [];
      // for (let i = 0; i < this.items.data.path.length; i++) {
      //   path.push(
      //     new window.google.maps.LatLng(
      //       this.items.data.path[i].gps_lat,
      //       this.items.data.path[i].gps_lon
      //     )
      //   );
      // }
      // // console.log(path);
      // this.flightPath = new window.google.maps.Polyline({
      //   path: path,
      //   geodesic: true,
      //   strokeColor: "PURPLE",
      //   strokeOpacity: 1.0,
      //   strokeWeight: 2,
      // });

      // this.flightPath.setMap(this.map);
    },
    drawMapData(){
      console.log('Drawing Data...')
      var paths = []
      var index = 0;
      console.log(this.mission);
      console.log(index,' of ', this.mission.wpID.length);
      for ( index = 0; index < this.mission.wpID.length; index ++){
        console.log(index,' of ', this.mission.wpID.length);
        console.log(this.mission.wpCommand[index])
        if (this.mission.wpCommand[index] === 16){
          paths.push(
            new window.google.maps.LatLng(this.mission.wpLat[index], this.mission.wpLng[index])
          )
          this.displayMarker(this.mission.wpLat[index], this.mission.wpLng[index], index);
        }
      }
      this.polyPath(paths);
      this.map.setCenter(paths[0]);
    },
    async getMissions() {
      console.log("Get Mission");
      console.log('this.missionDataID : ', this.missionDataID);
      // this.dataTask.ETA = this.dataTask.ETD + this.dataTask.totalTime;
      let currentObj = this;
      await this.axios
        // .get(this.apiServer + "/getmissionlist/" + this.siteName)
        .get(
          this.webAPI + "/app/api/missiondata?id=" + this.missionDataID,
          {
            headers: {
              authorization: this.apiKey,
            },
          }
        )
        .then(function(response) {
          currentObj.mission = response.data;
          // this.onReset();
          // alert("Request Command : "+response.data.message);

        })
        .catch(function(error) {
          currentObj.output2 = error;
          console.log(error);
        });
        console.log(this.mission);
        this.drawMapData();
      // this.missions.missions.forEach(this.buildMissionOption);
      // this.missionOption.push({ value: {}, text: "---Create New Mission---" });
    },

    displayMarker(lat, lon, index) {
      var image = {
        url: require("../assets/point.png"),
        // url: require("../assets/ROI1.png"),
        // This marker is 20 pixels wide by 32 pixels high.
        size: new window.google.maps.Size(30, 30),
        // The origin for this image is (0, 0).
        origin: new window.google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(15, 15),
      };
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lon),
        icon: image,
        label: (index + 1).toString(),
        map: this.map,
        draggable: false,
        // animation: new window.google.maps.Animation.DROP,
        // zIndex: 0,
        title: "WP#" + (index + 1).toString(),
      });
    },
    polyPath(path) {
      const lineSymbol = {
        path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
      };
      const aIcon = {
        icon: lineSymbol,
        offset: "100%",
        repeat: "50px",
      };
      let icon = [aIcon];
      return new window.google.maps.Polyline({
        path: path,
        icons: icon,
        geodesic: true,
        strokeColor: "YELLOW",
        strokeOpacity: 1,
        strokeWeight: 1.5,
        map: this.map,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.map {
  //   height: 600px;
  background: gray;
  width: 100%;
  height: 100%;
  // border: 1px solid #bfbfbf;
  // border-radius: 0.7rem;
    // position: relative;
}
</style>
