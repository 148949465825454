<template>
  <div class="body-outer" :class="{ sos: alertType.toLowerCase() === 'sos' }">
    <!-- <t> titleppp = {{ title }}, asd </t> -->
    <div class="body-inner">
      <div :class="types[alertType.toLowerCase()].iconClass">
        <img
          class="icon-img"
          :src="types[alertType.toLowerCase()].icon"
          alt=""
        />
      </div>
      <div class="data-container">
        <div class="data-container-info">
          <h2 class="header-name">{{ title }}</h2>
          <p class="container-data">
            Date : {{ displayDate(parseFloat(time)) }}
          </p>
          <p class="container-data">{{ device }} on {{ location }}</p>
          <p class="container-data">
            {{ reason }}
          </p>
        </div>
      </div>
      <img
        class="x-icon-action"
        width="18px"
        src="/imgs/v3/icons/icon_x.svg"
        alt=""
        @click="$emit('callbackAlert', item, index)"
      />
    </div>
    <img
      width="290px" @click="$emit('callbackFullscreen', item, index)"
      class="img-alert"
      :src="'data:image/jpeg;base64,' + imageData"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    title: String,
    alertType: String,
    device: String,
    imageData: String,
    location: String,
    reason: String,
    time: Number,
    index: Number,
  },
  data() {
    return {
      types: {
        warning: {
          imgLogo: "/imgs/v3/icons/Ellipse.svg",
          icon: "/imgs/v3/icons/warning_icon.svg",
          iconClass: "icon-container",
        },
        sos: {
          imgLogo: "/imgs/v3/icons/Ellipse.svg",
          icon: "/imgs/v3/icons/sos_icon.png",
          iconClass: "icon-container-sos",
        },
      },
    };
  },
  methods: {
    displayDate(time) {
      var date = new Date(time);
      return date.toDateString() + " | " + date.toLocaleTimeString();
    },
  },
};
</script>

<style scoped>
.x-icon-action {
}
.x-icon-action:hover {
  cursor: pointer;
  background: #ff0000;
}
.img-alert {
  /* text-shadow: 2px 2px; */
}
.img-alert:hover {
  text-shadow: 2px 2px;
  cursor: pointer;
}
.header {
  display: flex;
  width: 290px;
  margin-left: 100px;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-primary-50, #ececef);
  background: var(--opacity-gray-secondary-90080, rgba(13, 13, 13, 0.8));
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
}
.body-outer {
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  border-radius: 20px;
  border: 1px solid var(--gray-primary-900, #1f2937);
  background: var(--opacity-gray-secondary-90080, rgba(13, 13, 13, 0.8));
  backdrop-filter: blur(10px);

  color: var(--warning-500, #ffbf5f);
}
.sos {
  color: var(--warning-500, #ff0000);
}

.body-inner {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.icon-container {
  padding: auto;
  text-align: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  background: var(--opacity-warning-50020, rgba(255, 191, 95, 0.2));
}
.icon-container-sos {
  padding: auto;
  text-align: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  background: var(--opacity-warning-50020, rgba(255, 95, 95, 0.2));
}

.icon-img {
  margin: auto;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.icon-x {
  width: 18px;
  height: 18px;
}
.data-container-info {
  display: flex;
  width: 224px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
}
.data-container {
  display: flex;
  width: 224px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.header-name {
  /* ENG/Subtitle1 */
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  width: 240px;
  height: 22px;
  flex-direction: column;
  justify-content: center;
}
.container-data {
  /* color: #FFBF5F; */
  /* ENG/Body3 */
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
  display: flex;
  width: 240px;
  flex-direction: column;
}
</style>
