<template>
  <div>
    <div
      class="gauge"
    >
    <img src="../assets/CompassBG.png" width="150px" style="transform: translate( -1.5px, -1px);"/>
    <!-- <img src="../assets/Pointer.png" class="pointer1"/> -->
    <img src="../assets/CompassArrow.png" width="150px" class="pointer1" :style="classObject2"/>
    <div class="value1" style="color: rgb(101, 252, 0); background-color:black;">{{head.toFixed(0)}} Deg.</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    head: Number,
  },
  data() {
    return {
    //   bgimg: require("~/asset/rice-field-texas.jpg"),
    };
  },
  computed: {
    // carStyles() {
    classObject: function() {
        // console.log('Test')
      return {
          'transform': 'translate( -20px, ' + ((this.head*2.666666667)-260) + 'px)',
      };
    },
    classObject2: function() {
        // console.log('Test')
      return {
          'transform': 'rotate('+this.head +'deg)',
          'transform-origin': '50% 50%'
      };
    },
    classObject3: function() {
        // console.log('Test')
      return {
          'transform': 'rotate('+ (0 -this.roll) +'deg)',
          'transform-origin': '50% 50%'
      };
    },
  },
};
</script>
<style scoped>
.gauge {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: auto;
  border: 1px solid #00000069;
  overflow: hidden;
  /* background-image: url('~@/assets/rice-field-texas.jpg'); */
  /* background-repeat: no-repeat, repeat; */
  /* background-position: 50% 50%; */
  /* background-color: blue; */
}
.pointer1{
  position:absolute;
  top: 0px;
  left: 0px;
  /* transform: translate( -60px, -20px); */
}
.value1{
  position:absolute;
  top: 50%;
  left: 50%;
  font-size: 0.75rem;
  /* width:100px; */
  transform:translate( -50%, -50%);
  /* transform: translate( -60px, -20px); */
}
</style>
