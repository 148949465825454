<template>
  <div class="mission">
    <!-- <h1>This is an about page</h1> -->
    <div class="map full" ref="map"></div>
    <div class="contentMission">
      <h3 style="padding: 3px; margin: 0px; text-align: left; padding: 20px">
        Mission Path Planning
      </h3>
      <!-- {{missionInfo}} -->
      <div>
        <!-- <label>Site Name : {{ dataMission.siteName }}</label> -->
        <b-form-group label="Site Name:" label-for="input-sid">
          <b-form-select
            id="input-ReType"
            v-model="missionInfo.sid"
            :options="siteSelectOption"
            @change="sidChanged"
          ></b-form-select>
          <!-- <b-form-input disabled v-model="siteName"></b-form-input> -->
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Mission Name :"
          label-for="input-1"
        >
          <b-form-select
            style="margin-bottom: 1rem"
            @change="decodeMission"
            v-model="missionSelected"
            :options="missionOption"
          ></b-form-select>
          <b-form-input
            id="input-1"
            placeholder="Enter mission name"
            v-model="missionInfo.name"
            v-if="!missionSelected.missionName"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Description :"
          label-for="input-1"
        >
          <b-form-textarea
            id="textarea-1"
            placeholder="Enter mission description..."
            v-model="missionInfo.desc"
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div>
        <b-tabs content-class="mt-3">
          <b-tab title="Default" active
            ><div>
              <b-form-group
                id="default-group"
                label="Mission Default:"
                label-for="command-group-1"
              >
                <b-row align-v="center" style="padding: 2px">
                  <b-col sm="6">
                    <label for="input-small">Default Altitude</label>
                  </b-col>
                  <b-col sm="4">
                    <b-form-input
                      id="input-alt"
                      v-model="mission.default.altitude"
                      style="text-align: end"
                      type="number"
                      min="30.0"
                      max="150.0"
                      step="1"
                      @input="changeDefaultAlt"
                    ></b-form-input>
                  </b-col>
                  <b-col sm="2"> m. </b-col>
                </b-row>

                <b-row align-v="center" style="padding: 2px">
                  <b-col sm="6">
                    <label for="input-small">Default Speed</label>
                  </b-col>
                  <b-col sm="4">
                    <b-form-input
                      id="input-alt"
                      v-model="mission.default.speed.value"
                      style="text-align: end"
                      type="number"
                      min="0.1"
                      max="15.0"
                      step="0.1"
                      @change="changeDefaultSpeed"
                    ></b-form-input>
                  </b-col>
                  <b-col sm="2"> m/s. </b-col>
                </b-row>

                <b-row align-v="center" style="padding: 2px">
                  <b-col sm="6">
                    <label for="input-small">Default Speed Type</label>
                  </b-col>
                  <b-col sm="6">
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="mission.default.speed.isForcedAll"
                      :options="speed_options"
                      @change="changeDefaultSpeedOption"
                      name="radio-options"
                    ></b-form-radio-group>
                  </b-col>
                </b-row>
              </b-form-group></div
          ></b-tab>
          <b-tab title="Mission">
            <div class="accordion" role="tablist">
              <b-form-group
                id="command-group"
                label="Mission :"
                label-for="command-group-1"
              >
                <div class="command-group" ref="cmdGroup">
                  <b-card
                    no-body
                    variant="dark"
                    class="mb-1 wey-point-body"
                    v-for="(item, index) in mission.missions"
                    :key="index"
                  >
                    <b-card-header
                      header-tag="header"
                      variant="dark"
                      class="p-1 command-header1"
                      role="tab"
                      v-b-toggle="'accordion' + index"
                    >
                      <div class="command-header">
                        {{ index + 1 }} | {{ item.name }}
                      </div>
                      <div style="color: black; font-size: 1.7em">
                        <!-- <i
                    class="las la-arrow-circle-up over-green"
                    @click="moveMissionUp(index)"
                    v-if="index != 0"
                  ></i> -->
                        <!-- <i
                    class="las la-arrow-circle-down over-green"
                    @click="moveMissionDown(index)"
                    v-if="!(index == mission.missions.length - 1)"
                  ></i> -->
                        <i
                          class="lar la-trash-alt over-red"
                          @click="removePoint(index)"
                        ></i>
                        <!-- <i
                    class="lar la-copy over-green"
                    @click="duplicateMission(index)"
                  ></i> -->
                      </div>
                    </b-card-header>
                    <b-collapse
                      v-if="item.name != 'Cancel ROI'"
                      :id="'accordion' + index"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <div class="wp-1">
                          <b-container fluid>
                            <b-row
                              v-if="item.name != 'Waypoint'"
                              align-v="center"
                            >
                              <b-col sm="5"> ROI Altitude </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  size="sm"
                                  v-model="item.altitude"
                                ></b-form-input>
                              </b-col>
                              <b-col sm="3"> m </b-col>
                            </b-row>
                            <b-row v-else align-v="center">
                              <b-col sm="5">
                                <b-form-checkbox
                                  v-model="item.isSpecificAltitude"
                                  >Altitude</b-form-checkbox
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  size="sm"
                                  v-model="item.altitude"
                                  :disabled="!item.isSpecificAltitude"
                                ></b-form-input>
                              </b-col>
                              <b-col sm="3"> m </b-col>
                            </b-row>
                            <b-row
                              v-if="item.name == 'Waypoint'"
                              align-v="center"
                            >
                              <b-col sm="5">
                                <label for="input-small">Hold</label>
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  v-model="item.hold"
                                  size="sm"
                                ></b-form-input>
                              </b-col>
                              <b-col sm="3"> sec. </b-col>
                            </b-row>
                            <b-row
                              v-if="item.name == 'Waypoint'"
                              align-v="center"
                            >
                              <b-col sm="5">
                                <b-form-checkbox v-model="item.yaw.isActivated"
                                  >Yaw</b-form-checkbox
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  size="sm"
                                  v-model="item.yaw.value"
                                  :disabled="!item.yaw.isActivated"
                                ></b-form-input>
                              </b-col>
                              <b-col sm="3"> deg. </b-col>
                            </b-row>
                            <b-row
                              v-if="item.name == 'Waypoint'"
                              align-v="center"
                            >
                              <b-col sm="5">
                                <b-form-checkbox
                                  v-model="item.speed.isActivated"
                                  >Speed</b-form-checkbox
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  size="sm"
                                  v-model="item.speed.value"
                                  :disabled="!item.speed.isActivated"
                                ></b-form-input>
                              </b-col>
                              <b-col sm="3"> m/s </b-col>
                            </b-row>
                            <div
                              v-if="item.name == 'Waypoint'"
                              v-b-toggle="'accordion-inner-' + index"
                              style="
                                margin-top: 10px;
                                display: block;
                                border-bottom: solid 1px #222;
                              "
                            >
                              Camera
                            </div>
                            <b-collapse
                              v-if="item.name == 'Waypoint'"
                              :id="'accordion-inner-' + index"
                              :visible="item.camera.isCamera"
                              role="tabpanel"
                            >
                              <b-form-select
                                v-model="item.camera.commandCode"
                                :options="optionsCameraAction"
                                size="sm"
                                class="mt-3"
                              ></b-form-select>
                              <b-row
                                class="mt-2"
                                v-if="item.camera.commandCode == 2"
                              >
                                <b-col sm="6">
                                  <label for="input-small">Time(s) :</label>
                                </b-col>
                                <b-col sm="6">
                                  <b-form-input
                                    type="number"
                                    v-model="item.camera.time"
                                    size="sm"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row
                                class="mt-2"
                                v-if="item.camera.commandCode == 3"
                              >
                                <b-col sm="6">
                                  <label for="input-small">Distance(m) :</label>
                                </b-col>
                                <b-col sm="6">
                                  <b-form-input
                                    type="number"
                                    v-model="item.camera.distance"
                                    size="sm"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="mt-2">
                                <b-col sm="4">
                                  <label>Gimbal</label>
                                </b-col>
                                <b-col sm="4">
                                  <label>Pitch</label>
                                </b-col>
                                <b-col sm="4">
                                  <label>Yaw</label>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col sm="4">
                                  <b-form-checkbox
                                    v-model="item.camera.isGimbalControl"
                                  ></b-form-checkbox>
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    v-model="item.camera.gimbalPitch"
                                    :disabled="!item.camera.isGimbalControl"
                                    size="sm"
                                  ></b-form-input>
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    v-model="item.camera.gimbalYaw"
                                    :disabled="!item.camera.isGimbalControl"
                                    size="sm"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                            <div
                              class="mt-3"
                              v-if="item.name == 'Waypoint'"
                              v-b-toggle="'accordion-inner-ml-' + index"
                              style="
                                margin-top: 10px;
                                display: block;
                                border-bottom: solid 1px #222;
                              "
                            >
                              Analytics
                            </div>
                            <b-collapse
                              v-if="item.name == 'Waypoint'"
                              :id="'accordion-inner-ml-' + index"
                              :visible="item.analytics.isML"
                              role="tabpanel"
                            >
                              <b-row class="mt-2">
                                <b-col sm="3">
                                  <label for="input-small">Action :</label>
                                </b-col>
                                <b-col sm="9">
                                  <b-form-select
                                    v-model="item.analytics.commandCode"
                                    :options="optionsAnalyticsAction"
                                    size="sm"
                                  ></b-form-select>
                                </b-col>
                              </b-row>
                              <b-row
                                class="mt-2"
                                v-if="
                                  item.analytics.commandCode != 50003 &&
                                  item.analytics.commandCode != 0
                                "
                              >
                                <b-col sm="3">
                                  <label for="input-small">Model :</label>
                                </b-col>
                                <b-col sm="9">
                                  <b-form-select
                                    v-model="item.analytics.model"
                                    :options="optionsAnalyticsModel"
                                    size="sm"
                                  ></b-form-select>
                                </b-col>
                              </b-row>
                              <b-row
                                class="mt-2"
                                v-if="
                                  item.analytics.commandCode != 50003 &&
                                  item.analytics.commandCode != 0
                                "
                              >
                                <b-col sm="6">
                                  <label for="input-small"
                                    >Is DeepZoom ML :</label
                                  >
                                </b-col>
                                <b-col sm="6">
                                  <b-form-select
                                    v-model="item.analytics.isDeepZoom"
                                    :options="optionsEnableDeepZoom"
                                    size="sm"
                                  ></b-form-select>
                                </b-col>
                              </b-row>
                              <b-row
                                class="mt-2"
                                v-if="
                                  item.analytics.commandCode != 50003 &&
                                  item.analytics.commandCode != 0
                                "
                              >
                                <b-form-input
                                  v-model="item.analytics.deepZoomDeviceID"
                                  placeholder="DeepZoom Device ID"
                                ></b-form-input>
                                <!-- <b-col sm="3">
                                  <label for="input-small">DeepZoom DeviceID :</label>
                                </b-col>
                                <b-col sm="9">
                                  <b-form-select
                                    v-model="item.analytics.deepZoomDeviceID"
                                    :options="optionsDeepZoomDeviceID"
                                    size="sm"
                                  ></b-form-select>
                                </b-col> -->
                              </b-row>
                            </b-collapse>
                          </b-container>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </b-form-group>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <div>
        <div class="button-layout">
          <b-button
            @click="createMission"
            variant="success"
            style="width: 95%"
            :disabled="missionInfo.name == null"
            v-if="!missionSelected.missionName"
            >Create Mission</b-button
          >
          <div class="button2-layout" v-if="missionSelected.missionName">
            <b-button
              @click="createNewMission"
              variant="secondary"
              style="width: 32%"
              >Save As</b-button
            >
            <b-button
              @click="updateMission"
              variant="success"
              style="width: 32%"
              >Save Update</b-button
            ><b-button
              @click="deleteMission"
              variant="danger"
              style="width: 32%"
              >Delete</b-button
            >
          </div>
        </div>
      </div>
      <!-- <div class="mission-footer">
        <b-button
          size="lg"
          @click="createMission"
          variant="success"
          style="width:100%;"
          v-if="!missionSelected.missionName"
          >Create Mission</b-button
        >
        <b-button
          size="lg"
          @click="updateMission"
          variant="secondary"
          style="width:100%; margin-top:5px;"
          v-if="missionSelected.missionName"
          >Update</b-button
        >
        <b-button
          size="lg"
          @click="deleteMission"
          variant="danger"
          style="width:100%; margin-top:5px;"
          v-if="missionSelected.missionName"
          >Delete</b-button
        >
      </div> -->
    </div>
    <div class="contentInstruction">
      <div
        @click="instructionSelect('WP')"
        class="ins-command"
        :class="{ 'ins-command test': instructionKey.isWPSelected }"
      >
        <!-- <div :class="{ test : !isWPSelected }"> -->
        <i class="las la-plus-circle"> </i>
        <span>Waypoint</span>
      </div>
      <div
        @click="instructionSelect('ROI')"
        class="ins-command"
        :class="{ 'ins-command test': instructionKey.isROISelected }"
      >
        <i class="las la-map-marker-alt"></i>
        <span>{{ instructionKey.ROImsg }}</span>
      </div>
      <div
        @click="instructionSelect('Survey')"
        class="ins-command"
        :class="{ 'ins-command test': instructionKey.isSurvey }"
      >
        <i class="las la-stroopwafel"></i>
        <span>Survey</span>
      </div>
    </div>
    <div class="contentEstimation">
      <div class="est-status">
        <i class="lar la-clock"></i>
        <!-- <span>Est. Flight Time : {{ timeRange.toFixed(2) }} Min.</span> -->
        <span>Est. Flight Time : {{ (timeRange / 60).toFixed(2) }} Min.</span>
      </div>
      <div class="est-status">
        <i class="las la-road"></i>
        <!-- <span>Flight Range : {{ (distance / 1000).toFixed(2) }} kM.</span> -->
        <span>Flight Range : {{ (distance / 1000).toFixed(3) }} km.</span>
      </div>
      <!-- <div class="est-status">
        <i class="las la-plane"></i>
        <span>Flight Altitude (m)</span>
      </div>currentObj.mission.missions.forEach((item) => {
          item.speed.value = this.mission.default.speed.value;
          item.speed.isActivated = true;
        })
      <apexchart
      width="100%"
      type="area"
      ref="chart"
      :options="chartOptions"
      :series="series"
    ></apexchart> -->
    </div>
    <div class="grid-surv-option" v-if="instructionKey.isSurvey">
      <div class="est-status">
        <b-tabs xs content-class="mt-3" fill style="font-size: 0.75rem">
          <b-tab title="Camera" active>
            <div style="width: 300px">
              <b-form-group
                label="Focal Length : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-input
                  type="number"
                  v-model="gridSurvey.camera.Specification.FocalLength"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Image Height : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-input
                  type="number"
                  v-model="gridSurvey.camera.Specification.ImageHeight"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Image Width : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-input
                  type="number"
                  v-model="gridSurvey.camera.Specification.ImageWidth"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Sensor Height : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-input
                  type="number"
                  v-model="gridSurvey.camera.Specification.SensorHeight"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Sensor Width : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-input
                  type="number"
                  v-model="gridSurvey.camera.Specification.SensorWidth"
                ></b-form-input>
              </b-form-group>
            </div>
          </b-tab>
          <b-tab title="Style">
            <div style="width: 300px">
              <b-form-group
                label="Camera Pitch : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-input
                  v-model="gridSurvey.camera.Style.PitchRaw"
                  type="number"
                  @change="
                    gridSurvey.camera.Style.Pitch =
                      gridSurvey.camera.Style.PitchRaw * -1
                  "
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Front Overlap : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-input
                  v-model="gridSurvey.camera.Style.FrontalOverlap"
                  type="number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Side Overlap : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-input
                  v-model="gridSurvey.camera.Style.SideOverlap"
                  type="number"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="ValueSet Is Distance : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-radio-group
                  v-model="gridSurvey.camera.Style.ValueSetIsDistance"
                  :options="SetIsDistanceOption"
                  class="mb-3"
                  value-field="item"
                  text-field="name"
                ></b-form-radio-group>
              </b-form-group>

              <b-form-group
                label="Distance To Surface : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
                v-if="!gridSurvey.camera.Style.ValueSetIsDistance"
              >
                <b-form-input
                  v-model="gridSurvey.camera.Style.DistanceToSurface"
                  type="number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Image Density (cm/px) : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
                v-if="gridSurvey.camera.Style.ValueSetIsDistance"
              >
                <b-form-input
                  v-model="gridSurvey.camera.Style.ImageDensity"
                  type="number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Orientation : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-radio-group
                  v-model="gridSurvey.camera.Style.Landscape"
                  :options="landScapeBoolOptions"
                  class="mb-3"
                  value-field="item"
                  text-field="name"
                ></b-form-radio-group>
                <!-- <b-form-input
                  v-model="gridSurvey.camera.Style.Landscape"
                  type="bool"
                ></b-form-input> -->
              </b-form-group>
            </div>
          </b-tab>
          <b-tab title="Properties">
            <div style="width: 300px">
              <b-form-group
                label="TurnAround Distance : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-input
                  type="number"
                  v-model="gridSurvey.polygon.Properties.TurnAroundDistance"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Angle : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-input
                  type="number"
                  v-model="gridSurvey.polygon.Properties.Angle"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="entry Location : "
                label-cols-sm="6"
                selected
              >
                <b-form-select
                  v-model="gridSurvey.polygon.Properties.entryLocation"
                  :options="entryPptions"
                ></b-form-select>
                <!-- <b-form-input type="number"
                  v-model="gridSurvey.polygon.Properties.entryLocation"
                ></b-form-input> -->
              </b-form-group>

              <b-form-group
                label="Enable Border Gliding : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-checkbox
                  id="checkbox-PolygonCoverage"
                  v-model="gridSurvey.polygon.Properties.PolygonCoverage"
                  name="PolygonCoverage-1"
                  switch
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                label="Refly 90 Deg. : "
                label-cols-sm="6"
                label-align-sm="right"
                class="mb-2"
                content-cols-sm
              >
                <b-form-checkbox
                  id="checkbox-Refly90Degrees"
                  v-model="gridSurvey.polygon.Properties.Refly90Degrees"
                  name="Refly90Degrees-1"
                  switch
                >
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div v-if="draw_gridSurvey.showStatistic" style="font-size: 0.75rem">
        <ul>
          <li v-for="(value, key) in draw_gridSurvey.showStatistic" :key="key">
            {{ key }}: {{ value }}
          </li>
        </ul>
      </div>
      <div>
        <b-button
          size="sm"
          @click="getGridPath"
          variant="success"
          style="width: 100%"
          >Preview Route</b-button
        >
        <b-button
          size="sm"
          @click="applyGridPath"
          variant="secondary"
          style="width: 100%; margin-top: 5px"
          >Apply Route</b-button
        >
      </div>
    </div>
    <div class="chart-container" v-if="mission.missions.length > 1">
      <apexchart
        width="100%"
        height="100%"
        type="line"
        ref="chart"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import MainMenu from '@/components/NavigateManu.vue'

import swal from "sweetalert2";
import { modeSorted } from "simple-statistics";
// import WaypointForm from "@/components/mission/Waypoint.vue";

export default {
  name: "Mission",
  components: {
    // MainMenu
    // WaypointForm,
  },
  data() {
    return {
      set_default: {
        altitude: 50.0,
        speed: {
          value: 10.0,
          isForcedAll: false,
        },
      },
      speed_options: [
        { text: "Initial", value: false },
        { text: "Force All", value: true },
      ],
      user: this.$store.state.user,
      siteName: this.$store.state.site,
      siteLocation: this.$store.state.siteLocation,
      distance: 0,
      timeRange: 0,
      mission: {
        name: null,
        description: null,
        default: {
          altitude: 50.0,
          speed: {
            value: 10.0,
            isForcedAll: false,
          },
        },
        missions: [],
      },
      missionTemplate: {
        name: "",
        isLocation: false,
        isPath: false,
        isSpecificAltitude: false,
        code: 0,
        marker: {},
        camMarker: {},
        path: {},
        location: null,
        index: {},
        ROILocation: {},
        altitude: 50.0,
        hold: 0.0,
        yaw: {
          isActivated: false,
          value: NaN,
        },
        speed: {
          isActivated: false,
          value: 0,
        },
        camera: {
          isCamera: false,
          commandCode: 0,
          isModeActivated: false,
          mode: 0,
          isGimbalControl: false,
          gimbalPitch: 0,
          gimbalYaw: 0,
          time: 10,
          distance: 1,
        },
        analytics: {
          isML: false,
          commandCode: 0,
          model: 0,
          isDeepZoom: false, // 0 =false, 1 = true
          deepZoomDeviceID: 0,
        },
      },
      instructionKey: {
        selectedKey: "WP",
        isWPSelected: true,
        isROISelected: false,
        isROIfirst: true,
        isSurvey: false,
        ROImsg: "ROI",
      },
      visible: [false],
      commandOptions: [],
      commandCodeDetail: {},
      indexOfCMD: [0, 6, 4],
      upload: false,
      file: "",
      dataMission: {
        missionName: null,
        missionDescription: null,
        siteName: this.$store.state.site,
        totalRange: 0.0,
        flightTime: 0.0,
        commands: [],
        by: null,
      },
      items: null,
      map: null,
      elevator: null,
      boxtatus: null,
      timeNow: "XX:XX:XX",
      apiServer: this.$store.state.apiDataServe,
      marker: [],
      marker2: [],
      planPath: new window.google.maps.Polyline(),
      flightPath: new window.google.maps.Polyline(),
      menualpanelShow: false,
      paramShow: false,
      plan: [],
      path: [],
      icon: [],
      closeLine: new window.google.maps.Polyline(),
      planPointer: null,
      LoadedPlanNo: 0,
      // planPath:null,
      MissionID: 205,
      assets: [],
      customer: "ARV",
      options2: [
        { value: "H", text: "Hourly Routine" },
        { value: "2H", text: "Twice Hourly Routine" },
        { value: "D", text: "Daily Routine" },
        { value: "2D", text: "Twice Day Routine" },
        { value: "W", text: "Weekly Routine" },
        { value: "2W", text: "Twice Weekly Routine" },
      ],
      drones: [],
      boxlocation: {},
      boxs: [],
      flyRange: 0,
      commandIndex: 1,
      missionCommand: {
        wp: 1,
        cf: 3,
        cmd: 22,
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        lat: 0,
        lng: 0,
        alt: 20,
        p8: 1,
      },
      optionsAnalyticsAction: [
        { value: 0, text: "None" },
        { value: 50001, text: "Start Analytic" },
        { value: 50002, text: "Stop Analytic" },
        { value: 50003, text: "Stop All Analytic" },
      ],
      optionsAnalyticsModel: [
        { value: 0, text: "None" },
        { value: 1, text: "Vehicle Detection" },
        { value: 2, text: "Traffic Survey" },
        { value: 3, text: "Lane Detection" },
        { value: 4, text: "Traffic Measurement" },
      ],
      optionsEnableDeepZoom: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
      optionsDeepZoomDeviceID: [
        { value: 0, text: "None" },
        { value: 1, text: "POS_1-patrol" },
        { value: 2, text: "POS_2-patrol" },
        { value: 3, text: "POS_3-patrol" },
        { value: 4, text: "POS_4-patrol" },
        { value: 5, text: "POS_5-patrol" },
        { value: 6, text: "POS_6-patrol" },
        { value: 7, text: "POS_7-patrol" },
        { value: 8, text: "POS_8-patrol" },
        { value: 9, text: "POS_9-patrol" },
        { value: 10, text: "POS_10-patrol" },
        { value: 11, text: "POS_11-patrol" },
        { value: 12, text: "POS_12-patrol" },
        { value: 13, text: "POS_13-patrol" },
        { value: 14, text: "POS_14-patrol" },
        { value: 15, text: "POS_15-patrol" },
        { value: 16, text: "POS_16-patrol" },
        { value: 17, text: "POS_17-patrol" },
        { value: 18, text: "POS_18-patrol" },
        { value: 19, text: "POS_19-patrol" },
        { value: 20, text: "POS_20-patrol" },
        { value: 21, text: "POS_21-patrol" },
        { value: 22, text: "POS_22-patrol" },
        { value: 23, text: "POS_23-patrol" },
        { value: 24, text: "POS_24-patrol" },
        { value: 25, text: "POS_25-patrol" },
        { value: 26, text: "POS_26-patrol" },
        { value: 27, text: "POS_27-patrol" },
        { value: 28, text: "POS_28-patrol" },
        { value: 29, text: "POS_29-patrol" },
      ],
      optionsCameraAction: [
        { value: 0, text: "No Change" },
        { value: 1, text: "Take Photo" }, //T0 be checked
        { value: 2, text: "Take Photos (Time)" },
        { value: 3, text: "Take Photo (Distance)" },
        { value: 4, text: "Stop Taking Photos" },
        { value: 5, text: "Start Record Video" },
        { value: 6, text: "Stop Recording Video" },
      ],
      optionsCameraMission: [
        { value: 0, text: "Photo" },
        { value: 1, text: "Video" }, //T0 be checked
        { value: 2, text: "Survey" },
      ],
      missionOption: [],
      missionSelected: {},
      missions: [],
      //////////////////////////
      webAPI: this.$store.state.webAPI,
      deviceKey: this.$store.state.deviceKey,
      selectedAsset: this.$store.state.selectedAsset,
      customerAssets: this.$store.state.customerAsset,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
      missionInfo: {
        msid: null,
        name: null,
        desc: null,
        sid: 2,
        cid: this.$store.state.customerID,
        mission: [],
        totalRange: 0,
        flightTime: 0,
      },
      siteSelectOption: [],
      siteInfo: {
        polygon: null,
      },
      landScapeBoolOptions: [
        { item: true, name: "Landscape" },
        { item: false, name: "Portrait" },
      ],
      SetIsDistanceOption: [
        { item: true, name: "Fixed GSD" },
        { item: false, name: "Fixed Alt" },
      ],
      entryPptions: ["top-left", "top-right", "bot-left", "bot-right"],
      droneSpeed: {
        TakeoffSpeed: 2.5,
        OnAirSpeed: 8,
        LandingSpeed: [1.5, 0.5, 0.3],
        ExtraTime: 30,
      },
      draw_gridSurvey: {
        paths: [],
        polygon: null,
        markers: [],
        midleLine: [],
        center: [0, 0],
        flightPath: {
          markers: [],
          polyline: null,
          paths: [],
        },
        showStatistic: null,
        preview_data: {},
      },
      gridSurvey: {
        camera: {
          CameraName: "Custom Camera",
          Specification: {
            FocalLength: 4.08,
            ImageHeight: 3888,
            ImageWidth: 5184,
            SensorHeight: 6.6294,
            SensorWidth: 8.83478,
          },
          Style: {
            FrontalOverlap: 70,
            SideOverlap: 70,
            DistanceToSurface: 100,
            ImageDensity: 5.0,
            Landscape: true,
            ValueSetIsDistance: false,
            Pitch: -80,
            PitchRaw: 80,
          },
        },
        polygon: {
          polygon: [],
          Properties: {
            TurnAroundDistance: 0,
            Angle: 0,
            entryLocation: "top-left",
            PolygonCoverage: false,
            Refly90Degrees: true,
          },
        },
      },
      chartOptions: {
        chart: {
          id: "chart2",
          type: "line",
          height: 230,
          foreColor: "#ccc",
          toolbar: {
            autoSelected: "pan",
            show: false,
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        colors: ["#01881C", "#00BAEC"],
        stroke: {
          show: true,
          curve: ["smooth", "straight", "stepline"],
          // lineCap: "butt",
          // colors: undefined,
          width: 2,
          dashArray: 0,
        },
        grid: {
          borderColor: "#555",
          clipMarkers: false,
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: [0, 5],
          colors: ["#01881C", "#00BAEC"],
          strokeColor: "#00BAEC",
          strokeWidth: 3,
        },
        tooltip: {
          theme: "dark",
        },
        xaxis: {
          type: "numeric",
        },
        fill: {
          type: ["gradient", "solid"],
          gradient: {
            enabled: true,
            type: "vertical",
            opacityFrom: 1,
            opacityTo: 0.5,
          },
        },
        // yaxis: {
        //   min: 0,
        //   tickAmount: 4,
        // },
      },
      series: [
        {
          name: "Ground Level",
          type: "area",
          // data: [30, 40, 45, 50, 49, 60, 70, 81],
          data: [],
        },
        {
          name: "Fly Altitude",
          type: "line",
          // data: [30, 40, 45, 50, 49, 60, 70, 81],
          data: [],
        },
      ],
    };
  },
  mounted() {
    if (this.customerAssets.length == 0) this.getAssets();
    else {
      this.customerAssets.sites.forEach((obj) => {
        this.siteSelectOption.push({ value: obj.sid, text: obj.name });
      });
      this.missionInfo.sid = this.siteSelectOption[0].value;
      this.map = new window.google.maps.Map(this.$refs["map"], {
        mapTypeId: "satellite",
        center: { lat: 13.932, lng: 100.573 },
        zoom: 17,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      });
      const bounds = new window.google.maps.LatLngBounds(
        // new window.google.maps.LatLng(12.993362, 101.442383),
        new window.google.maps.LatLng(12.996194676996756, 101.44234656957615),
        // new window.google.maps.LatLng(12.99652, 101.449361)
        new window.google.maps.LatLng(12.993267640020683, 101.44929863155996)
      );
      // let image = require("../assets/map/ptic.png");
      let image = require("../assets/map/PTIC_Orthomosaic.png");

      class USGSOverlay extends window.google.maps.OverlayView {
        bounds;
        image;
        div;
        constructor(bounds, image) {
          super();
          this.bounds = bounds;
          this.image = image;
        }
        /**
         * onAdd is called when the map's panes are ready and the overlay has been
         * added to the map.
         */
        onAdd() {
          this.div = document.createElement("div");
          this.div.style.borderStyle = "none";
          this.div.style.borderWidth = "0px";
          this.div.style.position = "absolute";

          // Create the img element and attach it to the div.
          const img = document.createElement("img");

          img.src = this.image;
          img.style.width = "100%";
          img.style.height = "100%";
          img.style.position = "absolute";
          this.div.appendChild(img);

          // Add the element to the "overlayLayer" pane.
          const panes = this.getPanes();

          panes.overlayLayer.appendChild(this.div);
        }
        draw() {
          // We use the south-west and north-east
          // coordinates of the overlay to peg it to the correct position and size.
          // To do this, we need to retrieve the projection from the overlay.
          const overlayProjection = this.getProjection();
          // Retrieve the south-west and north-east coordinates of this overlay
          // in LatLngs and convert them to pixel coordinates.
          // We'll use these coordinates to resize the div.
          const sw = overlayProjection.fromLatLngToDivPixel(
            this.bounds.getSouthWest()
          );
          const ne = overlayProjection.fromLatLngToDivPixel(
            this.bounds.getNorthEast()
          );

          // Resize the image's div to fit the indicated dimensions.
          if (this.div) {
            this.div.style.left = sw.x + "px";
            this.div.style.top = ne.y + "px";
            this.div.style.width = ne.x - sw.x + "px";
            this.div.style.height = sw.y - ne.y + "px";
          }
        }
        /**
         * The onRemove() method will be called automatically from the API if
         * we ever set the overlay's map property to 'null'.
         */
        onRemove() {
          if (this.div) {
            this.div.parentNode.removeChild(this.div);
            delete this.div;
          }
        }
        /**
         *  Set the visibility to 'hidden' or 'visible'.
         */
        hide() {
          if (this.div) {
            this.div.style.visibility = "hidden";
          }
        }
        show() {
          if (this.div) {
            this.div.style.visibility = "visible";
          }
        }
        toggle() {
          if (this.div) {
            if (this.div.style.visibility === "hidden") {
              this.show();
            } else {
              this.hide();
            }
          }
        }
        toggleDOM(map) {
          if (this.getMap()) {
            this.setMap(null);
          } else {
            this.setMap(map);
          }
        }
      }

      const overlay = new USGSOverlay(bounds, image);

      overlay.setMap(this.map);
      this.elevator = new window.google.maps.ElevationService();
      this.planPointer = {};
      this.flightPath = new window.google.maps.Polyline({
        path: [],
        geodesic: true,
        strokeColor:
          "linear-gradient(#6778FF 100%, #6778FF 100%, #2636DB 100%)",
        // strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      const that = this;
      this.map.addListener("click", function (mapsMouseEvent) {
        that.addMarker(mapsMouseEvent.latLng);
      });
      this.sidChanged();
    }
    // this.getTerrin();
    // this.test_fn();
  },
  created() {
    // let commandCode = require("../assets/cmd.json");
    // this.commandOptions = commandCode.cmd;
    // this.commandOptions.forEach(this.generateCommandCode);
  },
  methods: {
    changeDefaultSpeedOption() {
      console.log("speedOptionChange");
      let currentObj = this;
      if (this.mission.default.speed.isForcedAll) {
        currentObj.mission.missions.forEach((item) => {
          item.speed.value = this.mission.default.speed.value;
          item.speed.isActivated = true;
        });
      } else {
        currentObj.mission.missions.forEach((item) => {
          item.speed.value = NaN;
          item.speed.isActivated = false;
        });
        if (this.mission.missions.length > 0) {
          currentObj.mission.missions[0].speed.value =
            this.mission.default.speed.value;
          currentObj.mission.missions[0].speed.isActivated = true;
        }
      }
    },
    changeDefaultSpeed() {
      console.log("changeDefaultSpeed");
      let currentObj = this;
      if (this.mission.default.speed.isForcedAll) {
        currentObj.mission.missions.forEach((item) => {
          item.speed.value = this.mission.default.speed.value;
          item.speed.isActivated = true;
        });
      } else {
        if (this.mission.missions.length > 0) {
          currentObj.mission.missions[0].speed.value =
            this.mission.default.speed.value;
          currentObj.mission.missions[0].speed.isActivated = true;
        }
      }
    },
    changeDefaultAlt() {
      console.log("changeDefaultAlt");
      const val = this.mission.default.altitude;
      setTimeout(() => {
        console.log("Delayed for 1 second.");
        if (val === this.mission.default.altitude) {
          let currentObj = this;
          currentObj.missionTemplate.altitude = this.mission.default.altitude;
          currentObj.mission.missions.forEach((item) => {
            if (!item.isSpecificAltitude) {
              item.altitude = this.mission.default.altitude;
            }
          });
          this.getTerrin();
        }
      }, 1000);
    },
    async estimateDefaultConfiguration() {
      let currentObj = this;
      var isForcedAll = true;
      var initialSpeed = 0.0;
      if (this.mission.missions.length > 0)
        initialSpeed = this.mission.missions[0].speed.value;
      var alt = [];
      await this.mission.missions.forEach((item) => {
        alt.push(item.altitude);
      });
      currentObj.mission.default.altitude = modeSorted(alt);
      currentObj.missionTemplate.altitude = parseFloat(
        currentObj.mission.default.altitude
      );
      console.log("Default alt : ", currentObj.mission.default.altitude);
      await this.mission.missions.forEach((item) => {
        if (item.altitude != currentObj.mission.default.altitude) {
          item.isSpecificAltitude = true;
        } else {
          item.isSpecificAltitude = false;
        }
        if (isForcedAll) {
          console.log(isForcedAll, item.speed.isActivated);
          isForcedAll = isForcedAll && item.speed.isActivated;
          if (initialSpeed != parseFloat(item.speed.value)) {
            isForcedAll = false;
          }
        }
      });
      currentObj.mission.default.speed.isForcedAll = isForcedAll;
    },
    async getTerrin() {
      console.log("Get Terrin function Call");
      let currentObj = this;
      // const path = [
      //   { lat: 12.99418689369652, lng: 101.44408066429435 },
      //   { lat: 12.980414858273038, lng: 101.44641520945608 },
      // ];
      var path = [];
      var altitude_conf = [];
      var index_data = [];
      this.mission.missions.forEach((item, index) => {
        if (index === 0 || (item.isLocation && item.isPath)) {
          path.push({ lat: item.location.lat(), lng: item.location.lng() }); //mission.missions.0.location.lat
          altitude_conf.push(parseFloat(item.altitude));
          index_data.push(item.index + 1);
        }
      });
      // console.log(path);
      // const elevator = new window.google.maps.ElevationService();
      // var x = 0;
      var alt1;
      alt1 = await this.getAltitude(path);
      currentObj.series[1].data = [];
      currentObj.series[0].data = [];
      // console.log(alt1);
      const offset = parseFloat(alt1[0].elevation);
      // const offset = -20;

      // console.log("Path : ", path);
      const sample = 20;
      // var index = 0;

      // for (let item of alt1) {
      for (var index = 0; index < alt1.length; index++) {
        // await alt1.forEach(async (item, index) => {
        // currentObj.series[1].data.push([index, (offset + parseFloat(item.elevation.toFixed(2)))]);
        // currentObj.series[1].data.push([index_data[index], (offset + altitude_conf[index])]);
        currentObj.series[1].data.push([
          parseFloat(index_data[index].toFixed(2)),
          parseFloat((offset + altitude_conf[index]).toFixed(2)),
        ]);
        // console.log(item);
        // Call for terrin data.
        if (index + 1 < alt1.length) {
          await this.getElevation(
            [path[index], path[index + 1]],
            sample,
            index,
            index_data
          );
        }
        // await currentObj.series[0].data.push(await this.getElevation(
        //   [path[index],path[index + 1]],
        //   sample,index, index_data
        // ));
        // index++;
        // });
      }

      // var samples = sample * (path.length - 1);

      // elevator
      //   .getElevationAlongPath({
      //     path: path,
      //     samples: samples,
      //   })
      //   .then((results) => {
      //     console.log(results);
      //     currentObj.series[0].data = [];
      //     // var i = 0.0;
      //     results.results.forEach((item, index) => {
      //       var i = Math.floor(index / sample);
      //       currentObj.series[0].data.push([
      //         parseFloat(
      //           (
      //             index_data[i] +
      //             ((index % sample) / sample) *
      //               (index_data[i + 1] - index_data[i])
      //           ).toFixed(2)
      //         ),
      //         parseFloat(item.elevation.toFixed(2)),
      //       ]);
      //       // console.log(typeof currentObj.series[1].data[currentObj.series[1].data.length -1][0]);
      //       // i = i + 1 / 50;
      //     });
      //     this.$refs.chart.updateSeries(
      //       [
      //         {
      //           data: this.series[0].data,
      //         },
      //         {
      //           data: this.series[1].data,
      //         },
      //       ],
      //       false,
      //       true
      //     );
      //   })
      //   .catch((e) => {
      //     console.error(e);
      //   });
      this.$refs.chart.updateSeries(
        [
          {
            data: this.series[0].data,
          },
          {
            data: this.series[1].data,
          },
        ],
        false,
        true
      );
    },
    async test_fn() {
      let currentObj = this;
      const path = [
        { lat: 12.99418689369652, lng: 101.44408066429435 },
        { lat: 12.980414858273038, lng: 101.44641520945608 },
      ];
      currentObj.series[0].data = await this.getElevation(path, 50, 0);
      // this.getAltitude(path);
      this.$refs.chart.updateSeries(
        [
          {
            data: this.series[0].data,
          },
          // {
          //   data: this.series[1].data,
          // },
        ],
        false,
        true
      );
    },
    async getAltitude(path) {
      var altitude = [];
      await this.elevator
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        .getElevationForLocations({
          locations: path,
        })
        .then((result) => {
          // var data = [];
          // console.log(result);
          altitude = result.results;
        })
        .catch((e) => {
          console.error(e);
        });
      // console.log(altitude);
      return altitude;
    },
    async getTerrinElevation(path, index) {
      var terrin = await this.getElevation(path, 50, index);
      return terrin;
    },
    async getElevation(path, sample, index, index_data) {
      let currentObj = this;
      // var data = [];
      const elevator = new window.google.maps.ElevationService();
      await elevator
        .getElevationAlongPath({
          path: path,
          samples: sample,
        })
        .then((results) => {
          results.results.forEach((item, el_index) => {
            currentObj.series[0].data.push([
              // data.push([
              parseFloat(
                (
                  index_data[index] +
                  ((el_index % sample) / sample) *
                    (index_data[index + 1] - index_data[index])
                ).toFixed(2)
              ),
              parseFloat(item.elevation.toFixed(2)),
            ]);
          });
          // console.log(data);
          // return data;
        })
        .catch((e) => {
          console.error(e);
          console.log(path);
        });
    },
    sidChanged() {
      // let currentObj = this;
      // clear sitePolygon
      // try {
      //   this.clearMap(this.siteInfo.polygon);
      // } catch (error) {
      //   console.error(error);
      // }
      // CreateNew sitePlygon
      this.missionSelected = {};

      let siteFench = this.getSiteInfo(this.missionInfo.sid).fence;
      var center = [0, 0];

      siteFench.forEach((item) => {
        // console.log(center, " | ", item);
        if (center[0] === 0 && center[1] === 0) {
          center = item;
        } else {
          center[0] = (center[0] + item[0]) / 2;
          center[1] = (center[1] + item[1]) / 2;
        }
      });
      // console.log(center[0], " | ", center[1]);
      // this.decodeMission();
      // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      this.map.setCenter(new window.google.maps.LatLng(center[0], center[1]));
      this.getMissions();
      // currentObj.siteInfo.polygon = this.drawPolygon(siteFench);
    },
    async getAssets() {
      console.log("Get Asset Function");
      // this.datashowing.spinner = true;
      let currentObj = this;
      await this.axios
        .get(this.webAPI + "/app/api/asset?cid=" + this.customerID, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then((response) => {
          if (response.status === 400) {
            sessionStorage.clear();
            this.$store.commit("setLoginState", false);
            window.location.href = "/#/";
          }
          currentObj.customerAssets = response.data;
          // console.log(currentObj.assetList);
          currentObj.$store.commit(
            "setcustomerAsset",
            currentObj.customerAssets
          );
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          console.log("Get Asset Function__EER");
          currentObj.output2 = error;
          // alert(error);
        });

      console.log(this.customerAssets.devices);
      this.customerAssets.sites.forEach((obj) => {
        this.siteSelectOption.push({ value: obj.sid, text: obj.name });
      });
      this.missionInfo.sid = this.siteSelectOption[0].value;
      this.map = new window.google.maps.Map(this.$refs["map"], {
        mapTypeId: "satellite",
        center: { lat: 13.932, lng: 100.573 },
        zoom: 17,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      });
      this.planPointer = {};
      this.flightPath = new window.google.maps.Polyline({
        path: [],
        geodesic: true,
        strokeColor:
          "linear-gradient(#6778FF 100%, #6778FF 100%, #2636DB 100%)", //"#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      const that = this;
      this.map.addListener("click", function (mapsMouseEvent) {
        that.addMarker(mapsMouseEvent.latLng);
      });
      this.sidChanged();
    },
    getSiteInfo(sid) {
      var siteInfo = null;
      // console.log("sid:", sid);
      try {
        this.customerAssets.sites.forEach((obj) => {
          // console.log(obj.name);
          if (obj.sid === sid) {
            // console.log("name:", obj.name);
            siteInfo = obj;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return siteInfo;
    },
    drawPolygon() {
      this.draw_gridSurvey.paths = [];
      this.draw_gridSurvey.markers.forEach(this.buildNewPath);
      return new window.google.maps.Polygon({
        paths: this.draw_gridSurvey.paths,
        strokeColor: "#FFA500",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: "#FFA500",
        fillOpacity: 0.35,
        map: this.map,
      });
    },
    buildNewPath(item) {
      this.draw_gridSurvey.paths.push(item.position);
      if (this.draw_gridSurvey.center[0] != 0)
        this.draw_gridSurvey.center[0] =
          (this.draw_gridSurvey.center[0] + item.position.lat()) / 2;
      else this.draw_gridSurvey.center[0] = item.position.lat();
      if (this.draw_gridSurvey.center[1] != 0)
        this.draw_gridSurvey.center[1] =
          (this.draw_gridSurvey.center[1] + item.position.lng()) / 2;
      else this.draw_gridSurvey.center[1] = item.position.lng();
    },
    // drawPolygon(item) {
    //   var paths = [];
    //   var sumLat = 0;
    //   var sumLng = 0;
    //   item.forEach((obj) => {
    //     paths.push(new window.google.maps.LatLng(obj[0], obj[1]));
    //     sumLat = sumLat + obj[0];
    //     sumLng = sumLng + obj[1];
    //   });
    //   console.log(paths);
    //   sumLat = sumLat / paths.length;
    //   sumLng = sumLng / paths.length;
    //   this.map.setCenter(new window.google.maps.LatLng(sumLat, sumLng));
    //   return new window.google.maps.Polygon({
    //     paths: paths,
    //     strokeColor: "#FFA500",
    //     strokeOpacity: 0.8,
    //     strokeWeight: 3,
    //     fillColor: "#FFA500",
    //     fillOpacity: 0.15,
    //     map: this.map,
    //   });
    // },
    RunningIndex(item, index) {
      // console.log("Running New Index...", index);
      item.index = index;
      if (item.name != "Cancel ROI") {
        item.marker.setMap(null);
        // var icon = item.marker.getIcon();
        if (item.name == "Waypoint") {
          // icon.label = (index + 1).toString();
          // icon.title = "P#" + (index + 1).toString();

          item.marker.label = (index + 1).toString();
          item.marker.title = "P#" + (index + 1).toString();
        } else if (item.name == "SET ROI") {
          // icon.label = "ROI-" + (index + 1).toString();
          // icon.title = "Region of Interest-" + (index + 1).toString();

          item.marker.label = "ROI-" + (index + 1).toString();
          item.marker.title = "Region of Interest-" + (index + 1).toString();
        }
        // item.marker.setIcon(icon);
        item.marker.setMap(this.map);
      }
    },
    changeParam(item, index) {
      this.path[index - 1] = new window.google.maps.LatLng(item.lat, item.lng);
      this.clearPathPlan();
      this.initialMarker(this.home.lat, this.home.lng);
      // this.form.Waypoint.forEach(this.repathData);
      this.polylinedrow();
      this.path.forEach(this.addMarker2);
      this.calTotalRange();
    },
    calTimeBetween: function (distance) {
      return distance / this.droneSpeed.OnAirSpeed;
      // if (distance > 0) {
      //   let A = 2; // m/s2
      //   let Vmax = 10; // m/s
      //   let Vmid = (D * A) ^ 0.5;
      //   var Tab = 0;
      //   if (Vmid < Vmax) {
      //     //Case 1
      //     Tab = 2 * (Vmid / A);
      //   } else {
      //     //Case 2
      //     Tab = Vmax / A + D / Vmax;
      //   }
      //   return Tab;
      // } else {
      //   return 0;
      // }
    },
    calTimeTakeoff: function (altitude) {
      return altitude / this.droneSpeed.TakeoffSpeed;
    },
    calTimeLanding: function (altitude) {
      var time = 0;
      if (altitude > 10) {
        time += (altitude - 10) / this.droneSpeed.LandingSpeed[0];
        altitude = 10;
      }
      if (5 < altitude <= 10) {
        time +=
          (2 * (altitude - 5)) /
          (this.droneSpeed.LandingSpeed[1] + this.droneSpeed.LandingSpeed[2]);
        altitude = 5;
      }
      if (altitude <= 5) {
        time += altitude / this.droneSpeed.LandingSpeed[2];
        altitude = 0;
      }
      return time;
    },
    calTotalRange: function () {
      var i;
      this.distance = 0;
      this.timeRange = 0;
      for (i = 1; i < this.mission.missions.length; i++) {
        let pp = this.getDistance(
          this.mission.missions[i - 1].location,
          this.mission.missions[i].location
        );
        this.distance += pp;
        this.timeRange += this.calTimeBetween(pp);
      }
      // console.log(
      //   "Total Distant : ",
      //   this.distance,
      //   "  Total Flight Time : ",
      //   this.timeRange
      // );
    },
    // calTimeBetween: function(D) {
    //   if (D > 0) {
    //     let A = 2; // m/s2
    //     let Vmax = 10; // m/s
    //     let Vmid = (D * A) ^ 0.5;
    //     var Tab = 0;
    //     if (Vmid < Vmax) {
    //       //Case 1
    //       Tab = 2 * (Vmid / A);
    //     } else {
    //       //Case 2
    //       Tab = Vmax / A + D / Vmax;
    //     }
    //     return Tab;
    //   } else {
    //     return 0;
    //   }
    // },
    getDistance: function (p1, p2) {
      var R = 6378137; // Earth’s mean radius in meter
      var dLat = this.rad(p2.lat() - p1.lat());
      var dLong = this.rad(p2.lng() - p1.lng());
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.rad(p1.lat())) *
          Math.cos(this.rad(p2.lat())) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      // console.log("Calculate : ", d, " m");
      return d; // returns the distance in meter
    },
    // async getdata() {
    //   let currentObj = this;
    //   await this.axios
    //     .get(this.apiServer + "/asset/v2/list/" + this.customer)
    //     .then(function(response) {
    //       currentObj.assets = response.data;
    //       // alert("Request Command : "+response.data.message);
    //     })
    //     .catch(function(error) {
    //       currentObj.output2 = error;
    //       // alert(error);
    //     });
    //   this.assets.forEach(this.addDataToList);
    //   //////////////////////////////// TO GET BOX LOCATION for HOME LOCATION//////////////////////////////////////////////////////////
    // },
    // addDataToList(item) {
    //   if (item.type == "Drone") {
    //     this.drones.push(item.id);
    //     // console.log("Add Drone");
    //   } else if (item.type == "Box") {
    //     this.boxs.push(item.id);
    //     // console.log("Add Box");
    //   }
    // },
    // generateCommandCode(item) {
    //   this.commandCodeDetail[item.value] = item;
    // },
    calTime() {
      var d = new Date();
      this.timeNow =
        d.getHours().toString().padStart(2, "0") +
        ":" +
        d.getMinutes().toString().padStart(2, "0") +
        ":" +
        d.getSeconds().toString().padStart(2, "0");
    },
    // async dronedata() {
    //   let currentObj = this;
    //   if (!this.isSimulate) {
    //     await this.axios
    //       .get(this.apiServer + "/drone/v2/status/UAV-0000001")
    //       .then(function(response) {
    //         currentObj.dronestatus = response.data;
    //       })
    //       .catch(function(error) {
    //         currentObj.output2 = error;
    //       });
    //   }

    //   if (
    //     currentObj.dronestatus.GPS_lat != 0 &&
    //     currentObj.dronestatus.GPS_lon != 0
    //   ) {
    //     currentObj.path.push({
    //       lat: currentObj.dronestatus.GPS_lat,
    //       lng: currentObj.dronestatus.GPS_lon,
    //     });
    //   }
    //   if (currentObj.path.length > 6000) {
    //     currentObj.path.shift();
    //   }
    //   // this.caltime();
    //   if (currentObj.dronestatus.StateCode >= 1100) {
    //     // this.planPath.setMap(null);
    //     this.clearPathPlan();
    //   } else if (
    //     currentObj.dronestatus.StateCode >= 500 &&
    //     currentObj.dronestatus.StateCode < 1100 &&
    //     currentObj.dronestatus.MissionID != currentObj.LoadedPlanNo
    //   ) {
    //     currentObj.LoadedPlanNo = currentObj.dronestatus.MissionID;
    //     this.Getpathdata(currentObj.dronestatus.MissionID);
    //   }
    //   var index = Math.round(currentObj.dronestatus.StateCode / 100);
    //   currentObj.dronestatus.StateCodeTxt = this.state_code[index];
    //   this.locChange();
    //   this.polylinedrow();
    //   // this.Plandrow();
    // },
    // async boxdata() {
    //   if (!this.isSimulate) {
    //     let currentObj = this;
    //     // console.log("call : " + this.apiServer + "/box/v2/status/" + this.boxID);
    //     await this.axios
    //       .get(this.apiServer + "/box/v2/status/DBX-000001") // + this.boxID)
    //       .then(function(response) {
    //         currentObj.boxtatus = response.data;
    //         // console.log(currentObj.boxtatus);
    //         // alert("Request Command : "+response.data.message);
    //       })
    //       .catch(function(error) {
    //         currentObj.output2 = error;
    //         // alert(error);
    //       });
    //     // this.caltime();
    //   }
    // },
    // clearPathPlan() {
    //   this.planPath.setMap(null);
    //   if (this.marker2 != []) {
    //     this.marker2.forEach(this.clearMarker);
    //   }
    // },
    // initialMarker(lat, lon) {
    //   var image = {
    //     url: require("../assets/DB2_50x50.png"),
    //     size: new window.google.maps.Size(50, 50),
    //     origin: new window.google.maps.Point(0, 0),
    //     anchor: new window.google.maps.Point(15, 15),
    //   };
    //   this.marker2[0] = new window.google.maps.Marker({
    //     position: new window.google.maps.LatLng(lat, lon),
    //     map: this.map,
    //     icon: image,
    //     title: "Box",
    //     zIndex: 0,
    //   });
    //   this.plan.push({ lat: lat, lng: lon });
    //   this.marker2[0].setMap(this.map);
    // },
    clearMarker(item) {
      item.setMap(null);
    },
    findPointBeforeIndex(index) {
      var BIndex = 0;
      // Find previous point of flight path
      for (let loop = index - 1; loop >= 0; loop--) {
        if (
          this.mission.missions[loop].isLocation &&
          this.mission.missions[loop].isPath
        ) {
          BIndex = loop;
          break;
        }
        if (loop == 0) {
          BIndex = 0;
          break;
        }
      }
      return BIndex;
    },

    findPointNextIndex(index) {
      var NIndex = 0;
      // Find previous point of flight path
      for (let loop = index + 1; loop < this.mission.missions.length; loop++) {
        if (
          this.mission.missions[loop].isLocation &&
          this.mission.missions[loop].isPath
        ) {
          NIndex = loop;
          break;
        }
      }
      return NIndex;
    },
    addMarker(location) {
      // console.log(location);
      let item = JSON.parse(JSON.stringify(this.missionTemplate));
      item.location = location;
      item.index = this.mission.missions.length;
      if (this.instructionKey.isWPSelected) {
        item.name = "Waypoint";
        item.isLocation = true;
        // let rotation = 0;
        if (this.mission.missions.length > 0) {
          item.isPath = true;
          var changeIndexBegin = this.findPointBeforeIndex(item.index);
          item.path = this.polyPath([
            this.mission.missions[changeIndexBegin].location,
            location,
          ]);
        }
        item.marker = this.displayMarker(
          location.lat(),
          location.lng(),
          item.index
        );

        item.marker.addListener("drag", () => {
          if (item.index > 0) {
            var changeIndexBefore = this.findPointBeforeIndex(item.index);
            item.path.setMap(null);
            item.path = this.polyPath([
              this.mission.missions[changeIndexBefore].location,
              item.marker.position,
            ]);
            item.path.setMap(this.map);
          }
          item.location = item.marker.position;
          // Check Is not last point of mission path
          if (this.mission.missions.length - 1 > item.index) {
            var changeIndex = this.findPointNextIndex(item.index);
            if (changeIndex > 0) {
              this.mission.missions[changeIndex].path.setMap(null);
              this.mission.missions[changeIndex].path = this.polyPath([
                item.marker.position,
                this.mission.missions[changeIndex].marker.position,
              ]);
              this.mission.missions[changeIndex].path.setMap(this.map);
            }
          }
          // this.calTotalRange();
        });

        item.marker.addListener("dragend", () => {
          this.calTotalRange();
          this.getTerrin();
        });
        this.mission.missions.push(item);
        if (this.mission.missions.length > 1) {
          this.calTotalRange();
          this.getTerrin();
        }
      } else if (this.instructionKey.isROISelected) {
        if (this.instructionKey.isROIfirst) {
          item.name = "SET ROI";
          item.isPath = false;
          item.isLocation = true;
          this.instructionKey.ROImsg = "Cancel ROI";
          item.marker = this.displayROI(
            location.lat(),
            location.lng(),
            item.index
          );
          item.ROILocation = location;
          this.instructionKey.isROIfirst = !this.instructionKey.isROIfirst;
          item.location = this.mission.missions[item.index - 1].location;
          item.marker.addListener("dragend", () => {
            // item.location = item.marker.position;
            item.ROILocation = item.marker.position;
          });
          this.mission.missions.push(item);
        }
      }
      this.scrollBottom();
    },
    // changeMarkerLoc() {
    //   var icon = this.marker.getIcon();
    //   icon.rotation = this.dronestatus.heading;
    //   this.marker.setIcon(icon);
    //   this.marker.setPosition(
    //     new window.google.maps.LatLng(
    //       this.dronestatus.gpsLat,
    //       this.dronestatus.gpsLon
    //     )
    //   );

    //   this.marker.setMap(this.map);
    //   // this.changeRotate();
    // },
    polyPath(path) {
      // const lineSymbol = {
      //   path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
      // };
      // const aIcon = {
      //   icon: lineSymbol,
      //   offset: "100%",
      //   repeat: "50px",
      // };
      // let icon = [aIcon];
      return new window.google.maps.Polyline({
        path: path,
        //icons: icon,
        geodesic: true,
        strokeColor: "#00FA9A", //#6778FF
        // strokeColor: "#6778FF",
        strokeOpacity: 1,
        strokeWeight: 5,
        map: this.map,
      });
    },
    displayROI(lat, lon, index) {
      const svgMarker2 = {
        path: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
        fillColor: "yellow",
        fillOpacity: 0.3,
        strokeWeight: 0.2,
        // rotation: rotation,
        scale: 1,
        labelOrigin: new window.google.maps.Point(100, 100),
        anchor: new window.google.maps.Point(100, 100),
      };
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lon),
        icon: svgMarker2,
        label: "ROI-" + (index + 1).toString(),
        map: this.map,
        draggable: true,
        // animation: new window.google.maps.Animation.DROP,
        // zIndex: 0,
        title: "Region of Interest-" + (index + 1).toString(),
      });
    },
    displayMarker(lat, lon, index) {
      // var image = {
      //   url: require("../assets/point.png"),
      //   // url: require("../assets/ROI1.png"),
      //   // This marker is 20 pixels wide by 32 pixels high.
      //   size: new window.google.maps.Size(30, 30),
      //   // The origin for this image is (0, 0).
      //   origin: new window.google.maps.Point(0, 0),
      //   // The anchor for this image is the base of the flagpole at (0, 32).
      //   anchor: new window.google.maps.Point(15, 15),
      // };
      var image = {
        path: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
        fillColor: "white",
        fillOpacity: 0.8,
        strokeColor: "#D9211B",
        // strokeColor: "#00FA9A",

        strokeOpacity: 0.8,
        strokeWeight: 3,
        // strokeColor: "white",
        // rotation: rotation,
        scale: 0.2,
        labelOrigin: new window.google.maps.Point(100, 100),
        anchor: new window.google.maps.Point(100, 100),
      };
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lon),
        icon: image,
        label: (index + 1).toString(),
        map: this.map,
        draggable: true,
        // animation: new window.google.maps.Animation.DROP,
        // zIndex: 0,
        title: "P#" + (index + 1).toString(),
      });
    },
    displayCam(lat, lon, rotation) {
      const svgMarker2 = {
        path: "M -6.4 -10 L 0 -3 L 6.4 -10 Z",
        // path: "M 0, 0 m -37.5, 0 a 37.5,37.5 90 1,0 75,0 a 37.5,37.5 90 1,0 -75,0",
        // path: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
        fillColor: "white",
        fillOpacity: 0.6,
        strokeWeight: 1,
        rotation: rotation,
        scale: 2,
        anchor: new window.google.maps.Point(0, 0),
      };
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lon),
        icon: svgMarker2,
        // label: 'P',
        map: this.map,
        // zIndex: 0,
        // title: "WP#" + index.toString(),
      });
    },
    // async addMarker2(item, index) {
    //   console.log("index:", index);
    //   if (index > 0) {
    //     // console.log('index = ' + index.toString())
    //     var image = {
    //       url: require("../assets/point.png"),
    //       // This marker is 20 pixels wide by 32 pixels high.
    //       size: new window.google.maps.Size(30, 30),
    //       // The origin for this image is (0, 0).
    //       origin: new window.google.maps.Point(0, 0),
    //       // The anchor for this image is the base of the flagpole at (0, 32).
    //       anchor: new window.google.maps.Point(15, 15),
    //     };
    //     this.marker2.push(
    //       new window.google.maps.Marker({
    //         position: item,
    //         icon: image,
    //         label: index.toString(),
    //         map: this.map,
    //         zIndex: 0,
    //         title: "WP#" + index.toString(),
    //       })
    //     );
    //     //this.marker2[index].setMap(this.map);
    //   }
    // },
    // polylinedrow() {
    //   //alert('Drawing polyline ........')
    //   this.flightPath.setMap(null);
    //   // this.closeLine.setMap(null);
    //   const lineSymbol = {
    //     path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
    //   };
    //   const aIcon = {
    //     icon: lineSymbol,
    //     offset: "100%",
    //     repeat: "50px",
    //   };
    //   // Define a symbol using SVG path notation, with an opacity of 1.
    //   // const DlineSymbol = {
    //   //   path: "M 0,-1 0,1",
    //   //   strokeOpacity: 1,
    //   //   scale: 4,
    //   // };
    //   var icon = [];
    //   for (let i = 0; i < this.path.length; i++) {
    //     icon.push(aIcon);
    //     console.log(i);
    //   }
    //   this.flightPath = new window.google.maps.Polyline({
    //     path: this.path,
    //     icons: icon,
    //     geodesic: true,
    //     strokeColor: "YELLOW",
    //     strokeOpacity: 1,
    //     strokeWeight: 2,
    //   });
    //   // this.closeLine = new window.google.maps.Polyline({
    //   //   path: [this.path[this.path.length - 1], this.path[0]],
    //   //   strokeOpacity: 0,
    //   //   strokeColor: "YELLOW",
    //   //   icons: [
    //   //     {
    //   //       icon: DlineSymbol,
    //   //       offset: "0",
    //   //       repeat: "20px",
    //   //     },
    //   //   ],
    //   // });
    //   this.flightPath.setMap(this.map);
    //   // this.closeLine.setMap(this.map);
    //   //alert('DONE ........')
    // },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    filecheck(inputfile) {
      if (inputfile != null) {
        // this.show = true;
        let formData = new FormData();
        formData.append("file", this.file);
        let currentObj = this;
        this.axios
          .post(this.apiServer + "/mission/v2/decode", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => (this.items = response.data))
          .catch(function (error) {
            currentObj.output = error;
          });
        this.delayTime();
      }
    },
    // sethome() {
    //   this.map.setCenter({ lat: this.home.lat, lng: this.home.lng });
    // },
    rad: function (x) {
      return (x * Math.PI) / 180;
    },
    buildPath(item) {
      this.path.push(new window.google.maps.LatLng(item.lat, item.lng));
    },
    removePoint(index) {
      if (index > -1) {
        if (this.mission.missions[index].isLocation) {
          if (index > 0) {
            if (this.mission.missions[index].isPath) {
              this.mission.missions[index].path.setMap(null);
            }
          }
          if (index < this.mission.missions.length - 1) {
            let nextPoint = this.findPointNextIndex(index);
            if (nextPoint > 0) {
              let previousPoint = this.findPointBeforeIndex(index);
              this.mission.missions[nextPoint].path.setMap(null);
              this.mission.missions[nextPoint].path = this.polyPath([
                this.mission.missions[previousPoint].marker.position,
                this.mission.missions[nextPoint].marker.position,
              ]);
              this.mission.missions[nextPoint].path.setMap(this.map);
            }
          }
          this.mission.missions[index].marker.setMap(null);
        }
        this.mission.missions.splice(index, 1);
        // deepcode ignore UseArrowFunction: <please specify a reason of ignoring this>
        this.mission.missions.forEach(this.RunningIndex);
      }
    },
    removeMission(index) {
      if (index > -1) {
        if (this.mission.missions[index].name == "SET ROI") {
          this.mission.missions[index].marker.setMap(null);
          this.mission.missions.splice(index, 1);
        } else if (this.mission.missions[index].name == "Cancel ROI") {
          this.mission.missions.splice(index, 1);
        } else if (this.mission.missions[index].name == "Waypoint") {
          this.mission.missions[index].marker.setMap(null);
          // this.mission.missions[index].camMarker.setMap(null);
          if (index > 0) {
            this.mission.missions[index].path.setMap(null);
          }
          var changeIndexUpper = this.findPointNextIndex(index);
          if (changeIndexUpper > 0) {
            if (changeIndexUpper <= this.mission.missions.length - 1) {
              this.mission.missions[changeIndexUpper].path.setMap(null);
            }
          }
          this.mission.missions.splice(index, 1);

          var changeIndexLower = this.findPointBeforeIndex(index);
          if (index > 0) {
            if (!this.mission.missions[index].name.includes("ROI")) {
              this.mission.missions[index].path.setMap(null);
              this.mission.missions[index].path = this.polyPath([
                this.mission.missions[changeIndexLower].marker.position,
                this.mission.missions[index].marker.position,
              ]);
              this.mission.missions[index].path.setMap(this.map);
            }
          }
          if (index + 1 <= this.mission.missions.length - 1) {
            if (!this.mission.missions[index].name.includes("ROI")) {
              this.mission.missions[index + 1].path.setMap(null);
              this.mission.missions[index + 1].path = this.polyPath([
                this.mission.missions[index].marker.position,
                this.mission.missions[index + 1].marker.position,
              ]);
              this.mission.missions[index + 1].path.setMap(this.map);
            }
          }
        }
      }
      // deepcode ignore UseArrowFunction: <please specify a reason of ignoring this>
      this.mission.missions.forEach(this.RunningIndex);
    },
    moveMissionUp(index) {
      let item1 = JSON.parse(JSON.stringify(this.mission.missions[index]));
      let item2 = JSON.parse(JSON.stringify(this.mission.missions[index - 1]));
      // this.mission.missions.splice(index, 1);
      // this.mission.missions.splice(index, 1, item2);
      this.mission.missions.splice(index - 1, 1, item1);
      this.mission.missions.splice(index, 1, item2);

      // let item_up = JSON.parse(
      //   JSON.stringify(this.mission.missions[index - 1])
      // );
      // this.mission.missions[index] = item_up;
      // this.mission.missions[index - 1] = item;
      // this.mission.push(item);
      // this.mission.pop();
      // console.log("Move Up....................");
    },
    moveMissionDown(index) {
      let item1 = JSON.parse(JSON.stringify(this.mission.missions[index]));
      let item2 = JSON.parse(JSON.stringify(this.mission.missions[index + 1]));
      // this.mission.missions.splice(index, 1);
      this.mission.missions.splice(index + 1, 1, item1);
      this.mission.missions.splice(index, 1, item2);
      // let item_down = JSON.parse(
      //   JSON.stringify(this.mission.missions[index + 1])
      // );
      // this.mission.missions[index] = item_down;
      // this.mission.missions[index + 1] = item;
      // console.log("Move Down....................");
    },
    duplicateMission(index) {
      let item = JSON.parse(JSON.stringify(this.mission.missions[index]));
      this.mission.missions.push(item);
      // this.mission.push(item);
      // this.mission.pop();
    },
    instructionSelect(key) {
      if (key === "WP") {
        //
        if (this.instructionKey.isSurvey) this.clearGridSurvey();
        this.instructionKey.isROISelected = false;
        this.instructionKey.isWPSelected = true;
        this.instructionKey.isSurvey = false;
        // this.instructionKey.isROIfirst = true;
      } else if (key === "ROI") {
        //
        if (this.instructionKey.isSurvey) this.clearGridSurvey();

        if (!this.instructionKey.isROIfirst) {
          this.instructionKey.ROImsg = "ROI";
          let item = JSON.parse(JSON.stringify(this.missionTemplate));
          // item.location = location;
          item.index = this.mission.missions.length;
          item.name = "Cancel ROI";
          item.location = this.mission.missions[item.index - 1].location;
          this.mission.missions.push(item);
          this.instructionKey.isROIfirst = true;
          this.instructionKey.isROISelected = false;
          this.instructionKey.isWPSelected = true;
          this.instructionKey.isSurvey = false;
        } else {
          this.instructionKey.isROISelected = true;
          this.instructionKey.isWPSelected = false;
          this.instructionKey.isSurvey = false;
        }
      } else if (key === "Survey" && !this.instructionKey.isSurvey) {
        let bound = this.map.getBounds();
        let center = this.map.getCenter();
        this.instructionKey.isROISelected = false;
        this.instructionKey.isWPSelected = false;
        this.instructionKey.isSurvey = true;
        // let currentObj = this;

        let sw = bound.getSouthWest();
        let ne = bound.getNorthEast();
        console.log(center);
        console.log(sw);
        console.log(ne);
        let cen_lat = center.lat();
        let cen_lng = center.lng();
        let top = ne.lat();
        let buttom = sw.lat();
        let left = sw.lng();
        let right = ne.lng();

        console.log("cen : ", cen_lat);
        console.log("top : ", top);
        console.log("but : ", buttom);
        console.log("cen : ", cen_lng);
        console.log("lef : ", left);
        console.log("rig : ", right);

        let y1 = (cen_lng + left) / 2;
        let y2 = (cen_lng + right) / 2;
        let x1 = (cen_lat + top) / 2;
        let x2 = (cen_lat + buttom) / 2;
        // var pos_center = [center.lat(),center.lng()];
        // console.log('LAT : ', pos_center[0]);
        // console.log('LON : ', pos_center[1]);
        // const const_pos = 0.0002;
        console.log("x1,y1 : ", [x1, y1]);
        console.log("x2,y1 : ", [x2, y1]);
        console.log("x2,y2 : ", [x2, y2]);
        console.log("x1,y2 : ", [x1, y2]);
        console.log("Marker Lenght : ", this.draw_gridSurvey.markers.length);
        if (this.draw_gridSurvey.markers.length > 0) {
          this.draw_gridSurvey.markers.forEach(this.clearMarker);
          this.draw_gridSurvey.markers = [];
        }
        var polygon_paths = [];
        polygon_paths.push([x1, y1]);
        polygon_paths.push([x2, y1]);
        polygon_paths.push([x2, y2]);
        polygon_paths.push([x1, y2]);
        console.log(polygon_paths);
        polygon_paths.forEach(this.addpoint);
        this.map.setCenter(
          new window.google.maps.LatLng(
            this.draw_gridSurvey.center[0],
            this.draw_gridSurvey.center[1]
          )
        );

        // currentObj.draw_gridSurvey.polygon = this.drawPolygon(this.gridSurvey.polygon.polygon);
        // if (!this.instructionKey.isROIfirst) {
        //   this.instructionKey.ROImsg = "Survey";
        //   let item = JSON.parse(JSON.stringify(this.missionTemplate));
        //   // item.location = location;
        //   item.index = this.mission.missions.length;
        //   item.name = "Cancel ROI";
        //   item.location = this.mission.missions[item.index - 1].location;
        //   this.mission.missions.push(item);
        //   this.instructionKey.isROIfirst = true;
        //   this.instructionKey.isROISelected = false;
        //   this.instructionKey.isWPSelected = true;
        // } else {
        //   this.instructionKey.isROISelected = true;
        //   this.instructionKey.isWPSelected = false;
        // }
      }
    },
    addpoint(location) {
      // var marker = this.displayMarker(location.lat(), location.lng(), " ");
      var marker = this.displayMarkerGridSurvey(location[0], location[1], " ");

      marker.addListener("drag", () => {
        if (this.draw_gridSurvey.paths.length > 1) {
          //
          try {
            this.draw_gridSurvey.polygon.setMap(null);
          } catch {
            //
          }
          this.draw_gridSurvey.polygon = this.drawPolygon();
          this.drawMidlePoint();
        }
      });
      this.draw_gridSurvey.markers.push(marker);
      // this.site.paths.push(location);
      if (this.draw_gridSurvey.markers.length > 1) {
        //
        try {
          this.draw_gridSurvey.polygon.setMap(null);
        } catch {
          //
        }
        this.draw_gridSurvey.polygon = this.drawPolygon();
        this.drawMidlePoint();
      }
    },
    drawMidlePoint() {
      this.draw_gridSurvey.midleLine.forEach((item) => item.setMap(null));
      this.draw_gridSurvey.midleLine = [];
      var pl = this.draw_gridSurvey.markers.length;
      for (let i = 1; i < pl; i++) {
        console.log(this.draw_gridSurvey.markers[i - 1].position.lat());
        console.log(this.draw_gridSurvey.markers[i].position.lat());
        var calPoint = this.calculateMidlePosition(
          this.draw_gridSurvey.markers[i - 1],
          this.draw_gridSurvey.markers[i]
        );
        console.log(calPoint.lat, ", ", calPoint.lng);
        this.buildMidlePoint(calPoint.lat, calPoint.lng, i - 1);
      }
      var point = this.calculateMidlePosition(
        this.draw_gridSurvey.markers[pl - 1],
        this.draw_gridSurvey.markers[0]
      );
      this.buildMidlePoint(point.lat, point.lng, pl - 1);
    },
    calculateMidlePosition(p1, p2) {
      var lat = (p1.position.lat() + p2.position.lat()) / 2;
      var lng = (p1.position.lng() + p2.position.lng()) / 2;
      return { lat: lat, lng: lng };
    },
    buildMidlePoint(lat, lng, index) {
      var item = this.displayMarkerGridSurvey(lat, lng, "+");
      item.addListener("click", () => {
        console.log("Clicked : ", index);
        var newMarker = this.displayMarkerGridSurvey(
          item.position.lat(),
          item.position.lng(),
          " "
        );
        newMarker.addListener("drag", () => {
          if (this.draw_gridSurvey.paths.length > 1) {
            //
            try {
              this.draw_gridSurvey.polygon.setMap(null);
            } catch {
              //
            }
            // deepcode ignore WrongNumberOfArguments: <please specify a reason of ignoring this>
            this.draw_gridSurvey.polygon = this.drawPolygon(
              this.gridSurvey.polygon.polygon
            );
            this.drawMidlePoint();
          }
        });
        this.draw_gridSurvey.markers.splice(index + 1, 0, newMarker);
        try {
          this.draw_gridSurvey.polygon.setMap(null);
        } catch {
          //
        }
        this.draw_gridSurvey.polygon = this.drawPolygon();
        this.drawMidlePoint();
      });
      this.draw_gridSurvey.midleLine.push(item);
    },
    displayMarkerGridSurvey(lat, lon, label) {
      var dragOption = true;
      var image = {
        path: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
        fillColor: "white",
        fillOpacity: 0.8,
        strokeColor: "#FFA500",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        // strokeColor: "white",
        // rotation: rotation,
        scale: 0.2,
        labelOrigin: new window.google.maps.Point(100, 100),
        anchor: new window.google.maps.Point(100, 100),
      };
      if (label == "+") {
        image = {
          path: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
          fillColor: "white",
          fillOpacity: 0.5,
          strokeWeight: 1,
          strokeColor: "white",
          // rotation: rotation,
          scale: 0.25,
          labelOrigin: new window.google.maps.Point(100, 100),
          anchor: new window.google.maps.Point(100, 100),
        };
        dragOption = false;
      }
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lon),
        icon: image,
        label: {
          text: label,
          color: "white",
          fontSize: "30px",
        },
        map: this.map,
        draggable: dragOption,
        // animation: new window.google.maps.Animation.DROP,
        // zIndex: 0,
        // title: "P#" + (index + 1).toString(),
      });
    },
    CalRotation(p1, p2) {
      let x = p2.lng() - p1.lng();
      let y = p2.lat() - p1.lat();
      let base = 0;
      // console.log("x : ", x, " y: ", y);
      if (x >= 0) {
        if (y >= 0) base = 0;
        else base = 90;
      } else {
        if (y >= 0) base = 240;
        else base = 180;
      }
      // console.log("base : ", base);
      // console.log("Atan : ", Math.atan(y / x));
      let dddd = base + Math.atan(y / x);
      // console.log("Rotate Cal : ", dddd);
      return dddd;
    },
    scrollBottom() {
      var objDiv = this.$refs["cmdGroup"]; //document.getElementById("your_div");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    async createNewMission() {
      let currentObj = this;
      swal
        .fire({
          title: "Please input new mission name",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Create Mission",
          showLoaderOnConfirm: true,
          preConfirm: (login) => {
            console.log("Exist:", currentObj.dataMission.missionName);
            console.log("New:", login);
            currentObj.missionInfo.name = login;
          },
          allowOutsideClick: () => !swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.createMission();
          }
        })
        .catch(e => console.error(e));
    },
    async createMission() {
      this.commandIndex = 1;
      // this.dataMission.missionName = this.mission.name;
      // this.dataMission.missionDescription = this.mission.description;
      // this.dataMission.totalRange = this.distance;
      this.missionInfo.totalRange = this.distance;
      // this.dataMission.flightTime = this.timeRange;
      this.missionInfo.flightTime = this.timeRange;
      // this.dataMission.by = this.user;
      // this.dataMission.commands = [];
      this.mission.missions.forEach(this.prepareMission);
      let currentObj = this;

      // console.log("mission request : ", JSON.stringify(this.dataMission));
      // console.log("call : " + this.apiServer + "/box/v2/status/" + this.boxID);
      await this.axios
        // .post(this.apiServer + "/createmission", {
        //   mission: currentObj.dataMission,
        // }) // + this.boxID)
        .post(this.webAPI + "/manage/api/msid", currentObj.missionInfo, {
          headers: {
            authorization: this.apiKey,
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log("res_code : ", response);
          swal.fire({
            icon: "success",
            title: "Your Mission has been created",
            showConfirmButton: false,
            timer: 2000,
          });
          window.location.href = "/#/";
        })
        .catch(function (error) {
          console.error(error);
          swal.fire({
            icon: "error",
            title: "Oops... ",
            text: "Something went wrong! Please try again later",
            //footer: "<a href>Why do I have this issue?</a>",
          });
        });
    },
    async deleteMission() {
      let currentObj = this;
      // console.log("mission request : ", JSON.stringify(this.dataMission));
      // console.log("call : " + this.apiServer + "/box/v2/status/" + this.boxID);
      await this.axios
        // .post(this.apiServer + "/remove/mission", {
        //   missionID: currentObj.missionSelected.missionID,
        // }) // + this.boxID)
        .delete(
          this.webAPI + "/manage/api/msid?msid=" + currentObj.missionInfo.msid,
          {
            headers: {
              authorization: this.apiKey,
            },
          }
        )
        .then(function (response) {
          console.log("res_code : ", response);
          swal.fire({
            icon: "success",
            title:
              "Mission ID : " +
              currentObj.missionSelected.missionID +
              " has been removed.",
            showConfirmButton: false,
            timer: 2000,
          });
          window.location.href = "/#/";
        })
        .catch(function (error) {
          console.warn(error);
          swal.fire({
            icon: "error",
            title: "Oops... ",
            text: "Something went wrong! Please try again later",
            //footer: "<a href>Why do I have this issue?</a>",
          });
        });
    },
    prepareMission(item, index) {
      if (index == 0) {
        this.dataMission.commands = [];
        this.missionInfo.mission = [];
      }
      let cmd = JSON.parse(JSON.stringify(this.missionCommand));
      if (item.name === "Waypoint") {
        //
        cmd.wp = this.commandIndex;
        cmd.cmd = 16;
        cmd.p1 = item.hold;
        if (item.yaw.isActivated)
          if (item.yaw.value == 0) cmd.p4 = 0.01;
          else cmd.p4 = item.yaw.value;
        else cmd.p4 = 0;
        cmd.lat = item.location.lat();
        cmd.lng = item.location.lng();
        cmd.alt = item.altitude;
        this.dataMission.commands.push(cmd);
        this.missionInfo.mission.push(cmd);

        if (item.speed.isActivated) {
          this.commandIndex += 1;
          let cmd4 = JSON.parse(JSON.stringify(this.missionCommand));
          cmd4.wp = this.commandIndex;
          cmd4.cmd = 178;
          cmd4.p1 = 1;
          cmd4.p2 = item.speed.value;
          cmd4.p3 = -1;
          this.dataMission.commands.push(cmd4);
          this.missionInfo.mission.push(cmd4);
        }

        let cmd2 = JSON.parse(JSON.stringify(this.missionCommand));
        // console.log(cmd2);
        if (item.camera.commandCode == 1) {
          this.commandIndex += 1;
          cmd2.wp = this.commandIndex;
          cmd2.cmd = 2000;
          cmd2.cf = 2;
          cmd2.p3 = 1;
          this.dataMission.commands.push(cmd2);
          this.missionInfo.mission.push(cmd2);
        } else if (item.camera.commandCode == 2) {
          this.commandIndex += 1;
          cmd2.wp = this.commandIndex;
          cmd2.cmd = 2000;
          cmd2.cf = 2;
          cmd2.p2 = item.camera.time;
          this.dataMission.commands.push(cmd2);
          this.missionInfo.mission.push(cmd2);
        } else if (item.camera.commandCode == 3) {
          this.commandIndex += 1;
          cmd2.wp = this.commandIndex;
          cmd2.cmd = 206;
          cmd2.p1 = item.camera.distance;
          cmd2.p3 = 1;
          cmd2.cf = 2;
          this.missionInfo.mission.push(cmd2);
          this.dataMission.commands.push(cmd2);
        } else if (item.camera.commandCode == 4) {
          this.commandIndex += 1;
          cmd2.wp = this.commandIndex;
          cmd2.cmd = 206;
          cmd2.cf = 2;
          this.missionInfo.mission.push(cmd2);
          this.dataMission.commands.push(cmd2);
          this.commandIndex += 1;
          let cmd3 = JSON.parse(JSON.stringify(this.missionCommand));
          cmd3.wp = this.commandIndex;
          cmd3.cmd = 2001;
          cmd2.cf = 2;
          this.dataMission.commands.push(cmd3);
          this.missionInfo.mission.push(cmd3);
        } else if (item.camera.commandCode == 5) {
          this.commandIndex += 1;
          cmd2.wp = this.commandIndex;
          cmd2.cmd = 2500;
          cmd2.p2 = 0.2;
          cmd2.cf = 2;
          this.dataMission.commands.push(cmd2);
          this.missionInfo.mission.push(cmd2);
        } else if (item.camera.commandCode == 6) {
          this.commandIndex += 1;
          cmd2.wp = this.commandIndex;
          cmd2.cmd = 2501;
          cmd2.cf = 2;
          this.missionInfo.mission.push(cmd2);
          this.dataMission.commands.push(cmd2);
        }

        if (item.camera.isGimbalControl) {
          let cmd22 = JSON.parse(JSON.stringify(this.missionCommand));
          this.commandIndex += 1;
          cmd22.wp = this.commandIndex;
          cmd22.cmd = 205;
          cmd22.cf = 2;
          cmd22.p1 = parseInt(item.camera.gimbalPitch) * -1;
          cmd22.p3 = item.camera.gimbalYaw;
          cmd22.alt = 2;
          this.missionInfo.mission.push(cmd22);
          this.dataMission.commands.push(cmd22);
        }

        if (item.analytics.commandCode > 0) {
          let cmd_ml = JSON.parse(JSON.stringify(this.missionCommand));
          this.commandIndex += 1;
          cmd_ml.wp = this.commandIndex;
          cmd_ml.cmd = item.analytics.commandCode;
          cmd_ml.cf = 2;
          cmd_ml.p1 = item.analytics.model;
          cmd_ml.p2 = item.analytics.isDeepZoom;
          cmd_ml.p3 = item.analytics.deepZoomDeviceID;
          this.dataMission.commands.push(cmd_ml);
          this.missionInfo.mission.push(cmd_ml);
        }
      } else if (item.name === "SET ROI") {
        //
        cmd.wp = this.commandIndex;
        cmd.cmd = 195;
        cmd.p1 = 0;
        cmd.p2 = 0;
        cmd.p3 = 0;
        cmd.p4 = 0;
        cmd.lat = item.ROILocation.lat();
        cmd.lng = item.ROILocation.lng();
        cmd.alt = item.altitude;
        cmd.cf = 3;
        this.missionInfo.mission.push(cmd);
        this.dataMission.commands.push(cmd);
      } else if (item.name === "Cancel ROI") {
        //
        cmd.wp = this.commandIndex;
        cmd.cmd = 197;
        cmd.p1 = 0;
        cmd.p2 = 0;
        cmd.p3 = 0;
        cmd.p4 = 0;
        cmd.lat = 0;
        cmd.lng = 0;
        cmd.alt = 0;
        cmd.cf = 2;
        this.missionInfo.mission.push(cmd);
        this.dataMission.commands.push(cmd);
      }
      this.commandIndex += 1;
    },
    async getMissions() {
      // console.log("Get Mission");
      // this.dataTask.ETA = this.dataTask.ETD + this.dataTask.totalTime;
      let currentObj = this;
      currentObj.missionOption = [];
      await this.axios
        // .get(this.apiServer + "/getmissionlist/" + this.siteName)
        .get(
          // this.webAPI + "/manage/api/msid?sid=" + this.selectedAsset.info.sid, //missionInfo.sid
          this.webAPI + "/manage/api/msid?sid=" + this.missionInfo.sid,
          {
            headers: {
              authorization: this.apiKey,
            },
          }
        )
        .then(function (response) {
          currentObj.missions = response.data;
          // this.onReset();
          // alert("Request Command : "+response.data.message);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      this.missions.missions.forEach(this.buildMissionOption);
      this.missionOption.push({ value: {}, text: "---Create New Mission---" });
    },
    buildMissionOption(item) {
      this.missionOption.push({ value: item, text: item.missionName });
    },
    decodeMission() {
      // console.log("decodeMission");
      this.mission.missions.forEach(this.clearMap);
      ////////////////////////////////////////////////////
      // this.series[0].data = [];
      this.mission.missions = [];
      this.mission.name = "";
      // deepcode ignore UncomaparableValues: <please specify a reason of ignoring this>
      if (this.missionSelected === {}) {
        //Do here new
        this.mission.description = "";
        this.missionInfo.desc = null;
        this.missionInfo.msid = null;
        this.missionInfo.totalRange = 0;
        this.missionInfo.flightTime = 0;
        this.mission.default.speed.value = 10.0;
        this.mission.default.speed.isForcedAll = false;
        this.mission.default.altitude = 50.0;
      } else {
        //Build mission viewer
        this.missionInfo.msid = this.missionSelected.missionID;
        this.mission.description = this.missionSelected.missionDesc;
        this.missionInfo.desc = this.missionSelected.missionDesc;
        this.missionInfo.totalRange = this.missionSelected.totalRange;
        this.missionInfo.flightTime = this.missionSelected.EstMissionTime;
        var checkedID = 0;
        checkedID = 0;
        for (let i = 0; i < this.missionSelected.wpCMD.length; i++) {
          // console.log("WP_ID : ", checkedID);
          var cmd = this.missionSelected.wpCMD[i];
          if (cmd == 16) {
            // Waypoints
            checkedID = this.mission.missions.length;
            let item = JSON.parse(JSON.stringify(this.missionTemplate));
            item.name = "Waypoint";
            item.isLocation = true;
            item.index = this.mission.missions.length;
            // item.isSpecificAltitude = true;
            item.altitude = this.missionSelected.wpAlt[i];
            item.location = new window.google.maps.LatLng(
              this.missionSelected.wpLat[i],
              this.missionSelected.wpLng[i]
            );
            item.hold = this.missionSelected.wpP1[i];
            if (this.missionSelected.wpP4[i] > 0) {
              item.yaw.isActivated = true;
              item.yaw.value = this.missionSelected.wpP4[i];
            }
            if (this.mission.missions.length > 0) {
              var changeIndexBegin = this.findPointBeforeIndex(item.index);
              item.isPath = true;
              item.path = this.polyPath([
                this.mission.missions[changeIndexBegin].location,
                item.location,
              ]);
            }
            item.marker = this.displayMarker(
              this.missionSelected.wpLat[i],
              this.missionSelected.wpLng[i],
              this.mission.missions.length
            );
            item.marker.addListener("drag", () => {
              // console.log(
              //   "Location : ",
              //   item.marker.position.lat(),
              //   " , ",
              //   item.marker.position.lng()
              // );
              if (item.index > 0) {
                var changeIndexBefore = this.findPointBeforeIndex(item.index);
                item.path.setMap(null);
                item.path = this.polyPath([
                  this.mission.missions[changeIndexBefore].location,
                  item.marker.position,
                ]);
                item.path.setMap(this.map);
              }
              item.location = item.marker.position;

              if (item.index < this.mission.missions.length - 1) {
                // console.log('Item Point is : ', item.index);
                var changeIndex = this.findPointNextIndex(item.index);
                // console.log('Next Point is : ', changeIndex);
                if (changeIndex > 0) {
                  this.mission.missions[changeIndex].path.setMap(null);
                  this.mission.missions[changeIndex].path = this.polyPath([
                    item.marker.position,
                    this.mission.missions[changeIndex].marker.position,
                  ]);
                  this.mission.missions[changeIndex].path.setMap(this.map);
                }
              }
            });

            item.marker.addListener("dragend", () => {
              this.calTotalRange();
              this.getTerrin();
            });
            this.mission.missions.push(item);
            if (this.mission.missions.length > 1) {
              this.calTotalRange();
              // this.getTerrin();
            }
          } else if (cmd == 178) {
            //Change Speed
            this.mission.missions[checkedID].speed.isActivated = true;
            this.mission.missions[checkedID].speed.value =
              this.missionSelected.wpP2[i];
          } else if (cmd == 2000) {
            //Photos with timeinterval (check P3 and P2)

            if (this.missionSelected.wpP2[i] > 0) {
              this.mission.missions[checkedID].camera.commandCode = 2;
              this.mission.missions[checkedID].camera.time =
                this.missionSelected.wpP2[i];
            } else {
              this.mission.missions[checkedID].camera.commandCode = 1;
            }
          } else if (cmd == 2001) {
            //Stop Photos with timeinterval
            // console.log("CMD Code line 1870");
            this.mission.missions[checkedID].camera.commandCode = 4;
          } else if (cmd == 206) {
            //Photos with distance
            if (this.missionSelected.wpP1[i] > 0) {
              // console.log("CMD Code line 1875");
              this.mission.missions[checkedID].camera.commandCode = 3;
              this.mission.missions[checkedID].camera.distance =
                this.missionSelected.wpP1[i];
            } else {
              // console.log("CMD Code line 1880");
              // console.log(this.mission.missions[checkedID]);
              this.mission.missions[checkedID].camera.commandCode = 4;
            }
          } else if (cmd == 2500) {
            // Recode Video
            this.mission.missions[checkedID].camera.commandCode = 5;
          } else if (cmd == 2501) {
            // Stop Video
            this.mission.missions[checkedID].camera.commandCode = 6;
          } else if (cmd == 205) {
            // Gimbal Control
            this.mission.missions[checkedID].camera.isGimbalControl = true;
            this.mission.missions[checkedID].camera.gimbalPitch =
              parseInt(this.missionSelected.wpP1[i]) * -1; //parseInt(item.camera.gimbalPitch) * -1
            this.mission.missions[checkedID].camera.gimbalYaw =
              this.missionSelected.wpP3[i]; //item.camera.gimbalYaw;
          } else if (cmd == 50001) {
            // Start Analytic
            this.mission.missions[checkedID].analytics.isML = true;
            this.mission.missions[checkedID].analytics.commandCode = 50001;
            this.mission.missions[checkedID].analytics.model = parseInt(
              this.missionSelected.wpP1[i]
            );
            this.mission.missions[checkedID].analytics.isDeepZoom = parseInt(
              this.missionSelected.wpP2[i]
            );
            this.mission.missions[checkedID].analytics.deepZoomDeviceID =
              parseInt(this.missionSelected.wpP3[i]);
          } else if (cmd == 50002) {
            // Stop Analytic
            this.mission.missions[checkedID].analytics.isML = true;
            this.mission.missions[checkedID].analytics.commandCode = 50002;
            this.mission.missions[checkedID].analytics.model = parseInt(
              this.missionSelected.wpP1[i]
            );
            this.mission.missions[checkedID].analytics.isDeepZoom = parseInt(
              this.missionSelected.wpP2[i]
            );
            this.mission.missions[checkedID].analytics.deepZoomDeviceID =
              parseInt(this.missionSelected.wpP3[i]);
          } else if (cmd == 50003) {
            // Stop All Analytic
            this.mission.missions[checkedID].analytics.isML = true;
            this.mission.missions[checkedID].analytics.commandCode = 50003;
          } else if (cmd == 195) {
            // Set ROI
            let item = JSON.parse(JSON.stringify(this.missionTemplate));
            item.name = "SET ROI";
            item.altitude = this.missionSelected.wpAlt[i];
            item.isPath = false;
            item.isLocation = true;
            //
            item.marker = this.displayROI(
              this.missionSelected.wpLat[i],
              this.missionSelected.wpLng[i],
              this.mission.missions.length
            );
            item.ROILocation = new window.google.maps.LatLng(
              this.missionSelected.wpLat[i],
              this.missionSelected.wpLng[i]
            );
            item.location =
              this.mission.missions[this.mission.missions.length - 1].location;
            item.marker.addListener("dragend", () => {
              // item.location = item.marker.position;
              item.ROILocation = item.marker.position;
            });
            this.mission.missions.push(item);
          } else if (cmd == 197) {
            // Cancel ROI
            let item = JSON.parse(JSON.stringify(this.missionTemplate));
            item.name = "Cancel ROI";
            item.isLocation = false;
            item.isPath = false;
            // item.altitude = this.missionSelected.wpAlt[i];
            item.location =
              this.mission.missions[this.mission.missions.length - 1].location;
            this.mission.missions.push(item);
          }
          // console.log(checkedID);
        }
        this.mission.default.speed.value = parseFloat(
          this.mission.missions[0].speed.value
        );

        if (this.mission.default.speed.value === 0.0)
          this.mission.default.speed.value = 15.0;
        this.mission.default.speed.isForcedAll = false;
        // this.mission.default.altitude = parseFloat(
        //   this.mission.missions[0].altitude
        // );
        this.locationchange();
        console.log("Decode Call");
        this.getTerrin();
        this.estimateDefaultConfiguration();
      }
      // this.$refs.chart.updateSeries(
      //   [
      //     {
      //       data: this.series[0].data,
      //     },
      //   ],
      //   false,
      //   true
      // );
    },
    locationchange() {
      console.log(
        this.missionSelected.wpLat[0],
        ", ",
        this.missionSelected.wpLng[0]
      );
      this.map.setCenter(
        new window.google.maps.LatLng(
          this.missionSelected.wpLat[0],
          this.missionSelected.wpLng[0]
        )
      );
    },
    clearMap(item) {
      try {
        item.marker.setMap(null);
        item.path.setMap(null);
      } catch {
        //
        // item.marker.setMap(null);
      }
    },
    async updateMission() {
      this.commandIndex = 1;
      // let updateData = new JSON.phase(JSON.stringify(this.dataMission));
      // this.dataMission.missionName = this.missionSelected.missionName;
      // this.dataMission.missionDescription = this.mission.description;
      // this.dataMission.totalRange = this.distance;
      // this.dataMission.flightTime = this.timeRange;
      // this.dataMission.by = this.user;
      this.missionInfo.totalRange = this.distance;
      this.missionInfo.flightTime = this.timeRange;
      // deepcode ignore UseArrowFunction: <please specify a reason of ignoring this>
      this.mission.missions.forEach(this.prepareMission);
      let currentObj = this;
      // console.log("mission request : ", JSON.stringify(this.dataMission));
      // console.log("call : " + this.apiServer + "/box/v2/status/" + this.boxID);
      await this.axios
        // .post(this.apiServer + "/editmission", {
        //   mission: currentObj.dataMission,
        // }) // + this.boxID)
        .put(this.webAPI + "/manage/api/msid", currentObj.missionInfo, {
          headers: {
            authorization: this.apiKey,
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log("res_code : ", response);
          swal.fire({
            icon: "success",
            title: "Your Mission has been updated",
            showConfirmButton: false,
            timer: 2000,
          });
          window.location.href = "/#/";
        })
        .catch(function (error) {
          console.warn(error);
          swal.fire({
            icon: "error",
            title: "Oops... ",
            text: "Something went wrong! Please try again later",
            //footer: "<a href>Why do I have this issue?</a>",
          });
        });
    },
    addPreviewMarker(item, index) {
      console.log(item);
      let currentObj = this;
      if (item.command === 16) {
        var image = {
          url: require("../assets/point.png"),
          // url: require("../assets/ROI1.png"),
          // This marker is 20 pixels wide by 32 pixels high.
          size: new window.google.maps.Size(30, 30),
          // The origin for this image is (0, 0).
          origin: new window.google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new window.google.maps.Point(15, 15),
        };
        currentObj.draw_gridSurvey.flightPath.markers.push(
          new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              item.params[4],
              item.params[5]
            ),
            icon: image,
            label: (index + 1).toString(),
            map: this.map,
            draggable: false,
            // animation: new window.google.maps.Animation.DROP,
            // zIndex: 0,
            title: "P#" + (index + 1).toString(),
          })
        );
        currentObj.draw_gridSurvey.flightPath.paths.push(
          new window.google.maps.LatLng(item.params[4], item.params[5])
        );
      }
    },
    addPreviewpolyPath() {
      console.log("Preview Paths");
      // const lineSymbol = {
      //   path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
      // };
      // const aIcon = {
      //   icon: lineSymbol,
      //   offset: "100%",
      //   repeat: "50px",
      // };
      // let icon = [aIcon];
      return new window.google.maps.Polyline({
        path: this.draw_gridSurvey.flightPath.paths,
        // icons: icon,
        geodesic: true,
        strokeColor: "GREEN",
        // strokeColor: "#00FA9A",
        strokeOpacity: 1,
        strokeWeight: 2,
        map: this.map,
      });
    },
    async getGridPath() {
      let currentObj = this;
      currentObj.gridSurvey.polygon.polygon = [];
      this.draw_gridSurvey.markers.forEach(this.buildGridPolygonArray);
      await this.axios
        // .post(this.apiServer + "/createmission", {
        //   mission: currentObj.dataMission,
        // }) // + this.boxID)
        .post(this.webAPI + "/app/api/gridsurvey", currentObj.gridSurvey, {
          headers: {
            // authorization: this.apiKey,
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log("grid : ", response);
          currentObj.draw_gridSurvey.preview_data = response.data;

          //
          // swal.fire({
          //   icon: "success",
          //   title: "Your Mission has been created",
          //   showConfirmButton: false,
          //   timer: 2000,
          // });
          // window.location.href = "/#/";
        })
        .catch(function (error) {
          console.warn(error);
          swal.fire({
            icon: "error",
            title: "Oops... ",
            text: "Something went wrong! Please try again later",
            //footer: "<a href>Why do I have this issue?</a>",
          });
        });

      // try{
      if (this.draw_gridSurvey.flightPath.markers != [])
        currentObj.draw_gridSurvey.flightPath.markers.forEach(this.clearMarker);
      console.log("clear Markers");
      if (currentObj.draw_gridSurvey.flightPath.polyline != null)
        this.clearMarker(currentObj.draw_gridSurvey.flightPath.polyline);
      console.log("clear Polyline");
      currentObj.draw_gridSurvey.showStatistic =
        this.draw_gridSurvey.preview_data.mission[0].Statistics;
      // currentObj.draw_gridSurvey.flightPath.polyline.setma
      // currentObj.draw_gridSurvey.flightPath.polyline.forEach(this.clearMarker);
      // }
      // catch{
      //   // Not thing
      // }
      // console.log(currentObj.draw_gridSurvey.preview_data.mission[0].Items);
      currentObj.draw_gridSurvey.flightPath.markers = [];
      currentObj.draw_gridSurvey.flightPath.paths = [];
      // deepcode ignore UseArrowFunction: <please specify a reason of ignoring this>
      currentObj.draw_gridSurvey.preview_data.mission[0].Items.forEach(
        currentObj.addPreviewMarker
      );

      currentObj.draw_gridSurvey.flightPath.polyline =
        this.addPreviewpolyPath();
    },
    clearGridSurvey() {
      if (this.draw_gridSurvey.flightPath.markers != []) {
        this.draw_gridSurvey.flightPath.markers.forEach(this.clearMarker);
        this.draw_gridSurvey.flightPath.markers = [];
      }
      console.log("clear Markers");
      if (this.draw_gridSurvey.flightPath.polyline != null) {
        this.clearMarker(this.draw_gridSurvey.flightPath.polyline);
        this.draw_gridSurvey.flightPath.polyline = null;
      }
      console.log("clear Polyline");
      if (this.draw_gridSurvey.markers.length > 0) {
        this.draw_gridSurvey.markers.forEach(this.clearMarker);
        this.draw_gridSurvey.markers = [];
      }
      if (this.draw_gridSurvey.polygon != null) {
        this.draw_gridSurvey.polygon.setMap(null);
        this.draw_gridSurvey.polygon = null;
      }
      if (this.draw_gridSurvey.midleLine.length > 0) {
        this.draw_gridSurvey.midleLine.forEach(this.clearMarker);
        this.draw_gridSurvey.midleLine = [];
      }
    },
    applyGridPath() {
      this.instructionKey.isROISelected = false;
      this.instructionKey.isWPSelected = true;
      this.instructionKey.isSurvey = false;
      this.clearGridSurvey();
      //Build mission viewer
      this.missionInfo.totalRange +=
        this.draw_gridSurvey.showStatistic.TotalDistance;
      this.missionInfo.flightTime +=
        this.draw_gridSurvey.showStatistic.EstMissionTime;
      var checkedID = 0;
      checkedID = 0;
      this.draw_gridSurvey.preview_data.mission[0].Items.forEach((obj) => {
        var cmd = obj.command;
        if (cmd == 16) {
          // Waypoints
          checkedID = this.mission.missions.length;
          let item = JSON.parse(JSON.stringify(this.missionTemplate));
          item.name = "Waypoint";
          item.isLocation = true;
          item.index = this.mission.missions.length;
          item.altitude = obj.params[6];
          item.hold = obj.params[0];
          item.location = new window.google.maps.LatLng(
            obj.params[4],
            obj.params[5]
          );
          if (this.mission.missions.length > 0) {
            var changeIndexBegin = this.findPointBeforeIndex(item.index);
            item.isPath = true;
            item.path = this.polyPath([
              this.mission.missions[changeIndexBegin].location,
              item.location,
            ]);
          }
          item.marker = this.displayMarker(
            obj.params[4],
            obj.params[5],
            this.mission.missions.length
          );
          item.marker.addListener("drag", () => {
            // console.log(
            //   "Location : ",
            //   item.marker.position.lat(),
            //   " , ",
            //   item.marker.position.lng()
            // );
            if (item.index > 0) {
              var changeIndexBefore = this.findPointBeforeIndex(item.index);
              item.path.setMap(null);
              item.path = this.polyPath([
                this.mission.missions[changeIndexBefore].location,
                item.marker.position,
              ]);
              item.path.setMap(this.map);
            }
            item.location = item.marker.position;

            if (item.index < this.mission.missions.length - 1) {
              // console.log('Item Point is : ', item.index);
              var changeIndex = this.findPointNextIndex(item.index);
              // console.log('Next Point is : ', changeIndex);
              if (changeIndex > 0) {
                this.mission.missions[changeIndex].path.setMap(null);
                this.mission.missions[changeIndex].path = this.polyPath([
                  item.marker.position,
                  this.mission.missions[changeIndex].marker.position,
                ]);
                this.mission.missions[changeIndex].path.setMap(this.map);
              }
            }
          });

          item.marker.addListener("dragend", () => {
            this.calTotalRange();
            console.log("Dragend Call");
            this.getTerrin();
          });
          this.mission.missions.push(item);
          if (this.mission.missions.length > 1) {
            this.calTotalRange();
            // this.getTerrin();
          }
        } else if (cmd == 178) {
          //Change Speed
          this.mission.missions[checkedID].speed.isActivated = true;
          this.mission.missions[checkedID].speed.value = obj.params[1];
        } else if (cmd == 2000) {
          //Photos with timeinterval (check P3 and P2)

          if (obj.params[1] > 0) {
            this.mission.missions[checkedID].camera.commandCode = 2;
            this.mission.missions[checkedID].camera.time = obj.params[1];
          } else {
            this.mission.missions[checkedID].camera.commandCode = 1;
          }
        } else if (cmd == 2001) {
          //Stop Photos with timeinterval
          // console.log("CMD Code line 1870");
          this.mission.missions[checkedID].camera.commandCode = 4;
        } else if (cmd == 206) {
          //Photos with distance
          if (obj.params[0] > 0) {
            // console.log("CMD Code line 1875");
            this.mission.missions[checkedID].camera.commandCode = 3;
            this.mission.missions[checkedID].camera.distance = obj.params[0];
          } else {
            // console.log("CMD Code line 1880");
            // console.log(this.mission.missions[checkedID]);
            this.mission.missions[checkedID].camera.commandCode = 4;
          }
        } else if (cmd == 2500) {
          // Recode Video
          this.mission.missions[checkedID].camera.commandCode = 5;
        } else if (cmd == 2501) {
          // Stop Video
          this.mission.missions[checkedID].camera.commandCode = 6;
        } else if (cmd == 205) {
          // Gimbal Control
          this.mission.missions[checkedID].camera.isGimbalControl = true;
          this.mission.missions[checkedID].camera.gimbalPitch =
            parseInt(obj.params[0]) * -1; //parseInt(item.camera.gimbalPitch) * -1
          this.mission.missions[checkedID].camera.gimbalYaw = obj.params[2]; //item.camera.gimbalYaw;
        } else if (cmd == 195) {
          // Set ROI
          let item = JSON.parse(JSON.stringify(this.missionTemplate));
          item.name = "SET ROI";
          item.altitude = obj.params[6];
          item.isPath = false;
          item.isLocation = true;
          //
          item.marker = this.displayROI(
            obj.params[4],
            obj.params[5],
            this.mission.missions.length
          );
          item.ROILocation = new window.google.maps.LatLng(
            obj.params[4],
            obj.params[5]
          );
          item.location =
            this.mission.missions[this.mission.missions.length - 1].location;
          item.marker.addListener("dragend", () => {
            // item.location = item.marker.position;
            item.ROILocation = item.marker.position;
          });
          this.mission.missions.push(item);
        } else if (cmd == 197) {
          // Cancel ROI
          let item = JSON.parse(JSON.stringify(this.missionTemplate));
          item.name = "Cancel ROI";
          item.isLocation = false;
          item.isPath = false;
          // item.altitude = this.missionSelected.wpAlt[i];
          item.location =
            this.mission.missions[this.mission.missions.length - 1].location;
          this.mission.missions.push(item);
        }
      });

      // for (let i = 0; i < this.missionSelected.wpCMD.length; i++) {
      //   // console.log("WP_ID : ", checkedID);

      //   // console.log(checkedID);
      // }
      this.locationchange();
    },
    buildGridPolygonArray(item) {
      this.gridSurvey.polygon.polygon.push([
        item.position.lat(),
        item.position.lng(),
      ]);
    },
  },
};
</script>

<style scoped>
.chart-container {
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  margin: 1rem;

  width: calc(100vw - 480px - 2rem);
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 9999;
  /* padding: 1rem; */
  /* overflow: scroll none; */
  height: 20vh;
  border-radius: 0.5rem;
  padding: 1rem;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
}
.button2-layout {
  font-size: 12px;
  font-family: Roboto;
  display: flex;
  /* padding: 16px; */
  flex-direction: row;
  width: 100%;
  padding: 0px;
  margin: 0px;
  /* align-items: center; */
  /* gap: 3px; */
  justify-content: space-around;
}
.button-layout {
  position: absolute;
  bottom: 10px;
  left: 0;
  display: flex;
  /* padding: 16px; */
  flex-direction: column;
  width: 100%;
  padding: 0px;
  margin: 0px;
  align-items: center;
  gap: 8px;
  /* justify-content: space-around; */
}
.mission {
  position: relative;
  width: calc(100%);
  height: 100vh;
}
.full {
  /* position: absolute; */
  /* left: 0px; */
  /* bottom: 0px; */
  height: 100vh;
  width: 100%;
  display: block;
  margin: 0px;
  padding: 0px;
  z-index: 0;
}
.mini {
  border-radius: 10px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: black;
  width: 400px;
  height: 225px;
  z-index: 1;
}
.alarm {
  background-color: rgba(255, 0, 0, 0.8);
  /* color: yellow; */
}
.contentMission {
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 300px;
  height: 100%;
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 9999;
  padding: 1rem;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
  overflow: scroll none;

  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 400px;
  padding: 0px;
  margin: 0px;
  height: 100%;
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 9999;
  padding: 1rem;
  overflow: scroll none;

  padding: 32px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
}
.instruction {
  display: inline;
  font-size: 2 rem;
  /* justify-content:space-around; */
  /* height: 2rem; */
  /* background: cadetblue; */
  /* padding: auto; */
}
.ins-command {
  /* margin: auto; */
  display: inline-block;
  justify-content: space-around;
  /* border-radius: 1rem; */
  width: 150px;
  height: 75px;
  /* background: coral; */
  /* margin: 1rem; */
  padding: 5px 10px;
  transition: all 0.3s linear;
  font-size: 3 rem;
  border-radius: 5px;
}

.test {
  /* width: 36px; */
  /* border: 2px solid rgb(46, 97, 48); */
  background: rgb(0, 185, 93);
  color: rgb(218, 247, 221);
  border-radius: 0px;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
}
/* .ins-command span, */
.ins-command i {
  font-size: 2.5rem;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  /* background: brown; */
}
.ins-command span {
  display: flex;
  justify-content: center;
  font-size: 1rem;
}
.ins-command:hover {
  background: rgb(83, 83, 83);
  color: rgb(255, 255, 255);
  border-bottom: 2px solid black;
  font-size: 2 rem;
  cursor: pointer;
}
.contentInstruction {
  position: absolute;
  height: 75px;
  top: 1rem;
  left: 450px;
  /* margin: 1rem; */
  /* margin: auto; */
  width: auto;
  /* height: calc(100% - 2rem); */
  background: rgba(242, 242, 242, 0.5);
  z-index: 9999;
  /* padding: 1rem; */
  overflow: scroll none;
  border-radius: 0.5rem;
  /* background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6)); */
  backdrop-filter: blur(10px);
}
.command-header {
  /* color: var(--text-grey); */
  color: var(--color-dark);
  font-size: 0.85em;
  display: block;
  /* margin-left:1rem; */
  padding: auto;
  margin: auto;
  text-align: left;
  /* background: var(--main-color); */
}
.command-header1 {
  display: flex;
  justify-content: space-between;
  transition: all 0.2s linear;
  /* border: #f2f2f2 solid 0.5px; */
  /* border-radius: 5px; */
  /* background: var(--color-dark); */
}
.command-header1:hover {
  cursor: pointer;
  border-bottom: 2px solid black;
  background: rgb(116, 116, 116);
}
.over-green,
.over-red {
  transition: all 0.3s;
  /* border-radius: 50%; */
}
.over-green:hover {
  color: green;
  /* background: #f2f2f2; */
  border-bottom: 2px solid black;
  /* border-radius: 50%; */
}
.over-red:hover {
  color: red;
  /* background: #f2f2f2; */
  border-bottom: 2px solid black;
  /* border-radius: 50%; */
}
.wp-1 {
  color: var(--color-dark);
  font-size: 0.75em;
}
.contentEstimation {
  position: absolute;
  top: 0rem;
  right: 0rem;
  margin: 1rem;
  width: auto;
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 9999;
  /* padding: 1rem; */
  overflow: scroll none;
  border-radius: 0.5rem;
  padding: 1rem;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
}
.est-status i {
  font-size: 2rem;
  margin-right: 5px;
  /* background: brown; */
}
.est-status span {
  /* font-size: 2rem; */
  margin-left: 5px;
  /* background: brown; */
}
.command-group {
  height: auto;
  max-height: calc(100vh - 600px);
  /* min-height: 500px; */
  overflow-y: auto;
}
.command-group::-webkit-scrollbar {
  width: 0em;
}

.command-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.command-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0px solid slategrey;
  border-radius: 2px;
}
.mission-footer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: block;
  margin-left: auto;
  margin-left: 0rem;
  width: 270px;
  padding: 1 rem;
}
.grid-surv-option {
  position: absolute;
  top: 50%;
  right: 0rem;
  transform: translate(0%, -50%);
  margin: 1rem;
  width: auto;
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 9999;
  /* padding: 1rem; */
  overflow: scroll none;
  border-radius: 0.5rem;
  padding: 1rem;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
}
b-tab {
  font-size: 0.75rem;
}
.tab-class {
  font-size: 0.5rem;
}
.nav-tab-calss {
  font-size: 0.5rem;
}
.wey-point-body {
  margin-left: 12px;
  margin-right: 12px;
}
</style>
