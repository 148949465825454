<template>
  <div>
    <div class="main-content" style="background: var(--color-dark)">
      <header1/>
      <b-button v-b-modal.add-modal variant="primary" class="btn-create-battery"
        ><b-icon icon="plus-circle" style="font-size: 24px"></b-icon> Register
        New Asset</b-button
      >
      <div class="container-table">
        <b-table
          sticky-header="950px"
          hover
          :fields="fields"
          :items="assets"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(sid)="row">
            {{ getName(sites_json[row.item.sid]) }}
          </template>
          <template v-slot:cell(cid)="row">
            {{ getName(customers_json[row.item.cid]) }}
          </template>
          <template v-slot:cell(mid)="row">
            {{ getName(assets_json[row.item.mid]) }} ({{ row.item.mid }})
          </template>
          <template v-slot:cell(Action)="row">
            <!-- <b-button-toolbar style="justify-content: end; display: flex"> -->
            <b-button-group>
              <b-button variant="warning" @click="requestUpdateData(row.item)">
                <i class="las la-edit" style="font-size: 24px"></i>
                Update
              </b-button>
              <b-button
                variant="info"
                :to="'/mnt/asset_dashboard/' + row.item.aid"
              >
                <b-icon icon="info-square" aria-hidden="true"></b-icon>
                Details
              </b-button>
              <b-button @click="confirmDelete(row.item)" variant="danger">
                <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                Delete
              </b-button>
            </b-button-group>
            <!-- </b-button-toolbar> -->
          </template>
        </b-table>
        <!-- </b-card-text> -->
        <!-- </b-card> -->
        <!-- </b-container> -->
      </div>
      <!-- //////// Create Customer Form \\\\\\\\\\\\\ -->
      <b-modal id="add-modal" hide-footer title="Create New Customer">
        <b-form v-on:submit.prevent="createCustomer">
          <b-input-group prepend="Code Name :" class="mb-2 mr-sm-2 mb-sm-2">
            <b-form-input
              id="inline-form-input-username"
              placeholder="Enter Code Name"
              v-model="form.code"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="Name :" class="mb-2 mr-sm-2 mb-sm-2">
            <b-form-input
              id="inline-form-input-username"
              placeholder="Enter customer Name"
              v-model="form.name"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="Description :" class="mb-2 mr-sm-2 mb-sm-2">
            <b-form-input
              id="inline-form-input-username"
              placeholder="Enter Description"
              v-model="form.desc"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="Line App.ID :" class="mb-2 mr-sm-2 mb-sm-2">
            <b-form-input
              id="inline-form-input-username"
              type="text"
              required
              placeholder="Line Notify App ID"
              v-model="form.lineID"
            ></b-form-input>
          </b-input-group>
          <b-button
            type="submit"
            variant="primary"
            style="display: block; width: 100%"
            >Submit</b-button
          >
        </b-form>
      </b-modal>
      <!-- //////// Add Charging Log Form \\\\\\\\\\\\\ -->
      <b-modal id="charge-modal" hide-footer title="Update Customer Data">
        <b-form v-on:submit.prevent="updateCustomer">
          <b-input-group prepend="Code Name :" class="mb-2 mr-sm-2 mb-sm-2">
            <b-form-input
              readonly
              required
              id="inline-form-input-username"
              placeholder="Enter Code Name"
              v-model="update.code"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="Name :" class="mb-2 mr-sm-2 mb-sm-2">
            <b-form-input
              id="inline-form-input-username"
              placeholder="Enter customer Name"
              v-model="update.name"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="Description :" class="mb-2 mr-sm-2 mb-sm-2">
            <b-form-input
              id="inline-form-input-username"
              placeholder="Enter Description"
              v-model="update.desc"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="Line App.ID :" class="mb-2 mr-sm-2 mb-sm-2">
            <b-form-input
              id="inline-form-input-username"
              type="text"
              placeholder="Line Notify App ID"
              v-model="update.lineID"
            ></b-form-input>
          </b-input-group>
          <b-button
            type="submit"
            variant="primary"
            style="display: block; width: 100%"
            >Update</b-button
          >
        </b-form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import header1 from "@/components/header/Header_Normal.vue";
// import CardValue from "@/components/CardValue.vue";
import swal from "sweetalert2";
export default {
  name: "Dashboard",
  components: {
    header1,
    // CardValue,
  },
  props: {
    serverTime: Object,
  },
  data() {
    return {
      form: {
        code: null,
        name: null,
        desc: null,
        lineID: null,
      },
      update: {
        code: null,
        name: null,
        desc: null,
        lineID: null,
      },
      isBusy: true,
      fields: [
        // "edit",
        { key: "aid", label: "ID", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "cid", label: "Owner", sortable: true },
        { key: "desc", label: "Description", sortable: false },
        { key: "sid", label: "Site Name", sortable: true },
        // { key: "location", label: "Site Name", sortable: true},
        // { key: "emergency", label: "Site Name", sortable: true},
        { key: "mid", label: "Mate", sortable: true },
        { key: "model", label: "Model" },
        { key: "sn", label: "S/N Number", sortable: true },
        "Action",
        //, sortable: true
      ],
      pageRenderInfo: {
        deviceID: -1,
        deviceIndex: -1,
        siteIndex: -1,
        deviceKey: "",
      },
      droneID: null,
      webAPI: this.$store.state.webAPI,
      deviceKey: this.$store.state.deviceKey,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
      // selectedAsset: this.$store.state.selectedAsset,
      customerAssets: this.$store.state.customerAsset,
      batteryData: {
        chart: [],
        info: {},
        logs: [],
        table: [],
        battery: [],
      },
      customers: [],
      customers_json: {},
      assets: [],
      sites_json: {},
      assets_json: {},
    };
  },
  beforeCreate() {},
  created() {},
  computed: {},
  mounted() {
    // this.getBatteryData();
    this.getCustomers();
    this.getAssets();
    this.isBusy = false;
  },
  methods: {
    verifyAttachBattery(asset) {
      let currentObj = this;
      currentObj.conflicts = this.batteryData.battery.filter(
        (battery) => battery.DroneID == asset.aid
      );
      this.$bvModal.hide("attach-drone-selection");
      console.log(this.conflicts.length);
      if (this.conflicts.length > 0) {
        //
        // this.$bvModal.show("attach-conflict");
        this.conflicts.forEach;
        let msg = "";
        for (let i = 0; i < this.conflicts.length; i++) {
          msg +=
            "<b>" +
            (i + 1) +
            ") Battery ID :</b> " +
            this.conflicts[i].ID +
            ". <br/>";
        }
        swal
          .fire({
            icon: "warning",
            title: "Conflict was found, Need to Confirm!",
            html:
              "Drone " +
              this.getName(asset) +
              "(" +
              asset.aid +
              ") is attaching to other Battery following, Please <b>>confirm</b> or <b>unattach</b> other battery prior proceed next? <br/><br/>" +
              msg,
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: "Confirm",
            denyButtonText: `Cancel`,
          })
          .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.updateBatteryData(this.selected.battery.ID, asset.aid);
              // swal.fire("Done!", "", "success");
            } else if (result.isDenied) {
              swal.fire("Changes are not saved", "", "info");
            }
          });
      }
      // if(typeof this.conflicts === 'undefined')
      else {
        swal
          .fire({
            title:
              "Please confirm that below BatteryID was attached/installed from  Below Drone ID?",
            html:
              "<b>Battery ID :</b> " +
              this.selected.battery.ID +
              ". <br/><b>Drone :</b> " +
              this.assets_json[asset.aid].name +
              "(" +
              asset.aid +
              ")",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: "Confirm",
            denyButtonText: `Cancel`,
          })
          .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.updateBatteryData(this.selected.battery.ID, asset.aid);
              // swal.fire("Done!", "", "success");
            } else if (result.isDenied) {
              swal.fire("Changes are not saved", "", "info");
            }
          });
      }
      // else
      // else{
      //   swal.fire("Conflict checking failed!! ", "", "error");
      // }
    },
    getName(object) {
      try {
        console.log(object);
        var newObj = JSON.parse(JSON.stringify(object));
        console.log(newObj);
        return newObj.name;
      } catch {
        return "undefined";
      }
    },
    getName2(object) {
      // try {
      console.log(object);
      var newObj = JSON.parse(JSON.stringify(object));
      console.log(newObj);
      return newObj.name;
      // } catch {
      //   return "undefined";
      // }
    },
    getSiteNameBySID(sid) {
      try {
        const sites_json = JSON.parse(JSON.stringify(this.sites_json));
        console.log("sid:", sid);
        console.log(sites_json)
        console.log(this.sites_json);
        // var newObj2 = this.sites.filter(obj => obj.sid === sid);
        console.log(this.sites_json[sid]);
        var newObj = JSON.parse(JSON.stringify(this.sites_json[sid]));
        console.log(newObj);
        // return newObj2.name;
      } catch {
        return "Undefined";
      }
      // var newObj2 = this.sites.filter(obj => obj.sid === sid);
      // console.log(newObj2);
      // return "test"
    },
    async droneListRequest(battery) {
      let currentObj = this;
      currentObj.selected.battery = battery;
      this.$bvModal.show("attach-drone-selection");
    },
    async confirmDelete(element) {
      swal
        .fire({
          title: "Please confirm to delete customer?",
          html:
            "<b>Customer ID </b>: " +
            element.cid +
            "<br/><b>Code Name </b>: " +
            element.code +
            "<br/><b>Customer Name </b>: " +
            element.name,
          showDenyButton: true,
          // showCancelButton: true,
          confirmButtonText: "Confirm",
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.executeRemove(element.code);
            // swal.fire("Done!", "", "success");
          } else if (result.isDenied) {
            swal.fire("Changes are not saved", "", "info");
          }
        });
    },
    async executeRemove(code) {
      let data = JSON.stringify({
        code: code,
      });

      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: this.webAPI + "/manage/api/cid",
        headers: {
          "Content-Type": "application/json",
          authorization: this.apiKey,
        },
        data: data,
      };

      await this.axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          swal.fire("Deleted!", "", "error");
        })
        .catch((error) => {
          console.log(error);
          swal.fire("Remove failed, Caused from " + error, "", "error");
        });
      this.getAssets();
    },
    async updateCustomer() {
      swal
        .fire({
          title: "Please confirm to proceed?",
          showDenyButton: true,
          // showCancelButton: true,
          confirmButtonText: "Confirm",
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$bvModal.hide("charge-modal");
            this.executeUpdate();
            // swal.fire("Done!", "", "success");
          } else if (result.isDenied) {
            swal.fire("Changes are not saved", "", "info");
          }
        });
    },
    async executeUpdate() {
      let data = JSON.stringify(this.update);

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: this.webAPI + "/manage/api/cid",
        headers: {
          "Content-Type": "application/json",
          authorization: this.apiKey,
        },
        data: data,
      };

      await this.axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          swal.fire("Done!", "", "success");
        })
        .catch((error) => {
          console.log(error);
          swal.fire("Update data failed, Caused from " + error, "", "error");
        });
      this.getAssets();
    },
    async getSites(customer) {
      let currentObj = this;
      currentObj.customers_json[customer.cid] = customer;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: this.webAPI + "/manage/api/sid?cid=" + customer.cid,
        headers: {
          authorization: this.apiKey,
        },
      };

      await this.axios
        .request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          response.data.sites.forEach((element) => {
            currentObj.sites_json[element.sid] = element;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getCustomers() {
      let currentObj = this;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: this.webAPI + "/manage/api/cid",
        headers: {
          authorization: this.apiKey,
        },
      };

      await this.axios
        .request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          currentObj.customers = response.data.customers;
          this.customers.forEach(this.getSites);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getAssets() {
      let currentObj = this;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: this.webAPI + "/manage/api/aid",
        headers: {
          authorization: this.apiKey,
        },
      };

      await this.axios
        .request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          currentObj.assets = response.data.assets;
          currentObj.assets.forEach((asset) => {
            currentObj.assets_json[asset.aid] = asset;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMillisecond(str_date, str_time) {
      // Example datetime string
      const datetimeString = str_date + "T" + str_time;

      // Parse the datetime string into a Date object
      const date = new Date(datetimeString);

      // Get the number of milliseconds since January 1, 1970
      const milliseconds = date.getTime();

      //console.log(milliseconds);
      return milliseconds;
    },
    async requestUpdateData(customer) {
      let currentObj = this;
      currentObj.update = JSON.parse(JSON.stringify(customer));
      this.$bvModal.show("charge-modal");
    },
    async addChargeLog() {
      let currentObj = this;
      var milliseconds = this.getMillisecond(
        this.log_form.charge_date,
        this.log_form.charge_time
      );
      //console.log(milliseconds);
      currentObj.charging_log.time = milliseconds;
      const raw = this.log_form.duration.split(":");
      //console.log(raw);
      var charge_ms = 0;
      if (raw.length == 3) {
        charge_ms =
          ((parseFloat(raw[0]) * 60.0 + parseFloat(raw[1])) * 60.0 +
            parseFloat(raw[2])) *
          1000.0;
      }
      //console.log(charge_ms);
      currentObj.stop_log.time = milliseconds + charge_ms;
      currentObj.charging_log.battery_volt = this.log_form.charge_volt;
      currentObj.charging_log.battery_percent = this.log_form.charge_percent;
      currentObj.charging_log.note = "Added log by " + this.userName;
      currentObj.stop_log.note = "Added log by " + this.userName;

      //post start charge data
      currentObj.stop_log.battery_volt = this.log_form.stop_volt;
      currentObj.stop_log.battery_percent = this.log_form.stop_percent;
      currentObj.stop_log.qcount = this.log_form.mAhCount * 3.6;
      await this.postChangeLog(this.charging_log, true);
      await this.postChangeLog(this.stop_log, false);
      //

      this.$bvModal.hide("add-charge-log-modal");
    },
    async postChangeLog(payload, isCharge) {
      let data = JSON.stringify(payload);
      let currentObj = this;
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: "http://127.0.0.1:8970/app/info/bid",
        url: this.webAPI + "/app/info/bid",
        headers: {
          "Content-Type": "application/json",
          authorization: this.apiKey,
        },
        data: data,
      };

      await this.axios
        .request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          if (isCharge) {
            currentObj.stop_log.charge_id = response.data.charge_id;
          }
          swal.fire({
            icon: "success",
            title: "Logging is recorded.",

            html: "Charging ID : " + response.data.charge_id,
            showConfirmButton: false,
            showCloseButton: true,
            // timer: 1500,
          });

          return true;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    async createCustomer() {
      let data = JSON.stringify(this.form);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: this.webAPI + "/manage/api/cid",
        headers: {
          "Content-Type": "application/json",
          authorization: this.apiKey,
        },
        data: data,
      };

      await this.axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          this.$bvModal.hide("add-modal");
          swal
            .fire({
              icon: "success",
              title: "New Customer has been created.",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 1500,
            })
            .then(() => {
              this.getAssets();
            });
        })
        .catch((error) => {
          console.log(error);
          swal.fire({
            icon: "error",
            title: "We cannot created customer, due to " + error,
            showCloseButton: true,
            // timer: 1500,
          });
        });
    },
    async getBatteryData() {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: this.webAPI + "/manage/api/bid",
        headers: {
          authorization: this.apiKey,
        },
      };
      let currentObj = this;
      await this.axios
        .request(config)
        .then((response) => {
          // //console.log(JSON.stringify(response.data));
          currentObj.batteryData = response.data;
          //
          // currentObj.series[0].data = response.data.chart;

          // this.$refs.chart.updateSeries(
          //   [
          //     {
          //       data: this.series[0].data,
          //     },
          //   ],
          //   false,
          //   true
          // );
          // var health =
          //   response.data.table[response.data.table.length - 1].health;

          // var cycle = (
          //   (response.data.table.length / response.data.info.Expire) *
          //   100
          // ).toFixed(2);
          // this.$refs["chart-health"].updateSeries([health.toFixed(2)]);
          // this.$refs["chart-cycle"].updateSeries([cycle]);
          currentObj.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ms2str(ms) {
      // Create a new Date object using the milliseconds
      const date = new Date(ms);

      // Get the date components
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      // Construct the datetime string
      const datetimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return datetimeString;
    },
  },
  beforeDestroy() {},
};
</script>
<style scoped>
.grid-container2 {
  display: grid;
  grid-template-columns: auto 34%;
  padding: 10px;
  margin: auto;
  column-gap: 2%;
}
.grid-container {
  display: grid;
  grid-template-columns: 32% 66%;
  column-gap: 2%;
  padding: 10px;
  text-align: start;
  margin: auto;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}
h2 {
  font-size: 1.2em;
}
.card-data-value h4 {
  font-size: 0.95em;
  width: 95%;
  overflow: hidden;
  display: inline-block;
  /* text-align: center; */
}
.contentmain {
  margin-left: 2rem;
  margin-right: 2rem;
  height: calc(100vh - 100px);
}
.card-body {
  max-height: 70vh;
  overflow-y: auto;
}
main {
  margin-top: 0px;
  padding: 1.4rem 0rem 0rem 0rem;
  background: #f1f5f9;
  /* min-height: calc(100vh - 85px); */
}
.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  /* margin-top: 0rem; */
}
.card-single {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 1rem;
  border-radius: 2px;
}
.card-single div:last-child span {
  color: var(--main-color);
  font-size: 3rem;
}
.card-single h1 {
  font-size: 1.2em;
}

.card-single div:first-child span {
  color: var(--text-grey);
  font-size: 00.8em;
}
.card-single:last-child {
  background: var(--main-color);
}
.card-single:last-child h1,
.card-single:last-child div:last-child span,
.card-single:last-child div:first-child span {
  color: #fff;
}
.recent-grid {
  margin-top: 2rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 67% auto;
}
.card {
  background: #fff;
  border-radius: 5px;
}
.card-header,
.card-body {
  padding: 0.5rem 1rem;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1 solid #f0f0f0;
  margin: 0px;
  min-height: 3.5rem;
}
.card-header button {
  background: var(--main-color);
  border-radius: 10px;
  color: #fff;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--main-color);
}
table {
  /* border-collapse: collapse;
   */
  position: relative;
  border-collapse: collapse;
}
thead th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
}
thead tr {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
thead td {
  font-weight: 700;
}
td {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  color: #222;
}
tr td:last-child {
  display: flex;
  align-items: center;
}
td .status {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 1rem;
}
.red {
  background-color: red !important;
}
.yellow {
  background-color: yellow !important;
}
.green {
  background-color: green !important;
  color: white;
}
.table-responsive {
  width: 100%;
  overflow-x: auto;
}
.customer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.7rem;
}
.info {
  display: flex;
  align-items: center;
}
.info img {
  border-radius: 50%;
  margin-right: 1rem;
}
.info h4 {
  font-size: 0.8rem;
  font-weight: 700;
  color: #222;
}
.info small {
  font-weight: 600;
  /* color: var(--text-grey); */
}
.contact span {
  font-size: 1.2rem;
  display: inline-block;
  margin-left: 0.5rem;
  color: var(--main-color);
}

.search-wrapper {
  border: 1px solid #ccc;
  border-radius: 30px;
  /* height: 50px; */
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0px;
  background: #f0f0f0;
}

.search-wrapper span {
  display: inline-block;
  padding: 0rem 1rem;
  font-size: 1.5em;
}
.search-wrapper input {
  height: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
}
.sys-status {
  position: absolute;
  display: flexbox;
  align-content: center;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  min-width: 20%;
  width: 75%;
  text-align: center;
  background: var(--main-color);
  color: var(--text-grey);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section {
  width: calc(100vw - 350px - 80px - 4rem);
  /* width: 545px; */
  height: 700px;
  /* background: yellow; */
  /* text-align: center; */
  margin-top: 1rem;
  float: left;
  margin-left: 1rem;
  margin-right: 0px;
}

aside {
  width: 305px;
  height: 88vh;
  /* background: yellow; */
  /* text-align: center; */
  margin-top: 1rem;
  float: right;
  margin-left: 1rem;
  margin-right: 41px;
}
.clearfix {
  clear: both;
  margin-bottom: 20px;
}
footer {
  width: 880px;
  height: 50px;
  background: yellow;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.content1 {
  /* background-color: aquamarine; */
  background-color: whitesmoke;
  /* margin: 0 auto; */
  /* margin: 1rem; */
  padding: 1rem;
  border-radius: 10px;
  /* margin-bottom: 1rem; */
  border: #222 solid 1px;
  height: calc(50% - 1rem);
  /* width: 100%; */
}
.content1-upper {
  /* background-color: aquamarine; */
  background-color: whitesmoke;
  /* margin: 0 auto; */
  /* margin: 1rem; */
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: #222 solid 1px;
  height: 50%;
  /* width: 100%; */
}
.main-container {
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: #222 solid 1px;
  position: relative;
  width: 100%;
  height: 88vh;
  display: block;
  position: relative;
  background: gray;
  overflow: hidden;
  /* width: 100%; */
}
.content1-upper h1,
.infor-status table,
.content1 h1 {
  font-size: 0.75rem;
}
.video-main {
  /* float: left; */
  width: 100%;
  /* width: calc(100vw - 350px - 80px - 4rem - 31%); */
  /* padding-right: 1rem; */
  /* height: calc(100vh - 72%); */
  height: 26.8vw;
  border-radius: 10px;
  overflow: hidden;
  max-height: 50vh;
  /* background-color: black; */
}
.map-panel {
  border-radius: 5px;
  margin: 10px;
  width: 100%;
  /* height:300px; */
  height: 18.76vw;
}
.emergency-btn {
  width: 30%;
  height: 70px;
  /* border-radius: 25px 25px; */
  /* background-color: rgb(207, 0, 0); */
  font-size: 1rem;
  /* margin: 0 auto; */
  cursor: pointer;
  margin: 2px;
}
.cmdPanel-btn {
  width: 30%;
  height: 70px;
  /* border-radius: 25px 25px; */
  /* background-color: rgb(207, 0, 0); */
  font-size: 1rem;
  /* margin: 0 auto; */
  cursor: pointer;
  margin: 2px;
}
.cmdPanel-btn:hover {
  background-color: rgb(0, 255, 170);
  /* color: goldenrod; */
  /* border-color: yellow; */
  border-width: 2px;
  font-weight: bold;
}
.emergency-btn:hover {
  background-color: rgb(255, 0, 0);
  color: yellow;
  /* border-color: yellow; */
  border-width: 2px;
  font-weight: bold;
}
.log-msg {
  width: 100%;
  height: calc(100vh - 26.8vw - 130px);
  /* min-height: 350px;*/
  max-height: calc(100vh - 26.8vw - 130px);
  overflow: auto;
  /* overflow-y: auto; */
  /* background-color: var(--text-grey); */
  border-radius: 10px;
  font-size: 0.3rem;
}

.test {
  font-size: 0.5rem;
}
.control-status {
  min-height: 200px;
  max-height: 350px;
}
.device-status {
  float: left;
  width: calc(50% - 1rem);
  /* max-height: 250px;
  overflow-y: auto; */
}
.device-control {
  float: right;
  width: calc(50% - 1rem);
  height: 280px;
}
.tab-device-status {
  max-height: 250px;
  overflow-y: auto;
}
.cmd-btn {
  height: 50px;
  width: 150px;
  margin: 5px;
  padding: 5px;
  font-size: 0.75rem;
  border-radius: 10px;
  border-width: 1px;
}
.cmd-btn:hover {
  background-color: lightseagreen;
}
b-tab {
  font-size: 0.75rem;
}
.tab-class {
  font-size: 0.5rem;
}
.nav-tab-calss {
  font-size: 0.5rem;
}
.content-operation {
  width: 100%;
  height: 90vh;
  display: block;
  position: relative;
  background: gray;
}

.arm-status {
  text-shadow: 2px 2px #000000;
  color: white;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 auto;
  padding: 0 auto;
  transform: translate(-50%, 0%);
  text-align: center;
  z-index: 100;
}
.miniMap {
  transition: top 300ms;
  border-radius: 200px;
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  /* background-color: rgba(22, 22, 22, 0.8); */
  width: 400px;
  height: 400px;
  z-index: 1;
  overflow: hidden;
  opacity: 0.3;
}
.zoombtn {
  position: absolute;
  left: 270px;
  bottom: 190px;
  z-index: 2;
  width: 140px;
}
.alarm {
  background-color: rgba(255, 0, 0, 0.8);
  /* color: yellow; */
}
.paraView {
  position: absolute;
  top: 500px;
  right: 10px;
  margin: 1rem;
  align-content: flex-end;
  /* background: rgba(60, 60, 60, 0.6); */
  color: #f2f2f2;
  font-size: 1.8em;
  z-index: 100;
}
.sys-status {
  position: absolute;
  display: flexbox;
  align-content: center;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  min-width: 20%;
  width: 75%;
  text-align: center;
  background: var(--main-color);
  color: var(--text-grey);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.status-card {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.infor-view {
  position: absolute;
  padding: 1rem;
  top: 1rem;
  left: 1rem;
  /* margin: 1rem; */
  align-content: flex-start;
  background: rgba(60, 60, 60, 0.74);
  color: #f2f2f2;
  /* font-size: 1.8em; */
  z-index: 100;
}
.icon-wrapper {
  display: flex;
  align-items: center;
  min-width: 100px;
  margin-bottom: 10pex;
}
.icon-wrapper span {
  border-radius: 50%;
  margin-right: 0.5rem;
  font-size: 2rem;
}
.icon-wrapper h4 {
  margin-bottom: -2px;
}
.icon-wrapper small {
  display: inline-block;
  /* color: var(--text-grey); */
  margin-top: -10rem !important;
}
.control-command {
  /* position: absolute;
  bottom: 1rem;
  right: 1rem; */
  /* margin: 1rem; */
  align-content: flex-end;
  /* background: rgba(60, 60, 60, 0.74); */
  color: #f2f2f2;
  /* font-size: 1.8em; */
}
.test1 {
  /* margin: 2px; */
  margin: 0px;
  padding: 0px;
}
.left1-container {
  margin-top: 10px;
}
.center1-container {
  margin-top: 10px;
}

.card-data-value {
  margin: 2px;
  padding: 2px;
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
}
.card-data-value2 {
  margin: 2px;
  padding: 2px;
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
  text-align: center;
}
.card-data {
  margin: 2px;
  padding: 20px;
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
  max-height: calc(100vh - 26.8vw - 105px);
  overflow: auto;
}
.card-frame {
  /* margin: 2px; */
  /* padding: 20px; */
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
  max-height: calc(100vh - 26.8vw - 105px);
  overflow: auto;
}
/* @-webkit-keyframes alarm { */
@keyframes alarm {
  0%,
  49% {
    background-color: whitesmoke;
    /* border: 1px solid red; */
  }
  50%,
  100% {
    background-color: yellow;

    /* border: 1px solid red; */
  }
}
.statusMini {
  max-width: 25vw;
}
.valueMaxCard {
  max-width: 100% !important;
  display: block;
}
.valveMinCard {
  max-width: 25% !important;
  display: inline;
}
.value-moving {
  /* margin: 2px; */
  /* background-color: yellow; */
  color: red;
  /* text-align: end; */
  /* padding-right: 5px; */
  /* padding: 2px; */
  border-bottom: 1px gray solid;
  -webkit-animation: alarm 1s infinite; /* Safari 4+ */
  -moz-animation: alarm 1s infinite; /* Fx 5+ */
  -o-animation: alarm 1s infinite; /* Opera 12+ */
  animation: alarm 1s infinite; /* IE 10+, Fx 29+ */
}
.uavControlPanel {
  font-size: 0.75rem;
  margin: 0px;
  padding: 0px;
  height: 35vh;
}
@media only screen and (max-width: 560px) {
  .map-panel {
    height: 25vh !important;
    /* display: none; */
  }
  .video-main {
    height: 25vh !important;
    /* display: none; */
  }
  .statusMini {
    max-width: 100vw !important;
  }
  .card-frame {
    max-height: auto;
    height: auto !important;
  }
  .uavControlPanel {
    height: auto !important;
  }
  .card-data,
  .card-body {
    max-height: 100% !important;
    height: auto !important;
  }
}

h3 {
  width: 100%;
  font-size: 1em;
  text-align: center;
}
.card-data-value-test {
  font-size: 0.75em;
}
.zoomControlBtn {
  display: block;
  width: 100%;
  margin: 10px 0 10px 0;
  font-size: 2em;
}
.menualPalyloadBtn {
  display: block;
  width: 40%;
  margin: 10px;
  font-size: 2em;
}
.menualPalyloadBtn2 {
  display: block;
  width: 25%;
  margin: 10px;
  font-size: 2em;
}
.menualPalyloadBtn3 {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  /* font-size: 2em; */
}
.tranfrom45 {
  transform: rotate(45deg);
}
.tranfrom-45 {
  transform: rotate(-45deg);
}
.noMargin {
  margin: 0 !important;
  width: 50%;
}
.shutterControlBtn {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-width: 5px;
}
.shutterStopControlBtn {
  width: 70px;
  height: 70px;
  /* border-radius: 50%; */
}
.btn-layout {
  display: grid;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 10px;
  width: 100%;
}
.btn-mode {
  /* width: 100%; */
  height: 70px;
  padding: 20px;
  font-size: 12px;
  text-align: center;
}
.btn-create-battery {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 60px;
}
.container-table {
  border-radius: 10px;
  background-color: #fff;
  margin: 20px;
  overflow: hidden;
}
</style>
