<template>
  <div class="station-status-container">
    <small>{{ title }}</small>
    <div
      class="data-container"
      :class="[
        status,
        { 'value-processing': isProcessing },
        { 'value-moving': isMoving },
      ]"
    >
      <h3>{{ value }} {{ unit }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    status: {
      type:String,
      default:"",
    },
    isMoving: Boolean,
    isProcessing: Boolean,
    value: String,
    unit: String,
  },
};
</script>

<style scoped>
.data-container {
  position: relative;
  border: 1px black solid;
  width: 100%;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  height: 30px;
  background-color: rgba(128, 128, 128, 0.753);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.data-container h3 {
  position: absolute;
  font-size: 0.8rem;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.station-status-container {
  margin: 0px;
  padding: 10px;
  width: 100%;
  color: white;
  border: 1px black solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 10px;
  background: rgba(13, 13, 13, 0.6);
  font-size: 0.8rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
}

/* @-webkit-keyframes alarm { */
@keyframes alarm {
  0%,
  49% {
    background-color: gray;
    /* border: 1px solid red; */
  }
  50%,
  100% {
    background-color: rgb(163, 163, 65);

    /* border: 1px solid red; */
  }
}
/* @-webkit-keyframes processing { */
@keyframes processing {
  0%,
  49% {
    background-color: gray;
    /* border: 1px solid red; */
  }
  50%,
  100% {
    background-color: rgb(4, 100, 52);

    /* border: 1px solid red; */
  }
}
.red {
  background-color: rgb(160, 48, 48);
}
.yellow {
  background-color: rgb(163, 163, 65);
}
.green {
  background-color: rgb(4, 100, 52);
  color: white;
}

.value-moving {
  /* margin: 2px; */
  /* background-color: yellow; */
  color: rgb(160, 48, 48);
  /* text-align: end; */
  /* padding-right: 5px; */
  /* padding: 2px; */
  border-bottom: 1px gray solid;
  -webkit-animation: alarm 1s infinite; /* Safari 4+ */
  -moz-animation: alarm 1s infinite; /* Fx 5+ */
  -o-animation: alarm 1s infinite; /* Opera 12+ */
  animation: alarm 1s infinite; /* IE 10+, Fx 29+ */
}
.value-processing {
  /* margin: 2px; */
  /* background-color: yellow; */
  /* color: red; */
  /* text-align: end; */
  /* padding-right: 5px; */
  /* padding: 2px; */
  border-bottom: 1px gray solid;
  -webkit-animation: processing 1s infinite; /* Safari 4+ */
  -moz-animation: processing 1s infinite; /* Fx 5+ */
  -o-animation: processing 1s infinite; /* Opera 12+ */
  animation: processing 1s infinite; /* IE 10+, Fx 29+ */
}
</style>
