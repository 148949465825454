<template>
  <div class="home">
    <alert_card_full
      v-if="isFullImageActive"
      :index="detailData.index"
      :item="detailData.item"
      style="z-index: 99991; position: absolute; top: 50%; left: 50%"
      @callbackAlert="isFullImageActive = false"
    />
    <div class="map" ref="map"></div>
    <div class="contentMission">
      <div class="container-content">
        <div class="siteop-header">
          <div class="siteop-header-layout">
            <h1 class="siteop-sitename">
              All Site (2)
            </h1>
          </div>
        </div>
        <div class="device-container">
          <site_info_card/>
          <site_info_card/>
          <site_info_card/>
          <!-- <devInfo
            v-for="item in assets"
            :key="item.name"
            :class="{
              'dis-none': !(activeKey === 'all' || item.type === activeKey),
            }"
            :devType="item.type"
            :devName="item.name"
            :isOnline="onlineStatus[item.aid]"
            :deviceInfo="item"
            @callbackDroneStatus="polylinedrow"
            @callbackBoxStatus="boxUpdateStatus"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import GmapPanel from "../components/MapPanel.vue";
// import CardValue from "../components/CardValue.vue";
import site_info_card from '../components/v3/subcomponents/small-site-info-card.vue'
// import io from "socket.io-client";
// import alert_card_full from "../components/v3/subcomponents/alert-card-full.vue";
// import alert_card from "../components/v3/subcomponents/alert-card.vue";
// import swal from "sweetalert2";
import mockData from "../assets/test/mock_assets.json";
// import mockAlert from "../assets/test/mock_alert.json";
// import qtyCard from "../components/v3/subcomponents/small-qty-card.vue";
// import devInfo from "../components/v3/subcomponents/small-device-info-card.vue";
export default {
  name: "SiteOperation",
  components: {
    // HelloWorld
    // GmapPanel,
    // CardValue,
    // test1
    // qtyCard,
    // devInfo,
    // alert_card,
    // alert_card_full,
    site_info_card
  },
  data() {
    return {
      markerIcon: {
        drone: {
          normal: "marker-icon-drone.png",
          warning: "marker-icon-drone-warning.png",
          error: "marker-icon-drone-error.png",
          offline: "marker-icon-drone-offline.png",
        },
        box: {
          normal: "marker-icon-box.png",
          warning: "marker-icon-box-warning.png",
          error: "marker-icon-box-error.png",
          offline: "marker-icon-box-offline.png",
        },
        iot: {
          normal: "marker-icon-iot.png",
          warning: "marker-icon-iot-warning.png",
          error: "marker-icon-iot-error.png",
          offline: "marker-icon-iot-offline.png",
        },
      },
      isFullImageActive: false,
      detailData: {
        item: {},
        index: 0,
      },
      // alertData: mockAlert.alert,
      alertData: [],
      activeKey: "all",
      assets: mockData.assets,
      assetCount: {
        box: 0,
        drone: 0,
        iot: 0,
        other: 0,
      },
      alert: {},
      statusData: [],
      deviceKey: null,
      mock: mockData,
      deviceStatus: {
        time: {
          value: null,
        },
        Sim_Mode: {
          value: true,
        },
      },
      filter: "box",
      socket: null,
      drone_socket: null,
      webAPI: this.$store.state.webAPI,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
      assetList: {},
      /////////////////////////////////////////////////////
      item: {},
      boxesAsset: [],
      assetStatus: [],
      apiServer: this.$store.state.apiDataServe,
      realTimeServer: this.$store.state.apiRealtimeServe,
      states: [],
      customer: this.$store.state.owner,
      output2: null,
      datashowing: {
        boxid: this.$store.state.selectedBoxID,
        droneid: this.$store.state.selectedDroneID,
        boxdata: {},
        dronedata: {},
        boxUpdate: "",
        droneUpdate: "",
        show: false,
        spinner: true,
        index: 0,
        type: "",
      },
      fields: [
        // "edit",
        // { key: "aid", label: "Asset ID" },
        { key: "name", label: "Name" },
        { key: "type", label: "Type" },
        { key: "sid", label: "Site" },
        { key: "Online", label: "Status" },
        "View",
      ],
      socket_alert: null,
      map: null,
      markers: [],
      interValDrone: null,
      interValBox: null,
      interValAvai: null,
      interValAsset: null,
      zoom: 20,
      interValZoomIn: null,
      interValZoomOut: null,
      zoomProcess: false,
      onlineStatus: [],
      /////////////////////////////////////////////////////
      tempo: {
        assetCount: {
          box: 0,
          drone: 0,
          iot: 0,
          other: 0,
        },
      },
    };
  },
  mounted() {
    // if (this.customerID == null) {
    //   this.customerID = sessionStorage.getItem("setcustomerID");
    // }
    // if (this.apiKey == null) {
    //   this.apiKey = sessionStorage.getItem("setAPIKey");
    // }
    // if (this.sioServer == null) {
    //   this.sioServer = sessionStorage.getItem("setSioServer");
    // }
    // this.socket_alert = io(this.sioServer + "https://dbx-prod.arv.co.th/station/status", {
    //   auth: { deviceID: this.userName, token: this.apiKey },
    // });
    // this.socket_alert = io(this.sioServer + "/alert", {
    //   auth: { deviceID: this.userName, token: this.apiKey },
    // });
    // // this.drone_socket = io(this.sioServer + "/drone/status", {
    // //   auth: { deviceID: this.userName, token: this.apiKey },
    // // });
    // this.socket_alert.on("iot", (data) => {
    //   console.log(data);
    //   this.alertData.push(data.data);
    //   this.changeIconStatus(data.data, "error");
    // }); //this.alertHandling(data));
    // this.socket_alert.on("ml", (data) => {
    //   console.log(data);
    //   this.alertData.push(data.data);
    // }); //this.alertHandling(data));
    // this.socket.on("status", (data) => this.onStatus(data));
    // this.socket.on("alert", (data) => this.showAlert(data));
    // this.drone_socket.on("status", (data) => this.onStatus(data));
    // this.socket.on("authen", (data) => {
    //   console.log(data);
    //   sessionStorage.clear();
    //   this.$store.commit("setLoginState", false);
    //   window.location.href = "/#/";
    // });
    // this.drone_socket.on("authen", (data) => {
    //   console.log(data);
    //   sessionStorage.clear();
    //   this.$store.commit("setLoginState", false);
    //   window.location.href = "/#/";
    // });
    var mapStyles = [
      {
        featureType: "all",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ];
    this.map = new window.google.maps.Map(this.$refs["map"], {
      mapTypeId: "hybrid",
      center: { lat: 13.932, lng: 100.573 },
      zoom: this.zoom,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      styles: mapStyles,
      tilt: 47.5,
    });
    // this.getAlertItems();
    // this.getAssets();
    // this.getOnlineStatus();
    // this.interValOnline = setInterval(this.getOnlineStatus, 2000);
  },
  methods: {
    boxUpdateStatus(status, online) {
      var items = this.assets.filter(
        (device) => device.aid.toString() === status.device_id.value.toString()
      );
      var item = items[0];
      console.log(online);
      console.log(item);
    },
    polylinedrow(status, online) {
      var items = this.assets.filter(
        (device) => device.aid.toString() === status.device_id.value.toString()
      );
      // console.log("Callback Status");
      // console.log(items);
      var item = items[0];
      //alert('Drawing polyline ........')
      console.log(online);
      if (!("historyPoints" in item)) {
        console.log("No Exist historyPoints");
        item.historyPoints = [];
      }
      if (
        parseFloat(status.system.data.position_lat.value) != 0.0 &&
        parseFloat(status.system.data.position_long.value) != 0.0
      ) {
        item.historyPoints.push(
          new window.google.maps.LatLng(
            parseFloat(status.system.data.position_lat.value),
            parseFloat(status.system.data.position_long.value)
          )
        );
        if (item.historyPoints.length > 500) {
          item.historyPoints.shift();
        }

        try {
          item.historyPath.setMap(null);
        } catch {
          console.log("No existing path");
        }
        item.historyPath = new window.google.maps.Polyline({
          path: item.historyPoints,
          geodesic: true,
          strokeColor: "PURPLE",
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });

        // item.marker.setMap(this.map);
        if (this.activeKey === "drone" || this.activeKey === "all") {
          item.marker.setPosition(
            new window.google.maps.LatLng(
              parseFloat(status.system.data.position_lat.value),
              parseFloat(status.system.data.position_long.value)
            )
          );
          item.marker.setMap(this.map);
        }
        item.historyPath.setMap(this.map);
      }
      //alert('DONE ........')
    },
    showDetailData(item, index) {
      console.log(item);
      console.log(index);
      let currentObj = this;
      currentObj.detailData.item = item;
      currentObj.detailData.index = index;
      currentObj.isFullImageActive = true;
      console.log("image clicked!!");
    },
    changeIconStatus(data, status) {
      var items = this.assets.filter(
        (device) => device.aid.toString() === data.tid.toString()
      );
      console.log(items);
      var item = items[0];
      try {
        item.marker.setMap(null);
        var image = {
          url: require("../assets/markers/" +
            this.markerIcon[item.type][status]),
          // url: require("../assets/drone2.png"),
          //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
          // This marker is 20 pixels wide by 32 pixels high.
          size: new window.google.maps.Size(60, 60),
          // The origin for this image is (0, 0).
          origin: new window.google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new window.google.maps.Point(30, 50),
        };
        let currentObj = this;
        item.marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            item.location[0],
            item.location[1]
          ),
          icon: image,
          title: item.type, //"DBX Station" + index.toString(),
          map: currentObj.map,
          draggable: false,
        });

        item.marker.addListener("mouseover", function () {
          var icon = item.marker.getIcon();
          icon.scaledSize = new window.google.maps.Size(120, 120);
          icon.size = new window.google.maps.Size(120, 120);
          // icon.scale= 2;
          icon.anchor = new window.google.maps.Point(60, 100);
          item.marker.setIcon(icon);
        });

        // assuming you also want to hide the infowindow when user mouses-out
        item.marker.addListener("mouseout", function () {
          var icon = item.marker.getIcon();
          icon.scaledSize = new window.google.maps.Size(60, 60);
          icon.size = new window.google.maps.Size(60, 60);
          // icon.scale= 1;
          icon.anchor = new window.google.maps.Point(30, 50);
          item.marker.setIcon(icon);
        });
        if (status === "error") {
          item.marker.setAnimation(window.google.maps.Animation.BOUNCE);
        } else {
          item.marker.setAnimation(null);
        }
      } catch {
        console.log("marker null");
      }
    },
    async clearAlertItem(item, index) {
      let currentObj = this;
      console.log(item, index);
      var config = {
        method: "post",
        url:
          this.sioServer +
          "/external/alert/reset?device_id=" +
          item.tid +
          "&type=" +
          item.type +
          "&time=" +
          item.time,
        headers: {
          authorization: this.apiKey,
        },
      };

      console.log(config.url);
      await this.axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          currentObj.alertData.splice(index, 1);
        })
        .catch(function (error) {
          console.log(error);
        });
      if (item.type.toLowerCase() === "sos") {
        this.changeIconStatus(item, "normal");
      }
      await this.getAlertItems();
    },
    async onChangeFilter(type) {
      let currentObj = this;
      currentObj.activeKey = type;
      await this.assets.forEach((item) => {
        item.marker.setMap(null);
        if (item.type === type || type === "all") {
          item.marker.setMap(this.map);
        }
      });
    },
    async getAlertItems() {
      let currentObj = this;
      var config = {
        method: "get",
        url: this.sioServer + "/external/alert?sid=2",
        headers: {
          authorization: this.apiKey,
        },
      };

      await this.axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          // currentObj.alertData = response.data.alert;
          currentObj.alertData = response.data.alert.filter(
            (data) => data.status.toString() === "1"
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async assetCountFN() {
      let currentObj = this;
      currentObj.tempo.assetCount.box = 0;
      currentObj.tempo.assetCount.drone = 0;
      currentObj.tempo.assetCount.iot = 0;
      currentObj.tempo.assetCount.other = 0;
      await this.assets.forEach(this.count);
      currentObj.assetCount.box = this.tempo.assetCount.box;
      currentObj.assetCount.drone = this.tempo.assetCount.drone;
      currentObj.assetCount.iot = this.tempo.assetCount.iot;
      currentObj.assetCount.other = this.tempo.assetCount.other;
    },
    // drawAssetOnMap(){
    count(item) {
      let currentObj = this;
      if (item.type === "box") currentObj.tempo.assetCount.box++;
      else if (item.type === "drone") currentObj.tempo.assetCount.drone++;
      else if (item.type === "iot") currentObj.tempo.assetCount.iot++;
      else currentObj.tempo.assetCount.other++;
      this.displayAssetLocation2(item);
    },
    displayAssetLocation2(item) {
      // console.log("Location Item : ", item.location);
      var image = {
        // deepcode ignore CodeInjection: <please specify a reason of ignoring this>
        url: require("../assets/markers/" + this.markerIcon[item.type].normal),
        // url: require("../assets/drone2.png"),
        //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new window.google.maps.Size(60, 60),
        // The origin for this image is (0, 0).
        origin: new window.google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(30, 50),
      };
      let currentObj = this;
      item.marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          item.location[0],
          item.location[1]
        ),
        icon: image,
        title: item.name, //"DBX Station" + index.toString(),
        map: currentObj.map,
        draggable: false,
      });
      item.marker.addListener("mouseover", function () {
        var icon = item.marker.getIcon();
        icon.scaledSize = new window.google.maps.Size(120, 120);
        icon.size = new window.google.maps.Size(120, 120);
        // icon.scale= 2;
        icon.anchor = new window.google.maps.Point(60, 100);
        item.marker.setIcon(icon);
      });

      // assuming you also want to hide the infowindow when user mouses-out
      item.marker.addListener("mouseout", function () {
        var icon = item.marker.getIcon();
        icon.scaledSize = new window.google.maps.Size(60, 60);
        icon.size = new window.google.maps.Size(60, 60);
        // icon.scale= 1;
        icon.anchor = new window.google.maps.Point(30, 50);
        item.marker.setIcon(icon);
      });
    },
    // },
    showAlert(data) {
      // console.log('Alert MSG...')
      this.alert = data;
      console.log(this.alert);
      this.$bvToast.toast(data.data.msg, {
        title: "Alert",
        variant: "danger",
        solid: true,
      });
    },
    showWarning(msg) {
      this.$bvToast.toast(msg, {
        title: "Warning",
        variant: "warning",
        solid: true,
      });
    },
    getSiteName(sid) {
      var siteName = null;
      // console.log("sid:", sid);
      try {
        this.assetList.sites.forEach((obj) => {
          // console.log(obj.name);
          if (obj.sid === sid) {
            // console.log("name:", obj.name);
            siteName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      // for (let item in this.assetList.sites){
      //   console.log(item)
      //   if (item.sid === sid){
      //     console.log('name:',item.name)
      //     return item.name;
      //   }
      // }
      // console.log('name:null')
      return siteName;
    },
    getDeviceName(mid) {
      var assetName = null;
      // console.log("mid:", mid);
      try {
        this.assetList.devices.forEach((obj) => {
          // console.log(obj.name);
          if (obj.aid === mid) {
            // console.log("name:", obj.name);
            assetName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      // for (let item in this.assetList.sites){
      //   console.log(item)
      //   if (item.sid === sid){
      //     console.log('name:',item.name)
      //     return item.name;
      //   }
      // }
      // console.log('name:null')
      return assetName;
    },
    closeDialog() {
      this.datashowing.show = false;
      this.zoomProcess = true;
      // deepcode ignore CodeInjection: <please specify a reason of ignoring this>
      this.interValZoomOut = setInterval(this.zoomOutDelay, 200);
    },
    Caltime: function (item_time) {
      // console.log(item_time)
      var ddd = parseInt(item_time);
      var current_datetime = new Date(ddd);
      // console.log(current_datetime)
      var dateTime =
        current_datetime.getFullYear() +
        "-" +
        (current_datetime.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        current_datetime.getDate().toString().padStart(2, "0") +
        " " +
        current_datetime.getHours().toString().padStart(2, "0") +
        ":" +
        current_datetime.getMinutes().toString().padStart(2, "0") +
        ":" +
        current_datetime.getSeconds().toString().padStart(2, "0");
      return dateTime;
    },
    async getAsset() {
      let currentObj = this;
      this.boxesAsset.forEach((element) => element.setMap(null));
      // console.log("Owner : ", currentObj.customer);
      await this.axios
        .get(this.apiServer + "/getlist/boxes/" + currentObj.customer)
        .then(function (response) {
          // console.log(response.data);
          currentObj.boxesAsset = response.data;
          currentObj.boxesAsset.forEach((lement) =>
            currentObj.$store.commit("setAssetInfo", lement)
          );
          // currentObj.$store.commit("setAssetInfo", currentObj.boxesAsset);
          sessionStorage.setItem("setAssetInfo", currentObj.boxesAsset);
          // currentObj.states = response.data;
          // alert("Request Command : "+response.data.message);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      await this.getAssetStatus();
      this.boxesAsset.forEach(this.displayBoxLocation);

      if (currentObj.boxesAsset.length > 0) {
        // console.log("Data : ", currentObj.boxesAsset);
        currentObj.$store.commit("setBoxID", this.boxesAsset[0].id);
        currentObj.$store.commit("setDroneID", this.boxesAsset[0].mapping);
        currentObj.$store.commit("setSite", this.boxesAsset[0].site);
        currentObj.$store.commit("setBoxLocation", this.boxesAsset[0].location);
        currentObj.$store.commit(
          "setSiteLocation",
          this.boxesAsset[0].siteLocation
        );
      }
      // this.assets.forEach(this.addDataToList);
      //////////////////////////////// TO GET BOX LOCATION for HOME LOCATION//////////////////////////////////////////////////////////
    },
    async getAssetStatus() {
      let currentObj = this;
      // console.log("Owner : ", currentObj.customer);
      await this.axios
        .post(this.realTimeServer + "/dbx/v2/available", {
          boxes: this.boxesAsset,
        })
        .then(function (response) {
          // console.log(response.data.status);
          currentObj.assetStatus = response.data.status;
          // currentObj.states = response.data;
          // alert("Request Command : "+response.data.message);
        })
        .catch(function (error) {
          // currentObj.output2 = error;
          console.error(error);
          // alert(error);
        });
    },
    displayBoxLocation(item, index) {
      // console.log("Location Item : ", item.location);
      var image = {
        url: require("../assets/DB2_50x50.png"),
        // url: require("../assets/drone2.png"),
        //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new window.google.maps.Size(50, 50),
        // The origin for this image is (0, 0).
        origin: new window.google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(25, 25),
      };
      this.markers.push(
        new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            item.location[0],
            item.location[1]
          ),
          icon: image,
          title: "DBX Station" + index.toString(),
          map: this.map,
          draggable: false,
        })
      );
      this.markers[index].addListener("click", () => {
        // this.map.setZoom(18);
        // this.map.setCenter(item.marker.getPosition());
        this.showDetailStationByID(item, index);
        // console.log("Box Station Index : ", index);
      });
    },
    async showDetailStationByID(item, index) {
      let currentObj = this;
      console.log(item);
      currentObj.datashowing.boxid = item.id;
      currentObj.datashowing.droneid = item.mapping;
      currentObj.datashowing.show = true;
      currentObj.datashowing.info = item;
      currentObj.datashowing.dronedata = {};
      currentObj.datashowing.boxdata = {};
      currentObj.datashowing.index = index;
      currentObj.$store.commit("setBoxID", item.id);
      currentObj.$store.commit("setDroneID", item.mapping);
      currentObj.$store.commit("setSite", item.site);
      currentObj.$store.commit("setBoxLocation", item.location);
      currentObj.$store.commit("setSiteLocation", item.siteLocation);
      this.getDroneData();
      if (currentObj.zoom > 6) {
        this.zoomProcess = true;
        this.interValZoomOut = setInterval(() => this.zoomOutDelay, 200);
      }

      // currentObj.zoom = 6;
      this.interValZoomIn = setInterval(() => this.zoomDelay, 200);
    },
    zoomDelay() {
      if (!this.zoomProcess) {
        if (this.zoom == 6) {
          this.map.setCenter(
            // this.markers[this.datashowing.index].getPosition()
            new window.google.maps.LatLng(
              this.datashowing.loc[0],
              this.datashowing.loc[1]
            )
          );
        }
        if (this.zoom < 16) {
          this.zoom++;
          this.map.setZoom(this.zoom);
        } else {
          clearInterval(this.interValZoomIn);
        }
      }
    },
    zoomOutDelay() {
      if (this.zoom > 6) {
        this.zoom--;
        this.map.setZoom(this.zoom);
      } else {
        clearInterval(this.interValZoomOut);
        this.zoomProcess = false;
      }
    },
    async getDroneData() {
      if (this.datashowing.show) {
        let currentObj = this;
        // console.log("Getting Data of Drone ID : ", this.datashowing.droneid);
        // console.log("call : " + this.apiServer + "/drone/v2/status/" + this.datashowing.droneid);
        this.axios
          .get(
            this.realTimeServer + "/drone/v2/status/" + this.datashowing.droneid
          )
          .then(function (response) {
            // console.log("Respone Code : ", response.status);
            if (response.status == 200) {
              currentObj.datashowing.dronedata = response.data;
              // console.log(currentObj.datashowing.dronedata);
              currentObj.datashowing.spinner = false;
            }
          })
          .catch(function (error) {
            currentObj.output2 = error;
          });
        currentObj.datashowing.droneUpdate = this.Caltime(
          currentObj.datashowing.dronedata.time
        );
      }
    },
    async getBoxData() {
      if (this.datashowing.show) {
        let currentObj = this;
        // console.log("Getting Data of Base ID : ", this.datashowing.boxid);
        // console.log('Owner : ', currentObj.customer)
        await this.axios
          .get(this.realTimeServer + "/dbx/v2/status/" + this.datashowing.boxid) // + currentObj.customer)
          .then(function (response) {
            // console.log(response.data);
            currentObj.datashowing.boxdata = response.data;
            // response.data.controlElement.forEach(this.declareData);
            // currentObj.datashowing.boxdata.UPDATE = this.Caltime(response.data.time)

            for (var iii in response.data.controlElement) {
              // console.log(response.data.controlElement[iii]);
              var item = response.data.controlElement[iii];
              if (item.type == "COVER") {
                if (item.diag) currentObj.datashowing.boxdata.COVER = "Opened";
                else currentObj.datashowing.boxdata.COVER = "Closed";
              } else if (item.type == "LIFT") {
                if (item.diag) currentObj.datashowing.boxdata.LIFT = "Up";
                else currentObj.datashowing.boxdata.LIFT = "Down";
              } else if (item.type == "CEN_X") {
                if (item.diag)
                  currentObj.datashowing.boxdata.CEN_X = "Centered";
                else currentObj.datashowing.boxdata.CEN_X = "Released";
              } else if (item.type == "CEN_Y") {
                if (item.diag)
                  currentObj.datashowing.boxdata.CEN_Y = "Centered";
                else currentObj.datashowing.boxdata.CEN_Y = "Released";
              }
            }
            // console.log(currentObj.datashowing.boxdata.controlElement);
            currentObj.datashowing.spinner = false;
            // currentObj.states = response.data;
            // alert("Request Command : "+response.data.message);
          })
          .catch(function (error) {
            currentObj.output2 = error;
            // alert(error);
          });
        currentObj.datashowing.boxUpdate = this.Caltime(
          currentObj.datashowing.boxdata.time
        );
      }
    },
    //////////////////////////// Verify function ///////////////////////////////////////
    async getAssets() {
      console.log("Get Asset Function");
      // this.datashowing.spinner = true;
      let currentObj = this;
      await this.axios
        .get(this.webAPI + "/app/api/asset?cid=" + this.customerID, {
          headers: {
            authorization: this.apiKey,
          },
        })

        .then((response) => {
          if (response.status === 400) {
            sessionStorage.clear();
            this.$store.commit("setLoginState", false);
            window.location.href = "/#/";
          }
          currentObj.assetList = response.data;
          // console.log(currentObj.assetList);
          currentObj.$store.commit("setcustomerAsset", currentObj.assetList);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          console.log("Get Asset Function__EER");
          currentObj.output2 = error;
          // alert(error);
        });

      console.log(this.assetList.devices);
      console.log("Get Asset Function3");
      if (this.assetList.devices.length > 0) {
        if (this.assetList.devices[0].type === "box") {
          console.log("Get Asset Function4");
          currentObj.datashowing.boxid = this.assetList.devices[0].aid;
          currentObj.datashowing.droneid = this.assetList.devices[0].mid;
        } else {
          console.log("Get Asset Function5");
          currentObj.datashowing.boxid = this.assetList.devices[0].mid;
          currentObj.datashowing.droneid = this.assetList.devices[0].aid;
        }
        console.log("Get Asset Function6");
        currentObj.datashowing.type = this.assetList.devices[0].type;
        currentObj.datashowing.name = this.assetList.devices[0].name;
        currentObj.datashowing.desc = this.assetList.devices[0].desc;
        currentObj.datashowing.loc = this.assetList.devices[0].location;
        currentObj.datashowing.sName = this.getSiteName(
          this.assetList.devices[0].sid
        );
        currentObj.datashowing.show = false;
        currentObj.datashowing.info = this.assetList.devices[0];
        //   currentObj.datashowing.dronedata = {};
        //   currentObj.datashowing.boxdata = {};
        //   currentObj.datashowing.index = index;
        console.log(this.datashowing);
        currentObj.$store.commit("setBoxID", currentObj.datashowing.boxid);
        currentObj.$store.commit("setDroneID", currentObj.datashowing.droneid);
        currentObj.$store.commit("setSite", currentObj.datashowing.sName);
        currentObj.$store.commit("setselectedAsset", currentObj.datashowing);
        // currentObj.$store.commit("setBoxID", currentObj.datashowing.boxid);
        // currentObj.$store.commit("setDroneID", currentObj.datashowing.droneid);
        // currentObj.$store.commit("setSite", currentObj.datashowing.sName);
        // currentObj.$store.commit("setselectedAsset", currentObj.datashowing);
        // this.assetList.devices.forEach(this.displayAssetLocation);

        currentObj.assets = this.assetList.devices.filter(
          (item) => item.sid === 2
        );
        this.assetCountFN();

        this.map.setCenter({
          lat: this.assets[0].location[0],
          lng: this.assets[0].location[1],
        });
      }
    },
    async getOnlineStatus() {
      // this.datashowing.spinner = true;
      let currentObj = this;
      var aid = "";
      await this.assetList.devices.forEach((id) => {
        if (aid === "") {
          aid += id.aid;
        } else aid += "," + id.aid;
      });
      await this.axios
        // .get(this.webAPI + "/app/api/device?aid=" + aid, {
        .get(this.webAPI + "/app/api/device?aid=" + aid, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          currentObj.onlineStatus = response.data.status;
          // console.log(currentObj.onlineStatus);
          // currentObj.$store.commit("setcustomerAsset", currentObj.assetList);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      // console.log(currentObj.onlineStatus['10'])
    },
    async showDetailStationBySIO_ID(item, index) {
      let currentObj = this;
      this.$forceUpdate();
      if (this.deviceKey != null) {
        console.log("Leave...");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.socket.emit("leave", {
          token: this.apiKey,
          room: this.deviceKey,
        });
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.drone_socket.emit("leave", {
          token: this.apiKey,
          room: this.deviceKey,
        });
        currentObj.deviceStatus = {
          time: {
            value: null,
          },
          Sim_Mode: {
            value: true,
          },
        };
      }

      console.log("AssetID : ", item.aid, " | ", index);
      currentObj.deviceKey = await this.getKey(item.aid);

      if (item.type === "box") {
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        if (this.socket.connected) {
          console.log("Box Connected");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
          this.socket.emit("join", {
            token: this.apiKey,
            room: currentObj.deviceKey,
          });
        }
        currentObj.datashowing.boxid = item.aid;
        currentObj.datashowing.droneid = item.mid;
      } else {
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        if (this.drone_socket.connected) {
          console.log("Drone Connected");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
          this.drone_socket.emit("join", {
            token: this.apiKey,
            room: currentObj.deviceKey,
          });
        }
        currentObj.datashowing.boxid = item.mid;
        currentObj.datashowing.droneid = item.aid;
      }

      currentObj.datashowing.type = item.type;
      currentObj.datashowing.name = item.name;
      currentObj.datashowing.desc = item.desc;
      currentObj.datashowing.loc = item.location;
      currentObj.datashowing.sName = this.getSiteName(item.sid);
      currentObj.datashowing.show = true;
      currentObj.datashowing.info = item;
      //   currentObj.datashowing.dronedata = {};
      //   currentObj.datashowing.boxdata = {};
      //   currentObj.datashowing.index = index;
      currentObj.$store.commit("setBoxID", currentObj.datashowing.boxid);
      currentObj.$store.commit("setDroneID", currentObj.datashowing.droneid);
      currentObj.$store.commit("setSite", currentObj.datashowing.sName);
      currentObj.$store.commit("setselectedAsset", currentObj.datashowing);

      // if (item.type === 'box'){
      //   currentObj.$store.commit("setBoxID", currentObj.datashowing.aid);
      //   currentObj.$store.commit("setDroneID", currentObj.datashowing.mid);
      // }
      // else{
      //   currentObj.$store.commit("setBoxID", currentObj.datashowing.mid);
      //   currentObj.$store.commit("setDroneID", currentObj.datashowing.aid);
      // }

      // currentObj.$store.commit("setSite", this.getSiteName(item.sid));
      // currentObj.$store.commit("setselectedAsset", currentObj.datashowing);
      //   currentObj.$store.commit("setBoxLocation", item.location);
      //   currentObj.$store.commit("setSiteLocation", item.siteLocation);
      //   this.getDroneData();
      if (currentObj.zoom === 16) {
        this.map.setCenter(
          // this.markers[this.datashowing.index].getPosition()
          new window.google.maps.LatLng(
            this.datashowing.loc[0],
            this.datashowing.loc[1]
          )
        );
      } else if (currentObj.zoom > 6) {
        this.zoomProcess = true;
        this.interValZoomOut = setInterval(() => this.zoomOutDelay, 200);
      }

      // currentObj.zoom = 6;
      this.interValZoomIn = setInterval(() => this.zoomDelay, 200);
    },
    async getKey(device_id) {
      let currentObj = this;
      var deviceKey = null;
      await this.axios
        .post(this.sioServer + "/auth/getkey?code=" + device_id, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          deviceKey = response.data.key;
          console.log(deviceKey);
          currentObj.$store.commit("setDeviceKey", deviceKey);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      return deviceKey;
    },
    onStatus(data) {
      // console.log(data);
      let currentObj = this;
      currentObj.statusData = [];
      let existData = this.deviceStatus;
      // console.log('Exi : ',JSON.stringify(existData))
      let newData = data.data;
      // console.log('New : ',JSON.stringify(newData))
      currentObj.deviceStatus = Object.assign(existData, newData);
      // for (const property in data.data) {
      //   this.createTable(data.data[property]);
      // }
    },
    createTable(data) {
      if (data.name) {
        for (const property in data.data) {
          this.createList(data.data[property]);
        }
      }
    },
    createList(data) {
      //console.log(type)
      // console.log(data);

      let currentObj = this;
      currentObj.statusData.push(data);
      // $("#status").append("<tr>");
      // $("#status").append("<td>" + data.key + "</td>");
      // $("#status").append("<td>" + data.value + "</td>");
      // $("#status").append("<td>" + data.unit + "</td>");
      // $("#status").append("</tr>");
    },
    getUpdateTime(ms) {
      var date = new Date(ms);
      return date.toLocaleTimeString("th-TH", { hour12: false });
    },
    displayAssetLocation(item, index) {
      if (item.type === "box") {
        console.log("Location Item : ", item.location);
        console.log(item);
        var url = require("../assets/DB2_50x50.png");
        if (item.type === "drone") url = require("../assets/uav30x30.png");
        else url = require("../assets/DB2_50x50.png");
        var image = {
          url: url,
          // url: require("../assets/DB2_50x50.png"),
          // url: require("../assets/drone2.png"),
          //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
          // This marker is 20 pixels wide by 32 pixels high.
          size: new window.google.maps.Size(50, 50),
          // The origin for this image is (0, 0).
          origin: new window.google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new window.google.maps.Point(25, 25),
        };
        var marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            item.location[0],
            item.location[1]
          ),
          icon: image,
          title: item.name,
          map: this.map,
          draggable: false,
        });
        marker.addListener("click", () => {
          // this.map.setZoom(18);
          // this.map.setCenter(item.marker.getPosition());
          this.showDetailStationBySIO_ID(item, index);
          // console.log("Box Station Index : ", index);
        });
        this.markers.push(marker);
      }
    },
    initDragElement() {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      var popups = document.getElementsByClassName("popup");
      var elmnt = null;
      var currentZIndex = 100; //TODO reset z index when a threshold is passed

      for (var i = 0; i < popups.length; i++) {
        var popup = popups[i];
        var header = getHeader(popup);

        popup.onmousedown = function () {
          this.style.zIndex = "" + ++currentZIndex;
        };

        if (header) {
          header.parentPopup = popup;
          header.onmousedown = dragMouseDown;
        }
      }

      function dragMouseDown(e) {
        elmnt = this.parentPopup;
        elmnt.style.zIndex = "" + ++currentZIndex;

        e = e || window.event;
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        if (!elmnt) {
          return;
        }

        e = e || window.event;
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }

      function getHeader(element) {
        var headerItems = element.getElementsByClassName("popup-header");

        if (headerItems.length === 1) {
          return headerItems[0];
        }

        return null;
      }
    },

    initResizeElement() {
      var popups = document.getElementsByClassName("popup");
      var element = null;
      var startX, startY, startWidth, startHeight;

      for (var i = 0; i < popups.length; i++) {
        var p = popups[i];

        var right = document.createElement("div");
        right.className = "resizer-right";
        p.appendChild(right);
        right.addEventListener("mousedown", initDrag, false);
        right.parentPopup = p;

        var bottom = document.createElement("div");
        bottom.className = "resizer-bottom";
        p.appendChild(bottom);
        bottom.addEventListener("mousedown", initDrag, false);
        bottom.parentPopup = p;

        var both = document.createElement("div");
        both.className = "resizer-both";
        p.appendChild(both);
        both.addEventListener("mousedown", initDrag, false);
        both.parentPopup = p;
      }

      function initDrag(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startY = e.clientY;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10
        );
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10
        );
        document.documentElement.addEventListener("mousemove", doDrag, false);
        document.documentElement.addEventListener("mouseup", stopDrag, false);
      }

      function doDrag(e) {
        element.style.width = startWidth + e.clientX - startX + "px";
        element.style.height = startHeight + e.clientY - startY + "px";
      }

      function stopDrag() {
        document.documentElement.removeEventListener(
          "mousemove",
          doDrag,
          false
        );
        document.documentElement.removeEventListener(
          "mouseup",
          stopDrag,
          false
        );
      }
    },
  },
  beforeDestroy() {
    // console.log("Clear Home Interval Time....");
    // clearInterval(this.interValDrone);
    // clearInterval(this.interValBox);
    // clearInterval(this.interValAvai);
    // clearInterval(this.interValAsset);
    clearInterval(this.interValOnline);
    // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
    this.socket.close();
    // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
    this.drone_socket.close();
  },
};
</script>
<style scoped>
.main-container-siteop {
  position: relative;
  width: calc(100%);
  height: 100vh;
  background: green;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f15b;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888894;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.home {
  background-color: gray;
  width: 100%;
  height: 100%;
  border-radius: 2.5px;
  position: relative;
}
.map {
  height: calc(100vh - 63px);
}
@media only screen and (max-height: 560px) {
  .img-device {
    display: none !important;
  }
  .card-body {
    height: calc(100vh - 8rem);
  }

  .map {
    height: calc(100vh - 63px);
  }
}
.map {
  height: 100vh;
}
.contentMission {
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 400px;
  padding: 0px;
  margin: 0px;
  height: 100%;
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 9999;
  padding: 1rem;
  overflow: scroll none;

  padding: 32px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
}
@media only screen and (max-width: 560px) {
  .img-device {
    display: none !important;
  }
  .card-body {
    height: calc(100vh - 8rem);
  }

  .map {
    height: calc(100vh - 63px);
  }
}
.container-content {
  display: flex;
  margin: 0px;
  padding: 0px;
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.siteop-header {
  display: flex;
  width: 360px;
  align-items: flex-start;
  gap: 16px;
  color: var(--gray-50, #ececef);
}
.siteop-header-layout {
  display: flex;
  width: 342px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}
.siteop-sitename {
  color: var(--gray-50, #ececef);

  /* ENG/Headline [H6] */
  font-size: 18px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 28px;
}
.siteop-desc-layout {
  display: flex;
  align-items: center;
  gap: 6px;
}
.siteop-sitedesc {
  color: var(--gray-50, #ececef);

  /* ENG/Body2 */
  font-size: 14px;
  font-family: Roboto;
  line-height: 22px;
}
.siteop-loc-icon {
  color: #6778ff;
  font-size: 20px;
}
.card-qty:hover,
.card-qty-active:hover {
  cursor: pointer;
  /* border: solid 1px #6778ff; */
  background: var(--opacity-gray-secondary-90070, rgba(13, 13, 13, 0.7));
}
.siteop-qty {
  display: flex;
  width: 368px;
  height: 58px;
  align-items: flex-start;
  gap: 8px;
}
.card-qty-active {
  display: flex;
  padding: 7.5px 0px 6.5px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 12px;
  background: var(--opacity-gray-secondary-90070, rgba(13, 13, 13, 0.7));
  backdrop-filter: blur(10px);
}
.card-qty {
  display: flex;
  padding: 7.5px 3.7px 6.5px 2.5px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
}
.qty-group-val {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: -4px;
}
.val-qty-active {
  color: var(--primary-400, #6778ff);
  text-align: center;

  /* ENG/Headline [H5] */
  font-size: 20px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 30px;
}
.val-qty {
  color: var(--gray-primary-600, #757c87);
  text-align: center;

  /* ENG/Headline [H5] */
  font-size: 20px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 30px;
}
.title-qty-active {
  color: var(--primary-400, #6778ff);
  text-align: center;

  /* ENG/Body3 */
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
  margin: -10px;
}
.title-qty {
  margin: -10px;
  color: var(--gray-primary-600, #757c87);
  text-align: center;

  /* ENG/Body3 */
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
}
.div-clear {
  padding: 0px;
  margin: 0px;
}
.dis-none {
  display: none;
}
.device-container {
  /* width: 100%; */
  height: calc(100vh - 192px);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}
.alert-icon-layout {
  position: absolute;
  top: 20px;
  right: 20px;
}
.alert-icon:hover,
.badge-alert:hover,
.alert-icon-layout:hover {
  cursor: pointer;
}
.alert-icon {
  margin: 0;
  padding: 0;
  position: relative;
}
.badge-alert {
  position: absolute;
  bottom: 5px;
  left: 2px;
}
.popup {
  z-index: 9;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  text-align: center;
  min-height: 150px;
  min-width: 300px;
  max-height: 300px;
  max-width: 600px;
}

/*Drgable */

.popup {
  position: absolute;
  /*resize: both; !*enable this to css resize*! */
  overflow: auto;
}

.popup-header {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196f3;
  color: #fff;
}

/*Resizeable*/

.popup .resizer-right {
  width: 5px;
  height: 100%;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: e-resize;
}

.popup .resizer-bottom {
  width: 100%;
  height: 5px;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: n-resize;
}

.popup .resizer-both {
  width: 5px;
  height: 5px;
  background: transparent;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nw-resize;
}

/*NOSELECT*/

.popup * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.alert-layout {
  position: absolute;
  top: 100px;
  right: 10px;

  height: calc(100vh - 100px);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
