<template>
  <div class="home">
    <div class="map" ref="map"></div>
    <div class="rightcontainer">
      <div class="statusCard">
        <div class="eventMessageXX" style="padding: 0px">
          <div>
            <b-form-group
              label="Asset View : "
              label-cols-sm="4"
              label-align-sm="right"
              label-size="sm"
              class="mb-2 mt-2"
            >
              <b-form-radio-group v-model="filter" class="mt-1">
                <b-form-radio value="">All</b-form-radio>
                <b-form-radio value="box">Station</b-form-radio>
                <b-form-radio value="drone">Drone</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <!-- <test1 class="test1-com-style" v-for="item in assetList.devices" :key="item.name" :title="item.name"/> -->
          <div class="w-100" style="text-align: center">
            <b-table
              :items="assetList.devices"
              :fields="fields"
              :filter="filter"
              head-variant="dark"
              sticky-header="80vh"
            >
              <template v-slot:cell(name)="row">
                <a style="color: white">{{ row.item.name }} </a>
              </template>
              <template v-slot:cell(sid)="row">
                <a style="color: white">{{ getSiteName(row.item.sid) }} </a>
              </template>
              <template v-slot:cell(type)="row">
                <a style="color: white">{{ row.item.type }} </a>
              </template>
              <template v-slot:cell(View)="row">
                <!-- <b-button
                  v-if="row.item.Online" -->
                <b-button
                  title="Operation View"
                  @click="showDetailStationBySIO_ID(row.item, row.index)"
                  variant="dark"
                  style="margin: auto; padding: auto"
                >
                  <b-icon icon="eye-fill" aria-hidden="true"></b-icon>
                </b-button>
              </template>
              <template v-slot:cell(Online)="row">
                <div
                  v-if="onlineStatus[row.item.aid]"
                  style="
                    border: 1px solid #bfbfbf;
                    background-color: rgb(101, 252, 0);
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    padding: auto;
                    margin: auto;
                  "
                ></div>
                <div
                  v-else
                  style="
                    border: 1px solid #bfbfbf;
                    background-color: #bfbfbf;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    padding: auto;
                    margin: auto;
                  "
                ></div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div class="leftcontainer">
      <div class="statusCard1" v-if="datashowing.show">
        <p class="x-button" v-on:click="closeDialog">X</p>
        <b-tabs fill style="color: white">
          <b-tab no-body title="Information" active>
            <b-card-img
              v-if="datashowing.type === 'box'"
              bottom
              src="/img/headcard/DB1.jpg"
              alt="Image Base"
              class="img-device"
            ></b-card-img>
            <b-card-img
              v-if="datashowing.type === 'drone'"
              bottom
              src="/img/headcard/UAV.png"
              alt="Image UAV"
              class="img-device"
            ></b-card-img>

            <b-card-footer>Device Name : {{ datashowing.name }}</b-card-footer>
            <div class="card-body">
              <div class="headerBarXX">Device Information</div>
              <div class="eventMessageXX">
                <CardValue name="Device Name" :value="datashowing.name" />

                <CardValue
                  v-if="datashowing.type === 'box'"
                  name="Device ID"
                  :value="datashowing.boxid"
                />
                <CardValue
                  v-if="datashowing.type === 'drone'"
                  name="Device ID"
                  :value="datashowing.droneid"
                />
                <CardValue name="Description" :value="datashowing.desc" />
                <CardValue
                  v-if="datashowing.type === 'box'"
                  name="Mate ID"
                  :value="datashowing.droneid"
                />
                <CardValue
                  v-if="datashowing.type === 'drone'"
                  name="Mate ID"
                  :value="datashowing.boxid"
                />
                <CardValue
                  v-if="datashowing.type === 'box'"
                  name="Mate Name"
                  :value="getDeviceName(datashowing.droneid)"
                />
                <CardValue
                  v-if="datashowing.type === 'drone'"
                  name="Mate Name"
                  :value="getDeviceName(datashowing.boxid)"
                />
                <CardValue name="Location" :value="datashowing.loc" />

                <CardValue name="Site Name" :value="datashowing.sName" />
                <CardValue
                  name="Last Update"
                  :value="getUpdateTime(deviceStatus.time.value)"
                />
                <CardValue
                  v-if="datashowing.type === 'box'"
                  name="Sim Mode"
                  :value="deviceStatus.Sim_Mode.value"
                />
              </div>
              <div class="container-btn">
                <b-button
                  v-if="datashowing.type === 'box'"
                  title="Base Control Panel"
                  :to="'/panel/station/'+datashowing.info.aid"
                  variant="success"
                  class="btn-small-screen"
                >
                  Control Panel
                  <b-icon icon="eye-fill" aria-hidden="true"></b-icon>
                </b-button>
                <b-button
                  v-if="datashowing.type === 'drone'"
                  title="Drone Control Panel"
                  :to="'/panel/uav/'+datashowing.info.aid"
                  variant="success"
                  class="btn-small-screen"
                >
                  Control Panel
                  <b-icon icon="eye-fill" aria-hidden="true"></b-icon>
                </b-button>
              </div>
              <div v-for="item in deviceStatus" :key="item.key">
                <div v-if="item.name">
                  <div class="headerBarXX">{{ item.name }}</div>
                  <div class="eventMessageXX">
                    <CardValue
                      v-for="idata in item.data"
                      :key="idata.key + idata.value"
                      :name="idata.key"
                      :value="idata.value + ' ' + idata.unit"
                    />
                  </div>
                </div>
              </div>

              <!--            
              <div class="headerBarXX">Base Information</div>
              <div class="eventMessageXX">
                <CardValue name="Base ID" :value="datashowing.info.id" />
                <CardValue name="Base Name" :value="datashowing.info.name" />
                <CardValue name="Customer" :value="datashowing.info.customer" />
                <CardValue name="Version" value="V2.0.0" />
                <CardValue name="Firmware" value="1.0.0.0" />
                <CardValue name="Last Update" :value="datashowing.boxUpdate" />
                <CardValue name="Register Date" value="15-Sep-2021" />
              </div>
              <div class="headerBarXX">Operation Status</div>
              <div class="eventMessageXX">
                {{ datashowing.boxdata.time }}
                <CardValue name="Cover" :value="datashowing.boxdata.COVER" />
                <CardValue name="Lefting" :value="datashowing.boxdata.LIFT" />
                <CardValue
                  name="Centerlize-X"
                  :value="datashowing.boxdata.CEN_X"
                />
                <CardValue
                  name="Centerlize-Y"
                  :value="datashowing.boxdata.CEN_Y"
                />
                <CardValue name="Mode" :value="datashowing.boxdata.mode" />
                <CardValue name="IR Beacon" :value="datashowing.boxdata.IR" />
                <CardValue name="Lighting" :value="datashowing.boxdata.LED" />
                <CardValue name="CAM1" :value="datashowing.boxdata.CAM1" />
                <CardValue name="CAM2" :value="datashowing.boxdata.CAM2" />
                <CardValue name="CAM3" :value="datashowing.boxdata.CAM3" />
              </div>
              <div class="container-btn">
                <b-button
                  title="Base Camara View"
                  to="/dashboard"
                  variant="success"
                  class="btn-small-screen"
                >
                  Dashboard
                  <b-icon icon="eye-fill" aria-hidden="true"></b-icon>
                </b-button>
                <b-button
                  title="Base Camara View"
                  to="/cctv"
                  variant="success"
                  class="btn-small-screen"
                >
                  CCTV Panel
                  <b-icon icon="eye-fill" aria-hidden="true"></b-icon>
                </b-button>
              </div>
              <div class="headerBarXX">Power Status</div>
              <div class="eventMessageXX">
                <CardValue name="AC Power" value="Available" />
                <CardValue
                  name="Voltage"
                  :value="datashowing.boxdata.acVoltage"
                />
                <CardValue
                  name="Current"
                  :value="datashowing.boxdata.acCurrent"
                />
                <CardValue name="Watt" :value="datashowing.boxdata.acWatt" />
              </div>
              <div class="headerBarXX">Charger Status</div>
              <div class="eventMessageXX">
                <CardValue name="Mode" value="Charging" />
                <CardValue
                  name="Voltage"
                  :value="datashowing.boxdata.chargingVoltage"
                />
                <CardValue
                  name="Current"
                  :value="datashowing.boxdata.chargingCurrent"
                />
                <CardValue
                  name="Battery"
                  :value="datashowing.boxdata.chargingPercent"
                />
                <CardValue
                  name="Temperature"
                  :value="datashowing.boxdata.chargerTemperature"
                />
              </div>
              <div class="headerBarXX">Weather Condition</div>
              <div class="eventMessageXX">
                <CardValue name="Connection" value="Connected" />
                <CardValue
                  name="Wind Speed"
                  :value="datashowing.boxdata.windSpeed"
                />
                <CardValue
                  name="Gust Speed"
                  :value="datashowing.boxdata.gustSpeed"
                />
                <CardValue
                  name="Wind DIR"
                  :value="datashowing.boxdata.windDIR"
                />
                <CardValue
                  name="Rain"
                  :value="datashowing.boxdata.weatherRain"
                />
                <CardValue
                  name="Humidity"
                  :value="datashowing.boxdata.weatherHumidity"
                />
                <CardValue
                  name="Temperature"
                  :value="datashowing.boxdata.weatherTemperature"
                />
                <CardValue name="UV" :value="datashowing.boxdata.UV" />
                <CardValue name="Light" :value="datashowing.boxdata.light" />
                <CardValue name="Pressure" :value="datashowing.boxdata.press" />
                <CardValue name="Density" :value="datashowing.boxdata.den" />
              </div> -->
            </div>
          </b-tab>

          <!-- <b-tab no-body title="UAV" disabled>
            <b-card-img
              bottom
              src="/img/headcard/UAV.png"
              alt="Image UAV"
              class="img-device"
            ></b-card-img>
            <b-card-footer
              >UAV ID : {{ datashowing.info.mapping }}</b-card-footer
            >
            <div class="card-body">
              <div class="headerBarXX">UAV Information</div>
              <div class="eventMessageXX">
                <CardValue name="ID" :value="datashowing.info.mapping" />
                <CardValue name="Model" value="Phoenix" />
                <CardValue name="Version" value="3.1" />
                <CardValue name="Connection" value="Offline" />
                <CardValue name="Status" value="N/A" />
                <CardValue name="Customer" :value="datashowing.info.customer" />
                <CardValue name="Firmware" value="1.0.0.0" />
                <CardValue
                  name="Last Update"
                  :value="datashowing.droneUpdate"
                />
                <CardValue name="Register Date" value="15-Sep-2021" />
                <CardValue name="Operated Time" value="100 Hrs" />
                <CardValue name="Next Maint." value="200 Hrs" />
              </div>
              <div class="headerBarXX">Operation Status</div>
              <div class="eventMessageXX">
                <CardValue
                  name="Mode"
                  :value="datashowing.dronedata.operatingMode"
                />
                <CardValue
                  name="GPS Lat"
                  :value="datashowing.dronedata.gpsLat"
                />
                <CardValue
                  name="GPS Long"
                  :value="datashowing.dronedata.gpsLon"
                />
                <CardValue
                  name="Altitude"
                  :value="datashowing.dronedata.altitude"
                />
                <CardValue
                  name="Satellite"
                  :value="datashowing.dronedata.gpsSat"
                />
                <CardValue name="HDOP" :value="datashowing.dronedata.hdop" />
                <CardValue
                  name="Ground Speed"
                  :value="datashowing.dronedata.speed"
                />
                <CardValue
                  name="Heading"
                  :value="datashowing.dronedata.heading"
                />
                <CardValue
                  name="Dist. Home (m)"
                  :value="datashowing.dronedata.distToHome"
                />
                <CardValue name="Roll" :value="datashowing.dronedata.roll" />
                <CardValue name="Pitch" :value="datashowing.dronedata.pitch" />
                <CardValue name="Yaw" :value="datashowing.dronedata.yaw" />
                <CardValue
                  name="RSSI (%)"
                  :value="datashowing.dronedata.rssi"
                />
                <CardValue name="EKF" :value="datashowing.dronedata.ekfOk" />
                <CardValue name="Is Armed" :value="datashowing.dronedata.arm" />
                <CardValue name="Is in air" value="Unknow" />
                <CardValue
                  name="Batt. Volt (V)"
                  :value="datashowing.dronedata.battVoltage"
                />
                <CardValue
                  name="Batt. Current (A)"
                  :value="datashowing.dronedata.battCurrent"
                />
                <CardValue
                  name="Batt. Percent(%)"
                  :value="datashowing.dronedata.battPercent"
                />
              </div>
              <div class="container-btn">
                <b-button
                  title="UAV Operation View"
                  to="/operation"
                  variant="success"
                  class="btn-small-screen"
                >
                  Operation Panel
                  <b-icon icon="eye-fill" aria-hidden="true"></b-icon>
                </b-button>
              </div>
              <div class="headerBarXX">Task Status</div>
              <div class="eventMessageXX">
                <CardValue
                  name="Task ID"
                  :value="datashowing.dronedata.missionID"
                />
                <CardValue
                  name="Status"
                  :value="datashowing.dronedata.statusState"
                />
                <CardValue
                  name="State Code"
                  :value="datashowing.dronedata.statusCode"
                />
                <CardValue
                  name="Current WP"
                  :value="datashowing.dronedata.currentWaypoint"
                />
                <CardValue
                  name="Total WP"
                  :value="datashowing.dronedata.totalWaypoint"
                />
                <CardValue
                  name="Progress (%)"
                  :value="datashowing.dronedata.percentOfMission"
                />
                <CardValue name="Drone Cam." value="Unknow" />
              </div>
            </div>
          </b-tab> -->
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import GmapPanel from "../components/MapPanel.vue";
// import test1 from "../components/test/modDeviceInfo.vue"
import CardValue from "../components/CardValue.vue";
import io from "socket.io-client";
// import swal from "sweetalert2";
export default {
  name: "Home",
  components: {
    // HelloWorld
    // GmapPanel,
    CardValue,
    // test1
  },
  data() {
    return {
      alert: {},
      statusData: [],
      deviceKey: null,
      deviceStatus: {
        time: {
          value: null,
        },
        Sim_Mode: {
          value: true,
        },
      },
      filter: "box",
      socket: null,
      drone_socket: null,
      webAPI: this.$store.state.webAPI,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
      assetList: {},
      /////////////////////////////////////////////////////
      item: {},
      boxesAsset: [],
      assetStatus: [],
      apiServer: this.$store.state.apiDataServe,
      realTimeServer: this.$store.state.apiRealtimeServe,
      states: [],
      customer: this.$store.state.owner,
      output2: null,
      datashowing: {
        boxid: this.$store.state.selectedBoxID,
        droneid: this.$store.state.selectedDroneID,
        boxdata: {},
        dronedata: {},
        boxUpdate: "",
        droneUpdate: "",
        show: false,
        spinner: true,
        index: 0,
        type: "",
      },
      fields: [
        // "edit",
        // { key: "aid", label: "Asset ID" },
        { key: "name", label: "Name" },
        { key: "type", label: "Type" },
        { key: "sid", label: "Site" },
        { key: "Online", label: "Status" },
        "View",
      ],
      map: null,
      markers: [],
      interValDrone: null,
      interValBox: null,
      interValAvai: null,
      interValAsset: null,
      zoom: 6,
      interValZoomIn: null,
      interValZoomOut: null,
      zoomProcess: false,
      onlineStatus: [],
    };
  },
  mounted() {
    if (this.customerID == null) {
      this.customerID = sessionStorage.getItem("setcustomerID");
    }
    if (this.apiKey == null) {
      this.apiKey = sessionStorage.getItem("setAPIKey");
    }
    if (this.sioServer == null) {
      this.sioServer = sessionStorage.getItem("setSioServer");
    }
    this.socket = io(this.sioServer + "/station/status", {
      auth: { deviceID: this.userName, token: this.apiKey },
      transports: ["websocket"],
    });
    this.drone_socket = io(this.sioServer + "/drone/status", {
      auth: { deviceID: this.userName, token: this.apiKey },
      transports: ["websocket"],
    });
    // this.socket.on("log", (data) => this.logHandle(data));
    this.socket.on("status", (data) => this.onStatus(data));
    this.socket.on("alert", (data) => this.showAlert(data));
    this.drone_socket.on("status", (data) => this.onStatus(data));
    this.socket.on("authen", (data) => {
      console.log(data);
      sessionStorage.clear();
      this.$store.commit("setLoginState", false);
      window.location.href = "/#/";
    });
    this.drone_socket.on("authen", (data) => {
      console.log(data);
      sessionStorage.clear();
      this.$store.commit("setLoginState", false);
      window.location.href = "/#/";
    });
    this.map = new window.google.maps.Map(this.$refs["map"], {
      mapTypeId: "terrain",
      center: { lat: 13.932, lng: 100.573 },
      zoom: this.zoom,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    });
    this.map.setCenter({ lat: 13.7191758, lng: 100.5205329 });
    this.getAssets();
    // this.getAsset();
    this.getOnlineStatus();
    this.interValOnline = setInterval(this.getOnlineStatus, 2000);

    // this.drawAssetOnMap();
    // this.interValBox = setInterval(this.getBoxData, 1000);
    // this.interValDrone = setInterval(this.getDroneData, 1000);
    // this.interValAvai = setInterval(this.getAssetStatus, 2000);
    // this.interValAsset = setInterval(this.getAsset, 30000);
  },
  methods: {
    // drawAssetOnMap(){

    // },
    showAlert(data) {
      // console.log('Alert MSG...')
      this.alert = data;
      console.log(this.alert);
      this.$bvToast.toast(data.data.msg, {
        title: "Alert",
        variant: "danger",
        solid: true,
      });
    },
    showWarning(msg) {
      this.$bvToast.toast(msg, {
        title: "Warning",
        variant: "warning",
        solid: true,
      });
    },
    getSiteName(sid) {
      var siteName = null;
      // console.log("sid:", sid);
      try {
        this.assetList.sites.forEach((obj) => {
          // console.log(obj.name);
          if (obj.sid === sid) {
            // console.log("name:", obj.name);
            siteName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      // for (let item in this.assetList.sites){
      //   console.log(item)
      //   if (item.sid === sid){
      //     console.log('name:',item.name)
      //     return item.name;
      //   }
      // }
      // console.log('name:null')
      return siteName;
    },
    getDeviceName(mid) {
      var assetName = null;
      // console.log("mid:", mid);
      try {
        this.assetList.devices.forEach((obj) => {
          // console.log(obj.name);
          if (obj.aid === mid) {
            // console.log("name:", obj.name);
            assetName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      // for (let item in this.assetList.sites){
      //   console.log(item)
      //   if (item.sid === sid){
      //     console.log('name:',item.name)
      //     return item.name;
      //   }
      // }
      // console.log('name:null')
      return assetName;
    },
    closeDialog() {
      this.datashowing.show = false;
      this.zoomProcess = true;
      this.interValZoomOut = setInterval(this.zoomOutDelay, 200);
    },
    Caltime: function (item_time) {
      // console.log(item_time)
      var ddd = parseInt(item_time);
      var current_datetime = new Date(ddd);
      // console.log(current_datetime)
      var dateTime =
        current_datetime.getFullYear() +
        "-" +
        (current_datetime.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        current_datetime.getDate().toString().padStart(2, "0") +
        " " +
        current_datetime.getHours().toString().padStart(2, "0") +
        ":" +
        current_datetime.getMinutes().toString().padStart(2, "0") +
        ":" +
        current_datetime.getSeconds().toString().padStart(2, "0");
      return dateTime;
    },
    async getAsset() {
      let currentObj = this;
      this.boxesAsset.forEach((element) => element.setMap(null));
      // console.log("Owner : ", currentObj.customer);
      await this.axios
        .get(this.apiServer + "/getlist/boxes/" + currentObj.customer)
        .then(function (response) {
          // console.log(response.data);
          currentObj.boxesAsset = response.data;
          currentObj.boxesAsset.forEach((lement) =>
            currentObj.$store.commit("setAssetInfo", lement)
          );
          // currentObj.$store.commit("setAssetInfo", currentObj.boxesAsset);
          sessionStorage.setItem("setAssetInfo", currentObj.boxesAsset);
          // currentObj.states = response.data;
          // alert("Request Command : "+response.data.message);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      await this.getAssetStatus();
      this.boxesAsset.forEach(this.displayBoxLocation);

      if (currentObj.boxesAsset.length > 0) {
        // console.log("Data : ", currentObj.boxesAsset);
        currentObj.$store.commit("setBoxID", this.boxesAsset[0].id);
        currentObj.$store.commit("setDroneID", this.boxesAsset[0].mapping);
        currentObj.$store.commit("setSite", this.boxesAsset[0].site);
        currentObj.$store.commit("setBoxLocation", this.boxesAsset[0].location);
        currentObj.$store.commit(
          "setSiteLocation",
          this.boxesAsset[0].siteLocation
        );
      }
      // this.assets.forEach(this.addDataToList);
      //////////////////////////////// TO GET BOX LOCATION for HOME LOCATION//////////////////////////////////////////////////////////
    },
    async getAssetStatus() {
      let currentObj = this;
      // console.log("Owner : ", currentObj.customer);
      await this.axios
        .post(this.realTimeServer + "/dbx/v2/available", {
          boxes: this.boxesAsset,
        })
        .then(function (response) {
          // console.log(response.data.status);
          currentObj.assetStatus = response.data.status;
          // currentObj.states = response.data;
          // alert("Request Command : "+response.data.message);
        })
        .catch(function (error) {
          // currentObj.output2 = error;
          console.error(error);
          // alert(error);
        });
    },
    displayBoxLocation(item, index) {
      // console.log("Location Item : ", item.location);
      var image = {
        url: require("../assets/DB2_50x50.png"),
        // url: require("../assets/drone2.png"),
        //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new window.google.maps.Size(50, 50),
        // The origin for this image is (0, 0).
        origin: new window.google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(25, 25),
      };
      this.markers.push(
        new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            item.location[0],
            item.location[1]
          ),
          icon: image,
          title: "DBX Station" + index.toString(),
          map: this.map,
          draggable: false,
        })
      );
      this.markers[index].addListener("click", () => {
        // this.map.setZoom(18);
        // this.map.setCenter(item.marker.getPosition());
        this.showDetailStationByID(item, index);
        // console.log("Box Station Index : ", index);
      });
    },
    async showDetailStationByID(item, index) {
      let currentObj = this;
      console.log(item);
      currentObj.datashowing.boxid = item.id;
      currentObj.datashowing.droneid = item.mapping;
      currentObj.datashowing.show = true;
      currentObj.datashowing.info = item;
      currentObj.datashowing.dronedata = {};
      currentObj.datashowing.boxdata = {};
      currentObj.datashowing.index = index;
      currentObj.$store.commit("setBoxID", item.id);
      currentObj.$store.commit("setDroneID", item.mapping);
      currentObj.$store.commit("setSite", item.site);
      currentObj.$store.commit("setBoxLocation", item.location);
      currentObj.$store.commit("setSiteLocation", item.siteLocation);
      this.getDroneData();
      if (currentObj.zoom > 6) {
        this.zoomProcess = true;
        this.interValZoomOut = setInterval(() => this.zoomOutDelay, 200);
      }

      // currentObj.zoom = 6;
      this.interValZoomIn = setInterval(() => this.zoomDelay, 200);
    },
    zoomDelay() {
      if (!this.zoomProcess) {
        if (this.zoom == 6) {
          this.map.setCenter(
            // this.markers[this.datashowing.index].getPosition()
            new window.google.maps.LatLng(
              this.datashowing.loc[0],
              this.datashowing.loc[1]
            )
          );
        }
        if (this.zoom < 16) {
          this.zoom++;
          this.map.setZoom(this.zoom);
        } else {
          clearInterval(this.interValZoomIn);
        }
      }
    },
    zoomOutDelay() {
      if (this.zoom > 6) {
        this.zoom--;
        this.map.setZoom(this.zoom);
      } else {
        clearInterval(this.interValZoomOut);
        this.zoomProcess = false;
      }
    },
    async getDroneData() {
      if (this.datashowing.show) {
        let currentObj = this;
        // console.log("Getting Data of Drone ID : ", this.datashowing.droneid);
        // console.log("call : " + this.apiServer + "/drone/v2/status/" + this.datashowing.droneid);
        this.axios
          .get(
            this.realTimeServer + "/drone/v2/status/" + this.datashowing.droneid
          )
          .then(function (response) {
            // console.log("Respone Code : ", response.status);
            if (response.status == 200) {
              currentObj.datashowing.dronedata = response.data;
              // console.log(currentObj.datashowing.dronedata);
              currentObj.datashowing.spinner = false;
            }
          })
          .catch(function (error) {
            currentObj.output2 = error;
          });
        currentObj.datashowing.droneUpdate = this.Caltime(
          currentObj.datashowing.dronedata.time
        );
      }
    },
    async getBoxData() {
      if (this.datashowing.show) {
        let currentObj = this;
        // console.log("Getting Data of Base ID : ", this.datashowing.boxid);
        // console.log('Owner : ', currentObj.customer)
        await this.axios
          .get(this.realTimeServer + "/dbx/v2/status/" + this.datashowing.boxid) // + currentObj.customer)
          .then(function (response) {
            // console.log(response.data);
            currentObj.datashowing.boxdata = response.data;
            // response.data.controlElement.forEach(this.declareData);
            // currentObj.datashowing.boxdata.UPDATE = this.Caltime(response.data.time)

            for (var iii in response.data.controlElement) {
              // console.log(response.data.controlElement[iii]);
              var item = response.data.controlElement[iii];
              if (item.type == "COVER") {
                if (item.diag) currentObj.datashowing.boxdata.COVER = "Opened";
                else currentObj.datashowing.boxdata.COVER = "Closed";
              } else if (item.type == "LIFT") {
                if (item.diag) currentObj.datashowing.boxdata.LIFT = "Up";
                else currentObj.datashowing.boxdata.LIFT = "Down";
              } else if (item.type == "CEN_X") {
                if (item.diag)
                  currentObj.datashowing.boxdata.CEN_X = "Centered";
                else currentObj.datashowing.boxdata.CEN_X = "Released";
              } else if (item.type == "CEN_Y") {
                if (item.diag)
                  currentObj.datashowing.boxdata.CEN_Y = "Centered";
                else currentObj.datashowing.boxdata.CEN_Y = "Released";
              }
            }
            // console.log(currentObj.datashowing.boxdata.controlElement);
            currentObj.datashowing.spinner = false;
            // currentObj.states = response.data;
            // alert("Request Command : "+response.data.message);
          })
          .catch(function (error) {
            currentObj.output2 = error;
            // alert(error);
          });
        currentObj.datashowing.boxUpdate = this.Caltime(
          currentObj.datashowing.boxdata.time
        );
      }
    },
    //////////////////////////// Verify function ///////////////////////////////////////
    async getAssets() {
      console.log("Get Asset Function");
      // this.datashowing.spinner = true;
      let currentObj = this;
      await this.axios
        .get(this.webAPI + "/app/api/asset?cid=" + this.customerID, {
          headers: {
            authorization: this.apiKey,
          },
        })

        .then((response)  => {
          if (response.status === 400) {
            sessionStorage.clear();
            this.$store.commit("setLoginState", false);
            window.location.href = "/#/";
          }
          currentObj.assetList = response.data;
          // console.log(currentObj.assetList);
          currentObj.$store.commit("setcustomerAsset", currentObj.assetList);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          console.log("Get Asset Function__EER");
          currentObj.output2 = error;
          // alert(error);
        });

      console.log(this.assetList.devices);
      console.log("Get Asset Function3");
      if (this.assetList.devices.length > 0) {
        if (this.assetList.devices[0].type === "box") {
          console.log("Get Asset Function4");
          currentObj.datashowing.boxid = this.assetList.devices[0].aid;
          currentObj.datashowing.droneid = this.assetList.devices[0].mid;
        } else {
          console.log("Get Asset Function5");
          currentObj.datashowing.boxid = this.assetList.devices[0].mid;
          currentObj.datashowing.droneid = this.assetList.devices[0].aid;
        }
        console.log("Get Asset Function6");
        currentObj.datashowing.type = this.assetList.devices[0].type;
        currentObj.datashowing.name = this.assetList.devices[0].name;
        currentObj.datashowing.desc = this.assetList.devices[0].desc;
        currentObj.datashowing.loc = this.assetList.devices[0].location;
        currentObj.datashowing.sName = this.getSiteName(
          this.assetList.devices[0].sid
        );
        currentObj.datashowing.show = false;
        currentObj.datashowing.info = this.assetList.devices[0];
        //   currentObj.datashowing.dronedata = {};
        //   currentObj.datashowing.boxdata = {};
        //   currentObj.datashowing.index = index;
        console.log(this.datashowing);
        currentObj.$store.commit("setBoxID", currentObj.datashowing.boxid);
        currentObj.$store.commit("setDroneID", currentObj.datashowing.droneid);
        currentObj.$store.commit("setSite", currentObj.datashowing.sName);
        currentObj.$store.commit("setselectedAsset", currentObj.datashowing);
        // currentObj.$store.commit("setBoxID", currentObj.datashowing.boxid);
        // currentObj.$store.commit("setDroneID", currentObj.datashowing.droneid);
        // currentObj.$store.commit("setSite", currentObj.datashowing.sName);
        // currentObj.$store.commit("setselectedAsset", currentObj.datashowing);
        this.assetList.devices.forEach(this.displayAssetLocation);
      }
    },
    async getOnlineStatus() {
      // this.datashowing.spinner = true;
      let currentObj = this;
      var aid = "";
      await this.assetList.devices.forEach((id) => {
        if (aid === "") {
          aid += id.aid;
        } else aid += "," + id.aid;
      });
      await this.axios
        .get(this.webAPI + "/app/api/device?aid=" + aid, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          currentObj.onlineStatus = response.data.status;
          // console.log(currentObj.onlineStatus);
          // currentObj.$store.commit("setcustomerAsset", currentObj.assetList);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      // console.log(currentObj.onlineStatus['10'])
    },
    async showDetailStationBySIO_ID(item, index) {
      let currentObj = this;
      this.$forceUpdate();
      if (this.deviceKey != null) {
        console.log("Leave...");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.socket.emit("leave", {
          token: this.apiKey,
          room: this.deviceKey,
        });
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.drone_socket.emit("leave", {
          token: this.apiKey,
          room: this.deviceKey,
        });
        currentObj.deviceStatus = {
          time: {
            value: null,
          },
          Sim_Mode: {
            value: true,
          },
        };
      }

      console.log("AssetID : ", item.aid, " | ", index);
      currentObj.deviceKey = await this.getKey(item.aid);

      if (item.type === "box") {
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        if (this.socket.connected) {
          console.log("Box Connected");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
          this.socket.emit("join", {
            token: this.apiKey,
            room: currentObj.deviceKey,
          });
        }
        currentObj.datashowing.boxid = item.aid;
        currentObj.datashowing.droneid = item.mid;
      } else {
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        if (this.drone_socket.connected) {
          console.log("Drone Connected");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
          this.drone_socket.emit("join", {
            token: this.apiKey,
            room: currentObj.deviceKey,
          });
        }
        currentObj.datashowing.boxid = item.mid;
        currentObj.datashowing.droneid = item.aid;
      }

      currentObj.datashowing.type = item.type;
      currentObj.datashowing.name = item.name;
      currentObj.datashowing.desc = item.desc;
      currentObj.datashowing.loc = item.location;
      currentObj.datashowing.sName = this.getSiteName(item.sid);
      currentObj.datashowing.show = true;
      currentObj.datashowing.info = item;
      //   currentObj.datashowing.dronedata = {};
      //   currentObj.datashowing.boxdata = {};
      //   currentObj.datashowing.index = index;
      currentObj.$store.commit("setBoxID", currentObj.datashowing.boxid);
      currentObj.$store.commit("setDroneID", currentObj.datashowing.droneid);
      currentObj.$store.commit("setSite", currentObj.datashowing.sName);
      currentObj.$store.commit("setselectedAsset", currentObj.datashowing);

      // if (item.type === 'box'){
      //   currentObj.$store.commit("setBoxID", currentObj.datashowing.aid);
      //   currentObj.$store.commit("setDroneID", currentObj.datashowing.mid);
      // }
      // else{
      //   currentObj.$store.commit("setBoxID", currentObj.datashowing.mid);
      //   currentObj.$store.commit("setDroneID", currentObj.datashowing.aid);
      // }

      // currentObj.$store.commit("setSite", this.getSiteName(item.sid));
      // currentObj.$store.commit("setselectedAsset", currentObj.datashowing);
      //   currentObj.$store.commit("setBoxLocation", item.location);
      //   currentObj.$store.commit("setSiteLocation", item.siteLocation);
      //   this.getDroneData();
      if (currentObj.zoom === 16) {
        this.map.setCenter(
          // this.markers[this.datashowing.index].getPosition()
          new window.google.maps.LatLng(
            this.datashowing.loc[0],
            this.datashowing.loc[1]
          )
        );
      } else if (currentObj.zoom > 6) {
        this.zoomProcess = true;
        // deepcode ignore CodeInjection: <please specify a reason of ignoring this>
        this.interValZoomOut = setInterval(this.zoomOutDelay, 200);
        // this.interValZoomOut = setInterval(() => this.zoomOutDelay, 200);
        // setInterval(this.getOnlineStatus, 2000)
      }

      // currentObj.zoom = 6;
      // deepcode ignore CodeInjection: <please specify a reason of ignoring this>
      this.interValZoomIn = setInterval(this.zoomDelay, 200);
      // this.interValZoomIn = setInterval(() => this.zoomDelay, 200);
    },
    async getKey(device_id) {
      let currentObj = this;
      var deviceKey = null;
      await this.axios
        .post(this.sioServer + "/auth/getkey?code=" + device_id, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          deviceKey = response.data.key;
          console.log(deviceKey);
          currentObj.$store.commit("setDeviceKey", deviceKey);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      return deviceKey;
    },
    onStatus(data) {
      // console.log(data);
      let currentObj = this;
      currentObj.statusData = [];
      let existData = this.deviceStatus;
      // console.log('Exi : ',JSON.stringify(existData))
      let newData = data.data;
      // console.log('New : ',JSON.stringify(newData))
      currentObj.deviceStatus = Object.assign(existData, newData);
      // for (const property in data.data) {
      //   this.createTable(data.data[property]);
      // }
    },
    createTable(data) {
      if (data.name) {
        for (const property in data.data) {
          this.createList(data.data[property]);
        }
      }
    },
    createList(data) {
      //console.log(type)
      // console.log(data);

      let currentObj = this;
      currentObj.statusData.push(data);
      // $("#status").append("<tr>");
      // $("#status").append("<td>" + data.key + "</td>");
      // $("#status").append("<td>" + data.value + "</td>");
      // $("#status").append("<td>" + data.unit + "</td>");
      // $("#status").append("</tr>");
    },
    getUpdateTime(ms) {
      var date = new Date(ms);
      return date.toLocaleTimeString("th-TH", { hour12: false });
    },
    displayAssetLocation(item, index) {
      if (item.type === "box") {
        console.log("Location Item : ", item.location);
        console.log(item);
        var url = require("../assets/DB2_50x50.png");
        if (item.type === "drone") url = require("../assets/uav30x30.png");
        else url = require("../assets/DB2_50x50.png");
        var image = {
          url: url,
          // url: require("../assets/DB2_50x50.png"),
          // url: require("../assets/drone2.png"),
          //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
          // This marker is 20 pixels wide by 32 pixels high.
          size: new window.google.maps.Size(50, 50),
          // The origin for this image is (0, 0).
          origin: new window.google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new window.google.maps.Point(25, 25),
        };
        var marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            item.location[0],
            item.location[1]
          ),
          icon: image,
          title: item.name,
          map: this.map,
          draggable: false,
        });
        marker.addListener("click", () => {
          // this.map.setZoom(18);
          // this.map.setCenter(item.marker.getPosition());
          this.showDetailStationBySIO_ID(item, index);
          // console.log("Box Station Index : ", index);
        });
        this.markers.push(marker);
      }
    },
  },
  beforeDestroy() {
    // console.log("Clear Home Interval Time....");
    // clearInterval(this.interValDrone);
    // clearInterval(this.interValBox);
    // clearInterval(this.interValAvai);
    // clearInterval(this.interValAsset);
    clearInterval(this.interValOnline);
    // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
    this.socket.close();
    // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
    this.drone_socket.close();
  },
};
</script>
<style scoped>
.test1-com-style {
  text-align: start;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.home {
  background-color: gray;
  width: 100%;
  height: 100%;
  border-radius: 2.5px;
  position: relative;
}
.statusCard {
  /* width: 580px; */
  margin: 10px;
  /* padding: 10px; */
  border-radius: 0.5rem;
  /* background-color: whitesmoke; */
  background-color: rgba(5, 5, 5, 0.6);
  /* height: 200px;  */
  overflow: hidden;
  padding: 0px;
  display: inline-block;
  border: 1px solid #646464;

  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
  /* margin-top: -50px; */
  /* position: relative; */
  /* padding: 0px; */
}
.x-button {
  position: absolute;
  top: 20px;
  right: 0px;
  transform: translate(-20%, -50%);
  color: white;
  background: rgb(119, 0, 0);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  padding: 0;
}
.x-button:hover {
  cursor: pointer;
  background: rgb(255, 0, 0);
}
.card-body {
  height: calc(100vh - 2rem - 280px);
  padding: 0;
  margin: 0;
  background: gray;
  overflow-y: auto;
}
.statusCard1 {
  width: 340px;
  /* margin: 10px; */
  /* padding: 10px; */
  border-radius: 0.5rem;
  /* background-color: whitesmoke; */
  background-color: rgba(5, 5, 5, 0.9);
  /* height: 200px;  */
  /* overflow: hidden; */
  padding: 0px;
  display: inline-block;
  border: 1px solid #646464;
  overflow: hidden;
  /* margin-top: -50px; */
  position: relative;
  backdrop-filter: blur(10px);
  /* padding: 0px; */
}
.headerBarXX {
  width: 100%;
  height: 38px;
  padding: 10px;
  text-align: center;
  color: white;
  background-color: rgb(37, 37, 37);
  border-bottom: gray solid 1px;
  /* border-top: gray solid 1px; */
  margin: 0px;
  /* font-size: 0.8em; */
  font-weight: bolder;
}
.eventMessageXX {
  /* width: 100%; */
  margin: 0px;
  /* margin-bottom: 0.5rem; */
  /* min-height: 200px; */
  /* max-height: 900px; */
  /* overflow-y: auto; */
  /* padding: 2px 5px 2px 5px; */
  color: white;
  /* text-align: start; */
  font: 0.9em sans-serif;
  padding: 0.2rem;
  /* overflow: scroll; */
}
.leftcontainer {
  max-width: 550px;
  min-width: 270px;
  width: 340px;
  /* float: left; */
  text-align: start;
  position: absolute;
  left: 1rem;
  top: 1rem;
  /* background-color: aquamarine; */
}
.rightcontainer {
  max-width: 580px;
  min-width: 280px;
  /* float: right; */
  /* background-color: aquamarine; */
  text-align: right;
  position: absolute;
  right: 1rem;
  top: 1rem;

  /* max-height: 90vh; */
  /* overflow-y: auto; */
}
button.mini {
  border-radius: 0.5rem;
  /* background-color: #130068; */
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.barprogress {
  display: block;
  width: 100%;
  height: 7vh;
  margin: 0;
  padding: 10px;
  background-color: rgba(5, 5, 5, 0.4);
}
.map {
  height: 100vh;
  background: gray;
}
.btn-small-screen {
  /* margin-left: auto; */
  /* margin-right: 10px; */
  padding: auto;
  width: 100%;
}
.container-btn {
  width: 100%;
  padding: 10px;
  text-align: center;
  /* background-color: aqua; */
}
@media only screen and (max-width: 560px) {
  .statusCard1 {
    width: 100vw !important;
  }
  .statusCard {
    width: 100vw !important;
    margin: 0px;
  }
  .leftcontainer {
    width: 100vw !important;
    left: 0%;
    /* text-align: center; */
    /* transform: translate(-0%, 0%); */
    top: 0rem;
    z-index: 999;
    background-color: aquamarine;
  }
  .rightcontainer {
    width: 100vw !important;
    left: 0% !important;
    text-align: center;
    /* transform: translate(-65%, 0%) !important; */
    top: 0rem;
    margin: 0;
    padding: 0;

    /* background-color: aquamarine; */
  }
  .card-body {
    height: calc(100vh - 65px - 280px);
  }
  .map {
    height: calc(100vh - 63px);
  }

  /* .btn-small-screen {
    display: none;
  }
  .container-btn {
    display: none;
  } */
}
.img-device {
  display: block;
}
@media only screen and (max-height: 560px) {
  .img-device {
    display: none !important;
  }
  .card-body {
    height: calc(100vh - 8rem);
  }

  .map {
    height: calc(100vh - 63px);
  }
}
@media only screen and (max-width: 560px) {
  .img-device {
    display: none !important;
  }
  .card-body {
    height: calc(100vh - 8rem);
  }

  .map {
    height: calc(100vh - 63px);
  }
}
</style>
