import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Operation from "../views/Operation.vue";
// import Operate from '../views/Operate.vue';
// import Asset from "../views/AssetData.vue";
// import Planner from '../views/Planner.vue';
// import Scheduled from '../views/NewScheduled.vue';
import Tasks from "../views/Scheduled.vue";
// import Cctv from "../views/Cctv.vue";
// import OpView from '../views/OperationView.vue';
// import Planner2 from "../views/TaskCreator.vue";
import Mission from "../views/v2_Mission.vue";
// import Dashboard from "../views/Dashboard.vue";
import Logout from "../views/Logout.vue";
import Site from "../views/Site.vue";
import Web250 from "../views/v2_panel.vue";
import uavPanel from "../views/v2_auv_panel.vue";
import Planner21 from "../views/v2_TaskCreator.vue";
import siteOper from "../views/SiteOperation.vue"
import siteDashboard from '../views/Sites.vue'
import station2 from "../views/v3_station_panel.vue"
import battery from "../views/v2_battery.vue"

// import mnt_login from '../views/backoffice/Login.vue'
import mnt_battery from '../views/backoffice/mnt/battery.vue'
import mnt_battery_status from '../views/backoffice/mnt/batteryStatus.vue'

import mnt_customer from '../views/backoffice/mnt/customer.vue'
import mnt_user from '../views/backoffice/mnt/user.vue'
import mnt_asset from '../views/backoffice/mnt/asset.vue'


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/mnt",
  //   name: "Login Management",
  //   component: mnt_login,
  // },
  // {
  //   path: "/asset",
  //   name: "Asset Management",
  //   component: Asset,
  // },
  // {
  //   path: '/planner2',
  //   name: 'Planner',
  //   component: Planner
  // },
  // {
  //   path: '/plan',
  //   name: 'Scheduled',
  //   component: Scheduled
  // },
  {
    path: "/task",
    name: "Tasks",
    component: Tasks,
  },
  {
    path: "/planner2",
    name: "Task Planner2",
    component: Planner21,
  },
  // {
  //   path: "/cctv",
  //   name: "Base CCTV",
  //   component: Cctv,
  // },
  {
    path: "/mission",
    name: "Mission Creator",
    component: Mission,
  },
  // {
  //   path: "/dashboard",
  //   name: "Live Dashboard",
  //   component: Dashboard,
  // },
  // {
  //   path: "/operation",
  //   name: "Operation",
  //   component: Operation,
  // },
  // {
  //   path: '/operate',
  //   name: 'Operate',
  //   component: Operate
  // },
  // {
  //   path: '/opview',
  //   name: 'OpView',
  //   component: OpView
  // },
  // {
  //   path: "/planner",
  //   name: "Task Planner",
  //   component: Planner2,
  // },
  {
    path: "/site",
    name: "Site Configuration",
    component: Site,
  },
  {
    path: "/siteinfo",
    name: "All Sites",
    component: siteDashboard,
  },
  // {
  //   path: "/v2",
  //   name: "Station Control Panel",
  //   component: Web250,
  // },
  // {
  //   path: "/station",
  //   name: "Station Control Panel",
  //   component: station2,
  // },
  // {
  //   path: "/uav2",
  //   name: "UAV Control Panel",
  //   component: uavPanel,
  // },
  {
    path: "/panel/station3/beta/:stationID",
    name: "Station Control Panel",
    component: station2,
  },
  {
    path: "/panel/uav/:droneID",
    name: "UAV Control Panel",
    component: uavPanel,
  },
  {
    path: "/panel/battery/:droneID",
    name: "UAV Battery Panel",
    component: battery,
  },
  {
    path: "/panel/station/:stationID",
    name: "Station Control Panel",
    component: Web250,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/siteoper",
    name: "Site Operation",
    component: siteOper,
  },
  {
    path: "/mnt/battery",
    name: "Battery Management",
    component: mnt_battery,
  },
  {
    path: "/mnt/battery_health/:batteryID",
    name: "Battery Logs Status",
    component: mnt_battery_status,
  },
  {
    path: "/mnt/organization",
    name: "Organization Management",
    component: mnt_customer,
  },
  {
    path: "/mnt/user",
    name: "Users Management",
    component: mnt_user,
  },
  {
    path: "/mnt/asset",
    name: "Assets Management",
    component: mnt_asset,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
