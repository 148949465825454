<template>
  <div id="header1">
    <h2 class="size-ctrl">
      <label for="nav-toggle">
        <span class="las la-chevron-left" @click="$router.go(-1)"></span>
        <!-- <span class="las la-chevron-left" @click="$router.push('/siteoper')"></span> -->
      </label>
      {{ $route.name }}
    </h2>
    <div v-if="search" class="search-wrapper">
      <span class="las la-search"></span>
      <input type="search" placeholder="Search here" />
    </div>

    <div v-if="enableStatus" class="icon-wrapper">
      <div v-if="enableOnlineStatus" class="icon-wrapper" style="margin-right: 1rem">
        <span :class="[isOnline ? 'las la-link' : 'las la-unlink']"></span>
        <div>
          <h4 style="width: 100%; margin-bottom: -0.5rem" :class="[isOnline ? 'online' : 'offline']">{{ isOnline ? "ONLINE" : "OFFLINE" }}</h4>
          <small style="width: 100%; margin-top: -20px">Device Status</small>
        </div>
      </div>
      <span v-if="deviceInfo && deviceInfo.type == 'box'" class="las la-box"></span>
      <span v-if="deviceInfo && deviceInfo.type == 'drone'" class="lab la-telegram-plane"></span>
      <div v-if="deviceInfo">
        <!-- <h2 style="width:100%; margin-bottom:-0.5rem;">{{ timeNow.time  }}</h2>
        <small style="width:100%; margin-top:-20px;" >{{timeNow.date }}</small> -->

        <h4 style="width: 100%; margin-bottom: -0.5rem">
          <!-- {{ getDeviceName(selectedAsset.boxid) }} -->
          {{ deviceInfo.name }}
        </h4>
        <small style="width: 100%; margin-top: -20px">
          Device Name
          </small>
      </div>
      <b-button v-if="deviceInfo && deviceInfo.type == 'drone'" variant="outline-primary" class="mateLink" :to="'/panel/battery/'+deviceInfo.aid"
        >Battery Health</b-button
      >
      <b-button v-if="deviceInfo && deviceInfo.type == 'drone'" variant="outline-primary" class="mateLink" :to="'/panel/station/'+deviceInfo.mid"
        >Station Panel</b-button
      >
      <b-button v-if="deviceInfo && deviceInfo.type == 'box'" variant="outline-primary" class="mateLink" :to="'/panel/uav/'+deviceInfo.mid"
        >Drone Panel</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    search: Boolean,
    lastUpdate: Number,
    enableOnlineStatus: Boolean,
    enableStatus: Boolean,
    deviceInfo: Object,
  },
  data() {
    return {
      selectedAsset: this.$store.state.selectedAsset,
      customerAssets: this.$store.state.customerAsset,
      webAPI: this.$store.state.webAPI,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      isOnline:false,
    };
  },
  mounted() {
    // deepcode ignore VueMissingCleanup: <please specify a reason of ignoring this>
    // setInterval(this.updateParam, 5000);
    if (this.enableOnlineStatus){
      this.OnlineChecking();
      this.checkOnlineInerval = setInterval(this.OnlineChecking, 500);
    }
  },
  beforeDestroy() {
    clearInterval(this.checkOnlineInerval);
  },
  methods: {
    OnlineChecking() {
      let ms = new Date().getTime(); //d.getMilliseconds();
      let currentObj = this;
      var diff_ms = ms - parseFloat(this.lastUpdate);
      if (diff_ms < 5000.0) {
        currentObj.isOnline = true;
      } else {
        currentObj.isOnline = false;
      }
    },
    updateParam() {
      this.selectedAsset = this.$store.state.selectedAsset;
      this.customerAssets = this.$store.state.customerAsset;
    },
    getSiteName(sid) {
      var siteName = null;
      // console.log("sid:", sid);
      try {
        this.customerAssets.sites.forEach((obj) => {
          // console.log(obj.name);
          if (obj.sid === sid) {
            // console.log("name:", obj.name);
            siteName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return siteName;
    },
    getDeviceName(mid) {
      var assetName = null;
      try {
        this.customerAssets.devices.forEach((obj) => {
          // console.log(obj.name);
          if (obj.aid === mid) {
            assetName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return assetName;
    },
    getMateInfo(mid) {
      var mateInfo = null;
      try {
        this.customerAssets.devices.forEach((obj) => {
          // console.log(obj.name);
          if (obj.aid === mid) {
            mateInfo = obj;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return mateInfo;
    },

    async getKey(device_id) {
      console.error("GET KEY CALL...");
      let currentObj = this;
      var deviceKey = null;
      await this.axios
        .post(this.sioServer + "/auth/getkey?code=" + device_id, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          deviceKey = response.data.key;
          console.log(deviceKey);
          currentObj.$store.commit("setDeviceKey", deviceKey);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      return deviceKey;
    },
    async mateLink() {
      let mid = this.selectedAsset.info.mid;
      let currentObj = this;
      let mateInfo = this.getMateInfo(mid);
      await this.getKey(mid);

      if (mateInfo) {
        currentObj.selectedAsset.type = mateInfo.type;
        currentObj.selectedAsset.name = mateInfo.name;
        currentObj.selectedAsset.desc = mateInfo.desc;
        currentObj.selectedAsset.loc = mateInfo.location;
        currentObj.selectedAsset.sName = this.getSiteName(mateInfo.sid);
        currentObj.selectedAsset.show = true;
        currentObj.selectedAsset.info = mateInfo;
        currentObj.$store.commit("setselectedAsset", currentObj.selectedAsset);
        if (currentObj.selectedAsset.type === "box")
          window.location.href = "/#/v2";
        else window.location.href = "/#/uav2";
      } else {
        console.error("Mate Information Error");
      }
    },
  },
};
</script>

<style scoped>
.online {
  color: green;
}
.offline{
  color: red;
  /* background: yellow; */
  /* border-radius: 10px; */
  text-align: center;
  /* border: #222 solid 1px; */
}
.la-bars:hover {
  cursor: pointer;
}

#header1 {
  background: #fff;
  display: flex;
  justify-content: space-between;
  height: 75px;
  padding: 1rem 1.5rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  /* position: fixed; */
  /* left: 345px; */
  width: 100%;
  /* calc(100hv - 80px); */
  /* top: 0; */
  z-index: 99;
  transition: left 300ms;
}

#header1 h2 {
  color: #222;
  font-size: 1.8em;
}

#header1 label span {
  font-size: 1.7rem;
  padding-right: 1rem;
}

.search-wrapper {
  border: 1px solid #ccc;
  border-radius: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.search-wrapper span {
  display: inline-block;
  padding: 0rem 1rem;
  font-size: 1.5rem;
}

.search-wrapper input {
  height: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
}

.user-wrapper {
  display: flex;
  align-items: center;
  /* display: none; */
}

.user-wrapper img {
  border-radius: 50%;
  margin-right: 0.5rem;
}

.user-wrapper small {
  display: inline-block;
  color: var(--text-grey);
  margin-top: -10rem !important;
}

.icon-wrapper {
  display: flex;
  align-items: center;
}

.icon-wrapper span {
  border-radius: 50%;
  margin-right: 0.5rem;
  font-size: 2rem;
}

.icon-wrapper small {
  display: inline-block;
  /* color: var(--text-grey); */
  margin-top: -10rem !important;
}

.size-ctrl {
  display: inline-block;
}

@media only screen and (max-width: 560px) {
  .size-ctrl {
    display: none;
  }
}

@media only screen and (max-height: 560px) {
  .size-ctrl {
    display: none;
  }
}

.mateLink {
  margin-left: 15px;
  height: 100%;
}
.la-chevron-left:hover{
cursor: pointer;
}
</style>
