<template>
  <div>
    <div
      class="gauge"
      :style="classObject2"
    >
    <img src="../assets/bg_Gauge1.png" :style="classObject" class="bg1"/>
    <img src="../assets/bg_Gauge2.png" width="150px" class="pointer1" :style="classObject3"/>
    <img src="../assets/Pointer.png" width="150px" class="pointer1"/>

    </div>
  </div>
</template>
<script>
export default {
  props: {
    roll: Number,
    pitch: Number,
  },
  data() {
    return {
    //   bgimg: require("~/asset/rice-field-texas.jpg"),
    };
  },
  computed: {
    // carStyles() {
    classObject: function() {
        // console.log('Test')
      return {
          'transform': 'translate( -20px, ' + ((this.pitch*2.666666667)-195) + 'px)',
      };
    },
    classObject2: function() {
        // console.log('Test')
      return {
          'transform': 'rotate('+this.roll +'deg)',
          'transform-origin': '50% 50%'
      };
    },
    classObject3: function() {
        // console.log('Test')
      return {
          'transform': 'rotate('+ (0 -this.roll) +'deg)',
          'transform-origin': '50% 50%'
      };
    },
  },
};
</script>
<style scoped>
.gauge {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: auto;
  border: 1px solid #00000069;
  overflow: hidden;
  /* background-image: url('~@/assets/rice-field-texas.jpg'); */
  /* background-repeat: no-repeat, repeat; */
  /* background-position: 50% 50%; */
  /* background-color: blue; */
}
.bg1{
  width: 180px;
  max-width:none;
}
.pointer1{
  position:absolute;
  top: 0px;
  left: 0px;
  /* transform: translate( -60px, -20px); */
}
</style>
