<template>
  <div class="planner">
    <!-- <h1>This is an about page</h1> -->
    <div class="map full" ref="map"></div>
    <div class="contentTask">
      <h3 style="padding: 3px; margin: 0px; text-align: left; padding: 20px">
        {{ $route.name }}
      </h3>
      <div class="contentFormTask" style="font-size:0.8em;">
        <b-form-group
          label="Site Name:"
          label-for="input-boxid"
          label-cols-sm="5"
          label-align-sm="right"
          class="mb-2"
        >
          <b-form-select
            id="input-ReType"
            v-model="taskInfo.sid"
            :options="siteSelectOption"
            @change="sidChanged"
          ></b-form-select>
          <!-- <b-form-input disabled v-model="siteName"></b-form-input> -->
        </b-form-group>
        <b-form-group
          label="Drone ID :"
          label-for="input-ReType"
          label-cols-sm="5"
          label-align-sm="right"
          class="mb-1"
          small
          content-cols-sm
        >
          <b-form-select
            id="input-ReType"
            v-model="taskInfo.droneID"
            :options="droneIDOption"
            @change="droneIDChanged"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          label="Task-Off Box:"
          label-for="input-boxid"
          label-cols-sm="5"
          label-align-sm="right"
          class="mb-2"
          content-cols-sm
        >
          <b-form-input disabled v-model="taskInfo.boxName"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Landing Box:"
          label-for="input-ReType"
          label-cols-sm="5"
          label-align-sm="right"
          class="mb-2"
        >
          <b-form-select
            id="input-ReType"
            v-model="taskInfo.landingBoxID"
            :options="boxs"
            @change="landingChanged"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label="Task Date:"
          label-cols-sm="5"
          label-align-sm="right"
          class="mb-2"
        >
          <b-input-group class="mb-2">
            <b-form-input
              id="example-input"
              v-model="taskRawInfo.taskDate"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="taskRawInfo.taskDate"
                :min="minDate"
                @input="
                  buildFlightTime(
                    taskRawInfo.taskDate,
                    taskRawInfo.taskTime,
                    false
                  )
                "
                @click="checkDate"
                button-only
                right
                locale="en-US"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Task Time:"
          label-for="input-missionTime"
          label-cols-sm="5"
          label-align-sm="right"
          class="mb-2"
        >
          <b-input-group class="mb-2">
            <b-form-input
              id="example-input"
              v-model="taskRawInfo.taskTime"
              type="text"
              placeholder="hh:mm"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-timepicker
                v-model="taskRawInfo.taskTime"
                @input="
                  buildFlightTime(
                    taskRawInfo.taskDate,
                    taskRawInfo.taskTime,
                    false
                  )
                "
                button-only
                right
                locale="th"
                aria-controls="example-input"
              ></b-form-timepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Take-off Alt :"
          label-for="input-alt"
          label-cols-sm="5"
          label-align-sm="right"
          class="mb-2"
        >
          <b-form-input
            id="input-alt"
            v-model="taskInfo.alt"
            type="number"
            min="20"
            @change="altChanged"
            max="100"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Select missions for task:" label-cols-sm="12">
          <b-form-select
            id="input-ReType"
            v-model="taskRawInfo.selectedMSID"
            @change="msidChanged"
            :options="missionOption"
            placeholder="test"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          v-if="taskRawInfo.selectedMSID"
          label="Mission Description:"
          label-cols-sm="12"
        >
          {{ getMissionDesc(taskRawInfo.selectedMSID) }}
          <!-- <label style="font-size:0.8em;"> {{missions[selectedMission].missionDescription}}</label> -->
          <!-- {{getMissionInfo(taskRawInfo.selectedMSID)}} -->
        </b-form-group>
        <b-form-checkbox
          id="checkbox-1"
          v-model="taskInfo.isRoutineTask"
          name="checkbox-1"
          switch
          label-cols-sm="12"
          label-align-sm="right"
          :value="true"
          :unchecked-value="false"
          style="text-align: left;"
        >
          Enable Routine Tasks
        </b-form-checkbox>

        <b-form-group
          v-if="taskInfo.isRoutineTask"
          label="Type :"
          label-for="input-ReType"
          label-cols-sm="5"
          label-align-sm="right"
          class="mb-2"
        >
          <b-form-select
            id="input-ReType"
            v-model="taskInfo.routinePeriod"
            :options="routineOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          v-if="taskInfo.isRoutineTask"
          label="End Date:"
          label-cols-sm="5"
          label-align-sm="right"
          class="mb-2"
        >
          <b-input-group class="mb-2">
            <b-form-input
              id="example-input"
              v-model="taskDate"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="taskRawInfo.endDate"
                :min="minDate"
                @input="
                  buildFlightTime(
                    taskRawInfo.endDate,
                    taskRawInfo.endTime,
                    true
                  )
                "
                @click="checkDate"
                button-only
                right
                locale="en-US"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          v-if="taskInfo.isRoutineTask"
          label="End Time:"
          label-for="input-missionTime"
          label-cols-sm="5"
          label-align-sm="right"
          class="mb-2"
        >
          <b-input-group class="mb-2">
            <b-form-input
              id="example-input"
              v-model="taskRawInfo.endTime"
              type="text"
              placeholder="hh:mm"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-timepicker
                v-model="taskRawInfo.endTime"
                @input="
                  buildFlightTime(
                    taskRawInfo.endDate,
                    taskRawInfo.endTime,
                    true
                  )
                "
                button-only
                right
                locale="th"
                aria-controls="example-input"
              ></b-form-timepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- {{series}} -->

        <div style="margin-top:10px;">
          <b-button
            :disabled="
              !taskInfo.droneID || !taskInfo.landingBoxID || !taskInfo.msid
            "
            @click="createTask"
            variant="success"
            style="width:100%;"
            size="lg"
            class="btn-create"
            >Create Task</b-button
          >
        </div>
      </div>
    </div>
    <!-- <div class="task-footer">
      <b-button
        :disabled="!dataTask.boxID || !dataTask.droneID || !dataTask.missions"
        @click="createTask"
        variant="success"
        style="width:100%;"
        size="lg"
        class="btn-create"
        >Create Task</b-button
      >
    </div> -->
    <div class="contentEstimation">
      <div class="est-status">
        <i class="lar la-clock"></i>
        <!-- <span>Est. Flight Time : {{ timeRange.toFixed(2) }} Min.</span> -->
        <!-- {{getMissionInfo(taskRawInfo.selectedMSID)}} -->
        <span
          >Est. Flight Time :{{
            EstTime.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
          }}
          Sec.</span
        >
        <!-- <span>Est. Flight Time : {{ dataTask.totalTime.toFixed(2) }} Sec.</span> -->
      </div>
      <div class="est-status">
        <i class="las la-road"></i>
        <!-- <span>Flight Range : {{ (distance / 1000).toFixed(2) }} kM.</span> -->
        <span
          >Flight Range :
          {{ EstRange.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") }} m.</span
        >
      </div>
      <div class="est-status">
        <i class="las la-plane"></i>
        <!-- <span>Flight Range : {{ (distance / 1000).toFixed(2) }} kM.</span> -->
        <span>Flight Altitude (m)</span>
      </div>
      <apexchart
        width="100%"
        type="area"
        ref="chart"
        :options="chartOptions"
        :series="series"
      ></apexchart>
      <!-- <hchart :values="heightData" /> -->
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import MainMenu from '@/components/NavigateManu.vue'

import swal from "sweetalert2";

export default {
  name: "Planner",
  components: {
    // MainMenu
  },
  data() {
    return {
      showData: "111",
      now: new Date(),
      EstTime: 0,
      EstRange: 0,
      RTLRange: 0,
      RTLTime: 0,
      boxid: this.$store.state.selectedBoxID,
      droneid: this.$store.state.selectedDroneID,
      assets: this.$store.state.assetInfo,
      minDate: new Date(),
      startLine: {},
      endLine: {},
      markers: [],
      heightData: [],
      pathsLocation: [],
      paths: [],
      boxLocation: this.$store.state.boxLocation,
      commandOptions: [],
      commandCodeDetail: {},
      indexOfCMD: [0, 6, 4],
      upload: false,
      file: "",
      taskDate: new Date().toISOString().slice(0, 10),
      taskTime: new Date().toTimeString().slice(0, 8),
      apiServer: this.$store.state.apiDataServe,
      siteName: this.$store.state.site,
      siteLocation: this.$store.state.siteLocation,
      missions: [{ missionDescription: "" }],
      dataTask: {
        boxID: this.$store.state.selectedBoxID, //
        droneID: this.$store.state.selectedDroneID, //
        altitude: 25, //
        ETD: 0, //
        ETA: 0, //
        isAutoTakeOff: true, //
        isRoutineTake: false,
        recurrence: "D",
        totalTask: 10,
        status: "New",
        missions: [],
        totalRange: 0.0,
        totalTime: 0.0,
        by: this.$store.state.user,
        missionNote: "",
        siteName: this.$store.state.site,
        company: this.$store.state.owner,
        landingBoxID: "",
      },
      assetInfo: this.$store.state.assetInfo,
      loaded: false,
      isSimulate: false,
      isConnected: false,
      socketMessage: "",
      centered: false,
      socket: null,
      socket2: null,
      socket3: null,
      socket4: null,
      videoShow: false,
      map: null,
      boxtatus: null,
      timeNow: { date: "", time: "" },

      marker2: [],
      planPath: new window.google.maps.Polyline(),
      flightPath: new window.google.maps.Polyline(),
      menualpanelShow: false,
      paramShow: false,
      plan: [],

      icon: [],
      closeLine: new window.google.maps.Polyline(),
      planPointer: null,
      LoadedPlanNo: 0,
      // planPath:null,
      MissionID: 205,
      // assets: [],
      customer: "ARV",
      options2: [
        { value: "H", text: "Hourly Routine" },
        { value: "2H", text: "Twice Hourly Routine" },
        { value: "D", text: "Daily Routine" },
        { value: "2D", text: "Twice Day Routine" },
        { value: "W", text: "Weekly Routine" },
        { value: "2W", text: "Twice Weekly Routine" },
      ],
      selectedMission: 0,
      drones: [],
      boxlocation: [],
      boxs: [],
      flyRange: 0,

      //////////////////////////
      webAPI: this.$store.state.webAPI,
      deviceKey: this.$store.state.deviceKey,
      selectedAsset: this.$store.state.selectedAsset,
      customerAssets: this.$store.state.customerAsset,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
      siteSelectOption: [],
      //////////////////////////
      taskInfo: {
        droneID: null, //this.$store.state.selectedAsset.droneid,
        droneName: null, // this.getDeviceName(this.$store.state.selectedAsset.droneid),
        boxID: null, //this.$store.state.selectedAsset.boxid,
        boxName: null, //this.getDeviceName(this.$store.state.selectedAsset.boxid),
        landingBoxID: null, //this.$store.state.selectedAsset.boxid,
        landingBoxName: null, //this.getDeviceName(this.$store.state.selectedAsset.boxid),
        alt: 50,
        time: 1652137200000,
        msid: [],
        cid: this.$store.state.customerID,
        sid: null, // this.$store.state.selectedAsset.info.sid,
        isExternalTask: false,
        isRoutineTask: false,
        routinePeriod: 3600000,
        routineEndTime: 1652251166278,
      },
      taskRawInfo: {
        taskDate: new Date().toISOString().slice(0, 10),
        taskTime: new Date().toTimeString().slice(0, 8),
        endDate: new Date().toISOString().slice(0, 10),
        endTime: new Date().toTimeString().slice(0, 8),
        selectedMSID : null,
      },
      routineOptions: [
        { value: 3600000, text: "Hourly Routine" },
        { value: 7200000, text: "Twice Hourly Routine" },
        { value: 86400000, text: "Daily Routine" },
        { value: 172800000, text: "Twice Day Routine" },
        { value: 604800000, text: "Weekly Routine" },
        { value: 1209600000, text: "Twice Weekly Routine" },
      ],
      droneSpeed: {
        TakeoffSpeed: 2.5,
        OnAirSpeed: 8,
        LandingSpeed: [1.5, 0.5, 0.3],
        ExtraTime: 30,
      },
      missionOption: [],
      droneIDOption: [],
      siteInfo: {
        polygon: null,
      },
      boxesMarker: [],
      chart: null,
      chartOptions: {
        chart: {
          id: "chart2",
          type: "area",
          height: 230,
          foreColor: "#ccc",
          toolbar: {
            autoSelected: "pan",
            show: false,
          },
        },
        colors: ["#00BAEC"],
        stroke: {
          width: 3,
        },
        grid: {
          borderColor: "#555",
          clipMarkers: false,
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          gradient: {
            enabled: true,
            opacityFrom: 0.55,
            opacityTo: 0,
          },
        },
        markers: {
          size: 5,
          colors: ["#000524"],
          strokeColor: "#00BAEC",
          strokeWidth: 3,
        },
        tooltip: {
          theme: "dark",
        },
        xaxis: {
          type: "numeric",
        },
        // yaxis: {
        //   min: 0,
        //   tickAmount: 4,
        // },
      },
      series: [
        {
          name: "AGL",
          // data: [30, 40, 45, 50, 49, 60, 70, 81],
          data: [],
        },
      ],
    };
  },
  mounted() {
    // this.buildFlightTime();
    if (this.customerAssets.length == 0) 
    {
      // console.log("ไม่มี Assets");
      this.getAssets();
    }
    else
    {  
      // console.log("มี Assets");
      this.taskInfo.droneID = this.$store.state.selectedAsset.droneid;
      this.taskInfo.boxID = this.$store.state.selectedAsset.boxid;
      this.taskInfo.landingBoxID = this.$store.state.selectedAsset.boxid;
      try {this.taskInfo.sid = this.$store.state.selectedAsset.info.sid;}
      catch{
        //
        this.taskInfo.sid = this.customerAssets.sites[0].sid;
      }
      if(this.taskInfo.sid == null){
        this.taskInfo.sid = this.customerAssets.sites[0].sid;
      }
      this.customerAssets.sites.forEach((obj) => {
        this.siteSelectOption.push({ value: obj.sid, text: obj.name });
      });
      this.buildDroneOption(this.taskInfo.sid);
      this.buildBoxsOption(this.taskInfo.sid);
      // let currentObj = this;
      this.getMissions(this.taskInfo.sid);
      this.chart = this.$refs["chart"];
      // this.getBoxLocation(this.boxid);
      this.map = new window.google.maps.Map(this.$refs["map"], {
        mapTypeId: "satellite",
        center: { lat: 13.932, lng: 100.573 },
        zoom: 20,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        tilt: 47.5,
      });
      // this.map.setTilt(25);
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(12.993362, 101.442383),
        new window.google.maps.LatLng(12.996520, 101.449361)
      );
      let image = require("../assets/map/ptic.png");

    class USGSOverlay extends window.google.maps.OverlayView {
        bounds;
        image;
        div;
        constructor(bounds, image) {
          super();
          this.bounds = bounds;
          this.image = image;
        }
        /**
         * onAdd is called when the map's panes are ready and the overlay has been
         * added to the map.
         */
        onAdd() {
          this.div = document.createElement("div");
          this.div.style.borderStyle = "none";
          this.div.style.borderWidth = "0px";
          this.div.style.position = "absolute";
    
          // Create the img element and attach it to the div.
          const img = document.createElement("img");
    
          img.src = this.image;
          img.style.width = "100%";
          img.style.height = "100%";
          img.style.position = "absolute";
          this.div.appendChild(img);
    
          // Add the element to the "overlayLayer" pane.
          const panes = this.getPanes();
    
          panes.overlayLayer.appendChild(this.div);
        }
        draw() {
          // We use the south-west and north-east
          // coordinates of the overlay to peg it to the correct position and size.
          // To do this, we need to retrieve the projection from the overlay.
          const overlayProjection = this.getProjection();
          // Retrieve the south-west and north-east coordinates of this overlay
          // in LatLngs and convert them to pixel coordinates.
          // We'll use these coordinates to resize the div.
          const sw = overlayProjection.fromLatLngToDivPixel(
            this.bounds.getSouthWest()
          );
          const ne = overlayProjection.fromLatLngToDivPixel(
            this.bounds.getNorthEast()
          );
    
          // Resize the image's div to fit the indicated dimensions.
          if (this.div) {
            this.div.style.left = sw.x + "px";
            this.div.style.top = ne.y + "px";
            this.div.style.width = ne.x - sw.x + "px";
            this.div.style.height = sw.y - ne.y + "px";
          }
        }
        /**
         * The onRemove() method will be called automatically from the API if
         * we ever set the overlay's map property to 'null'.
         */
        onRemove() {
          if (this.div) {
            this.div.parentNode.removeChild(this.div);
            delete this.div;
          }
        }
        /**
         *  Set the visibility to 'hidden' or 'visible'.
         */
        hide() {
          if (this.div) {
            this.div.style.visibility = "hidden";
          }
        }
        show() {
          if (this.div) {
            this.div.style.visibility = "visible";
          }
        }
        toggle() {
          if (this.div) {
            if (this.div.style.visibility === "hidden") {
              this.show();
            } else {
              this.hide();
            }
          }
        }
        toggleDOM(map) {
          if (this.getMap()) {
            this.setMap(null);
          } else {
            this.setMap(map);
          }
        }
      }    
      const overlay = new USGSOverlay(bounds, image);    
      overlay.setMap(this.map);
      
    this.checkDate();
    this.initialData();
    this.displayBoxesLocation();
    }
  },
  methods: {
    async getAssets() {
      // console.log("Get Asset Function");
      // this.datashowing.spinner = true;
      let currentObj = this;
      await this.axios
        .get(this.webAPI + "/app/api/asset?cid=" + this.customerID, {
          headers: {
            authorization: this.apiKey,
          },
        })
        // deepcode ignore UseArrowFunction: <please specify a reason of ignoring this>
        .then(function (response) {
          if (response.status === 400) {
            sessionStorage.clear();
            this.$store.commit("setLoginState", false);
            window.location.href = "/#/";
          }
          currentObj.customerAssets = response.data;
          // // console.log(currentObj.assetList);
          currentObj.$store.commit("setcustomerAsset", currentObj.customerAssets);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          // console.log("Get Asset Function__EER");
          currentObj.output2 = error;
          // alert(error);
        });
      
      if(this.taskInfo.sid === null){
        this.taskInfo.sid = this.customerAssets.sites[0].sid;
      }
      // console.log(this.customerAssets.devices);
      this.customerAssets.sites.forEach((obj) => {
        this.siteSelectOption.push({ value: obj.sid, text: obj.name });
      });
      this.buildDroneOption(this.taskInfo.sid);
      this.buildBoxsOption(this.taskInfo.sid);
      // let currentObj = this;
      this.getMissions(this.taskInfo.sid);
      this.chart = this.$refs["chart"];
      // this.getBoxLocation(this.boxid);
      this.map = new window.google.maps.Map(this.$refs["map"], {
        mapTypeId: "satellite",
        center: { lat: 13.932, lng: 100.573 },
        zoom: 20,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      });
      
      this.checkDate();
      this.initialData();
      this.displayBoxesLocation();
      this.sidChanged();
    },
    droneIDChanged() {
      let currentObj = this;
      // Change Drone Name
      currentObj.taskInfo.droneName = this.getDeviceName(this.taskInfo.droneID);
      // Change Box Name
      let deviceInfo = this.getDeviceInfo(this.taskInfo.droneID);
      currentObj.taskInfo.boxID = deviceInfo.mid;
      currentObj.taskInfo.boxName = this.getDeviceName(this.taskInfo.boxID);
      // Build New path
      if (this.taskRawInfo.selectedMSID) {
        this.clearMap(this.startLine);
        let originBoxInfo = this.getDeviceInfo(this.taskInfo.boxID);
        this.startLine = this.displayDashPath(
          new window.google.maps.LatLng(
            originBoxInfo.location[0],
            originBoxInfo.location[1]
          ),
          this.markers[0].position
        );
        this.map.setCenter(
          new window.google.maps.LatLng(
            originBoxInfo.location[0],
            originBoxInfo.location[1]
          )
        );
      }
    },
    landingChanged() {
      let currentObj = this;
      currentObj.taskInfo.landingBoxName = this.getDeviceName(
        this.taskInfo.landingBoxID
      );
      let landingBoxInfo = this.getDeviceInfo(this.taskInfo.landingBoxID);

      if (this.taskRawInfo.selectedMSID) {
        this.clearMap(this.endLine);
        this.endLine = this.displayDashPath(
          this.markers[this.markers.length - 1].position,
          new window.google.maps.LatLng(
            landingBoxInfo.location[0],
            landingBoxInfo.location[1]
          )
        );
        let RTLDist = this.getDistance(
          this.markers[this.markers.length - 1].position,
          new window.google.maps.LatLng(
            landingBoxInfo.location[0],
            landingBoxInfo.location[1]
          )
        );

        this.EstRange = this.EstRange - this.RTLRange;
        this.EstTime = this.EstTime - this.RTLTime;
        this.RTLRange = RTLDist;
        this.RTLTime = this.calTimeBetween(RTLDist);
        this.EstRange += this.RTLRange;
        this.EstTime += this.RTLTime;

        this.map.setCenter(
          new window.google.maps.LatLng(
            landingBoxInfo.location[0],
            landingBoxInfo.location[1]
          )
        );
      }
    },
    msidChanged() {
      let currentObj = this;
      currentObj.taskInfo.msid = [this.taskRawInfo.selectedMSID];
      // clear mission path

      // Draw new path
      var missionInfo = this.getMissionInfo(this.taskRawInfo.selectedMSID);
      let originBoxInfo = this.getDeviceInfo(this.taskInfo.boxID);
      let landingBoxInfo = this.getDeviceInfo(this.taskInfo.landingBoxID);
      this.EstTime = missionInfo.EstMissionTime;
      this.EstRange = missionInfo.totalRange;

      // // console.log("missionInfo :", missionInfo);
      this.markers.forEach(this.clearMap);
      this.markers = [];
      this.pathsLocation = [];
      // this.paths.setMap(null);
      this.clearMap(this.paths);
      this.clearMap(this.startLine);
      this.clearMap(this.endLine);
      this.series[0].data = [[0, this.taskInfo.alt]];

      this.addMarker(missionInfo);
      this.addPath(missionInfo);
      // this.addHeight(missionInfo);
      this.series[0].data.push([
        this.series[0].data[this.series[0].data.length - 1][0] + 1,
        0,
      ]);
      // this.chart.uda
      this.$refs.chart.updateSeries(
        [
          {
            data: this.series[0].data,
          },
        ],
        false,
        true
      );
      this.paths = this.polyPath(this.pathsLocation);
      this.startLine = this.displayDashPath(
        new window.google.maps.LatLng(
          originBoxInfo.location[0],
          originBoxInfo.location[1]
        ),
        this.markers[0].position
      );
      let approchDist = this.getDistance(
        new window.google.maps.LatLng(
          originBoxInfo.location[0],
          originBoxInfo.location[1]
        ),
        this.markers[0].position
      );
      this.EstRange += approchDist;
      this.EstTime += this.calTimeBetween(approchDist);
      this.endLine = this.displayDashPath(
        this.markers[this.markers.length - 1].position,
        new window.google.maps.LatLng(
          landingBoxInfo.location[0],
          landingBoxInfo.location[1]
        )
      );
      this.RTLRange = this.getDistance(
        this.markers[this.markers.length - 1].position,
        new window.google.maps.LatLng(
          landingBoxInfo.location[0],
          landingBoxInfo.location[1]
        )
      );
      this.EstRange += this.RTLRange;
      this.RTLTime = this.calTimeBetween(this.RTLRange);
      this.EstTime += this.RTLTime;
      this.map.setCenter(
        new window.google.maps.LatLng(
          landingBoxInfo.location[0],
          landingBoxInfo.location[1]
        )
      );
    },
    altChanged() {
      this.series[0].data[0] = [0, this.taskInfo.alt];
      this.$refs.chart.updateSeries(
        [
          {
            data: this.series[0].data,
          },
        ],
        false,
        true
      );
    },
    sidChanged() {
      let currentObj = this;

      // clear sitePolygon
      try {
        this.clearMap(this.siteInfo.polygon);
      } catch (error) {
        console.error(error);
      }
      // CreateNew sitePlygon
      let siteFench = this.getSiteInfo(this.taskInfo.sid).fence;
      currentObj.siteInfo.polygon = this.drawPolygon(siteFench);

      // Build new droneOption and auto assign droneID
      this.buildDroneOption(this.taskInfo.sid);
      this.taskInfo.droneID = this.droneIDOption[0].value;

      // Build new landing boxOption and assign same to MateID
      this.buildBoxsOption(this.taskInfo.sid);
      this.displayBoxesLocation();

      // Get MateID
      let deviceInfo = this.getDeviceInfo(this.taskInfo.droneID);
      this.taskInfo.boxID = deviceInfo.mid;
      this.taskInfo.landingBoxID = deviceInfo.mid;
      currentObj.taskInfo.droneName = this.getDeviceName(this.taskInfo.droneID);
      currentObj.taskInfo.boxName = this.getDeviceName(this.taskInfo.boxID);
      currentObj.taskInfo.landingBoxName = this.getDeviceName(
        this.taskInfo.landingBoxID
      );

      // clear selected mission
      this.taskInfo.msid = [];
      currentObj.missionOption = [];
      this.taskRawInfo.msid = null;
      this.taskInfo.isRoutineTask = false;
      // // console.log("OKKKKKK");
      this.getMissions(this.taskInfo.sid);
    },
    async createTask() {
      // let currentObj = this;
      // await this.calETA();
      // await this.buildFlightTime();
      // // console.log("mission request : ", JSON.stringify(this.dataTask));
      // // console.log("call : " + this.apiServer + "/box/v2/status/" + this.boxID);
      var confirm = false;
      await swal
        .fire({
          icon: "info",
          title: "Please confirm to create task?",
          showCancelButton: true,
          confirmButtonText: `Confirm`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            confirm = true;
          } else {
            confirm = false;
          }
        })
        .catch(e => console.error(e));
      if (confirm) {
        await this.axios
          // .post(this.apiServer + "/createtask", { task: currentObj.dataTask }) // + this.boxID)
          .post(this.webAPI + "/app/api/task", this.taskInfo, {
            headers: {
              authorization: this.apiKey,
              "Content-Type": "application/json",
            },
          })
          .then(function(response) {
            console.log("res_code : ", response);
            swal.fire({
              icon: "success",
              title: "Your Task has been created",
              showConfirmButton: false,
              timer: 2000,
            });
            window.location.href = "/#/task";
          })
          .catch(function(error) {
            console.warn(error);
            // currentObj.output = error;
            swal.fire({
              icon: "error",
              title: "Oops... ",
              text: "Something went wrong! Please try again later",
              //footer: "<a href>Why do I have this issue?</a>",
            });
          });
      }
    },
    initialData() {
      // console.log("Initial Data.................");
      let currentObj = this;
      this.sidChanged();
      this.buildFlightTime(
        this.taskRawInfo.taskDate,
        this.taskRawInfo.taskTime,
        false
      );
      this.buildFlightTime(
        this.taskRawInfo.taskDate,
        this.taskRawInfo.taskTime,
        true
      );
      // let siteFench = this.getSiteInfo(this.taskInfo.sid).fence;
      // currentObj.siteInfo.polygon = this.drawPolygon(siteFench);
      currentObj.taskInfo.droneName = this.getDeviceName(this.taskInfo.droneID);
      currentObj.taskInfo.boxName = this.getDeviceName(this.taskInfo.boxID);
      currentObj.taskInfo.landingBoxName = this.getDeviceName(
        this.taskInfo.landingBoxID
      );
    },
    buildDroneOption(sid) {
      this.droneIDOption = [];
      this.customerAssets.devices.forEach((obj) => {
        if (obj.sid === sid && obj.type === "drone")
          this.droneIDOption.push({ value: obj.aid, text: obj.name });
      });
    },
    buildBoxsOption(sid) {
      this.boxs = [];
      this.customerAssets.devices.forEach((obj) => {
        if (obj.sid === sid && obj.type === "box")
          this.boxs.push({ value: obj.aid, text: obj.name });
      });
    },
    getMissionDesc(msid) {
      var missionDesc = null;
      // // console.log("sid:", sid);
      try {
        this.missions.missions.forEach((obj) => {
          // // console.log(obj.name);
          if (obj.missionID === msid) {
            // // console.log("name:", obj.name);
            missionDesc = obj.missionDesc;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return missionDesc;
    },
    rad: function(x) {
      return (x * Math.PI) / 180;
    },
    calTimeBetween: function(distance) {
      return distance / this.droneSpeed.OnAirSpeed;
    },
    calTimeTakeoff: function(altitude) {
      return altitude / this.droneSpeed.TakeoffSpeed;
    },
    calTimeLanding: function(altitude) {
      var time = 0;
      if (altitude > 10) {
        time += (altitude - 10) / this.droneSpeed.LandingSpeed[0];
        altitude = 10;
      }
      if (5 < altitude <= 10) {
        time +=
          (2 * (altitude - 5)) /
          (this.droneSpeed.LandingSpeed[1] + this.droneSpeed.LandingSpeed[2]);
        altitude = 5;
      }
      if (altitude <= 5) {
        time += altitude / this.droneSpeed.LandingSpeed[2];
        altitude = 0;
      }
      return time;
    },
    calTotalRange: function() {
      var i;
      this.distance = 0;
      this.timeRange = 0;
      for (i = 1; i < this.mission.missions.length; i++) {
        let pp = this.getDistance(
          this.mission.missions[i - 1].location,
          this.mission.missions[i].location
        );
        this.distance += pp;
        this.timeRange += this.calTimeBetween(pp);
      }
    },
    getDistance: function(p1, p2) {
      var R = 6378137; // Earth’s mean radius in meter
      var dLat = this.rad(p2.lat() - p1.lat());
      var dLong = this.rad(p2.lng() - p1.lng());
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.rad(p1.lat())) *
          Math.cos(this.rad(p2.lat())) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      // // console.log("Calculate : ", d, " m");
      return d; // returns the distance in meter
    },
    getMissionInfo(msid) {
      var missionInfo = null;
      // console.log("getMissionInfo:", msid);
      try {
        this.missions.missions.forEach((obj) => {
          // // console.log(obj.name);
          if (obj.missionID === msid) {
            // console.log("name:", obj.missionName);
            missionInfo = obj;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return missionInfo;
    },
    getSiteName(sid) {
      var siteName = null;
      // // console.log("sid:", sid);
      try {
        this.customerAssets.sites.forEach((obj) => {
          // // console.log(obj.name);
          if (obj.sid === sid) {
            // // console.log("name:", obj.name);
            siteName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return siteName;
    },
    getDeviceName(mid) {
      var assetName = null;
      try {
        this.customerAssets.devices.forEach((obj) => {
          // // console.log(obj.name);
          if (obj.aid === mid) {
            assetName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return assetName;
    },
    async calTime(d) {
      // let d = this.now;
      // await  d = new Date();
      this.taskTime =
        d
          .getHours()
          .toString()
          .padStart(2, "0") +
        ":" +
        d
          .getMinutes()
          .toString()
          .padStart(2, "0") +
        ":" +
        d
          .setSeconds()
          .toString()
          .padStart(2, "0");
    },
    buildFlightTime(date, time, end) {
      var dd = date + "T" + time;
      this.showData = "DD : " + dd;
      // console.log(dd);
      // this.showData = "Date : " + date1.toString();
      // const fixDateForAllBrowsers = dd.replace(/-/g, '/');

      // var dstr = date.split('-');
      // var tstr = time.split(':');
      // var date = new Date(dstr[0], int(dstr[1]) - 1, dstr[2], tstr[0], tstr[1], tstr[2]); // yyyy, mm-1, dd, hh, mm, ss
      // alert(dd);
      // let dd = new Date(dstr[0], parseInt(dstr[1]) - 1, dstr[2], tstr[0], tstr[1], tstr[2]);
      let date2 = Date.parse(dd);
      this.showData += " | MS : " + date2;
      // console.log(date2);
      // var date2 = dd.getTime();
      // var date2 = new Date(dd).getTime();
      // // console.log(dd);
      // alert(dd);
      // var dateTimeParts = dd.split(' '),
      //     timeParts = dateTimeParts[1].split(':'),
      //     dateParts = dateTimeParts[0].split('-'),
      //     date2;

      //     date2 = new Date(dateParts[2], parseInt(dateParts[1], 10) - 1, dateParts[0], timeParts[0], timeParts[1], timeParts[2]);

      // var ms = date2.getTime();
      var ms = date2;
      // alert(date2);

      // let date5 = "2022-08-17"
      // let time5 = "16:51:00"
      // var strDate = date5 + "T" + time5;
      // this.showData += " | Date Str : " + strDate;
      // // console.log(strDate); //2022-08-17T16:51:00
      // let ms3= Date.parse(strDate);
      // this.showData += " | MS : " + ms3
      // // console.log(ms); //1660729860000

      if (end) {
        this.taskInfo.routineEndTime = ms;
      } else {
        this.taskInfo.time = ms;
      }
    },
    calETA() {
      this.dataTask.ETA = this.dataTask.ETD + this.dataTask.totalTime;
    },
    async getMissions(sid) {
      // console.log("Get Mission");
      // console.log(sid);
      // this.dataTask.ETA = this.dataTask.ETD + this.dataTask.totalTime;
      let currentObj = this;
      currentObj.missionOption = [];
      await this.axios
        // .get(this.apiServer + "/getmissionlist/" + this.siteName)
        .get(this.webAPI + "/manage/api/msid?sid=" + sid, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function(response) {
          currentObj.missions = response.data;
          // this.onReset();
          // alert("Request Command : "+response.data.message);
        })
        .catch(function(error) {
          currentObj.output2 = error;
          // alert(error);
        });
      this.missions.missions.forEach(this.buildMissionOption);
      // this.missionOption.push({ value: {}, text: "---Create New Mission---" });
    },
    buildMissionOption(item) {
      // console.log(item);
      this.missionOption.push({
        value: item.missionID,
        text: item.missionName,
      });
    },
    calMissionRangeAndTime() {
      this.dataTask.totalTime = 0;
      this.dataTask.totalRange = 0;
      this.dataTask.missionNote = "";
      this.dataTask.missions.forEach(this.missionRangeTime);
      this.markers.forEach(this.clearMap);
      this.markers = [];
      this.pathsLocation = [];
      // this.paths.setMap(null);
      this.clearMap(this.paths);
      this.clearMap(this.startLine);
      this.clearMap(this.endLine);
      this.paths = {};
      this.dataTask.missions.forEach(this.addMarker);
      this.dataTask.missions.forEach(this.addPath);
      this.paths = this.polyPath(this.pathsLocation);
      this.startLine = this.displayDashPath(
        this.boxMarker.position,
        this.markers[0].position
      );
      this.endLine = this.displayDashPath(
        this.markers[this.markers.length - 1].position,
        this.boxMarker.position
      );
    },
    missionRangeTime(item) {
      this.dataTask.totalTime += item.EstMissionTime;
      this.dataTask.totalRange += item.totalRange;
      this.dataTask.missionNote += "#" + item.missionDescription + " ";
    },
    addMarker(item) {
      // console.log("addMarker : ", item);
      for (let i = 0; i < item.wpCMD.length; i++) {
        if (item.wpCMD[i] == 16) {
          this.markers.push(
            this.displayMarker(
              item.wpLat[i],
              item.wpLng[i],
              this.markers.length
            )
          );
          this.series[0].data.push([this.markers.length,  Math.round(item.wpAlt[i])]);
        } else if (item.wpCMD[i] == 195)
          this.markers.push(
            this.displayROI(item.wpLat[i], item.wpLng[i], this.markers.length)
          );
      }
    },

    // addHeight(item) {
    //   console.log("addHeight : ", item);

    //   // for (let i = 0; i < item.wpCMD.length; i++) {
    //   //   // console.log('addHeight');
    //   //   if (item.wpCMD[i] == 16) this.series[0].data.push([this.series[0].data.length, item.wpAlt[i]]);
    //   // }
    // },
    addPath(item) {
      // console.log("addPath : ", item);
      for (let i = 0; i < item.wpCMD.length; i++) {
        if (item.wpCMD[i] == 16) {
          this.pathsLocation.push(
            new window.google.maps.LatLng(item.wpLat[i], item.wpLng[i])
          );
        }
      }
    },
    clearMap(item) {
      try {
        item.setMap(null);
      } catch {
        //
      }
    },
    displayBoxesLocation() {
      let boxesInfo = this.getBoxInfo(this.taskInfo.sid);
      // // console.log(boxesInfo);
      // // console.log("Location Item : ", location);
      var image = {
        url: require("../assets/DB2_50x50.png"),
        // url: require("../assets/drone2.png"),
        //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        // This marker is 20 pixels wide by 32 pixels high.
        size: new window.google.maps.Size(50, 50),
        // The origin for this image is (0, 0).
        origin: new window.google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(20, 20),
        labelOrigin: new window.google.maps.Point(20, 40),
      };
      this.boxesMarker.forEach(this.clearMap);
      boxesInfo.forEach((obj) => {
        this.boxesMarker.push(
          new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              obj.location[0],
              obj.location[1]
            ),
            icon: image,
            title: obj.name,
            map: this.map,
            draggable: false,
            label: obj.name,
          })
        );
      });
    },
    displayDashPath(start, end) {
      // const DlineSymbol = {
      //   path: "M 0,-1 0,1",
      //   strokeOpacity: 1,
      //   scale: 2,
      // };
      const lineSymbol = {
        path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
      };
      const aIcon = {
        icon: lineSymbol,
        offset: "100%",
        repeat: "50px",
      };
      let icon = [aIcon];
      return new window.google.maps.Polyline({
        path: [start, end],
        // strokeOpacity: 0,
        strokeColor: "RED",
        icons: icon,
        // icons: [
        //   {
        //     icon: DlineSymbol,
        //     offset: "0",
        //     repeat: "20px",
        //   },
        // ],

        strokeOpacity: 1,
        strokeWeight: 1,
        map: this.map,
        draggable: false,
      });
    },
    displayROI(lat, lon, index) {
      const svgMarker2 = {
        path: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
        fillColor: "yellow",
        fillOpacity: 0.3,
        strokeWeight: 0.2,
        // rotation: rotation,
        scale: 1,
        labelOrigin: new window.google.maps.Point(100, 100),
        anchor: new window.google.maps.Point(100, 100),
      };
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lon),
        icon: svgMarker2,
        label: "ROI-" + (index + 1).toString(),
        map: this.map,
        draggable: false,
        // animation: new window.google.maps.Animation.DROP,
        // zIndex: 0,
        title: "Region of Interest-" + (index + 1).toString(),
      });
    },
    displayMarker(lat, lon, index) {
      var image = {
        url: require("../assets/point.png"),
        // url: require("../assets/ROI1.png"),
        // This marker is 20 pixels wide by 32 pixels high.
        size: new window.google.maps.Size(30, 30),
        // The origin for this image is (0, 0).
        origin: new window.google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(15, 15),
      };
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lon),
        icon: image,
        label: (index + 1).toString(),
        map: this.map,
        draggable: false,
        // animation: new window.google.maps.Animation.DROP,
        // zIndex: 0,
        title: "WP#" + (index + 1).toString(),
      });
    },
    polyPath(path) {
      const lineSymbol = {
        path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
      };
      const aIcon = {
        icon: lineSymbol,
        offset: "100%",
        repeat: "50px",
      };
      let icon = [aIcon];
      return new window.google.maps.Polyline({
        path: path,
        icons: icon,
        geodesic: true,
        strokeColor: "YELLOW",
        strokeOpacity: 1,
        strokeWeight: 1.5,
        map: this.map,
      });
    },
    checkDate() {
      var now = new Date();
      var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      // 15th two months prior
      this.minDate = new Date(today);
    },
    getSiteInfo(sid) {
      var siteInfo = null;
      // // console.log("sid:", sid);
      try {
        this.customerAssets.sites.forEach((obj) => {
          // // console.log(obj.name);
          if (obj.sid === sid) {
            // // console.log("name:", obj.name);
            siteInfo = obj;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return siteInfo;
    },
    getDeviceInfo(aid) {
      var deviceInfo = null;
      try {
        this.customerAssets.devices.forEach((obj) => {
          // // console.log(obj.name);
          if (obj.aid === aid) {
            deviceInfo = obj;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return deviceInfo;
    },
    drawPolygon(item) {
      var paths = [];
      var sumLat = 0;
      var sumLng = 0;
      item.forEach((obj) => {
        paths.push(new window.google.maps.LatLng(obj[0], obj[1]));
        sumLat = sumLat + obj[0];
        sumLng = sumLng + obj[1];
      });
      // // console.log(paths);
      sumLat = sumLat / paths.length;
      sumLng = sumLng / paths.length;
      this.map.setCenter(new window.google.maps.LatLng(sumLat, sumLng));
      return new window.google.maps.Polygon({
        paths: paths,
        strokeColor: "#FFA500",
        strokeOpacity: 0.5,
        strokeWeight: 3,
        fillColor: "#FFA500",
        fillOpacity: 0.05,
        map: this.map,
      });
    },
    getBoxInfo(sid) {
      var boxInfo = [];
      this.customerAssets.devices.forEach((obj) => {
        if (obj.sid === sid && obj.type === "box") boxInfo.push(obj);
      });
      return boxInfo;
    },
  },
};
</script>

<style scoped>
.contentFormTask::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.planner {
  position: relative;
  width: calc(100%);
  height: 100vh;
}
.contentTask {
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 320px;
  height: 100%;
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 500;
  padding: 1rem;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
  /* overflow: scroll none; */
  
  
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 400px;
  padding: 0px;
  margin: 0px;
  height: 100%;
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 9999;
  padding: 1rem;
  /* overflow: scroll none; */

  padding: 32px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);

}
.full {
  /* position: absolute; */
  /* left: 0px; */
  /* bottom: 0px; */
  height: 100vh;
  width: 100%;
  display: block;
  margin: 0px;
  padding: 0px;
  z-index: 0;
}
.mini {
  border-radius: 10px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: black;
  width: 400px;
  height: 225px;
  z-index: 1;
}
.alarm {
  background-color: rgba(255, 0, 0, 0.8);
  /* color: yellow; */
}
.missionList {
  background-color: rgba(0, 0, 0, 0.8);
  max-height: 40vh;
  /* width: 300px; */
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px inset black;
  padding: 0;
  margin: 0;
}
.smallText {
  /* display: inline-block; */
  /* color: var(--text-grey); */
  /* margin-top: -10rem !important; */
  /* width: 100%; */
  font-size: 0.75em;
  /* background: greenyellow; */
}
.missionsItem {
  /* background: burlywood; */
  padding: 0.5rem 0 0.5rem 2rem;

  color: var(--text-grey);
}
.missionsItem:hover {
  background: var(--text-grey);
  color: var(--color-dark);
}
.missionDiv {
  width: 250px;
  /* background: indigo; */
}

.missionDiv:checked {
  background: red;
  color: var(--color-main);
}
.missionDiv:hover {
  cursor: pointer;
}
.contentFormTask {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 82vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* overflow: scroll none; */
}
.task-footer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: block;
  margin-left: auto;
  margin-left: 0rem;
  width: 270px;
  padding: 1 rem;
  z-index: 999;
}
.contentEstimation {
  position: absolute;
  top: 0rem;
  right: 0rem;
  margin: 1rem;
  width: auto;
  background: rgba(60, 60, 60, 0.6);
  color: #f2f2f2;
  z-index: 9999;
  /* padding: 1rem; */
  overflow: scroll none;
  border-radius: 0.5rem;
  padding: 1rem;
  background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6));
  backdrop-filter: blur(10px);
}
.est-status i {
  font-size: 2rem;
  margin-right: 5px;
  /* background: brown; */
}
.est-status span {
  /* font-size: 2rem; */
  margin-left: 5px;
  /* background: brown; */
}

@media only screen and (max-width: 620px) {
  .contentEstimation {
    display: none;
  }
  .contentTask {
    left: 50%;
    transform: translate(-50%, 0%);
    top: 0rem;
    width: 100%;
  }
  .contentFormTask {
    height: calc(100vh - 80px - 66px);
  }
  .task-footer {
    /* position: absolute; */
    top: 10px;
    right: 10px;
    left: auto;
    display: block;
    /* margin-left: auto; */
    /* margin-left: 0rem; */
    width: 100px;
    /* padding: 1 rem; */
    z-index: 9999;
  }
  .planner {
    height: calc(100vh - 63px);
  }
  .full {
    height: calc(100vh - 63px);
  }
}
.heightlevel {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: translate(0, -100%);
  z-index: 999;
  width: 80vw;
  /* height: 10vh; */
}
</style>
