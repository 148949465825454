<template>
  <div class="contentbody">
    <header1 class="pageHeader" />
    <div></div>
    <!-- {{items}} -->
    <!-- <h3>Scheduled Mission</h3> -->
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col sm="7" md="6" class="my-1">
          <b-form-group
            v-model="sortDirection"
            label="Filter Status : "
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            variant="dark"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              variant="dark"
              v-model="filter"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
              <!-- <b-form-checkbox value="pending">Pending</b-form-checkbox>
            <b-form-checkbox value="operating">Operating</b-form-checkbox>
            <b-form-checkbox value="completed">Completed</b-form-checkbox> -->
              <b-form-radio variant="dark" value="">All</b-form-radio>
              <b-form-radio value="New">Pending</b-form-radio>
              <b-form-radio value="Acked">Operating</b-form-radio>
              <b-form-radio value="Completed">Completed</b-form-radio>
              <b-form-radio value="Reject">Rejected</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col sm="3" md="3" class="my-1">
          <b-form-group
            label="Tasks page"
            label-for="per-page-select"
            label-cols-sm="2"
            label-cols-md="2"
            label-cols-lg="3"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              @input="getdata"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center">
        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            variant="dark"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            pills
            size="sm"
            class="my-0"
            @input="getdata"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>

    <div class="tableAsset">
      <!-- <b-table
        hover
        striped
        stacked="md"
        sticky-header="88vh"
        :current-page="currentPage"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      > -->
      <b-table
        hover
        striped
        stacked="md"
        sticky-header="88vh"
        :items="items"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        variant="dark"
      >
        <template v-slot:cell(taskTime)="row">
          {{ caltime(row.item.taskTime) }}
        </template>
        <template v-slot:cell(isExternalTask)="row">
          {{ row.item.isExternalTask ? "Yes" : "No" }}
        </template>
        <template v-slot:cell(siteID)="row">
          {{ getSiteName(row.item.siteID) }}
        </template>
        <template v-slot:cell(droneID)="row" >
          <router-link href=""  :to="'/panel/uav/'+row.item.droneID">{{ getDeviceName(row.item.droneID) }}</router-link>
        </template>
        <template v-slot:cell(landingBoxName)="row" >
          <router-link href="" :to="'/panel/station/'+row.item.landingBoxID">{{ row.item.landingBoxName }}</router-link>
        </template>
        <template v-slot:cell(taskTotalRange)="row">
          {{ (row.item.taskTotalRange / 1000).toFixed(2) }} km.
        </template>
        <template v-slot:cell(Action)="row">
          <b-button-toolbar style="justify-content: end; display: flex">
            <b-button-group>
              <!-- <b-button
                title="View Flight Path"
                @click="showRoute(row.item)"
                variant="primary"
              >
                <span class="las la-route"></span>
              </b-button> -->

              <!-- <b-button
                v-if="row.item.status != 'New'"
                title="View Postdata"
                @click="showPostDataFn(row.item)"
                variant="success"
              >
                <b-icon icon="camera" aria-hidden="true"></b-icon>
              </b-button> -->
              <b-button size="sm" @click="row.toggleDetails">
                <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon>
                {{ row.detailsShowing ? "Hide" : "Show" }} Details
              </b-button>
              <b-button
                title="Delete Task"
                @click="confirmDelete(row.item)"
                variant="danger"
              >
                <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </template>
        <template #row-details="row">
          <b-card>
            <b-tabs content-class="mt-3">
              <b-tab title="Flight Information" active>
                <b-container
                  class="bv-example-row"
                  style="height: 60vh; overflow-y: scroll"
                >
                  <b-row>
                    <b-col cols="4">
                      <ul>
                        <li v-for="(value, key) in row.item" :key="key">
                          {{ key }}: {{ value }}
                        </li>
                      </ul>
                    </b-col>
                    <b-col cols="8">
                      <missionview :missionDataID="row.item.missionDataID" />
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>
              <b-tab title="Photos" @click="getPostDataFn(row.item)">
                <div style="height: 60vh; overflow-y: scroll">
                  <div class="cards">
                    <b-img
                      v-for="(item, index) in postdata[
                        row.item.taskID.toString()
                      ].Images"
                      :key="'IMG-' + index"
                      center
                      thumbnail
                      fluid
                      class="card-nc"
                      :src="item.url"
                      :alt="'IMG-' + index"
                      v-on:click="
                        showPicZoom2(
                          postdata[row.item.taskID.toString()].Images,
                          index
                        )
                      "
                    />
                  </div>
                </div>
              </b-tab>
              <b-tab title="Videos" @click="getPostDataFn(row.item)">
                <div style="height: 60vh; overflow-y: scroll">
                  <div class="cards-video">
                    <!-- <b-embed
                    type="video"
                    aspect="16by9"
                    controls
                    poster="poster.png"
                    allowfullscreen
                    v-for="(item, index) in postdata[row.item.taskID.toString()].Videos"
                    :key="'VIDEO-' + index"
                    class="card-video "
                  >
                    <p>{{ item.filename }}</p>
                    <source :src="item.url" type="video/mp4" />
                  </b-embed> -->
                    <b-container fluid>
                      <b-row>
                        <b-col
                          md="6"
                          v-for="(item, index) in postdata[
                            row.item.taskID.toString()
                          ].Videos"
                          :key="'VIDEO-' + index"
                          ><b-embed
                            type="video"
                            aspect="16by9"
                            controls
                            poster="../assets/poster.png"
                            allowfullscreen
                          >
                            <p>{{ item.filename }}</p>
                            <source
                              :src="item.url"
                              type="video/mp4"
                            /> </b-embed
                        ></b-col>
                      </b-row>
                    </b-container>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Raw Data" @click="getPostDataFn(row.item)">
                <div style="height: 60vh; overflow-y: scroll">
                  <div class="cards-file">
                    <div
                      v-for="(item, index) in postdata[
                        row.item.taskID.toString()
                      ].Rawdatas"
                      :key="'IMG-' + index"
                      class="card-file"
                      v-on:click="downloadFile(item.url)"
                    >
                      <div style="font-size: 4rem; text-align: center">
                        <b-icon icon="file-earmark-text-fill"></b-icon>
                      </div>
                      <p class="fileName-tag">{{ item.filename }}</p>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab title="Recored Streams" @click="getPostDataFn(row.item)">
                <div style="height: 60vh; overflow-y: scroll">
                  <div class="cards-video">
                    <b-container fluid>
                      <b-row>
                        <b-col
                          md="6"
                          v-for="(item, index) in postdata[
                            row.item.taskID.toString()
                          ].RecordedStreams"
                          :key="'VIDEO-' + index"
                          ><b-embed
                            type="video"
                            aspect="16by9"
                            controls
                            poster="../assets/poster.png"
                            allowfullscreen
                          >
                            <p>{{ item.filename }}</p>
                            <source
                              :src="item.url"
                              type="video/mp4"
                            /> </b-embed
                        ></b-col>
                      </b-row>
                    </b-container>
                    <!-- <div class="card-video" v-for="(item, index) in postdata[row.item.taskID.toString()].RecordedStreams"
                    :key="'VIDEO-' + index"> -->

                    <!-- </div> -->
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </template>
      </b-table>
    </div>

    <b-modal
      id="modal-PostData"
      size="xl"
      hide-footer
      scrollable
      :title="'Tasked Data Viewer : ID ' + selectedShowData.taskID"
    >
      <div style="height: 80vh">
        <b-card no-body style="height: 100%">
          <b-tabs pills card vertical nav-wrapper-class="w-25">
            <b-tab title="Photos" active>
              <div style="height: 60vh; overflow-y: scroll">
                <b-col
                  v-for="(item, index) in selectedShowData.Images"
                  :key="'IMG-' + index"
                >
                  <b-img
                    center
                    class="card-nc"
                    :src="item.url"
                    :alt="'IMG-' + index"
                    v-on:click="showPicZoom(item)"
                  />
                </b-col>
              </div>
            </b-tab>
            <b-tab title="Videos">
              <div style="height: 60vh; overflow-y: scroll">
                <div class="cards-video">
                  <b-embed
                    type="video"
                    aspect="16by9"
                    controls
                    poster="poster.png"
                    allowfullscreen
                    v-for="(item, index) in selectedShowData.Videos"
                    :key="'VIDEO-' + index"
                    class="card-video"
                  >
                    <p>{{ item.filename }}</p>
                    <source :src="item.url" type="video/mp4" />
                  </b-embed>
                </div>
              </div>
            </b-tab>
            <b-tab title="Raw Data">
              <div style="height: 75vh; overflow-y: scroll">
                <div class="cards-file">
                  <div
                    v-for="(item, index) in selectedShowData.Rawdatas"
                    :key="'IMG-' + index"
                    class="card-file"
                    v-on:click="downloadFile(item.url)"
                  >
                    <div style="font-size: 4rem; text-align: center">
                      <b-icon icon="file-earmark-text-fill"></b-icon>
                    </div>
                    <p class="fileName-tag">{{ item.filename }}</p>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Recored Streams">
              <div style="height: 75vh; overflow-y: scroll">
                <div class="cards-video">
                  <!-- <b-card
                    overlay
                    img-src="https://picsum.photos/900/250/?image=3"
                    img-alt="Card Image"
                    text-variant="white"
                    title="Image Overlay"
                    sub-title="Subtitle"
                  >
                    <b-card-text>
                      Some quick example text to build on the card and make up the bulk of the card's content.
                    </b-card-text>
                  </b-card> -->
                  <b-embed
                    type="video"
                    aspect="16by9"
                    controls
                    poster="poster.png"
                    allowfullscreen
                    v-for="(item, index) in selectedShowData.RecordedStreams"
                    :key="'VIDEO-' + index"
                    class="card-video"
                  >
                    <p>{{ item.filename }}</p>
                    <source :src="item.url" type="video/mp4" />
                  </b-embed>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </b-modal>
    <b-modal
      id="modal-Zoom"
      size="xl"
      hide-header
      hide-footer
      body-bg-variant="dark"
      :title="picZoom.filename"
    >
      <!-- <b-img
        :src="picZoom.url"
        fluid-grow
        alt="Zoom image"
        class="imgZoom"
        v-on:click="downloadFile(picZoom.url)"
      ></b-img> -->
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="0"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
      >
        <!-- Text slides with image -->
        <b-carousel-slide
          v-for="(item, index) in showIMGs"
          :key="'IMG-' + index"
          :img-src="item.url"
        ></b-carousel-slide>

        <!-- Slide with blank fluid image to maintain slide aspect ratio -->
        <b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            eros felis, tincidunt a tincidunt eget, convallis vel est. Ut
            pellentesque ut lacus vel interdum.
          </p>
        </b-carousel-slide>
      </b-carousel>
    </b-modal>

    <b-modal
      id="modal-Route"
      ref="modal-Route"
      size="xl"
      hide-footer
      :title="'Route viewer of Task ID : ' + route.taskID"
    >
      <div style="height: 80vh; padding: 0; margin: 0">
        <!-- <iframe
          width="100%"
          style="width:100%; height:80vh; padding:0; margin:0;"
          :src="
            'https://webapp.dronebox-dev.arv.co.th/mission/display/index.html?boxid=' +
              route.boxID +
              '&missions=' +
              missions
          "
          frameborder="0"
        ></iframe> -->
        <missionview :missionDataID="missionDataID" />
      </div>
    </b-modal>
  </div>
</template>
<script>
// @ is an alias to /src
// import MainMenu from '@/components/NavigateManu.vue'
import swal from "sweetalert2";
import header1 from "../components/header/Header_Normal.vue";
import missionview from "../components/MapPanel.vue";
export default {
  name: "Tasks",
  components: {
    header1,
    missionview,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      pageOptions: [10, 15, 20, 30],
      selectedShowData: {
        Videos: [],
        Images: [],
        Rawdatas: [],
        RecordedStreams: [],
        taskID: 0,
      },
      picZoom: {
        url: "",
        filename: "",
      },
      route: {},
      postdata: {},
      missions: "",
      sortBy: "taskTime",
      sortDesc: true,
      sortDirection: "desc",
      filterOn: [],
      filter: "",
      customer: this.$store.state.owner,
      apidata: this.$store.state.apiDataServe,
      socket: null,
      control: null,
      loadingPostData: {},
      webAPI: this.$store.state.webAPI,
      deviceKey: this.$store.state.deviceKey,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
      selectedAsset: this.$store.state.selectedAsset,
      customerAssets: this.$store.state.customerAsset,
      output2: null,
      items: [],
      showIMGs: [],
      slide: 0,
      missionDataID: 0,
      fields: [
        // "edit",
        { key: "taskID", label: "Task ID", sortable: true },
        { key: "droneID", label: "Drone", sortable: true },
        { key: "landingBoxName", label: "Landing Target", sortable: true },
        {
          key: "taskTime",
          label: "Flight Time",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "missionName", label: "Missions" },
        { key: "isExternalTask", label: "External" },
        { key: "siteID", label: "Site", sortable: true },
        { key: "taskTotalRange", label: "Range" },
        // { key: "totalTime", label: "Flight Time"},
        { key: "taskStatus", label: "Status", sortable: true },
        { key: "ussOID", label: "USS ID" },
        // { key: "creator", label: "Created By", sortable: true },
        "Action",
        //, sortable: true
      ],
      form: {
        customer: "ARV",
        type: "Drone",
      },
      formEdit: {},
      types: ["Drone", "Box"],
      interValTime: null,
    };
  },
  mounted: function () {
    if (this.customerAssets.length == 0) this.getAssets();
    else this.getdata();
    // this.interValTime = setInterval(this.getdata, 2000);
  },
  methods: {
    
    async mateLink(objData) {
      console.log(objData)
      await this.getKey(objData.droneID);
      let selectedAsset = this.$store.state.selectedAsset;
      selectedAsset.type = "drone";
      selectedAsset.name = objData.droneName;
      selectedAsset.desc = objData.droneName;
      selectedAsset.loc = [];
      selectedAsset.sName = this.getSiteName(objData.siteID);
      selectedAsset.show = true;
      selectedAsset.info = {};
      this.$store.commit("setselectedAsset", selectedAsset);
      window.location.href = "/#/uav2";
    },
    async getKey(device_id) {
      console.error("GET KEY CALL...");
      let currentObj = this;
      var deviceKey = null;
      await this.axios
        .post(this.sioServer + "/auth/getkey?code=" + device_id, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          deviceKey = response.data.key;
          console.log(deviceKey);
          currentObj.$store.commit("setDeviceKey", deviceKey);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      return deviceKey;
    },
    async getAssets() {
      console.log("Get Asset Function");
      // this.datashowing.spinner = true;
      let currentObj = this;
      await this.axios
        .get(this.webAPI + "/app/api/asset?cid=" + this.customerID, {
          headers: {
            authorization: this.apiKey,
          },
        })

        .then( (response) => {
          if (response.status === 400) {
            sessionStorage.clear();
            this.$store.commit("setLoginState", false);
            window.location.href = "/#/";
          }
          currentObj.customerAssets = response.data;
          // console.log(currentObj.assetList);
          currentObj.$store.commit(
            "setcustomerAsset",
            currentObj.customerAssets
          );
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          console.log("Get Asset Function__EER");
          currentObj.output2 = error;
          // alert(error);
        });

      console.log(this.customerAssets.devices);
      this.getdata();
    },
    getSiteName(sid) {
      var siteName = null;
      // console.log("sid:", sid);
      try {
        this.customerAssets.sites.forEach((obj) => {
          // console.log(obj.name);
          if (obj.sid === sid) {
            // console.log("name:", obj.name);
            siteName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return siteName;
    },
    getDeviceName(mid) {
      var assetName = null;
      try {
        this.customerAssets.devices.forEach((obj) => {
          // console.log(obj.name);
          if (obj.aid === mid) {
            assetName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return assetName;
    },
    downloadFile(url) {
      window.open(url);
    },
    async showRoute(item) {
      let currentObj = this;
      console.log(item);
      currentObj.route = item;
      currentObj.missions = "";
      currentObj.missionDataID = item.missionDataID;
      // item.missions.forEach(this.buildMissionText);
      this.$refs["modal-Route"].show();
    },
    async buildMissionText(item, index) {
      let currentObj = this;
      currentObj.missions = currentObj.missions + item;
      if (currentObj.route.length - 1 > index)
        currentObj.missions = currentObj.missions + ",";
    },
    async showPostDataFn(item) {
      let currentObj = this;
      await this.axios
// .get(this.apidata + "/getpresignedurl/" + item.taskID)
        // /app/api/getpresignedurl
        .get(this.webAPI + "/app/api/getpresignedurl?taskID=" + item.taskID, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          // currentObj.items = response.data;
          currentObj.selectedShowData = response.data;
          // console.log(response.data);
          // alert("Request Command : "+response.data.message);
        })
        .catch(function (error) {
          console.log(error);
          // currentObj.output2 = error;
          // alert(error);
        });
      currentObj.selectedShowData.taskID = item.taskID;
      this.$bvModal.show("modal-PostData");
    },
    checkPostDataStatus(id) {
      return id in this.postdata;
    },
    async getPostDataFn(item) {
      let currentObj = this;
      // console.log(item.taskID in currentObj.postdata);
      console.log(currentObj.postdata[item.taskID.toString()].loaded);
      // if (!(item.taskID in currentObj.postdata))
      if (!currentObj.postdata[item.taskID.toString()].loaded) {
        console.log("Get Post data of taskID : ", item.taskID);
        await this.axios
          // .get(this.apidata + "/getpresignedurl/" + item.taskID)
          // /app/api/getpresignedurl
          .get(this.webAPI + "/app/api/getpresignedurl?taskID=" + item.taskID, {
            headers: {
              authorization: this.apiKey,
            },
          })
          .then(function (response) {
            // currentObj.items = response.data;
            // currentObj.selectedShowData = response.data;
            currentObj.postdata[item.taskID.toString()] = response.data;
            currentObj.postdata[item.taskID.toString()].loaded = true;
            // item.PostData = response.data;
            // console.log(response.data);
            // alert("Request Command : "+response.data.message);
          })
          .catch(function (error) {
            console.log(error);
            // currentObj.output2 = error;
            // alert(error);
          });
        // currentObj.selectedShowData.taskID = item.taskID;
        // item.toggleDetails = !item.toggleDetails;
        // item.detailsShowing = !item.detailsShowing;
      }
      this.$forceUpdate();
    },
    async showPicZoom(url) {
      let currentObj = this;
      currentObj.picZoom = url;
      this.$bvModal.show("modal-Zoom");
    },
    async showPicZoom2(items, index) {
      let currentObj = this;
      currentObj.showIMGs = items;
      currentObj.slide = index;
      console.log(index);
      this.$bvModal.show("modal-Zoom");
    },
    async getdata() {
      let currentObj = this;
      var qdata =
        "&orderby=taskTime&pagesize=" +
        this.perPage.toString() +
        "&currentpage=" +
        this.currentPage.toString();
      console.log(qdata);
      await this.axios
        // .get(this.apidata + "/gettask/boxes/" + this.customer)
        .get(this.webAPI + "/app/api/task?cid=" + this.customerID + qdata, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          currentObj.items = response.data.tasks;
          currentObj.totalRows = response.data.count;
          // alert("Request Command : "+response.data.message);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });

      currentObj.items.forEach((item) => {
        currentObj.postdata[item.taskID.toString()] = {
          loaded: false,
          Images: [],
          Rawdatas: [],
          RecordedStreams: [],
          Videos: [],
        };
        // currentObj.postdata[item.taskID.toString()].loaded = false;
        // currentObj.postdata[item.taskID.toString()].Images = [];
        // currentObj.postdata[item.taskID.toString()].Rawdatas = [];
        // currentObj.postdata[item.taskID.toString()].RecordedStreams = [];
        // currentObj.postdata[item.taskID.toString()].Videos = [];
      });
      // currentObj.totalRows = this.items.length;
      this.caltime();
    },
    caltime(item_time) {
      var current_datetime = new Date(item_time);
      var dateTime =
        current_datetime.getFullYear() +
        "-" +
        (current_datetime.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        current_datetime.getDate().toString().padStart(2, "0") +
        " " +
        current_datetime.getHours().toString().padStart(2, "0") +
        ":" +
        current_datetime.getMinutes().toString().padStart(2, "0") +
        ":" +
        current_datetime.getSeconds().toString().padStart(2, "0");
      return dateTime;
    },

    confirmDelete(item) {
      swal
        .fire({
          icon: "error",
          title: "Do you want to remove Task " + item.taskID + "?",
          text: "Task Decription : " + item.missionNote,
          showCancelButton: true,
          confirmButtonText: `Confirm`,
        })

        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.delete(item.taskID);
            // this.$swal.fire("Deleted!", item.missionID, "error");
            swal.fire({
              //position: 'top-end',
              icon: "success",
              title: "Task ID : " + item.taskID + " has been removed",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch(e => console.error(e));
    },

    async delete(id) {
      // evt.preventDefault();
      let currentObj = this;
      // console.log("Called Delete function.........");
      console.log("Remove Mission ID : " + id);
      var data = JSON.stringify({ taskID: id });
      await this.axios
        // .post(this.apidata + "/remove/task", { taskID: id })
        .delete(this.webAPI + "/app/api/task?cid=" + this.customerID, {
          headers: {
            authorization: this.apiKey,
            "Content-Type": "application/json",
          },
          data: data,
        })
        .then(function (response) {
          currentObj.output2 = response.data;
          //currentObj.output2 = currentObj.items;
        })
        .catch(function (error) {
          currentObj.output2 = error;
        });
      this.getdata();
    },
    async editForm(edata) {
      // console.log("Call Model Edit Form!!");
      let current = this;
      current.formEdit = edata;
      await this.$refs["edit-modal"].show();
      this.getdata();
    },

    async editRequest() {
      let currentObj = this;
      this.axios
        .put(
          this.apidata + "/asset/v2/update/" + this.formEdit.id,
          this.formEdit
        )
        .then(function (response) {
          currentObj.output2 = response.data;
        })
        .catch(function (error) {
          currentObj.output2 = error;
        });
    },
  },
  beforeDestroy() {
    // console.log("Clear Scheduled Interval Time....");
    clearInterval(this.interValTime);
  },
};
</script>

<style scoped>
.tableAsset {
  /* width: 90%; */
  /* text-align: center; */
  display: block !important;
  margin: auto;
  padding: 0.5rem 2rem;
  /* max-width: 900px; */
}
.tableAsset1 {
  display: none !important;
}
.contentbody {
  width: 100%;

  /* margin-top: 10px; */
  /* overflow: none; */
  /* text-align: center; */
  /* background-color: blue; */
  /* padding: auto; */
}
.videoframe {
  width: 100%;
  height: 100%;
}
.card-nc {
  /* background-color: dodgerblue; */
  /* color: white; */
  /* padding: 1rem; */
  width: 20%;
  display: inline !important;
}
.card-nc:hover {
  /* transform: scale(1.5); (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  border: 2px solid #000;
}
.card-file {
  /* background-color: dodgerblue; */
  /* color: white; */
  /* padding: 1rem; */
  /* height: 15vh; */
}
.card-file:hover {
  /*transform: scale(1.2);  (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  /* border: 2px solid #000; */
  border-radius: 10px;
  background: rgba(77, 78, 78, 0.747);
  cursor: pointer;
  color: whitesmoke;
}
.card-video {
  /* background-color: dodgerblue; */
  /* color: white; */
  /* padding: 1rem; */
  /* height: 20vh !important; */
  width: 40% !important;
  display: inline !important;
}
.cards-video {
  /* max-width: 1200px; */
  /* padding: 0rem; */
  /* margin: 0; */
  display: block;
  /* display: inline !important; */
  /* display: grid; */
  /* grid-gap: 1rem; */
  /* height: 75vh; */
  /* overflow-y: scroll; */
  /* align-items: center; */
}
.cards {
  /* max-width: 1200px; */
  padding: 0rem;
  margin: 0;
  /* display: grid; */
  grid-gap: 0.5rem;
  /* height: 75vh; */
  /* overflow-y: scroll; */
  /* align-items: center; */
}

.cards-file {
  max-width: 200px;
  display: grid;
  grid-gap: 0.5rem;
  align-items: center;
}

.imgZoom {
}
.imgZoom:hover {
  cursor: pointer;
}
.fileName-tag {
  max-width: 200px;
  height: auto;
  text-align: center;
}
.pageHeader {
  display: block;
}

@media only screen and (max-width: 900px) {
  .tableAsset {
    /* display: none !important; */
  }
  .pageHeader {
    display: none !important;
  }

  .tableAsset1 {
    display: inline-block !important;
    overflow-y: auto;
    width: 100%;
    height: calc(88vh);
  }
}
</style>
