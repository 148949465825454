<template>
  <div class="main-container">
    <div class="layout">
      <div class="img-site"></div>
      <div class="header-project">
        <div class="title-name">
          <h1>{Project Name}</h1>
          <img
            width="18px"
            height="18px"
            src="../../../assets/v3/icons/icon_more.svg"
          />
        </div>
        <div class="location-layout">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7.15942 1.1665C4.81396 1.1665 2.91699 2.99234 2.91699 5.24984C2.91699 6.26484 3.22002 7.21567 3.77154 8.07317C4.3473 8.9715 5.10487 9.7415 5.68669 10.6398C5.97154 11.0773 6.1776 11.4857 6.39578 11.9582C6.55336 12.279 6.68063 12.8332 7.15942 12.8332C7.6382 12.8332 7.76548 12.279 7.91699 11.9582C8.14123 11.4857 8.34123 11.0773 8.62608 10.6398C9.2079 9.74734 9.96548 8.97734 10.5412 8.07317C11.0988 7.21567 11.4018 6.26484 11.4018 5.24984C11.4018 2.99234 9.50487 1.1665 7.15942 1.1665ZM7.15942 6.854C6.32305 6.854 5.64426 6.20067 5.64426 5.39567C5.64426 4.59067 6.32305 3.93734 7.15942 3.93734C7.99578 3.93734 8.67457 4.59067 8.67457 5.39567C8.67457 6.20067 7.99578 6.854 7.15942 6.854Z"
              fill="#6778FF"
            />
          </svg>
          <p class="location-name">{ Location Name}</p>
        </div>
        <div class="device-layout">
          <div class="qty-lyout">
            <p class="device-name">Station</p>
            <p class="divece-qty">2</p>
          </div>
          <div class="qty-lyout">
            <p class="device-name">Drone</p>
            <p class="divece-qty">2</p>
          </div>
          <div class="qty-lyout">
            <p class="device-name">IoT</p>
            <p class="divece-qty">3</p>
          </div>
        </div>
        <p class="prj-desc">
          {ProjectDescription...........................................................................................................................................................................................................................................................}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main-container {
  display: inline-flex;

  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 30px;
  border: 1px solid var(--gray-primary-900, #1f2937);
  background: var(--opacity-gray-secondary-90080, rgba(13, 13, 13, 0.8));
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
    0px 12px 24px -4px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(10px);
}
.layout {
  display: flex;
  height: 341px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.img-site {
  width: 236px;
  width: 100%;
  height: 114px;
  flex-shrink: 0;
  border-radius: 20px;
  background: url("../../../assets/v3/imgs/img-site.png"),
    lightgray -52.974px -201.78px / 164.776% 279.611% no-repeat;
  backdrop-filter: blur(10px);
}
.title-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.header-project {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.location-layout {
  display: flex;
  align-items: center;
  gap: 6px;
}
.location-name {
  color: var(--gray-primary-600, #757c87);

  /* ENG/Body2 */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.device-layout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.qty-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.device-qty {
  color: var(--gray-primary-50, #ececef);
  text-align: center;

  /* ENG/Headline [H4] */
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.prj-desc {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-self: stretch;
  color: var(--gray-primary-600, #757c87);

  /* ENG/Body3 */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 268px;
}
</style>
