<template>
  <div class="mainlogin">
    <div class="container_login">
      <b-container fluid>
        
        <b-row style="height:485px;">
          <b-col class="con_left">
            <!-- <img src="../assets/LoginPic.png" width="100%" /> -->
          </b-col>
          <b-col class="con_right">
            <div class="loginui">
              <img class="img-logo-login" src="../assets/v3/Logo-HORRUS.png" width="200px" style="border-radius: 41px;" />
              <!-- <div style="margin:5px;padding:5px;">
                <img src="@/assets/imgs/DB2_50x50.png" />
                <h3 class="page-name">LOGIN</h3>
              </div> -->
              <b-form>
                <b-form-group
                  id="input-group-1"
                  label-for="input-1" class="" variant="dark"
                >
                <b-input-group prepend="Username" class="mt-3 input-text-1" variant="dark">
                  <b-form-input
                    id="input-1"
                    v-model="form.userName"
                    type="text"
                    placeholder="Enter Username"
                    required variant="dark"
                  ></b-form-input></b-input-group>
                </b-form-group>
                <b-form-group
                  id="input-group-2"
                  label-for="input-2" class="" variant="dark"
                ><b-input-group prepend="Password" class="mt-3 input-text-1" variant="dark">
                  <b-form-input
                    id="input-2" variant="dark"
                    type="password"
                    v-model="form.passWord" 
                    placeholder="Enter password"
                    required
                  ></b-form-input></b-input-group>
                </b-form-group>
              </b-form>

              <small v-if="!errorAuthen" style="color:red; font-size: 10px;"
                >You are unauthorizd! Please check user and/or password?</small
              >
              <div style="text-align: end;margin-top:20px;">
                <b-button
                  type="submit" pill
                  variant="outline-dark"
                  style="width:200px;"
                  @click="authen"
                  >Submit</b-button
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
export default {
  name: "Login",
  data() {
    return {
      form: {
        userName: null,
        passWord: null,
      },
      errorAuthen: true,
      apiServer: this.$store.state.apiDataServe,
      webAPI : this.$store.state.webAPI,
      apiKey : this.$store.state.apiKey,
    };
  },
  methods: {
    openFullscreen() {
      var elem = document.getElementById("app");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    async onSubmit() {

      let checkData = this.authen();
      // console.log("Checker : ", checkData);
      if (checkData) {
        // console.log("Submitted............");
        this.$store.commit("setLoginState", true);
        this.$store.commit("setUser", this.form.email);
        window.location.href = "/#/";
      }
    },
    async authen() {
      // this.openFullscreen();
      let currentObj = this;
      currentObj.errorAuthen = true;
      // console.log("call : " + this.apiServer + "/box/v2/status/" + this.boxID);
      await this.axios
        .post(this.webAPI + "/authorization", {
        // .post(this.apiServer + "/authorization", {
          user: this.form.userName,
          key: this.form.passWord,
        }) // + this.boxID)
        .then((response) => {
          console.log("res_code : ", response);
          if (response.status == 201) {
            // // console.log("Submitted............");
            sessionStorage.setItem("setLoginState", true);
            sessionStorage.setItem("setAPIKey", response.data.apiKey);
            sessionStorage.setItem("setUser", response.data.username);
            sessionStorage.setItem("setcustomerID", response.data.cid);
            // sessionStorage.setItem("setFullName", currentObj.form.userName);
            // sessionStorage.setItem("setOwner", response.data.company);
            // sessionStorage.setItem("setLoginState", true);
            // sessionStorage.setItem("setAssetBoxes", true);
            currentObj.$store.commit("setAPIKey", response.data.apiKey);
            currentObj.$store.commit("setLoginState", true);
            currentObj.$store.commit("setUser", response.data.username);
            // currentObj.$store.commit("setUser", currentObj.form.userName);
            // currentObj.$store.commit("setFullName", response.data.fullName);
            currentObj.$store.commit("setcustomerID", response.data.cid);
            sessionStorage.setItem("setSessionEndURL", '/#/');
            currentObj.$store.commit("setSessionEndURL", '/#/');
            this.$store.commit("setUserLevel", response.data.accessLevel);
            sessionStorage.setItem("setUserLevel", response.data.accessLevel);
            // currentObj.$store.commit("setOwner", response.data.company);
            // window.location.href = "/#/";
          } else if (response.status == 404) {
            swal
              .fire({
                icon: "error",
                title: "API Server Connection Failed!",
                text: " Cannot access to API Server, Please contact system administrator.",
                showCancelButton: false,
                confirmButtonText: `Ok`,
              })
              
              .then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "/#/";
                }
                // else{
                //   window.location.href = "/#/";
                // }
              })
              .catch(e => console.error(e));
          }else {
            currentObj.errorAuthen = false;
          }
        })
        .catch(function(error) {
          // currentObj.output2 = error;
          // console.log("Request Post Error!!!!!!!!!!!!!!!!!!!!!!!!");
          console.warn(error);
          currentObj.errorAuthen = false;
        });
      // this.caltime();
    },
  },
};
</script>

<style scoped>
.page-name {
  color: white;
  display: inline;
  margin-left: 1rem;
}
.mainlogin {
  width: 100vw;
  height: 100vh;
  background-color: rgb(95, 95, 95);
  /* background: black; */
  margin: auto;
  padding: auto;
}
.container_login {
  width: 1000px;
  height: 450px;
  /* background-color: aqua; */
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  overflow: hidden;
  box-shadow: rgba(5, 5, 5, 0.35) 0px 5px 15px;
}
.con_right {
  background-color: rgba(0, 11, 44, 1);
  height: 100%;
  /* background: var(--opacity-gray-seconddary-90060, rgba(13, 13, 13, 0.6)); */
  /* backdrop-filter: blur(10px); */
  border-radius: 0px 50px 50px 0px;
}
.con_left {
  background-color: rgb(255, 255, 255);
  height: 100%;
  position: relative;
  padding: 0px;
  margin: 0px;
  border-radius: 50px 0px 0px 50px;
  overflow: hidden;
  
  background-image:
    linear-gradient( 0.25turn,rgba(245, 246, 252, 0.0), rgba(245, 246, 252, 0.52), rgba(0, 11, 44, 1)),
    url('../assets/LoginPic.png');
}
.loginui {
  width: 400px;
  /* height: 250px; */
  background-color: rgba(255, 255, 255, 0.89); 
  backdrop-filter: blur(10px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: start;
  border-radius: 20px;
  padding: 50px 20px;
  position: relative;
  border-color: white;
  box-shadow: rgba(5, 5, 5, 0.35) 0px 5px 15px;
}
.img-logo-login{
  position: absolute;
  /* border: 1px solid black; */
  top: -30px;
}

.loginui span {
  border-radius: 50%;
  margin-right: 0.5rem;
  font-size: 2rem;
  display: inline;
  color: white;
}
.input-text-1{
  border-radius: 20px;
}
@media only screen and (max-width: 1000px) {
  .con_left {
    display: none;
  }
  .container_login {
    width: 100%;
    /* background: red; */
  }
  .loginui {
    width: auto;
  }
  .con_right {
    /* width:50%; */
    margin: 0 3rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid white;
    border-radius: 1rem;
  }
  .page-name {
    color: rgba(0, 11, 44, 1);
  }
  .mainlogin {
    background-color: rgba(0, 11, 44, 1);
  }
}
</style>
