<template>
  <div class="logout">
    <div class="logout-content" v-if="confirm">
      <b-spinner type="grow" label="Loading..."></b-spinner>
      <small> Signing Out...</small>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import swal from "sweetalert2";
export default {
  name: "Logout",
  data() {
    return {
      confim: false,
    };
  },
  mounted() {
    this.confirmation();
  },
  methods: {
    async confirmation() {
      // deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
      await swal
        .fire({
          icon: "warning",
          title: "Please confirm that, Do you want to Sign-out?",
          showCancelButton: true,
          confirmButtonText: `Confirm`,
          target: document.getElementById("app"),
        })

        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.confirm = true;
            setTimeout(this.logout_process, 1500);
            // this.$swal.fire("Deleted!", item.missionID, "error");
            swal.fire({
              //position: 'top-end',
              icon: "success",
              title: "Signed out.",
              showConfirmButton: false,
              timer: 1500,
              target: document.getElementById("app"),
            });
          } else {
            history.back();
          }
        })
        .catch((e) => console.error(e));
    },
    logout_process() {
      // sessionStorage.clear();
      sessionStorage.clear();
      this.$store.commit("setLoginState", false);
      // let sessionEndURL = this.$store.state.sessionEndURL;
      // let sessionEndURL = sessionStorage.getItem("setSessionEndURL");
      // this.$router.push({ path: "home" });
      // window.location.href = "/#/";
      window.location.href = this.$store.state.sessionEndURL;
    },
  },
};
</script>
<style scoped>
.logout-content {
  position: absolute;
  display: flexbox;
  align-content: center;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  min-width: 20%;
  text-align: center;
  background: var(--main-color);
  color: var(--text-grey);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
