<template>
  <div :class="{ 'card-qty-active': isActive, 'card-qty': !isActive}">
    <div class="qty-group-val">
      <h1 class="val-qty" :class="{ active: isActive}">{{ value }}</h1>
      <small class="title-qty" :class="{ active: isActive}">{{ title }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
    value: Number,
    title: String,
  },
};
</script>

<style scoped>
.card-qty:hover,
.card-qty-active:hover {
  cursor: pointer;
  /* border: solid 1px #6778ff; */
  background: var(--opacity-gray-secondary-90070, rgba(13, 13, 13, 0.7));
}
.siteop-qty {
  display: flex;
  width: 368px;
  height: 58px;
  align-items: flex-start;
  gap: 8px;
}
.card-qty-active {
  display: flex;
  padding: 7.5px 0px 6.5px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 12px;
  background: var(--opacity-gray-secondary-90070, rgba(13, 13, 13, 0.7));
  backdrop-filter: blur(10px);
}
.card-qty {
  display: flex;
  padding: 7.5px 3.7px 6.5px 2.5px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
}
.qty-group-val {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: -4px;
}
.val-qty {
  color: var(--gray-primary-600, #757c87);
  text-align: center;

  /* ENG/Headline [H5] */
  font-size: 20px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 30px;
}
.title-qty {
  margin: -10px;
  color: var(--gray-primary-600, #757c87);
  text-align: center;

  /* ENG/Body3 */
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
}
.active{
    color: var(--primary-400, #6778ff);
}
</style>
