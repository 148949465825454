<template>
  <div class="card-dev-info">
    <div class="card-dev-container">
      <img class="img-logo" :src="types[devType].imgLogo" />
      <div class="card-dev-data-container">
        <div class="header-device-container">
          <div class="data-header-container">
            <img class="img_icon" :src="types[devType].icon" />
            <h1 class="header-text">{{ devName }}</h1>
          </div>
          <div class="mission-name-text" v-if="isOnlineCheck">
            {{ taskData.missionName }}
          </div>
        </div>
        <div class="data-content-container">
          <p v-if="deviceData" class="data-last-time">
            <!-- {{ displayDate(deviceData.time.value) }} -->
            Last Updated : {{ timeString }}
          </p>
          <!-- <b-icon icon="camera-video-fill" aria-hidden="true"></b-icon> -->
          <div class="data-status-info">
            <div
              v-if="deviceInfo.type === 'drone' && deviceData"
              class="status-online-offline"
              :class="{
                online:
                  deviceData.system.data.drone_active.value && isOnlineCheck && !isInMission,
                offline: !(
                  deviceData.system.data.drone_active.value && isOnlineCheck
                ),
                onMission:deviceData.system.data.drone_active.value && isOnlineCheck && isInMission,
              }"
            >
              <p
                v-if="deviceData && !isInMission"
                class="text-device"
                :class="{
                  'online-text':
                    deviceData.system.data.drone_active.value && isOnlineCheck,
                  'offline-text': !(
                    deviceData.system.data.drone_active.value && isOnlineCheck
                  ),
                }"
              >
                {{
                  deviceData.system.data.drone_active.value && isOnlineCheck
                    ? "Online"
                    : "Offline"
                }}
              </p>
              <p
                v-if="deviceData && isInMission"
                class="text-device onMission-text">
                mission
              </p>
            </div>
            <div
              v-if="deviceInfo.type === 'box' && deviceData"
              class="status-online-offline"
              :class="{
                online: (isOnlineCheck && !isInMission),
                offline: (!(isOnlineCheck) && !isInMission),
                onMission:(isOnlineCheck && isInMission)
              }"
            >
              <p class="text-device" v-if="!isInMission">
                {{ isOnlineCheck ? "Online" : "Offline" }}
              </p>
              <p class="text-device  onMission-text" v-if="isInMission">
                mission
              </p>
            </div>
            <div class="data-power-info">
              <b-icon
                class="data-power-text"
                icon="plug-fill"
                scale="2"
                aria-hidden="true"
              ></b-icon>
              <b-icon
                class="data-power-text"
                icon="battery-charging"
                scale="2"
                aria-hidden="true"
              ></b-icon>
              <small
                v-if="deviceInfo.type === 'drone' && deviceData"
                class="data-power-text"
                >{{ deviceData.system.data.battery_voltage.value }} v</small
              >
              <small
                v-if="deviceInfo.type === 'box' && deviceData"
                class="data-power-text"
                >{{ deviceData.UPS.data.batt.value }} %</small
              >
            </div>
          </div>
          <div
            v-if="deviceInfo.type === 'drone' && isOnlineCheck && isInMission"
            class="progress-layout"
          >
          <!-- <div class="progress-layout"> -->
            <div class="bar-back">
              <div
                class="progress-bar"
                :style="'width:' + Math.floor(missionProgress) + '%;'"
              ></div>
              <img
                class="progress-icon"
                :style="'left:' + Math.floor(missionProgress) + '%;'"
                height="22px"
                src="../../../assets/v3/icons/progress_icon.svg"
              />
            </div>
            <h3 class="percent-text">{{ Math.floor(missionProgress) }}%</h3>
          </div>
          <!-- <div class="data-status-info2">
            <b-button variant="outline-dark">More</b-button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";
import mock_data from "../../../assets/test/mock_status.json";
import mock_task from "../../../assets/test/mock_task_info.json";
export default {
  props: {
    isOnline: Boolean,
    devType: String,
    devName: String,
    lastUpdate: Number,
    isAcAvailable: Boolean,
    batteryState: String,
    batteryPercent: Number,
    deviceInfo: Object,
  },
  data() {
    return {
      isOnlineCheck: false,
      types: {
        drone: {
          imgLogo: "/imgs/v3/imgs/Drone_image.png",
          icon: "/imgs/v3/icons/uav1_icon.svg",
          namespace: "/drone/status",
        },
        box: {
          imgLogo: "/imgs/v3/imgs/Station_image.png",
          icon: "/imgs/v3/icons/station_icon.svg",
          namespace: "/station/status",
        },
        iot: {
          imgLogo: "/imgs/v3/imgs/mcp_image.png",
          icon: "/imgs/v3/icons/iot_icon.svg",
          namespace: "",
        },
      },
      deviceData: mock_data,
      connector: null,
      deviceKey: "",
      apiKey: this.$store.state.apiKey,
      webAPI: this.$store.state.webAPI,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
      task_id: null,
      taskData: mock_task,
      checkOnlineInerval: null,
      timeString: null,
      isInMission: false,
      missionProgress: 0.0,
      hir: {
        key: "",
        value: false,
        timeout: 0.0,
        msg: "",
      },
    };
  },
  watch: {
    // task_id(newVal, oldVal) {
    task_id() {
      this.getTaskInfo();
    },
  },
  mounted() {
    if (this.customerID == null) {
      this.customerID = sessionStorage.getItem("setcustomerID");
    }
    if (this.apiKey == null) {
      this.apiKey = sessionStorage.getItem("setAPIKey");
    }
    if (this.sioServer == null) {
      this.sioServer = sessionStorage.getItem("setSioServer");
    }
    this.connector = io(
      this.sioServer + this.types[this.deviceInfo.type].namespace,
      {
        auth: { deviceID: this.userName, token: this.apiKey },
        transports: [ "websocket" ],
      }
    );
    // this.connector.on("connected", (data) => this.connect2device(data));
    this.connector.on("connect", this.connect2device);
    this.connector.on("status", (data) => this.onStatus(data));
    this.OnlineChecking();
    this.checkOnlineInerval = setInterval(this.OnlineChecking, 500);
    // this.connect2device();
  },
  beforeDestroy() {
    clearInterval(this.checkOnlineInerval);
  },
  methods: {
    async getTaskInfo() {
      let currentObj = this;
      var config = {
        method: "get",
        url: this.webAPI + "/app/api/task?taskID=" + this.task_id,
        headers: {
          authorization: this.apiKey,
        },
      };

      await this.axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          currentObj.taskData = response.data.tasks[0];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    OnlineChecking() {
      var date = new Date(this.deviceData.time.value);
      // var d = new Date();
      let ms = new Date().getTime(); //d.getMilliseconds();
      let currentObj = this;
      var diff_ms = ms - parseFloat(this.deviceData.time.value);
      // console.log('ms dif : [', ms, ' | ', parseFloat(this.deviceData.time.value), ' = ', diff_ms);
      // if (ms - parseFloat(this.deviceData.time.value) > 5000.0) {
      if (diff_ms > 5000.0) {
        currentObj.isOnlineCheck = false;
      } else {
        currentObj.isOnlineCheck = true;
      }

      if (diff_ms < 1000.0) {
        currentObj.timeString = "A seconed ago.";
      } else if (diff_ms < 60000.0) {
        currentObj.timeString = Math.floor(diff_ms / 1000) + " seconeds ago.";
      } else if (diff_ms < 300000.0) {
        currentObj.timeString = "A fews minutes ago.";
      } else if (diff_ms < 3600000.0) {
        currentObj.timeString = Math.floor(diff_ms / 300000) + " minutes ago.";
      } else if (diff_ms < 86400000.0) {
        currentObj.timeString = Math.floor(diff_ms / 3600000) + " hours ago.";
      } else {
        currentObj.timeString =
          date.toDateString() + " | " + date.toLocaleTimeString();
      }
    },
    displayDate(time) {
      var date = new Date(time);
      var d = new Date();
      let ms = d.getMilliseconds();
      let currentObj = this;
      var diff_ms = ms - parseFloat(time);
      if (ms - parseFloat(time) > 5000.0) {
        currentObj.isOnlineCheck = false;
      } else {
        currentObj.isOnlineCheck = true;
      }

      if (diff_ms < 1000) {
        return "A seconed ago.";
      } else if (diff_ms < 60000) {
        return Math.floor(diff_ms / 1000) + " seconeds ago.";
      } else if (ms - parseFloat(time) < 300000) {
        return "A fews minutes ago.";
      } else if (ms - parseFloat(time) < 3600000) {
        return Math.floor(diff_ms / 300000) + " minutes ago.";
      } else if (ms - parseFloat(time) < 86400000) {
        return Math.floor(diff_ms / 3600000) + " hours ago.";
      } else {
        return date.toDateString() + " | " + date.toLocaleTimeString();
      }
    },
    async connect2device() {
      let currentObj = this;
      this.$forceUpdate();

      console.log("AssetID : ", this.deviceInfo.aid);
      currentObj.deviceKey = await this.getKey(this.deviceInfo.aid);

      if (this.connector.connected) {
        console.log("SIO Connected");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.connector.emit("join", {
          token: this.apiKey,
          room: currentObj.deviceKey,
        });

        console.log("Asset ID : ", this.deviceInfo.aid, " Join");
      }
    },
    async getKey(device_id) {
      let currentObj = this;
      var deviceKey = null;
      await this.axios
        .post(this.sioServer + "/auth/getkey?code=" + device_id, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          deviceKey = response.data.key;
          console.log(deviceKey);
          currentObj.$store.commit("setDeviceKey", deviceKey);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      return deviceKey;
    },
    onStatus(data) {
      let currentObj = this;
      currentObj.deviceData = data.data;
      if (this.deviceInfo.type === "drone") {
        currentObj.$emit("callbackDroneStatus", currentObj.deviceData);
        // currentObj.$emit("callbackDroneStatus", currentObj.deviceData, currentObj.OnlineChecking);
        currentObj.task_id = this.deviceData.task.data.task_id.value;
        if (
          this.deviceData.task.data.mission_progress_total.value > 0 &&
          this.deviceData.task.data.mission_progress_current.value > -1
        ) {
          currentObj.isInMission = true;
          try {
            currentObj.missionProgress = Math.floor(
              (this.deviceData.task.data.mission_progress_current.value /
                this.deviceData.task.data.mission_progress_total.value) *
                100
            );
          } catch {
            console.log("Cannot Calculate Progress");
          }
        } else {
          currentObj.isInMission = false;
        }
      } else if (this.deviceInfo.type === "box") {
        // currentObj.$emit("callbackBoxStatus", currentObj.deviceData, currentObj.OnlineChecking);
        currentObj.$emit("callbackBoxStatus", currentObj.deviceData);
        currentObj.task_id = this.deviceData.task.data.taskID.value;
        if (this.deviceData.task.data.available.value === "Available") {
          currentObj.isInMission = false;
        } else {
          currentObj.isInMission = true;
        }
        currentObj.hir = this.deviceData.task.hir;
      }
      // console.log(data);
      // console.log('AID : ' + this.deviceInfo.aid + ' | ' + data);
    },
  },
};
</script>

<style scoped>
.progress-bar {
  /* position: absolute; */
  /* width: 163px; */
  border-radius: 5px;

  background: linear-gradient(0.25turn, #6778ff, #6778ff, #2636db);
  height: 5px;
  top: 50%;
  margin: 0px;
  /* transform: translate(-50%, -50%); */
}
.percent-text {
  display: flex;
  width: 36px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--gray-primary-50, #ececef);
  text-align: right;
  /* ENG/Subtitle2 */
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.progress-icon {
  position: absolute;
  /* width: 22.938px; */
  /* height: 22px; */
  top: 50%;
  transform: translate(-50%, -50%);
}
.bar-back {
  position: relative;
  width: 163px;
  height: 5px;
  padding: 0px;
  margin: 0px;
  flex-shrink: 0;
  background: #171e28;
  border-radius: 5px;
}
.progress-layout {
  display: flex;
  width: 220px;
  align-items: center;
  gap: 12px;
}
.header-device-container {
  display: flex;
  width: 220px;
  flex-direction: column;
  align-items: flex-start;
}
.mission-name-text {
  display: flex;
  width: 212px;
  flex-direction: column;

  color: var(--gray-50, #ececef);
  /* ENG/Subtitle2 */
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.card-dev-info {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  border-radius: 24px;
  border: 1px solid var(--gray-primary-900, #1f2937);
  background: var(--opacity-gray-secondary-90080, rgba(13, 13, 13, 0.8));
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
    0px 12px 24px -4px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(10px);
}
.card-dev-container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.img-logo {
  width: 100px;
  height: 99px;
  flex-shrink: 0;
  border-radius: 14px;
}
.card-dev-data-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.data-header-container {
  display: flex;
  width: 212px;
  align-items: center;
  gap: 8px;
}
.img-icon {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.header-text {
  color: var(--gray-primary-50, #ececef);

  /* ENG/Subtitle1 */
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 24px;
}
.data-content-container {
  display: flex;
  width: 220px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.data-last-time {
  display: flex;
  width: 212px;
  height: 17px;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-primary-600, #757c87);

  /* ENG/Body3 */
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
}
.data-status-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
.data-status-info {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
}
.data-power-info {
  display: flex;
  align-items: center;
  gap: 4px;
}
.status-online-offline {
  display: flex;
  height: 20px;
  width: 50px;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  /* border: 1px solid var(--primary-400, #6778ff); */
  background: var(--opacity-primary-50020, rgba(53, 73, 255, 0.2));
  /* ENG/Body3 */
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;

  border-radius: 6px;
  border: 1px solid var(--blue-secondary-400, #33a0ce);
  background: var(--opacity-blue-secondary-40020, rgba(51, 160, 206, 0.2));

  position: relative;
}
.text-device {
  color: var(--blue-secondary-400, #33a0ce);
  /* ENG/Body4 */

  font-size: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.online {
  border-radius: 6px;
  border: 1px solid var(--blue-secondary-400, #33a0ce);
  background: var(--opacity-blue-secondary-40020, rgba(51, 160, 206, 0.2));
}
.offline {
  border-radius: 6px;
  border: 1px solid var(--gray-primary-700, #5b626f);
  background: var(--opacity-gray-primary-70020, rgba(91, 98, 111, 0.2));
}
.onMission {
  border-radius: 6px;
  border: 1px solid var(--primary-400, #6778ff);
  background: var(--opacity-primary-50020, rgba(53, 73, 255, 0.2));
}
.online-text {
  color: var(--blue-secondary-400, #33a0ce);
}
.offline-text {
  color: var(--gray-primary-700, #5b626f);
}
.onMission-text {
  color: var(--primary-400, #6778ff);
}
.data-power-text {
  display: flex;
  /* width: 29px; */
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-primary-50, #ececef);

  /* ENG/Body3 */
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
}
</style>
