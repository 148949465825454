var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-dev-info"},[_c('div',{staticClass:"card-dev-container"},[_c('img',{staticClass:"img-logo",attrs:{"src":_vm.types[_vm.devType].imgLogo}}),_c('div',{staticClass:"card-dev-data-container"},[_c('div',{staticClass:"header-device-container"},[_c('div',{staticClass:"data-header-container"},[_c('img',{staticClass:"img_icon",attrs:{"src":_vm.types[_vm.devType].icon}}),_c('h1',{staticClass:"header-text"},[_vm._v(_vm._s(_vm.devName))])]),(_vm.isOnlineCheck)?_c('div',{staticClass:"mission-name-text"},[_vm._v(" "+_vm._s(_vm.taskData.missionName)+" ")]):_vm._e()]),_c('div',{staticClass:"data-content-container"},[(_vm.deviceData)?_c('p',{staticClass:"data-last-time"},[_vm._v(" Last Updated : "+_vm._s(_vm.timeString)+" ")]):_vm._e(),_c('div',{staticClass:"data-status-info"},[(_vm.deviceInfo.type === 'drone' && _vm.deviceData)?_c('div',{staticClass:"status-online-offline",class:{
              online:
                _vm.deviceData.system.data.drone_active.value && _vm.isOnlineCheck && !_vm.isInMission,
              offline: !(
                _vm.deviceData.system.data.drone_active.value && _vm.isOnlineCheck
              ),
              onMission:_vm.deviceData.system.data.drone_active.value && _vm.isOnlineCheck && _vm.isInMission,
            }},[(_vm.deviceData && !_vm.isInMission)?_c('p',{staticClass:"text-device",class:{
                'online-text':
                  _vm.deviceData.system.data.drone_active.value && _vm.isOnlineCheck,
                'offline-text': !(
                  _vm.deviceData.system.data.drone_active.value && _vm.isOnlineCheck
                ),
              }},[_vm._v(" "+_vm._s(_vm.deviceData.system.data.drone_active.value && _vm.isOnlineCheck ? "Online" : "Offline")+" ")]):_vm._e(),(_vm.deviceData && _vm.isInMission)?_c('p',{staticClass:"text-device onMission-text"},[_vm._v(" mission ")]):_vm._e()]):_vm._e(),(_vm.deviceInfo.type === 'box' && _vm.deviceData)?_c('div',{staticClass:"status-online-offline",class:{
              online: (_vm.isOnlineCheck && !_vm.isInMission),
              offline: (!(_vm.isOnlineCheck) && !_vm.isInMission),
              onMission:(_vm.isOnlineCheck && _vm.isInMission)
            }},[(!_vm.isInMission)?_c('p',{staticClass:"text-device"},[_vm._v(" "+_vm._s(_vm.isOnlineCheck ? "Online" : "Offline")+" ")]):_vm._e(),(_vm.isInMission)?_c('p',{staticClass:"text-device  onMission-text"},[_vm._v(" mission ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"data-power-info"},[_c('b-icon',{staticClass:"data-power-text",attrs:{"icon":"plug-fill","scale":"2","aria-hidden":"true"}}),_c('b-icon',{staticClass:"data-power-text",attrs:{"icon":"battery-charging","scale":"2","aria-hidden":"true"}}),(_vm.deviceInfo.type === 'drone' && _vm.deviceData)?_c('small',{staticClass:"data-power-text"},[_vm._v(_vm._s(_vm.deviceData.system.data.battery_voltage.value)+" v")]):_vm._e(),(_vm.deviceInfo.type === 'box' && _vm.deviceData)?_c('small',{staticClass:"data-power-text"},[_vm._v(_vm._s(_vm.deviceData.UPS.data.batt.value)+" %")]):_vm._e()],1)]),(_vm.deviceInfo.type === 'drone' && _vm.isOnlineCheck && _vm.isInMission)?_c('div',{staticClass:"progress-layout"},[_c('div',{staticClass:"bar-back"},[_c('div',{staticClass:"progress-bar",style:('width:' + Math.floor(_vm.missionProgress) + '%;')}),_c('img',{staticClass:"progress-icon",style:('left:' + Math.floor(_vm.missionProgress) + '%;'),attrs:{"height":"22px","src":require("../../../assets/v3/icons/progress_icon.svg")}})]),_c('h3',{staticClass:"percent-text"},[_vm._v(_vm._s(Math.floor(_vm.missionProgress))+"%")])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }